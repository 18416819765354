import { Box } from '@chakra-ui/react';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

const handleDropdownIndicator = (showSearchIcon: boolean) => (
  <Box
    as={showSearchIcon ? FiSearch : IoClose}
    color="primary.500"
    ml={2}
    mr={2}
    size={showSearchIcon ? '1.5rem' : '1.7rem'}
  />
);

export default handleDropdownIndicator;
