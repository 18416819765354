import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CameraIcon = (props: IconProps) => (
  <Icon viewBox="0 0 29 22" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <circle cx="17.707" cy="12.3" r="4.5" />
      <path d="M25.74 3.9h-1.58a.64.64 0 01-.577-.415C22.84 1.92 22.075.3 20.407.3h-6c-1.286 0-1.964.934-3.191 2.63-.461.632-.612.97-1.013.97h-7.2C.527 3.9.007 5.35.007 6.568v12.465A2.705 2.705 0 003.074 21.9h22.665a2.705 2.705 0 003.068-2.867V6.568c0-1.217-.532-2.668-3.068-2.668zm-1.133 8.4a6.9 6.9 0 11-6.9-6.9 6.907 6.907 0 016.9 6.9zm-18.6-3.9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
      <path d="M3.007 2.68l3.6.02a.6.6 0 00.6-.6v-.3a1.8 1.8 0 00-1.8-1.8h-1.2a1.8 1.8 0 00-1.8 1.8v.28a.6.6 0 00.6.6z" />
    </g>
  </Icon>
);

export default CameraIcon;
