import React from 'react';
import { VStack } from '@chakra-ui/react';

import { Link } from '~/src/components/Link';
import { ExternalLink, Maybe } from '~/src/api';

const YedLinks: React.FC<{ links: Maybe<ExternalLink>[] }> = ({ links }) => {
  const linkStack = links?.map((link, i) => {
    if (!link) return null;

    const { url, text, newWindow } = link;

    if (newWindow) {
      return (
        <Link key={`yed-link-${i}`} href={url} isExternal>
          {text}
        </Link>
      );
    }

    return (
      <Link key={`yed-link-${i}`} to={url}>
        {text}
      </Link>
    );
  });

  return (
    <VStack spacing={2} paddingTop={4}>
      {linkStack}
    </VStack>
  );
};

export default YedLinks;
