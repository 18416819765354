{
  "common": {
    "app_name": "Digivastaanotto",
    "app_organization": "Duodecim",
    "close": "Sulje",
    "continue": "Jatka",
    "dropdownDefaultPlaceholder": "Valitse vaihtoehto",
    "navigateBack": "Palaa takaisin",
    "search": {
      "label": "Hae",
      "placeholder": "Hae...",
      "noResults": "Ei tuloksia"
    },
    "charactersRemaining": "Merkkejä jäljellä",
    "logoutButton": "Kirjaudu ulos",
    "skipToContentLinkText": "Siirry suoraan sisältöön"
  },
  "errors": {
    "upcomingMaintenance": "Palvelussa on alkamassa pian huoltotauko, eikä uuden kyselyn aloittaminen ole siksi nyt mahdollista. Huoltotauko on ohi viimeistään klo",
    "generic": {
      "title": "Tapahtui odottamaton virhe",
      "message": "Hups, jotain meni pieleen. Yritä myöhemmin uudelleen."
    },
    "network": {
      "title": "Yhteysvirhe",
      "message": "Tarkista että olet yhteydessä internetiin."
    },
    "errorCard": {
      "title": "Hups, jokin meni pieleen!",
      "paragraph": "Sisältö jota haet ei ole saatavilla.",
      "ctaLabel": "Palaa etusivulle"
    },
    "sessionClosed": {
      "title": "Istunto on suljettu",
      "message": "Sisältö ei ole enää saatavilla."
    },
    "answerLocked": {
      "title": "Vastaus on lukittu",
      "message": "Hakemasi vastaus on lukittu, eikä sitä voi enää muuttaa.",
      "ctaLabel": "Seuraava avoin kysymys"
    },
    "validation": {
      "invalidEmail": "Sähköpostiosoite on virheellinen",
      "invalidPhone": "Puhelinnumero on virheellinen",
      "invalidValue": "Virheellinen syöte"
    }
  },
  "languageSelect": {
    "fi": "Suomeksi",
    "en": "In English",
    "sv": "På svenska"
  },
  "infoModal": {
    "title": "Ohjeita Digivastaanoton käyttöön",
    "paragraphContent": [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam magni ratione eius suscipit minima ipsa eos! Sint nisi maiores, molestias aut iste, repudiandae iure sed cum nostrum a rem. Labore.",
      "Another paragraph here..."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "intro": {
    "title": "Tervetuloa Digivastaanotolle",
    "paragraph": "Kerro meille muutama esitieto, <1>niin pääset ohituskaistaa hoitoon.</1>",
    "linkText": "Tietoja digivastaanotosta"
  },
  "flowInit": {
    "title": "Mitä asiasi koskee?",
    "paragraph": [],
    "privacyAcceptance": "Hyväksyn antamieni tietojen käsittelyn <linkOut>tietosuojaselosteen</linkOut> mukaisesti",
    "buttonLabels": {
      "healthIssue": "Terveysongelma",
      "appointmentBooking": "Ajanvarausasia",
      "qAndA": "Kysymys tai muu asia"
    }
  },
  "authentication": {
    "callback": {
      "loading": "Tarkistetaan tietoja..."
    }
  },
  "healthIssueFlow": {
    "instructions": {
      "title": "Ohjeita",
      "firstParagraph": "Kysymme sinulta muutaman kysymyksen, jotta saamme kuvan tilanteestasi. Aloita kertomalla ikäsi ja sukupuolesi.",
      "highlightedContent": "<0>Jos tunnet olosi todella heikoksi tai epäilet olevasi vakavasti sairas, älä täytä oirearviota vaan ota yhteyttä päivystykseen tai soita 112.</0>",
      "lastParagraph": "Tarvitset kyselyn lopuksi sähköisen tunnistautumisen. <0>Lue lisäohjeita</0>."
    },
    "basicInfo": {
      "title": "Perustiedot",
      "paragraphContent": [],
      "ageInput": {
        "label": "Ikä",
        "errorMessage": "Tarkista ikä"
      },
      "genderSelect": {
        "label": "Sukupuoli",
        "options": [
          {
            "value": "Male",
            "label": "Mies"
          },
          {
            "value": "Female",
            "label": "Nainen"
          }
        ]
      }
    },
    "symptomIntro": {
      "title": "Vastaa kyselyyn",
      "paragraphContent": [
        "Kysymme sinulta muutaman tarkentavan kysymyksen oireisiisi liittyen. Vastaa parhaan kykysi mukaisesti, ja jos et tiedä varmaksi, valitse ”en tiedä”.",
        "Voit lopuksi kertoa vielä avoimena tekstinä tarkentavia tietoja."
      ]
    },
    "symptomSelect": {
      "title": "Millaisia oireita sinulla on?",
      "select": {
        "ariaLabel": "Valitse millaisia oireita sinulla on",
        "placeholder": "Kirjoita oire...",
        "customOptionText": "Oma oire: Ohita kysely ja kerro oireestasi omin sanoin"
      }
    },
    "question": {
      "responseOptions": {
        "yes": "Kyllä",
        "no": "Ei",
        "unsure": "En tiedä"
      },
      "symptomSpanLabel": "Oireilun kesto",
      "additionalInfoButtonLabel": "Kerro lisätietoja halutessasi",
      "additionalInfoTextarea": {
        "label": "Lisätietoja",
        "placeholder": "Kirjoita lisätietoja"
      }
    },
    "alarmingSymptom": {
      "title": "Oireesi saattaa vaatia kiireellistä hoitoa",
      "paragraph": "Emme valitettavasti voi auttaa sinua etähoidossa. Hakeudu pikaisesti päivystysapuun, tai jos tilasi on vakava soita hätänumeroon.",
      "emergencyCarePrimaryLabel": "Valtakunnallinen päivystysapu",
      "emergencyCarePrimaryPhoneNumber": "116 117",
      "emergencyNumberLabel": "Hätänumero",
      "submitContinueLabel": "Ymmärrän, mutta haluan jatkaa",
      "submitLabel": "Lopeta"
    },
    "healthIssueDuration": {
      "title": "Oireiden kesto",
      "durationOptionsLabel": "Oireiden kesto"
    },
    "healthIssueInfo": {
      "title": "Lisätiedot",
      "description": "Kerro omin sanoin miten ammattilainen voi olla sinulle parhaiten avuksi.",
      "textarea": {
        "label": "Lisätiedot",
        "placeholder": "Anna halutessasi lisätietoja"
      }
    },
    "symptomOverview": {
      "title": "Tarkista vastauksesi",
      "paragraph": "Voit halutessasi palata muuttamaan valitsemaasi vastausta."
    },
    "thankYouForAnswering": {
      "title": "Kiitos vastauksistasi!",
      "paragraphContent": [
        "Kiitos, nämä tiedot riittävät hyvin! Pyydämme sinua tunnistautumaan seuraavaksi ennen kun välitämme vastauksesi ammattilaiselle."
      ]
    },
    "authentication": {
      "title": "Tunnistautuminen",
      "paragraph": "Tunnistautumisen jälkeen et voi enää muuttaa vastauksiasi.",
      "mobileCertificate": "Mobiilivarmenne",
      "initError": "Tunnistautumisen aloitus ei onnistunut. Yritä uudelleen.",
      "retryError": "Tunnistautuminen ei onnistunut. Yritä uudelleen."
    },
    "authenticationSuccess": {
      "title": "Tunnistautuminen onnistui!",
      "name": "Nimi",
      "personalSecurityNumber": "Hetu",
      "phoneInput": {
        "label": "Matkapuhelinnumero",
        "placeholder": "040 123 4567"
      },
      "contactMethodSelect": {
        "label": "Miten haluat että sinuun otetaan yhteyttä?",
        "options": [
          {
            "value": "phoneCall",
            "label": "Vain puhelimitse"
          },
          {
            "value": "phoneCallOrSMS",
            "label": "Puhelimitse tai tekstiviestillä"
          }
        ]
      }
    },
    "confirm": {
      "title": "Kiitos vastauksistasi, valmista tuli!",
      "paragraph": "Sinuun otetaan yhteyttä mahdollisimman pian."
    },
    "fastlane": {
      "title": "Kerro oireistasi omin sanoin",
      "issueDescription": {
        "label": "Kuvaus oireista",
        "placeholder": "Vapaamuotoinen kuvaus oireista ja niiden kestosta"
      },
      "buttonLabel": "Ohita kysely ja kerro oireistasi omin sanoin"
    }
  },
  "qAndAFlow": {
    "search": {
      "title": "Miten voimme auttaa?",
      "FAQ": "Usein kysytyt ja ajankohtaiset aiheet",
      "showAllResults": "Näytä kaikki",
      "noResultsLabel": "Ei tuloksia. Anna palautetta puuttuvasta sisällöstä"
    },
    "results": {
      "newQuestionButtonText": "Kysy uusi kysymys"
    }
  },
  "feedback": {
    "rating": {
      "label": "Miten hyvin pystyimme auttamaan sinua?",
      "options": {
        "1": "1 tähti",
        "2": "2 tähteä",
        "3": "3 tähteä",
        "4": "4 tähteä",
        "5": "5 tähteä"
      }
    },
    "input": {
      "labels": {
        "lowValue": "Voi harmi, miten olisimme voineet toimia paremmin?",
        "highValue": "Haluatko jättää vielä palautetta?"
      },
      "placeholder": "Anna vapaamuotoista palautetta"
    },
    "submitLabel": "Valmis"
  },
  "symptomDurationOptions": {
    "1": "Alle vuorokausi",
    "2": "1-3 vuorokautta",
    "3": "4-7 vuorokautta",
    "4": "Yli viikon tai pidempään"
  },
  "chat": {
    "user": {
      "pro": "Ammattilainen",
      "client": "Asiakas",
      "generic": "Käyttäjä"
    },
    "intro": {
      "title": "Chat-keskustelu",
      "firstParagraph": "Kun saat ohjauksen etähoitoon, saat chat-tunnisteen tekstiviestillä. Anna saamasi tunniste allaolevaan kentään ja pääset hoitoon.",
      "secondParagraph": "Mikäli sinulla ei ole chat-tunnistetta, siirry <1>Terveysongelma-hoitopolkuun</1>."
    },
    "init": {
      "inputLabel": "Chat-tunniste",
      "inputPlaceholder": "CID-1234-ABCD",
      "buttonLabel": "Aloita Chat"
    },
    "discussion": {
      "title": "Tervetuloa vastaanotolle!",
      "endDiscussion": "Lopeta keskustelu",
      "atYourService": "Sinua palvelee",
      "messageUnreadText": "Lukematta",
      "messageInputPlaceholder": "Kirjoita...",
      "messageAttachmentButtonText": "Lue liite",
      "endDiscussionAlert": {
        "title": "Oletko varma että haluat poistua keskustelusta?",
        "paragraph": "Käymäsi keskustelu katoaa, etkä voi palata siihen uudelleen.",
        "backButton": "Takaisin",
        "proceedButton": "Poistu"
      },
      "waitingForService": {
        "title": "Palvelemme sinua mahdollisimman pian",
        "paragraph": "Voit halutessasi aloittaa keskustelun odottaessasi asiakaspalvelijaamme."
      },
      "sessionEnded": {
        "message": "{{user}} poistui keskustelusta"
      }
    },
    "errors": {
      "generic": {
        "title": "Tapahtui virhe",
        "message": "Keskusteluun ei juuri nyt voida liittyä, yritä myöhemmin uudelleen."
      }
    }
  },
  "payment": {
    "title": "Maksaminen",
    "emailLabel": "Sähköpostiosoite",
    "doctorChat": "Chat tohtori",
    "doctorPhone": "Puhelin tohtori",
    "choosePaymentMethod": "Ole hyvä ja valitse maksutapa",
    "startpayment": "Siirry maksamiseen",
    "bank": "Verkkopankkimaksu",
    "creditcard": "Korttimaksut",
    "mobile": "Mobiilimaksu",
    "credit": "Maksa luotolla",
    "buttonText": "Maksa",
    "successMessage": "Maksu onnistui!",
    "successErrorTitle": "Maksutapahtuman tallennus epäonnistui",
    "successErrorDescription": "Maksu onnistui, mutta maksutapahtumaa ei voitu tallentaa. Ota yhteyttä asiakaspalveluumme.",
    "errorEmailTitle": "Maksutapahtuma epäonnistui",
    "errorEmailBody": "Maksutapahtuma epäonnistui. Kyselyn Id: {{answerId}}",
    "stateNotProcessed": "",
    "failedTitle": "Maksu keskeytyi",
    "failedDescription": "Maksu epäonnistui. Jos keskeytit maksutapahtuman, voit yrittää maksaa uudelleen. Jos maksu epäonnistui muusta syystä, ota yhteyttä asiakaspalveluumme.",
    "failedTryAgainButton": "Yritä uudelleen",
    "failedReturnLinkText": "Keskeytä",
    "callbackLoading": "Tarkistetaan maksutietoja...",
    "error": {
      "title": "Maksu epäonnistui. Maksua ei voitu luoda.",
      "description": "Maksua ei voitu luoda"
    },
    "serviceTermsModalTitle": "Palvelun käyttöehdot",
    "serviceTerms": "ja palvelun",
    "serviceTerms1": "käyttöehdot"
  },
  "calendly": {
    "showBookingModalButton": "Varaa aika",
    "noCalendlyEventUrl": "Emme löytäneet ajanvarauslinkkiä. Ota yhteyttä asiakaspalveluumme.",
    "errorEmailTitle": "Ajanvaraus epäonnistui",
    "errorEmailBody": "Ajanvaraus epäonnistui. Kyselyn Id: {{answerId}}"
  }
}
