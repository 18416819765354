import React from 'react';
import { VStack } from '@chakra-ui/react';
import { IMessage, ChatUsertype } from '~/src/types';
import ChatMessage from '../ChatMessage';

interface MessageThreadProps {
  messages?: IMessage[] | null | undefined;
  currentUsertype: ChatUsertype;
  otherUserIsOnline: boolean;
}

const MessageThread: React.FC<MessageThreadProps> = ({
  messages,
  currentUsertype,
  otherUserIsOnline,
}) => {
  return (
    <VStack marginTop={16} spacing={6}>
      {messages &&
        messages
          .sort((a, b) => {
            return new Date(a.time).valueOf() - new Date(b.time).valueOf();
          })
          .map(({ ...props }) => {
            return (
              <ChatMessage
                key={`chat-message_${props.id}`}
                currentUsertype={currentUsertype}
                otherUserIsOnline={otherUserIsOnline}
                {...props}
              />
            );
          })}
    </VStack>
  );
};

export default MessageThread;
