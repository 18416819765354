import { z } from 'zod';
import { merge, isObject } from 'lodash';
import { devlogger } from '.';

const localStorageSchema = z.object({
  siteTokenExp: z.number().optional(),
});
export type LocalStorage = z.infer<typeof localStorageSchema>;

/* Local storage functions */

export function setLocalStorage<T extends keyof LocalStorage>(
  key: T,
  value: LocalStorage[T],
): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage<T extends keyof LocalStorage>(
  key: T,
): LocalStorage[T] | null {
  const item = localStorage.getItem(key);
  if (!item) return null;
  try {
    const parsed = JSON.parse(item) as unknown;
    const result = localStorageSchema.shape[key].parse(parsed);
    return result as LocalStorage[T];
  } catch (error) {
    devlogger(
      'Error parsing local storage item. Removing it from local storage',
      error,
    );
    removeLocalStorage(key);
    return null;
  }
}

export function removeLocalStorage<T extends keyof LocalStorage>(key: T): void {
  localStorage.removeItem(key);
}

export function upsertLocalStorage<T extends keyof LocalStorage>(
  key: T,
  value: LocalStorage[T],
): void {
  const current = getLocalStorage(key);
  if (!current) return setLocalStorage(key, value);
  // Merge only works for objects
  const newValue =
    isObject(current) && isObject(value) ? merge(current, value) : value;
  setLocalStorage(key, newValue);
}

/* Session storage functions */
const sessionStorageSchema = z.object({
  redirect_url: z.string(), // .url() - not added cause its too strict
});

export type SessionStorage = z.infer<typeof sessionStorageSchema>;

export function setSessionStorage<T extends keyof SessionStorage>(
  key: T,
  value: SessionStorage[T],
): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorage<T extends keyof SessionStorage>(
  key: T,
): SessionStorage[T] | null {
  const item = sessionStorage.getItem(key);
  if (!item) return null;
  try {
    const parsed = JSON.parse(item) as unknown;
    const result = sessionStorageSchema.shape[key].parse(parsed);
    return result as SessionStorage[T];
  } catch (error) {
    devlogger(
      'Error parsing session storage item. Removing it from session storage',
      error,
    );
    removeSessionStorage(key);
    return null;
  }
}

export function removeSessionStorage<T extends keyof SessionStorage>(
  key: T,
): void {
  sessionStorage.removeItem(key);
}

export function upsertSessionStorage<T extends keyof SessionStorage>(
  key: T,
  value: SessionStorage[T],
): void {
  const current = getSessionStorage(key);
  if (!current) return setSessionStorage(key, value);
  // Merge only works for objects
  const newValue =
    isObject(current) && isObject(value) ? merge(current, value) : value;
  setSessionStorage(key, newValue);
}
