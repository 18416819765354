import React, { useState } from 'react';
import { Box, Button, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import defaultImage from '~/src/images/image-bank/symptom-inquiry.svg';
import { CardContentStack } from '~/src/components/Card';
import {
  AnswerInput,
  FlowResponse,
  QuestionType,
  YedQuestion,
} from '~/src/api';
import { surveyFlowRoot } from '~/src/constants';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemTitle } from './components/FlowItemTitle';
import Modal from '~src/components/Modal';
import {
  useCalendlyEventListener,
  InlineWidget,
  EventScheduledEvent,
} from 'react-calendly';
import { KUURA_CUSTOMER_SERVICE_EMAIL } from './Payment';
import { devlogger } from '~src/utils';
import {
  FlowItemMultiFields,
  isAllQuestionAnswered,
} from './components/FlowItemMultiFields';
import { useValidateAllYedQuestionAnswers } from '~src/hooks/useYedQuestionValidation';

const Calendly: React.FC<FlowResponse> = React.memo(({ id, flowItem }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [multiFieldValues, setMultiFieldValues] = useState<AnswerInput[]>([]);
  const { title, question, imgUrl, description, calendly } = flowItem || {};
  const { buttons, inputs } = question as YedQuestion;

  const { addAnswer } = useAddAnswer(surveyFlowRoot);
  const { validateAllAnswers } = useValidateAllYedQuestionAnswers(inputs);

  useCalendlyEventListener({
    onEventScheduled: (e: EventScheduledEvent) => {
      devlogger('onEventScheduled - eventUri:', e.data.payload.event.uri);
      handleBookingAnswer(e.data.payload.event.uri);
    },
  });

  function handleBookingAnswer(bookedEventUri: string) {
    addAnswer({
      variables: {
        id: id ?? '',
        answer: [
          ...multiFieldValues,
          {
            name: 'yed-button-option',
            type: QuestionType.ButtonOptions,
            value: buttons[0]?.value.toString(), // button value represents the "edgeNumber"
          },
          {
            name: 'bookedEventUri',
            type: QuestionType.Text,
            value: bookedEventUri,
          },
        ],
      },
    });
  }

  if (!id) return null;

  const imageToShow = imgUrl || defaultImage;

  const allQuestionsAnswered = isAllQuestionAnswered(
    inputs || [],
    multiFieldValues,
  );
  const isAnswersValid = validateAllAnswers(multiFieldValues);

  return (
    <CardContentStack className="calendly-item">
      <FlowItemTitle title={title} />
      <FlowItemImage imgUrl={imageToShow} />
      <FlowItemMultiFields
        multiFieldValues={multiFieldValues}
        setMultiFieldValues={setMultiFieldValues}
        inputs={inputs}
      />
      <FlowItemDescription description={description} />
      <Button
        isDisabled={!allQuestionsAnswered || !isAnswersValid}
        onClick={() => setShowModal(!showModal)}
        minWidth="unset"
      >
        {t('calendly.showBookingModalButton')}
      </Button>
      <Modal
        modalIsOpen={showModal}
        onCloseModal={() => setShowModal(false)}
        title={undefined}
        size="6xl"
        footerProps={{ display: 'none' }}
      >
        {calendly?.calendlyEventUrl ? (
          <InlineWidget
            url={calendly.calendlyEventUrl}
            styles={{ height: '690px' }}
            prefill={{
              name: calendly?.name || '',
              email: calendly?.customerEmail || '',
            }}
          />
        ) : (
          <Stack>
            <Box>{t('calendly.noCalendlyEventUrl')}</Box>
            <Box>
              <a
                href={`mailto:${KUURA_CUSTOMER_SERVICE_EMAIL}?subject=${t(
                  'calendly.errorEmailTitle',
                )}&body=${t('calendly.errorEmailBody', { answerId: id })}`}
                style={{ marginRight: 2 }}
              >
                {KUURA_CUSTOMER_SERVICE_EMAIL}
              </a>
            </Box>
          </Stack>
        )}
      </Modal>
    </CardContentStack>
  );
});

export { Calendly };
