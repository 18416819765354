{
  "common": {
    "app_name": "Digineuvola",
    "app_organization": "Digineuvola"
  },
  "intro": {
    "title": "Tervetuloa digineuvolaan!",
    "paragraph": "Digineuvolasta löydät tietoa raskausaikaan, lasten kasvatukseen ja vanhemmuuteen sekä lasten tavallisimpiin sairauksiin ja oireisiin.",
    "collections": {
      "heading": "Tutustu tietopankkeihimme",
      "items": [
        {
          "icon": "butterfly",
          "title": "Odottavan äidin käsikirja",
          "description": "Lapsen hankkimista suunnitteleville ja raskaana oleville tietoa raskaudesta ja synnytyksestä"
        },
        {
          "icon": "teddybear",
          "title": "Imeväisiän tavallisia ilmiöitä",
          "description": "Lastenlääkärit kertovat, millaiset ilmiöt ovat tavallisia imeväisiässä"
        },
        {
          "icon": "butterfly",
          "title": "100 kysymystä lastenlääkärille",
          "description": "Tavallisimpia kysymyksiä, jotka tulevat esille lastenlääkärin vastaanotolla."
        },
        {
          "icon": "teddybear",
          "title": "Kotineuvola",
          "description": "Aiheita, jotka tulevat vastaan jokaisessa perheessä ennen kuin lapsi on kouluikäinen"
        }
      ]
    }
  }
}
