// Note: The contents of this file is used in the backend to generate the Excel report
import { Gender, Report, ReportAnswer } from '~/src/api';

// Severity is only used in backend when creating the report
const SeverityDescriptions = {
  0: { severity: 'Ei oireita', detailedSeverity: 'Ei oireita' },
  [-1]: {
    severity: 'Kliinisesti merkitsevät oireet',
    detailedSeverity: 'Kliinisesti merkitseviä',
  },
  1: {
    severity: 'Lieviä / keskivaikeita oireita',
    detailedSeverity: 'Lieviä',
  },
  2: {
    severity: 'Lieviä / keskivaikeita oireita',
    detailedSeverity: 'Kohtalaisia',
  },
  3: { severity: 'Vakavia oireita', detailedSeverity: 'Vakavia' },
} as const;

type Severity = keyof typeof SeverityDescriptions;

type Limit = {
  min: number;
  max: number;
  text: string;
  severity?: Severity;
  gender?: Gender;
};

export const TestIds = [
  'phq',
  'gad',
  'spin',
  'pdss',
  'keds',
  'oci',
  'ies',
  'audit',
  'sci',
  'scoff',
  'auditYouth',
  'auditFull',
  'bbgs',
  'bat',
  'tsq',
  'dudit',
  'adsume',
] as const;

export type TestId = (typeof TestIds)[number];

// Frontend texts are defined in i18n translation files. Test limit text is used
// in the backend when generating Excel report, name and longName are not used
// anywhere
export const TestScores: Record<
  TestId,
  {
    name?: string;
    longName?: string;
    limits: Limit[];
    maxPoints: number;
  }
> = {
  phq: {
    name: 'PHQ-9',
    longName: 'Masennuskysely',
    limits: [
      {
        min: 0,
        max: 4,
        text: 'viittaa normaaliin mielialaan',
        severity: 0,
      },
      {
        min: 5,
        max: 9,
        text: 'viittaa <strong>lieviin / vähäisiin masennusoireisiin</strong>',
        severity: 1,
      },
      {
        min: 10,
        max: 14,
        text: 'viittaa <strong>lievä-asteisiin masennusoireisiin</strong>',
        severity: 1,
      },
      {
        min: 15,
        max: 19,
        text: 'viittaa <strong>keskivaikeisiin masennusoireisiin</strong>',
        severity: 2,
      },
      {
        min: 20,
        max: 27,
        text: 'viittaa <strong>vaikea-asteisiin masennusoireisiin</strong>',
        severity: 3,
      },
    ],
    maxPoints: 27,
  },
  gad: {
    name: 'GAD-7',
    longName: 'Ahdistuneisuuskysely',
    limits: [
      {
        min: 0,
        max: 4,
        text: 'viittaa vähäiseen ahdistuneisuuteen',
        severity: 0,
      },
      {
        min: 5,
        max: 9,
        text: 'viittaa <strong>lievään ahdistuneisuuteen</strong>',
        severity: 1,
      },
      {
        min: 10,
        max: 14,
        text: 'viittaa <strong>kohtalaiseen ahdistuneisuuteen</strong>',
        severity: 2,
      },
      {
        min: 15,
        max: 21,
        text: 'viittaa <strong>vaikeaan ahdistuneisuuteen </strong>',
        severity: 3,
      },
    ],
    maxPoints: 21,
  },
  oci: {
    name: 'OCI-R',
    longName: 'Pakko-oirekysely',
    limits: [
      {
        min: 0,
        max: 20,
        text: 'ei viittaa kliinisesti merkitsevään pakko-oiretasoon',
        severity: 0,
      },
      {
        min: 21,
        max: 72,
        text: 'viittaa <strong>kliinisesti merkitsevään pakko-oiretasoon.</strong>',
        severity: -1,
      },
    ],
    maxPoints: 72,
  },
  pdss: {
    name: 'PDSS-SR',
    longName: 'Paniikkioirekysely',
    limits: [
      {
        min: 0,
        max: 1,
        text: 'ei viitteitä merkittävistä paniikkioireista',
        severity: 0,
      },
      {
        min: 2,
        max: 5,
        text: 'viittaa <strong>lievään paniikkioireiluun.</strong>',
        severity: 1,
      },
      {
        min: 6,
        max: 9,
        text: 'viittaavat <strong>kohtalaiseen paniikkioireiluun</strong>',
        severity: 2,
      },
      {
        min: 10,
        max: 13,
        text: 'viittaa <strong>kohtalaisen vakavaan paniikkioireiluun</strong>',
        severity: 2,
      },
      {
        min: 14,
        max: 16,
        text: 'viittaa <strong>vakavaan paniikkioireiluun</strong>',
        severity: 3,
      },
      {
        min: 17,
        max: 28,
        text: 'viittaa <strong>erittäin vakavaan paniikkioireiluun. </strong>',
        severity: 3,
      },
    ],
    maxPoints: 28,
  },
  spin: {
    name: 'SPIN-FIN',
    longName: 'Sosiaalisen jännittämisen kysely',
    limits: [
      {
        min: 0,
        max: 18,
        text: 'viittaa normaaliin / vähäiseen sosiaalisten tilanteiden jännittämiseen',
        severity: 0,
      },
      {
        min: 19,
        max: 29,
        text: 'viittaa <strong>kohtalaiseen sosiaalisten tilanteiden jännittämiseen</strong>',
        severity: 1,
      },
      {
        min: 30,
        max: 39,
        text: 'viittaa <strong>voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>',
        severity: 3,
      },
      {
        min: 40,
        max: 68,
        text: 'viittaa <strong>hyvin voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>',
        severity: 3,
      },
    ],
    maxPoints: 68,
  },
  audit: {
    name: 'AUDIT-C',
    longName: 'Alkoholinkäytön kysely',
    limits: [
      { min: 0, max: 4, text: 'alle riskirajan' },
      { min: 5, max: 5, text: 'miehillä alle riskirajan', gender: Gender.Male },
      {
        min: 5,
        max: 5,
        text: 'viittaa <strong>naisilla riskikäyttöön</strong>',
        gender: Gender.Female,
      },
      {
        min: 6,
        max: 12,
        text: 'viittaa <strong>riskikäyttöön</strong>',
      },
    ],
    maxPoints: 12,
  },
  auditFull: {
    name: 'AUDIT',
    longName: 'Alkoholinkäytön kysely',
    limits: [
      { min: 0, max: 0, text: 'ei alkoholin käyttöä' },
      {
        min: 1,
        max: 6,
        text: '<strong>alkoholin käytön riskit vähäisiä</strong>',
      },
      {
        min: 7,
        max: 7,
        text: '<strong>miehillä alkoholin käytön riskit vähäisiä</strong>',
        gender: Gender.Male,
      },
      {
        min: 7,
        max: 7,
        text: '<strong>naisilla alkoholin käytön riskit lievästi kasvaneet</strong>',
        gender: Gender.Female,
      },
      {
        min: 8,
        max: 10,
        text: '<strong>alkoholin käytön riskit lievästi kasvaneet</strong>',
      },
      {
        min: 11,
        max: 14,
        text: '<strong>alkoholin käytön riskit selvästi kasvaneet</strong>',
      },
      {
        min: 15,
        max: 19,
        text: '<strong>alkoholin käytön riskit suuria</strong>',
      },
      {
        min: 20,
        max: 40,
        text: '<strong>riskit erittäin suuria (mahdollinen alkoholiriippuvuus)</strong>',
      },
    ],
    maxPoints: 40,
  },
  ies: {
    name: 'IES-R',
    longName: 'Traumaperäisen stressin kysely',
    limits: [
      {
        min: 0,
        max: 32,
        text: 'ei viittaa kliinisesti merkittävään traumaperäiseen stressiin',
        severity: 0,
      },
      {
        min: 33,
        max: 88,
        text: 'voi viitata <strong>kliinisesti merkittävään traumaperäiseen stressiin</strong>',
        severity: -1,
      },
    ],
    maxPoints: 88,
  },
  keds: {
    name: 'KEDS',
    longName: 'Uupumuskysely',
    limits: [
      {
        min: 0,
        max: 18,
        text: 'ei viittaa kliinisesti merkittävään uupumukseen',
        severity: 0,
      },
      {
        min: 19,
        max: 54,
        text: 'viittaa <strong>kliinisesti merkittävään uupumukseen</strong>',
        severity: -1,
      },
    ],
    maxPoints: 54,
  },
  sci: {
    name: 'SCI',
    longName: 'Unikysely',
    limits: [
      {
        min: 0,
        max: 16,
        text: '<strong>kliinisesti merkittävä unihäiriön epäily</strong>',
        severity: -1,
      },
      {
        min: 17,
        max: 32,
        text: 'ei viitteitä kliinisesti merkittävästä unihäiriöstä',
        severity: 0,
      },
    ],
    maxPoints: 32,
  },
  scoff: {
    name: 'SCOFF',
    longName: 'Syömishäiriöseula',
    limits: [
      {
        min: 0,
        max: 1,
        text: 'ei viitteitä kliinisesti merkittävistä syömiskäyttäytymisen haasteista',
        severity: 0,
      },
      {
        min: 2,
        max: 5,
        text: 'viittaa <strong>kliinisesti merkittäviin syömiskäyttäytymisen haasteisiin</strong>',
        severity: -1,
      },
    ],
    maxPoints: 5,
  },
  auditYouth: {
    name: 'AUDIT',
    longName: 'Alkoholinkäytön kysely',
    limits: [
      {
        min: 0,
        max: 0,
        text: 'ei viitteitä alkoholin käytöstä',
        severity: 0,
      },
      {
        min: 1,
        max: 40,
        text: '<strong>viitteitä alkoholin käytöstä</strong>',
        severity: -1,
      },
    ],
    maxPoints: 40,
  },
  bbgs: {
    name: 'BBGS',
    longName: 'Peliriippuvuuden seulakysely',
    limits: [
      {
        min: 0,
        max: 0,
        text: 'ei viittaa rahapeliriippuvuuteen',
        severity: 0,
      },
      {
        min: 1,
        max: 3,
        text: 'viittaa <strong>mahdolliseen rahapeliriippuvuuteen</strong>',
        severity: -1,
      },
    ],
    maxPoints: 3,
  },
  bat: {
    name: 'BAT-12',
    longName: 'Työuupumus kysely',
    limits: [
      {
        min: 0,
        max: 30,
        text: 'Ei työuupumusoireita',
        severity: 0,
      },
      {
        min: 31,
        max: 36,
        text: 'viittaa <strong>kohonneeseen riskiin työuupua</strong>',
        severity: 1,
      },
      {
        min: 37,
        max: 60,
        text: 'viittaa <strong>todennäköiseen työuupumukseen</strong>',
        severity: 2,
      },
    ],
    maxPoints: 60,
  },
  tsq: {
    name: 'TSQ',
    longName: 'Traumaperäisen stressin kysely',
    limits: [
      {
        min: 0,
        max: 5,
        text: 'ei viitteitä traumaperäisen stressihäiriön riskiin',
        severity: 0,
      },
      {
        min: 6,
        max: 10,
        text: 'viittaa <strong>kohonneeseen traumaperäisen stressihäiriön riskiin</strong>',
        severity: -1,
      },
    ],
    maxPoints: 10,
  },
  dudit: {
    name: 'DUDIT',
    longName: 'Huumeiden käytön riski',
    limits: [
      {
        min: 0,
        max: 0,
        text: 'ei huumeista johtuvia ongelmia',
        severity: 0,
      },
      {
        min: 1,
        max: 1,
        text: '<strong>satunnaisesti huumeista johtuvia ongelmia</strong>',
        severity: 1,
      },
      {
        min: 2,
        max: 5,
        text: '<strong>miehillä satunnaisesti huumeista johtuvia ongelmia</strong>',
        severity: 1,
        gender: Gender.Male,
      },
      {
        min: 2,
        max: 5,
        text: '<strong>naisilla todennäköisesti huumeista johtuvia ongelmia</strong>',
        severity: 2,
        gender: Gender.Female,
      },
      {
        min: 6,
        max: 24,
        text: '<strong>todennäköisesti huumeista johtuvia ongelmia</strong>',
        severity: 2,
      },

      {
        min: 25,
        max: 44,
        text: '<strong>todennäköisesti voimakas huumeriippuvuus</strong>',
        severity: 3,
      },
    ],
    maxPoints: 44,
  },
  adsume: {
    name: 'ADSUME',
    longName: 'Päihdekysely',
    limits: [
      {
        min: 0,
        max: 3,
        text: '- 14-15v: Viittaa raittiuteen tai kokeilukäyttöön<br>- 16-18v: Viittaa raittiuteen tai kokeilukäyttöön',
        severity: 0,
      },
      {
        min: 4,
        max: 6,
        text: '- 14-15v: Viittaa toistuvaan käyttöön<br>- 16-18v: Viittaa toistuvaan käyttöön',
        severity: 1,
      },
      {
        min: 7,
        max: 9,
        text: '- 14-15v: Viittaa riskikäyttöön<br>- 16-18v: Viittaa riskikäyttöön',
        severity: 2,
      },
      {
        min: 10,
        max: 12,
        text: '- 14-15v: Viittaa vaaralliseen kulutukseen<br>- 16-18v: Viittaa riskikäyttöön',
        severity: 3,
      },

      {
        min: 13,
        max: Infinity,
        text: '- 14-15v: Viittaa vaaralliseen kulutukseen<br>- 16-18v: Viittaa vaaralliseen kulutukseen',
        severity: 3,
      },
    ],
    maxPoints: Infinity,
  },
};

export function calculateLimit(data: Report, testId: TestId) {
  // Audit is special case since it may be short or full length
  testId = convertAudit(data.answers, testId);

  const points = calculatePoints(data, testId);
  if (points === null) return { points: null };

  const testScores = TestScores[testId];
  const { limit, idx } = findLimit(testScores.limits, points, data.gender);
  return { limit, idx, points };
}

// Audit is special case since it may be short (3 questions) or full
// (10 questions) and both have same audit id
// Set testId to auditFull only if the full questionnaire has been answered
export function convertAudit(answers: ReportAnswer[], testId: TestId) {
  if (testId === 'audit' && answers.some((a) => a.questionId === 'audit-10'))
    testId = 'auditFull';

  return testId;
}

function calculatePoints(data: Report, testId: string): number | null {
  let points = 0;
  let hasUserAnsweredThisTest = false;

  // Audit is special case since it may be short (3 questions) or full
  // (10 questions) and both have same audit id
  if (testId === 'auditFull') testId = 'audit';

  for (const answer of data.answers) {
    // When yEd files are parsed the node id's are converted to lower case
    if (answer.questionId?.startsWith(testId.toLowerCase())) {
      hasUserAnsweredThisTest = true;
      // Checkbox may have multiple comma separated values in answerValue
      const pointsFromAnswer = answer.answerValue
        ?.split(', ')
        .reduce((sum, a) => sum + Number(a), 0);
      // Only add points if answerValue is a proper number (note: if it is null
      // then 0 is added, i.e. it's not really added).
      // This is required to skip audit-sukupuoli which has undefined as
      // answerValue
      if (pointsFromAnswer != null && !Number.isNaN(pointsFromAnswer))
        points += pointsFromAnswer;
    }
  }

  if (hasUserAnsweredThisTest === false) return null;
  return points;
}

function findLimit(limits: Limit[], points: number, gender?: Gender | null) {
  const idx = limits.findIndex(
    (limit) =>
      points >= limit.min &&
      points <= limit.max &&
      (limit.gender === undefined || limit.gender === gender),
  );
  if (idx === -1) throw new Error('Questionnaire limit not found!');
  const limit = limits[idx];
  return { limit, idx };
}

export function getTestId(
  questionId: string | undefined | null,
): [TestId | null, string | null] {
  if (typeof questionId !== 'string') return [null, null];
  const [prefix, idx] = questionId.split('-');
  // When yEd files are parsed the node id's are converted to lower case
  const testId = TestIds.find((t) => t.toLowerCase() === prefix);
  if (testId == null) return [null, null];
  return [testId, idx ?? null];
}

// Only used in backend when creating the report
export function getSeverityDescription(severity: Severity) {
  return SeverityDescriptions[severity];
}
