import React from 'react';

const formatOptionLabel = (
  { label }: { label: string },
  { inputValue }: { inputValue: string },
) => {
  const highlighted = label.replace(
    new RegExp(inputValue, 'gi'),
    (highlighted: string) => `<strong>${highlighted}</strong>`,
  );
  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
};

export default formatOptionLabel;
