import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Heading, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SearchField from '~/src/components/SearchField';
import Card from '~/src/components/Card';
import ladybug from '~/src/images/neuvola/ladybug.png';
import collectionIcons from '~/src/images/collection-icons';
import type { Options } from '~/src/api';

const HomeNeuvola: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = (option: Options) => {
    history.push(`/question/${option.value}`);
  };

  return (
    <Container px={6}>
      <Heading size="xl" lineHeight="125%">
        {t('intro.title')}
      </Heading>

      <Heading size="md" mt={16} mb={9}>
        {t('qAndAFlow.search.title')}
      </Heading>

      <SearchField
        selectSurveyOption={handleChange}
        showUntilOptionsVisible={Collections()}
      />
      <Image
        position="absolute"
        right={0}
        top={32}
        src={ladybug}
        width="60px"
        height="81px"
        fit="cover"
        align="0% 50%"
      />
    </Container>
  );
};

function Collections() {
  const { t } = useTranslation();

  // Get collections shown under the search field
  const collections = (t('intro.collections.items', { returnObjects: true }) ||
    []) as Array<{ icon: string; title: string; description: string }>;

  return (
    <>
      <Container textAlign="center" mt={12} px={6}>
        {t('intro.paragraph')}
      </Container>

      <Heading size="md" mt={16} mb={9}>
        {t('intro.collections.heading')}
      </Heading>
      {collections.map((card, idx) => (
        <Collection
          key={idx}
          icon={collectionIcons[card.icon]}
          title={card.title}
          description={card.description}
        />
      ))}
    </>
  );
}

function Collection({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}): React.ReactElement {
  return (
    <Card my={5}>
      <Container m={2} centerContent>
        <Image src={icon} maxWidth="122px" my={3} />
        <Heading size="m" color="primary.600">
          {title}
        </Heading>
        <Text textAlign="center" mb={4} size="xs">
          {description}
        </Text>
      </Container>
    </Card>
  );
}

export default HomeNeuvola;
