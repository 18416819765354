import mobileid from '~/src/images/authentication/mobileid.png';
import aktia from '~/src/images/authentication/aktia.svg';
import alandsbanken from '~/src/images/authentication/alandsbanken.svg';
import danskebank from '~/src/images/authentication/danske-bank.svg';
import handelsbanken from '~/src/images/authentication/handelsbanken.svg';
import nordea from '~/src/images/authentication/nordea.svg';
import omasp from '~/src/images/authentication/omasp.svg';
import oplogo from '~/src/images/authentication/op-logo.svg';
import poppankki from '~/src/images/authentication/pop-pankki.svg';
import saastopankki from '~/src/images/authentication/saastopankki.svg';
import spankki from '~/src/images/authentication/spankki.svg';

// Defining the type for authenticationImages
export type AuthenticationImagesType = {
  [key: number]: string;
};

export const authenticationImages = {
  0: mobileid,
  1: aktia,
  2: alandsbanken,
  3: danskebank,
  4: handelsbanken,
  5: nordea,
  6: omasp,
  7: oplogo,
  8: poppankki,
  9: saastopankki,
  10: spankki,
};
