import React, { FC, ReactNode } from 'react';
import { Text } from '@chakra-ui/react';

export const Subtitle: FC<{
  children?: ReactNode;
  mt?: number;
  toUpper?: boolean;
}> = ({ children, mt, toUpper }) => {
  return (
    <Text as="h3" size="md" mt={mt ?? 6} mb={2} fontWeight="bold">
      {typeof children === 'string' && toUpper !== false
        ? children.toUpperCase()
        : children}
    </Text>
  );
};
