import React from 'react';
import { Heading, Text, Box, TextProps } from '@chakra-ui/react';
import Container from '~/src/components/Container';
import Card from '~/src/components/Card';
import { Link } from '~src/components/Link';

export const AccessibilityStatementFI: React.FC<{
  site: 'Terapianavigaattori.fi' | 'Interventionavigaattori.fi';
}> = ({ site }) => {
  return (
    <Container>
      <Card>
        <Box textAlign="left" p={[4, 6, 8]}>
          <Heading as="h1" size="xl" pb={8}>
            Saavutettavuus­seloste
          </Heading>
          <CustomText>
            Tässä saavutettavuusselosteessa kerrotaan, miten {site}
            -verkkosivustolla noudatetaan lakia digitaalisten palvelujen
            tarjoamisesta, mitä puutteita sivuston saavutettavuudessa on ja
            miten voit antaa meille palautetta saavutettavuusongelmista.
          </CustomText>
          <CustomText>
            Tästä sivustosta vastaa HUS-yhtymä. Haluamme, että mahdollisimman
            moni käyttäjä pystyy käyttämään digitaalisia palvelujamme. Otamme
            saavutettavuuden huomioon digitaalisten palvelujemme kehityksessä.
          </CustomText>
          <CustomText>
            Olemme arvioineet palvelun saavutettavuuden itse. Verkkosivusto
            täyttää suurelta osin lain vaatimat A- ja AA-tason
            saavutettavuuskriteerit (WCAG 2.1), eikä sivustolla ole todettu
            kriittisiä saavutettavuuspuutteita.
          </CustomText>
          <SubTitle>Huomasitko puutteita saavutettavuudesta?</SubTitle>
          <CustomText>
            Pyrimme jatkuvasti parantamaan sivuston saavutettavuutta. Jos löydät
            ongelmia, joita ei ole kuvattu tällä sivulla, ilmoita niistä meille
            ja teemme parhaamme puutteiden korjaamiseksi. Vastaamme 14 päivän
            kuluessa.
          </CustomText>
          <CustomText>
            Voit ottaa yhteyttä sähköpostitse osoitteella
            terapiatetulinjaan(at)hus.fi.
          </CustomText>
          <SubTitle>Saavutettavuuden valvonta</SubTitle>
          <CustomText>
            Etelä-Suomen aluehallintovirasto valvoo saavutettavuusvaatimusten
            toteutumista. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa
            vastausta lainkaan kahden viikon aikana, voit antaa palautteen
            Etelä-Suomen aluehallintovirastoon. Etelä-Suomen
            aluehallintoviraston sivulla kerrotaan tarkasti, miten valituksen
            voi tehdä ja miten asia käsitellään.
          </CustomText>
          <CustomText>
            Etelä-Suomen aluehallintovirasto
            <br />
            Saavutettavuuden valvonnan yksikkö
            <br />
            <Link href="https://www.saavutettavuusvaatimukset.fi" isBold>
              www.saavutettavuusvaatimukset.fi
            </Link>
            <br />
            saavutettavuus(at)avi.fi
            <br />
            puhelinnumero vaihde 0295 016 000
            <br />
          </CustomText>
          <SubTitle>Tarkemmat tiedot teknisestä saavutettavuudesta</SubTitle>
          <CustomText>
            Sivuston saavutettavuudessa ei ole tunnistettu puutteita.
          </CustomText>
          <CustomText>
            Sivusto on julkaistu 15.5.2023.
            <br />
            Tämä seloste on laadittu 15.5.2023. <br />
            Selostetta on päivitetty viimeksi 15.5.2023.
          </CustomText>
          <Link href="https://www.finlex.fi/fi/laki/alkup/2019/20190306">
            Laki digitaalisten palvelujen tarjoamisesta (306/2019)
          </Link>
          <br />
          <Link href="https://www.saavutettavuusvaatimukset.fi/">
            Saavutettavuusvaatimukset-verkkosivusto
          </Link>
        </Box>
      </Card>
    </Container>
  );
};

const SubTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Heading as="h2" size="lg" py={4} textAlign="left">
    {children}
  </Heading>
);

const CustomText: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text pb={4} {...rest}>
    {children}
  </Text>
);
