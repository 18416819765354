import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';

const animation = keyframes`
  0% {
    opacity: .2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: .2;
  }
`;

const TypingDot: React.FC = () => {
  return (
    <Box
      as="span"
      animation={`${animation} 1.1s infinite`}
      backgroundColor="primary.500"
      borderRadius="full"
      display="inline-block"
      height={2}
      marginRight={2}
      width={2}
      sx={{
        '&:nth-of-type(1)': {
          animationDelay: '0s',
        },
        '&:nth-of-type(2)': {
          animationDelay: '0.2s',
        },
        '&:nth-of-type(3)': {
          animationDelay: '0.4s',
        },
      }}
    />
  );
};

export default TypingDot;
