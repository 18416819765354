import React from 'react';
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Box,
  TextProps,
} from '@chakra-ui/react';
import Container from '~/src/components/Container';
import Card from '~/src/components/Card';

export const InterventioPrivacy: React.FC = () => {
  return (
    <Container>
      <Card>
        <Box textAlign="left" p={[4, 6, 8]}>
          <Heading as="h1" size="xl" pb={8}>
            Nuorten Interventio­navigaattori – Tieto­suoja­seloste
          </Heading>
          <CustomText>Tieto­suoja­seloste laadittu 21.2.2023</CustomText>
          <SubTitle>Rekisterinpitäjä</SubTitle>
          <CustomText>
            <strong>Nimi:</strong>
            <br />
            HUS-yhtymä
          </CustomText>
          <CustomText>
            <strong>Osoite:</strong>
            <br />
            HUS-yhtymä,
            <br />
            Stenbäckinkatu 9, Helsinki
            <br />
            PL 100, 00029 HUS
            <br />
          </CustomText>
          <CustomText>
            <strong>Yhteystiedot:</strong>
            <br />
            Puhelinvaihde: 09 4711
            <br />
            Kirjaamon telefax: 09 471 75500
            <br />
            Kirjaamon sähköposti: keskuskirjaamo@hus.fi
            <br />
            Postiosoite: HUS keskuskirjaamo, PL 200, 00029 HUS
          </CustomText>
          <SubTitle>Rekisterin vastuuhenkilö</SubTitle>
          Toimialajohtaja Jesper Ekelund
          <SubTitle>Tietosuojavastaavan yhteystiedot</SubTitle>
          <CustomText>
            <strong>Postiosoite:</strong>
            <br />
            HUS Keskuskirjaamo
            <br />
            PL 200 00029 HUS
          </CustomText>
          <CustomText>
            <strong>Sähköposti:</strong>
            <br />
            eutietosuoja@hus.fi
          </CustomText>
          <SubTitle>Yhteystiedot rekisteriä koskevissa asioissa</SubTitle>
          <CustomText>
            Terapiat etulinjaan -hanke: terapiatetulinjaan@hus.fi
          </CustomText>
          <SubTitle>Henkilötietojen käsittelyn tarkoitus</SubTitle>
          <CustomText>
            Interventionavigaattori on tarkoitettu mielenterveyden ongelmien
            vuoksi sosiaali- ja terveyspalveluihin hakeutuville ja heidän
            mahdollisten huoltajiensa täytettäväksi ennen tuen, hoidon tai
            palvelun tarpeen arviota. Kerättyjä tietoja säilytetään, jotta
            Interventionavigaattoriin vastannut voi luovuttaa ne arvion
            toteuttavalle taholle.
          </CustomText>
          Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.
          <SubTitle>Tietojen käsittelyn perusta</SubTitle>
          <CustomText>
            Henkilötietojen käsittelyn peruste on EU:n yleisen
            tietosuoja-asetuksen 6 artiklan 1 kohta: vastaajan
            Interventionavigaattoriin antamia tietoja käsitellään vastaajan
            nimenomaisella suostumuksella.
          </CustomText>
          <SubTitle>Rekisterin tietosisältö</SubTitle>
          <CustomText pb={0}>
            Nuorelle tarkoitetusta kyselystä tallennettavia tietoja:
          </CustomText>
          <UnorderedList>
            <ListItem>Vastaajan antama kuvaus omasta tilanteestaan</ListItem>
            <ListItem>
              Vastaukset mielenterveyden häiriöitä ja ongelmia kartoittaviin
              mittareihin, vastauksia kysymyksiin mm. toimintakyvystä,
              päihteiden käytöstä, sosiaalisista suhteista ja
              hyvinvointialueesta, jolla vastaaja asuu
            </ListItem>
            <ListItem>
              8-merkkinen koodi, jolla vastaukset ja niistä automaattisesti
              rakentuvan raportin saa kuukauden ajan auki. Kyselyssä ei kerätä
              sen vastaajasta suoria tunnistetietoja.
            </ListItem>
          </UnorderedList>
          <CustomText pt={4} pb={0}>
            Huoltajille tarkoitetusta kyselystä tallennettavia tietoja:
          </CustomText>
          <UnorderedList>
            <ListItem>Vastaajan antama kuvaus omasta huolenaiheestaan</ListItem>
            <ListItem>
              Vastauksia kysymyksiin mm. nuoren havaitusta voinnista,
              käyttäytymisestä, toimintakyvystä ja sosiaalisista suhteista,
              huoltajan avun tarpeesta ja hyvinvointialueesta, jolla vastaaja
              asuu
            </ListItem>
            <ListItem>
              8-merkkinen koodi, jolla vastaukset ja niistä automaattisesti
              rakentuvan raportin saa kuukauden ajan auki. Kyselyssä ei kerätä
              sen vastaajasta suoria tunnistetietoja.
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            8-merkkinen koodi annetaan vastaajalle Interventionavigaattorin
            täytön lopuksi. Vastaaja on itse vastuussa koodin säilyttämisestä,
            eikä kadotettua koodia voida palauttaa.
          </CustomText>
          <SubTitle>Säännönmukaiset tietolähteet</SubTitle>
          <CustomText>
            Tallennettavat tiedot kerätään vastaajalta käyttämällä
            Interventionavigaattori-palvelua tai generoidaan satunnaisesti
            vastaamisen yhteydessä (8-merkkinen vastauksiin viittaava koodi).
          </CustomText>
          <SubTitle>Tietojen vastaanottajaryhmät</SubTitle>
          <CustomText>
            Tiedot tallennetaan Googlen Haminan datakeskukseen.
          </CustomText>
          <CustomText>
            Paikallinen sosiaali- tai terveydenhuollon ammattilainen saa tiedot
            vain, mikäli vastaaja antaa hänelle 8-merkkisen koodin, jolla
            vastaukset ja niistä koostettu raportti voidaan avata kuukauden
            sisällä vastaamisesta.
          </CustomText>
          <CustomText>
            Järjestelmään tallentuviin tietoihin pääsee käsiksi vain
            järjestelmänvalvoja ssh-yhteyden yli, sekä Terapiat
            etulinjaan-projektiryhmä, joka voi ladata Interventionavigaattorin
            käytöstä raportin, jossa tiedot on anonymisoitu.
            Interventionavigaattorin on rakentanut ja sitä ylläpitää Kuura
            Health Oy.
          </CustomText>
          <SubTitle>Tietojen säännönmukaiset luovutukset</SubTitle>
          <CustomText>
            Rekisterinpitäjä ei luovuta tietoja säännönmukaisesti muille
            tahoille. Interventionavigaattorin täyttänyt henkilö, jolla on
            hallussaan 8-merkkinen koodi, voi jakamalla koodin jakaa tietonsa
            haluamalleen kolmannelle taholle.
          </CustomText>
          <SubTitle>Tietojen säilytysaika</SubTitle>
          <CustomText>
            Kerättyjä tietoja säilytetään kolmenkymmenenyhden (31) päivän ajan,
            minkä jälkeen ne anonymisoidaan pysyvästi poistamalla 8-merkkinen
            koodi ja avoimet vastaukset. Näiden tietojen poiston jälkeen tiedot
            eivät enää ole vastaajan käytettävissä.
          </CustomText>
          <SubTitle>Tietojen siirto EU:n tai ETA-alueen ulkopuolelle</SubTitle>
          <CustomText>
            Rekisterinpitäjä ei siirrä tietoja EU:n tai ETA-alueen ulkopuolelle.
          </CustomText>
          <SubTitle>Rekisterin suojauksen periaatteet</SubTitle>
          <CustomText>
            Rekisterin käsittelyssä noudatetaan huolellisuutta ja
            tietojärjestelmien avulla käsiteltävät tiedot suojataan
            asianmukaisesti. Kun rekisteritietoja säilytetään
            Internet-palvelimilla, niiden laitteiston fyysisestä ja
            digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti.
            Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä
            palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden
            kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain
            niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
          </CustomText>
          <SubTitle>Rekisteröidyn oikeudet</SubTitle>
          <CustomText pb={0}>Rekisteröidyllä on seuraavat oikeudet:</CustomText>
          <UnorderedList>
            <ListItem>
              Oikeus saada pääsy omiin henkilötietoihin (15 artikla)
              <br />
              rekisteröity voi tehdä kirjallisen tietopyynnön omista tiedoistaan
            </ListItem>
            <ListItem>
              Oikeus tietojen oikaisemiseen (16 artikla)
              <br />
              rekisteröity voi tehdä kirjallisen oikaisuvaatimuksen
            </ListItem>
            <ListItem>
              Oikeus tietojen poistamiseen (17 artikla)
              <br />
              rekisteröity voi vaatia rekisterin käyttötarkoituksen kannalta
              turhan tai väärän tiedon poistamista
            </ListItem>
            <ListItem>
              Oikeuskäsittelyn rajoittamiseen (18 artikla)
              <br />
              rekisteröity voi tehdä vapaamuotoisen vaatimuksen, joka
              käsitellään aina tapauskohtaisesti
            </ListItem>
            <ListItem>
              Oikeus vastustaa käsittelyä (21 artikla)
              <br />
              rekisteröity voi tehdä vapaamuotoisen vaatimuksen, joka
              käsitellään aina tapaus-kohtaisesti
            </ListItem>
            <ListItem>
              Oikeus siirtää tiedot järjestelmästä toiseen (20 artikla)
              <br />
              rekisteröity voi tehdä vapaamuotoisen vaatimuksen, joka
              käsitellään aina tapaus-kohtaisesti
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            Edellä mainittuja oikeuksia koskevat hakemukset tai vaatimukset
            toimitetaan kohdassa &quot;Yhteystiedot rekisteriä koskevissa
            asioissa&quot; ilmoitetulle taholle.
          </CustomText>
          <CustomText>
            Jos rekisterinpitäjä pystyy osoittamaan, ettei se pysty tunnistamaan
            rekisteröityä, 15–20 artiklaa ei sovelleta, paitsi jos rekisteröity
            näiden artikloiden mukaisia oikeuksiaan käyttääkseen antaa
            lisätietoja, joiden avulla hänet voidaan tunnistaa (11 artikla kohta
            2).
          </CustomText>
          <SubTitle>Oikeus tehdä valitus valvontaviranomaiselle</SubTitle>
          <CustomText>
            Jokaisella rekisteröidyllä on oikeus tehdä valitus
            valvontaviranomaiselle, erityisesti siinä jäsenvaltiossa, jossa
            hänen vakinainen asuinpaikkansa tai työpaikkansa on tai jossa
            väitetty rikkominen on tapahtunut, jos rekisteröity katsoo, että
            häntä koskevien henkilötietojen käsittelyssä rikotaan EU
            tietosuoja-asetusta, sanotun kuitenkaan rajoittamatta muita
            hallinnollisia muutoksenhakukeinoja tai oikeussuojakeinoja.
          </CustomText>
          <CustomText>Valvontaviranomaisen tiedot:</CustomText>
          <CustomText>
            <strong>Tietosuojavaltuutetun toimisto</strong>
            <br />
            https://tietosuoja.fi/etusivu
            <br />
            Käyntiosoite: Lintulahdenkuja 4, 00530 Helsinki
            <br />
            Postiosoite: PL 800, 00531 Helsinki
            <br />
            Sähköposti: tietosuoja(at)om.fi Puhelinvaihde: 029 566 6700
            <br />
            Kirjaamo: 029 566 6768
          </CustomText>
        </Box>
      </Card>
    </Container>
  );
};

const SubTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Heading as="h2" size="lg" py={4} textAlign="left">
    {children}
  </Heading>
);

const CustomText: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text pb={4} {...rest}>
    {children}
  </Text>
);
