export default {
  brand: {
    50: '#dff0ff',
    100: '#b0d0ff',
    200: '#7fb1ff',
    300: '#4d92ff',
    400: '#1f73fe',
    500: '#085ae5',
    600: '#0047B6', // <-- #0046b3 replaced with brand color
    700: '#003281',
    800: '#001e50',
    900: '#000a20',
  },
};
// Official brand color: #0047B6
