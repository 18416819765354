import React from 'react';
import { Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react';

export const P: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text mb={4} {...rest}>
    {children}
  </Text>
);
export const H1: React.FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading fontSize="4xl" textAlign="left" mb={4} {...rest}>
    {children}
  </Heading>
);
export const H2: React.FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading fontSize="2xl" textAlign="left" mb={4} {...rest}>
    {children}
  </Heading>
);
export const H3: React.FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading as="h3" fontSize="xl" textAlign="left" mb={4} {...rest}>
    {children}
  </Heading>
);
