import React, { FC } from 'react';
import { useGetSummaryData } from '../useGetSummaryData';
import { CombinationData } from '../CombinationReport';
import { YouthContent } from './Contents';
import { Section } from './Section';

export const SummaryBody: FC<{ data: CombinationData }> = ({ data }) => {
  const { summaryData, getGuardianElement } = useGetSummaryData(data);

  return (
    <>
      {summaryData.map((section, idx) => (
        <Section
          key={`${section.name}-${idx}`}
          name={section.name}
          youthContent={
            <YouthContent colSpan={2}>{section.youthContent}</YouthContent>
          }
          guardianContent={section.guardianContent.map(getGuardianElement)}
        />
      ))}
    </>
  );
};
