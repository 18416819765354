import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

import { ChatUsertype, IChatConnection } from '~/src/types';
import { envs } from '~/src/utils';

const useChatConnection = (): IChatConnection => {
  const { chatId, usertype } = useParams<{
    chatId: string;
    usertype: string | undefined;
  }>();
  const serverUrl = envs.SERVER_URL;
  const [socket, setSocket] = useState<Socket | null>(null);
  const connectedUsertype: ChatUsertype =
    usertype === 'professional'
      ? ChatUsertype.Professional
      : ChatUsertype.Client;

  useEffect(() => {
    const socketOptions = {
      auth: {
        usertype: connectedUsertype,
        username:
          connectedUsertype === ChatUsertype.Professional ? 'Tohtori' : null,
        chatId,
      },
    };

    const socketIO = io(serverUrl, socketOptions);
    setSocket(socketIO);

    return () => {
      socketIO.emit('leave', {
        chatId,
      });
    };
  }, [chatId, connectedUsertype, serverUrl, usertype]);

  return {
    socket: socket,
    connectedUsertype: connectedUsertype,
    chatId: chatId,
  };
};

export default useChatConnection;
