import { useId } from 'react';
import richTextParser from './richTextParser';

export type ModalContent = {
  uuid: string;
  title: string;
  modalDescription: string | React.ReactNode;
};

type DescriptionWithModal = {
  description: string;
  modals: ModalContent[];
};

export function pickModalsFromDescription(
  description: string,
  //showModal: (uuid: string) => void,
): DescriptionWithModal {
  let formattedDescription = description;
  // Regular expressions to find modal components
  const modalBlockRegex = /\[modal\].*?\[\/modal\]/gs;
  const titleRegex = /\[title\](.*?)\[\/title\]/s;
  const descriptionRegex = /\[description\](.*?)\[\/description\]/s;
  const linkTextRegex = /\[link-text\](.*?)\[\/link-text\]/;

  // Extract all modal blocks
  const modalBlocks = description.match(modalBlockRegex) || [];

  // Process each modal block
  const modals: ModalContent[] = modalBlocks.map((modalBlock) => {
    const titleMatch = modalBlock.match(titleRegex);
    const descriptionMatch = modalBlock.match(descriptionRegex);
    const linkTextMatch = modalBlock.match(linkTextRegex);

    const uuid = useId();
    const title = titleMatch ? titleMatch[1].trim() : '';
    const modalDescription = richTextParser(
      descriptionMatch ? descriptionMatch[1] : '',
      {
        allowAttr: ['className'],
      },
    );
    const linkText = linkTextMatch ? linkTextMatch[1].trim() : 'Show Details';

    // Replace modal block with button in original description
    formattedDescription = formattedDescription.replace(
      modalBlock,
      `<button data-modal-id="${uuid}" >${linkText}</button>`,
      //`<button onClick={${() => showModal(uuid)}} >${linkText}</button>`,
    );

    return {
      uuid,
      title,
      modalDescription,
    };
  });

  return {
    description: formattedDescription.trim(),
    modals,
  };
}
