export function devlogger(message?: unknown, ...optionalParams: unknown[]) {
  if (process.env.NODE_ENV !== 'development') return;
  if (message === 'error')
    // eslint-disable-next-line no-console
    return console.error(...optionalParams);

  // eslint-disable-next-line no-console
  console.log(message, ...optionalParams);
}

// Source: https://stackoverflow.com/a/58278753/1317406
export const isSomeEnum =
  <T extends { [s: string]: unknown }>(myEnum: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(myEnum).includes(token as T[keyof T]);

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function rangeToStr(min: number, max: number): string {
  if (min === max) return min.toString();
  if (max === Infinity) return '≥' + min.toString();
  return `${min}-${max}`;
}
