import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import Modal from '~src/components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { richTextParser } from '~src/utils';

type PaymentTermsProps = {
  paytrailTerms: string;
  paymentTerms: string;
};

const PaymentTerms: React.FC<PaymentTermsProps> = ({
  paytrailTerms,
  paymentTerms,
}) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex display="">
      <div
        className="payment-terms"
        dangerouslySetInnerHTML={{ __html: paytrailTerms }}
      />
      <Box className="payment-terms">
        {t('payment.serviceTerms') + ' '}
        <Button
          variant="link"
          fontSize="large"
          color="black"
          textDecoration="underline"
          onClick={() => onOpen()}
        >
          {t('payment.serviceTerms1')}
        </Button>
      </Box>

      <Modal
        modalIsOpen={isOpen}
        onCloseModal={onClose}
        title={t('payment.serviceTermsModalTitle')}
      >
        {richTextParser([paymentTerms], { allowTags: ['iframe'] }) ?? ''}
      </Modal>
    </Flex>
  );
};

export { PaymentTerms };
