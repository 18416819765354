import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { useGetAuthenticationQuery } from '~src/api';
import { useTranslation } from 'react-i18next';

const AuthenticationSuccessInfo: React.FC<{
  authAnswerId: string;
  kuuraAuthId: string;
}> = ({ authAnswerId, kuuraAuthId }) => {
  const { t } = useTranslation();

  const { data } = useGetAuthenticationQuery({
    skip: !authAnswerId || !kuuraAuthId,
    variables: {
      id: authAnswerId,
      kuuraAuthId: Number(kuuraAuthId),
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Flex gap="1">
        <Flex flexDirection="column">
          <Text fontWeight="700">
            {t('healthIssueFlow.authenticationSuccess.name')}:
          </Text>
          <Text fontWeight="700">
            {t('healthIssueFlow.authenticationSuccess.personalSecurityNumber')}:
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Flex>
            <Text mr={2}>{data?.authentication.givenNames}</Text>
            <Text>{data?.authentication.lastName}</Text>
          </Flex>
          <Text>{data?.authentication.hetu}</Text>
        </Flex>
      </Flex>
    </>
  );
};

export { AuthenticationSuccessInfo };
