import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box, Button } from '@chakra-ui/react';
import { envs } from '~/src/utils';

export const ReportFeedback: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [selectedFeedback, setSelectedFeedback] = useState<number>();

  const feedbackOptions: [string] | string = t(
    'terapia.reportFeedback.feedbackOptions',
    {
      returnObjects: true,
    },
  );

  const feedbackOptionsArray = Array.isArray(feedbackOptions)
    ? feedbackOptions
    : [feedbackOptions];

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => {
    setSelectedFeedback(idx);
    fetch(`${envs.SERVER_URL}api/v1/feedbacks/${envs.FEEDBACK_ID}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include', // Required if server is running in different host or port
      body: JSON.stringify({
        description: feedbackOptionsArray[idx],
        value: { idx, lang: i18n.language },
      }),
    });

    e.currentTarget.blur();
  };

  return (
    <>
      <Text fontWeight="bold" textAlign="center">
        {t('terapia.reportFeedback.feedbackQuestion')}
      </Text>
      <Box w="max-content" maxW="fit-content" mx="auto">
        {feedbackOptionsArray.map((text, idx) => (
          <FeedbackButton
            key={idx}
            isSelected={idx === selectedFeedback}
            onClick={(e) => handleClick(e, idx)}
          >
            {text}
          </FeedbackButton>
        ))}
        {selectedFeedback != null && (
          <Text textAlign="center">{t('terapia.reportFeedback.thankyou')}</Text>
        )}
      </Box>
    </>
  );
};

const FeedbackButton: React.FC<{
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSelected: boolean;
}> = ({ children, onClick, isSelected }) => {
  const dynamicProps = isSelected
    ? { variant: 'solid' }
    : {
        variant: 'outline',
        borderColor: 'gray.400',
        bg: 'white',
      };

  return (
    <Button
      display="block"
      my={4}
      textAlign="center"
      w="100%"
      whiteSpace="normal"
      onClick={onClick}
      {...dynamicProps}
    >
      {children}
    </Button>
  );
};
