{
  "common": {
    "app_name": "Duodecim Lääkärit",
    "app_organization": "Kustannus Oy Duodecim"
  },
  "flowInit": {
    "title": "Duodecim Lääkärit",
    "paragraph": [
      "Tervetuloa Duodecim lääkärit etävastaanotolle!",
      "Palvelun käyttö on mahdollista, kun olet sopinut lääkärin konsultaatiosta etukäteen. Palvelun käyttö edellyttää vahvaa tunnistautumista, esitietojen ja tarpeesi kuvaamista, sekä palvelumaksun maksamista. <strong>Kysy lisätietoja lääkäriltäsi!</strong>",
      "",
      "<strong>Duodecim lääkärit - vastuullista ja edistyksellistä terveydenhuoltoa</strong>",
      "Suomalainen Lääkäriseura Duodecim on vuonna 1881 perustettu, Suomen suurin tieteellinen yhdistys, jonka jäseniin kuuluu selkeä enemmistö suomalaisista lääkäreistä. Duodecim Lääkärit on vastaavasti yksityisen terveydenhuollon palveluntuottaja, jonka alaisuudessa Lääkäriseura Duodecimin jäsenet voivat lääketieteellisinä konsultaatioina tuottaa terveyshyötyjä kansalaisille."
    ]
  }
}
