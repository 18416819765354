import { GraphQLError } from 'graphql';
import { createStandaloneToast } from '@chakra-ui/react';
import i18n from '~/src/i18n';
import { NetworkError } from '@apollo/client/errors';

interface Errors {
  graphQLErrors: readonly GraphQLError[] | undefined;
  networkError: NetworkError | undefined;
}

const setError = ({ graphQLErrors, networkError }: Errors) => {
  const { toast } = createStandaloneToast();
  let errorTitle = i18n.t('errors.generic.title');
  let errorMessage = i18n.t('errors.generic.message');

  if (!graphQLErrors && networkError) {
    errorTitle = i18n.t('errors.network.title');
    errorMessage = i18n.t('errors.network.message');
  }

  return toast({
    title: errorTitle,
    description: errorMessage,
    status: 'error',
    duration: 5000,
    isClosable: true,
    position: 'top',
  });
};

export default setError;
