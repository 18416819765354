import { Container, SlideFade, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import {
  AddAnswerMutation,
  FlowItemType,
  QuestionType,
  useGetAuthenticationQuery,
} from '~src/api';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import Card, { CardContentStack } from '../../Card';
import Loading from '../../Loading';

// HOX: Needs to match backend and db enum values (kuura/chat/graphql/datasources/authentication.ts)
enum AuthenticationStatus {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export const AUTH_ANSWER_UUID_QUERY_PARAM = 'AUUID';
export const KUURA_AUTH_ID_QUERY_PARAM = 'KAID';

/**
 * Authentication callback component for kuura authentication
 *
 * Assumptions:
 * - flows authentication step :name in router is "authentication"
 */
const AuthenticationCallback: React.FC = () => {
  const { flow, answerId } = useParams<{ flow: string; answerId: string }>();
  const [amountOfRefetches, setAmountOfRefetches] = React.useState<number>(0);
  const { t } = useTranslation();
  const history = useHistory();
  const [searchParams] = useSearchParams();
  const kuuraAuthId = searchParams.get('state') || false;

  const { addAnswer } = useAddAnswer(flow, (data: AddAnswerMutation) => {
    const { flowItem, id } = data.addAnswer;
    // Add Authentication Answer id and KuuraAuth id as a query param to redirect url
    // when next flow item is authentication success so we can fetch auth info in success page
    const params =
      flowItem?.type === FlowItemType.AuthenticationSuccess
        ? `?${AUTH_ANSWER_UUID_QUERY_PARAM}=${answerId}&${KUURA_AUTH_ID_QUERY_PARAM}=${kuuraAuthId}`
        : '';
    history.push(`/${flow}/${flowItem?.name}/${id}${params}`);
    // Return true to prevent new redirect in parent function
    return true;
  });

  const isSlide = history.action === 'PUSH';
  const reTryUrl = `/${flow}/authentication/${answerId}?retry=true`;

  const { refetch } = useGetAuthenticationQuery({
    skip: !answerId || !kuuraAuthId,
    variables: {
      id: answerId,
      kuuraAuthId: Number(kuuraAuthId),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { status = AuthenticationStatus.ERROR } = data.authentication;
      handleRedirect(status as AuthenticationStatus);
    },
    onError() {
      handleRedirect(AuthenticationStatus.ERROR);
    },
  });

  if (!answerId || !flow || !kuuraAuthId) {
    history.push('/');
    return null;
  }

  function handleRedirect(status: AuthenticationStatus) {
    switch (status) {
      case AuthenticationStatus.SUCCESS:
        addAnswer({
          variables: {
            id: answerId,
            answer: [
              {
                type: QuestionType.AuthenticationSuccess,
                name: 'yed-button-option',
                // value (~ edgeNumber ) will be set in backend
              },
            ],
          },
        });
        break;
      case AuthenticationStatus.ERROR:
      case AuthenticationStatus.UNKNOWN:
        history.push(reTryUrl);
        break;
      case AuthenticationStatus.INIT:
        // If kuura auth callback to backend is not yet completed, try refetch status 5 times with 1s interval
        if (amountOfRefetches < 5) {
          setTimeout(() => {
            setAmountOfRefetches(amountOfRefetches + 1);
            refetch();
          }, 1000);
        } else {
          history.push(reTryUrl);
        }
        break;
      default:
        history.push('/');
        break;
    }
  }

  return (
    <Container
      key={answerId}
      px={[4, 8, 16]}
      py={4}
      sx={{
        '.chakra-offset-slide > *': {
          overflow: 'visible',
        },
      }}
    >
      <SlideFade in={true} offsetX={isSlide ? 300 : 0} offsetY={0}>
        <Card>
          <CardContentStack mb={[4, null, 8]}>
            <Loading />
            <Text>{t('authentication.callback.loading')}</Text>
          </CardContentStack>
        </Card>
      </SlideFade>
    </Container>
  );
};

export { AuthenticationCallback };
