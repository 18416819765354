import React, { FC } from 'react';
import { isDesktop } from 'react-device-detect';
import { Heading, Center } from '@chakra-ui/react';
import { CopyToClipboardButton } from '~/src/components/CopyToClipboardButton';
import { DownloadPdfButton } from '~/src/components/DownloadPdfButton';
import { PrintSummaryButton } from '~/src/components/PrintSummaryButton';
import { ReportType } from '~/src/types';
import { reactToString } from '~/src/utils/reactToString';
import { CombinationIds } from '../CombinationReport/CombinationReport';

export type Title = { text: string; id: string };

export const SummaryHeading: FC<{
  title?: Title;
  summaryBody: JSX.Element;
  id?: string;
  ids?: CombinationIds;
  type: ReportType;
  hidePdfButton?: boolean;
}> = ({ title, summaryBody, id, ids, type, hidePdfButton }) => (
  <>
    {title?.text ? (
      <Heading
        as="h2"
        size="lg"
        mt={16}
        mb={4}
        id={title.id}
        className="hideWhenPrinting"
      >
        {title.text}
      </Heading>
    ) : null}
    <Center flexWrap="wrap" mt={4}>
      {isDesktop && (
        <>
          <CopyToClipboardButton
            getContent={() => getClipboardContent(title?.text, summaryBody)}
          />
          <PrintSummaryButton id={id} type={type} ids={ids} />
        </>
      )}
      {hidePdfButton === true ? null : (
        <DownloadPdfButton reportType={type} reportId={id} />
      )}
    </Center>
  </>
);

function getClipboardContent(
  title: string | undefined,
  summaryBody: JSX.Element,
) {
  const titleStr = title ? title + '\r\n' : '';
  return titleStr + reactToString(summaryBody).trim();
}
