import { makeUniqueId } from '@apollo/client/utilities';
import React, { useEffect, useRef } from 'react';
import { FlowItem, Provider, useCreatePaymentMutation } from '~src/api';
import { flowPaymentCallbackRoot } from '~src/constants';

type CreatePaymentButtonProps = {
  id: string;
  paymentData: {
    selectedProvider: Provider;
    email: string;
    paymentProps: FlowItem['paymentProps'];
  };
  setError: (error: Error | string | boolean | null) => void;
};

/**
 * Create Payment Button
 *
 * rendered when provider icon clicked, then:
 * 1. trigger createPaymnet request to backend - payment providers are returned
 * 2. render selected payment provider form and submit it --> redirects to payment provider page
 *
 * @returns
 */

const CreatePaymentButton: React.FC<CreatePaymentButtonProps> = (props) => {
  const { id, paymentData, setError } = props;
  const { selectedProvider, email, paymentProps } = paymentData;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { protocol, hostname, port } = window.location;
  const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;

  const [createPayment, { data }] = useCreatePaymentMutation({
    variables: {
      id: id ?? '',
      data: {
        amount: Number(paymentProps?.price),
        productCode: paymentProps?.productCode || '',
        paymentMethod: selectedProvider.name || '',
        customerEmail: email,
        serviceCallbackUrl: `${baseUrl}/${flowPaymentCallbackRoot}/survey/${id}`,
      },
    },
    onError(error) {
      setError(error);
      throw `Error in createPaymentMutation: ${error}`;
    },
  });

  useEffect(() => {
    if (selectedProvider) {
      createPayment();
    }
  }, [createPayment, selectedProvider]);

  const parameterToInput = (param: any) => (
    <input
      key={makeUniqueId('input-param-')}
      type="hidden"
      name={param.name}
      value={param.value}
    />
  );

  const provider = data?.createPayment.providers.find(
    (provider: Provider) => provider.id === selectedProvider.id,
  );

  useEffect(() => {
    // Trigger click event on the selected provider form button after it has been rendered
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, [provider]);

  return provider ? (
    <form
      className="paytrail-provider-form"
      method="POST"
      action={provider?.url || ''}
    >
      {
        // provider will alway have parameters and url, validated in backend.
        // In type those are optional for reusability
        provider.parameters?.map(parameterToInput)
      }
      <button
        ref={buttonRef}
        className="paytrail-provider-button"
        // style is needed to hide the button from the user
        style={{
          width: '1px',
          height: '1px',
          borderColor: 'transparent',
          background: 'transparent',
        }}
      />
    </form>
  ) : null;
};

export { CreatePaymentButton };
