import React from 'react';
import { Flex, Box, Icon } from '@chakra-ui/react';
import { RiCheckboxCircleFill } from 'react-icons/ri';

const HalfCircleLeft = () => (
  <Box
    border="3px solid white"
    borderRight="0"
    borderTopLeftRadius="calc(1.2rem + 3px)"
    borderBottomLeftRadius=" calc(1.2rem + 3px)"
    height="2.4rem"
    marginRight="1.2rem"
    width="1.2rem"
  />
);

const HalfCircleRight = () => (
  <Box
    border="3px solid white"
    borderLeft="0"
    borderTopRightRadius="calc(1.2rem + 3px)"
    borderBottomRightRadius=" calc(1.2rem + 3px)"
    height="2.4rem"
    marginLeft="1.2rem"
    width="1.2rem"
  />
);

const SelectedToggleIndicator: React.FC<{
  selected: 'left' | 'right';
}> = ({ selected }) => {
  return (
    <Flex
      aria-hidden
      height="2.4rem"
      left="calc(50% - 1.4rem)"
      position="absolute"
      width="2.4rem"
    >
      <Box
        bg="white"
        height="1rem"
        position="absolute"
        left=".7rem"
        top=".7rem"
        width="1rem"
      />
      {selected === 'left' ? <HalfCircleRight /> : <HalfCircleLeft />}
      <Icon
        as={RiCheckboxCircleFill}
        color="primary.400"
        height="2.4rem"
        left="calc(50% - 1.2rem)"
        position="absolute"
        width="2.4rem"
      />
    </Flex>
  );
};

export default SelectedToggleIndicator;
