import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getLocalStorage,
  removeLocalStorage,
  upsertLocalStorage,
} from '~src/utils/storage';
import { siteTokenVerification } from '~/src/configurations';
import Loading from '../Loading';
import { Box } from '@chakra-ui/react';
import { IConfiguration } from '~src/types';
import { devlogger, envs } from '~src/utils';

interface DummyTokenProtectionProps {
  children: ReactNode;
}

const DummyTokenProtection: React.FC<DummyTokenProtectionProps> = ({
  children,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [tokenVerified, setTokenVerified] = useState<boolean>(false);
  const config = useMemo(
    (): IConfiguration['siteTokenVerification'] => siteTokenVerification,
    [],
  );
  const { dummyTokens, siteToRedirect, tokenExpHours } = config || {};
  const tokenProtectionEnabled = envs.SITE_TOKEN_PROTECTION_ENABLED;
  if (tokenProtectionEnabled) devlogger('Token protection enabled');

  const verifyToken = useCallback(
    (token: string | null) => {
      let tokenExpiration = getLocalStorage('siteTokenExp');

      if (token && dummyTokens && dummyTokens.includes(token)) {
        tokenExpiration = Date.now() + 1000 * 60 * 60 * (tokenExpHours || 24);
        upsertLocalStorage('siteTokenExp', tokenExpiration);
      }
      devlogger(
        'Token verified',
        tokenExpiration,
        tokenExpiration && Date.now() <= tokenExpiration,
      );
      return tokenExpiration && Date.now() <= tokenExpiration;
    },
    [dummyTokens, tokenExpHours],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    queryParams.delete('token');
    token && history.replace({ search: queryParams.toString() });

    if (!tokenProtectionEnabled) {
      setTokenVerified(true);
      return;
    }

    if (verifyToken(token)) {
      setTokenVerified(true);
    } else {
      removeLocalStorage('siteTokenExp');
      window.location.href = siteToRedirect || '';
    }
  }, [
    location.search,
    siteToRedirect,
    tokenProtectionEnabled,
    verifyToken,
    history,
  ]);

  return (
    <>
      {tokenVerified || !tokenProtectionEnabled ? (
        children
      ) : (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

export { DummyTokenProtection };
