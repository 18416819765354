import { Heading } from '@chakra-ui/react';
import React from 'react';
import { FlowItem } from '~src/api';
import { flowitemConf } from '~src/configurations';

const FlowItemTitle: React.FC<{
  title: FlowItem['title'];
}> = ({ title }) => {
  if (!title || flowitemConf.showIntro) return <></>;
  return <Heading as="h1">{title}</Heading>;
};

export { FlowItemTitle };
