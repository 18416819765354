import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, Icon } from '@chakra-ui/react';
import {
  HiOutlineClipboardCopy,
  HiOutlineClipboardCheck,
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

export const CopyToClipboardButton: React.FC<{
  getContent: () => string;
}> = ({ getContent }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <Button
      className="hideWhenPrinting"
      onClick={() => {
        setCopied(true);
        copy(getContent(), { format: 'text/plain' });
      }}
      variant="ghost"
      size="md"
      fontSize={16}
    >
      {t('terapia.report.copyToClipboard')}
      <Icon
        ml={2}
        fontSize={20}
        as={copied ? HiOutlineClipboardCheck : HiOutlineClipboardCopy}
      />
    </Button>
  );
};
