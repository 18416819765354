import React, { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogBody,
  AlertDialogFooter,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IChatConnection } from '~/src/types';

const EndDiscussion: React.FC<IChatConnection> = ({ socket, chatId }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: React.RefObject<any> = useRef();
  const history = useHistory();

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        marginRight={[0, null, 12]}
        padding={2}
      >
        {t('chat.discussion.endDiscussion')}
      </Button>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          borderRadius="2rem"
          marginX={4}
          padding={8}
          textAlign="center"
          maxWidth={['calc(100% - 2rem)', '28rem']}
        >
          <AlertDialogHeader
            as={Heading}
            fontWeight="bold"
            padding={0}
            size="sm"
          >
            {t('chat.discussion.endDiscussionAlert.title')}
          </AlertDialogHeader>

          <AlertDialogBody as="p" paddingBottom={8} paddingTop={4}>
            {t('chat.discussion.endDiscussionAlert.paragraph')}
          </AlertDialogBody>

          <AlertDialogFooter as={HStack} padding={0} justifyContent="center">
            <Button
              ref={cancelRef}
              onClick={onClose}
              minWidth={['8rem', '10rem']}
            >
              {t('chat.discussion.endDiscussionAlert.backButton')}
            </Button>

            <Button
              onClick={() => {
                socket?.emit('leave', {
                  chatId: chatId,
                });

                return history.push('/');
              }}
              minWidth={['8rem', '10rem']}
              width="auto"
            >
              {t('chat.discussion.endDiscussionAlert.proceedButton')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default EndDiscussion;
