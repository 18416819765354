{
  "common": {
    "pageTitle": "Terapinavigatorn",
    "app_name": "Terapinavigatorn",
    "app_organization": "Första linjens terapier",
    "close": "Stäng",
    "continue": "Fortsätt",
    "dropdownDefaultPlaceholder": "Välj ett alternativ",
    "charactersRemaining": "Återstående antal tecken",
    "allAnswersRequired": "Svara på alla frågor för att komma vidare",
    "navigateBack": "Gå tillbaka"
  },
  "errors": {
    "upcomingMaintenance": "Tjänsten är på väg att starta ett underhållsuppehåll och det är därför inte möjligt att starta en ny undersökning nu. Underhållsuppehållet är över senast kl",
    "generic": {
      "title": "Ett oväntat fel har inträffat",
      "message": "Oj, någonting gick fel. Försök på nytt senare."
    },
    "network": {
      "title": "Anslutningsfel",
      "message": "Kontrollera din internetanslutning."
    },
    "errorCard": {
      "title": "Oj, någonting gick fel!",
      "paragraph": "Sidan är inte tillgänglig.",
      "ctaLabel": "Tillbaka till framsidan"
    },
    "sessionClosed": {
      "title": "Sessionen har avslutats",
      "message": "Innehållet är inte längre tillgängligt."
    }
  },
  "infoModal": {
    "title": "Vanliga frågor",
    "paragraphContent": [
      "<strong>Vad gör jag om inget av svarsalternativen passar?</strong><br />Svara på det sätt du tycker passar bäst, så att du kommer vidare. När du tar kontakt med vården bedömer en yrkesperson din situation på nytt. Du får då vid behov komplettera och korrigera informationen.",
      "<strong>Jag tappade bort informationen, finns den sparad någonstans? / Kan jag få en ny kod?</strong><br />Om du fyllde i Terapinavigatorn och sparade koden i slutet kan du ladda ner din rapport på nytt med hjälp av koden. Av dataskyddsskäl kan informationen nås endast med hjälp av koden och bara under en månads tid. Nackdelen med den här lösningen är situationer när du blir avbruten medan du fyller i Terapinavigatorn, när koden tappas bort eller när du inte får någon kod på grund av tekniska problem. Vi beklagar besväret som uppkommer i de här situationerna och uppmanar dig att vid behov fylla i Terapinavigatorn på nytt. Till all lycka går svarandet ofta snabbare när man gör det andra gången.",
      "En yrkesperson inom hälsovården kan bedöma din situation också utan navigatorinformationen även vid enheter där Terapinavigatorn används. Bedömningen kan ändå i de här fallen ta längre tid.",
      "<strong>Måste jag fylla i Terapinavigatorn ända till slut?</strong><br />För att informationen Terapinavigatorn sammanställer ska kunna användas inom hälsovården, måste du ha fyllt i den ända till slut. Det kan kännas tungt att fylla i Terapinavigatorn särskilt om dina svar leder till att du uppmanas svara på flera av frågeformulären som navigatorn innehåller. När du fyller i Terapinavigatorn lönar det sig allmänt taget att försöka komma vidare och inte stanna upp alltför länge och fundera på enskilda svar.",
      "<strong>Hur är det med mitt dataskydd?</strong><br />Du fyller i Terapinavigatorn helt anonymt. Syftet är att svaren inte ska göra det möjligt att identifiera enskilda användare. Kom ihåg att inte i de öppna svarsfälten skriva in någon sådan information som kan hjälpa att identifiera dig, såsom namn eller e-postadress. Svarsrapporten kan öppnas endast med hjälp av koden du får i slutet. Kom ihåg att skydda din kod."
    ],
    "openButtonAriaLabel": "Öppna info om tjänsten"
  },
  "intro": {
    "title": "Terapinavigatorn",
    "paragraph": "",
    "linkText": "Information om Terapinavigatorn"
  },
  "flowInit": {
    "title": "Välkommen till Terapi­navigatorn!",
    "paragraph": [
      "Terapinavigatorn kartlägger din livssituation och eventuella aktuella problem eller svårigheter du har upplevt tidigare. Informationen samlas in för att du ska kunna hänvisas till sådana hjälp- eller stödformer som passar dig bäst."
    ],
    "notice": "Observera följande:",
    "noticeList": [
      "<strong>Terapinavigatorn fylls i anonymt.</strong> Skriv inte ut till exempel ditt namn, personnummer eller dina kontaktuppgifter i svaren.",
      "<strong>Reservera cirka 20–30 minuter för att fylla i Terapinavigatorn.</strong> Om du blir avbruten innan du är färdig sparas dina svar inte.",
      "<strong>Tag tillvara den personliga koden</strong> som du får när du har fyllt i Terapinavigatorn. TIPS! Ta en skärmdump eller bild av koden med din telefon.",
      "<strong>Ge koden till personen du får kontakt med inom sjukvården</strong> och gå genom resultaten tillsammans med hen. Koden är giltig i en månad."
    ],
    "warningBox": [
      "Om du har självdestruktiva tankar eller är orolig för din trygghet ska du genast berätta för någon närstående person och en yrkesperson. Sök dig också till vården (till exempel hälsostationen, arbetshälsovården eller studerandehälsovården) där en yrkeskunnig person kan bedöma din situation. Berätta om dina självdestruktiva tankar så att du kan få bästa möjliga hjälp. ",
      "Om du är i en situation där du behöver omedelbar hjälp, ring eller be någon närstående ringa 112."
    ],
    "privacyAcceptance": "Jag samtycker till behandlingen av mina uppgifter i enlighet med <linkOut>integritetspolicyn</linkOut>"
  },
  "terapia": {
    "startButtons": {
      "start": "Börja",
      "startTest": "Jag vill bara testa",
      "openResults": "Öppna dina resultat med koden",
      "viewExample": "Exempel på resultat",
      "professionalInfo": "Information för yrkespersoner",
      "accessibilityStatement": "Tillgänglighetsutlåtande"
    },
    "professionalInfo": {
      "title": "Information för yrkespersoner",
      "paragraphs": [
        "Terapinavigatorn är ett digitalt verktyg, som försnabbar bedömningen av vårdbehovet.",
        "Terapinavigatorn samlar in relevant bakgrundsinformation om klientens situation och aktuella symtom och gör ett sammandrag av dem för både klienten och yrkespersonen. Utgående från det här sammandraget bedömer yrkespersonen vårdbehovet genom en intervju, som i det här sammanhanget kallas <strong>situationsformulering</strong>.",
        "I de flesta fall har klienten uppmanats fylla i Terapinavigatorn redan före sin första kontakt med en yrkesperson inom hälsovården. När klienten fyllt i Terapinavigatorn får hen en kod, som används för att öppna hens resultat.",
        "Via den här länken kan du med hjälp av koden öppna klientens resultat: <linkIn to='/report'>https://www.terapianavigaattori.fi/report</linkIn>. Med hjälp av koden du får av klienten kommer du direkt till sammandragsrapporten. Du kan också öppna en mera omfattande rapport där du ser klientens svar på enskilda frågor.  "
      ],
      "reportSections": {
        "subtitle": "Terapinavigatorns sammandragsrapport består av följande delar:",
        "sections": [
          "Beskrivning av problem och varför klienten söker vård",
          "Resultat från frågeformulär om olika symtom",
          "Funktionsförmåga",
          "Vårdformer som klienten är intresserad av",
          "Behov av social-, arbets- och studerandetjänster",
          "Tidigare psykosocial vård"
        ]
      },
      "instructions": {
        "subtitle": "Fokusera på följande vid situationsformulering:",
        "list": [
          {
            "text": "Centrala symtom och svårighetsgrad",
            "subList": [
              "gå genom resultaten från frågeformulären med klienten",
              "intervjua klienten närmare om hur svåra symtomen är",
              "beskriv hur den här typen av symtom typiskt sett märks i livet",
              "fråga klienten vilket symtom hen i första hand önskar få hjälp och förändring med"
            ]
          },
          {
            "text": "Bedömning av funktionsförmåga",
            "subList": [
              "verkar klientens funktionsförmåga vara relativt bra eller bättre",
              "eller verkar klientens funktionsförmåga vara svag"
            ]
          },
          {
            "text": "Har klienten fått tidigare psykosocial vård?",
            "subList": [
              "har klienten tidigare tagit del av vårdformer med låg tröskel eller evidensbaserade vårdformer?"
            ]
          },
          {
            "text": "Vårdpreferenser ",
            "subList": [
              "vilka vårdformer med låg tröskel tror klienten sig kunna ha nytta av? "
            ]
          },
          {
            "text": "Precisera följande frågor om klienten tagit upp dem i sina svar",
            "subList": [
              "vilken typ av traumatisk kris/livskris handlar det om? hur påverkar den klienten? ",
              "precisera eventuella problem med sömnen",
              "missbruks- och beroendeproblematik",
              "självdestruktivitet: har klienten självmordsplaner eller -tankar? avsikt att förverkliga dem? metoder för att förverkliga dem? tidigare försök?",
              "bedöm behovet av samarbete med andra instanser (socialtjänster, arbetshälsovård, studerandehälsovård, arbets- och näringsbyrån, barnskydd)"
            ]
          }
        ]
      },
      "paragraphs2": [
        "Beroende på hurdana problem och symtom klienten beskriver och hens preferenser gällande vården kan du hänvisa hen till antingen 1) vård med låg tröskel som kan inledas snabbt eller 2) noggrannare bedömning/intensivare vård.",
        "Du kan också utnyttja och tillämpa Terapinavigatorns resultat som stöd vid situationsformulering av klientens symtom på basnivå. I de här fallen är det viktigt att berätta tydligt för klienten varför och hur Terapinavigatorn används."
      ]
    },
    "openReport": {
      "title": "Öppna resultat",
      "description": "Skriv in koden du fick efter att du fyllt i Terapinavigatorn för att öppna dina resultat på nytt.",
      "formLabel": "Kod för att öppna resultaten",
      "openBtn": "Öppna resultat"
    },
    "report": {
      "title": "Resultaten från Terapinavigatorn",
      "description": "Koden för dina resultat är <strong>{{id}}</strong>. Du kan använda samma kod om du senare vill se dina resultat på nytt. Gå då in på Terapinavigatorns framsida och klicka på länken \"Öppna resultat\".",
      "toc": "Innehåll",
      "copyToClipboard": "Kopiera ",
      "downloadPdf": "Ladda ner i pdf-format",
      "pdfInfoLink": "Du kan hitta information om tolkningen av Terapinavigator-resultat på",
      "pdfInfoLinkUrl": "https://www.terapianavigaattori.fi/report/instructions?lng=sv",
      "printSummary": "Skriv ut rapporten",
      "pointAbbreviation": "p",
      "not-found": "Rapporten kan inte hittas med den angivna koden"
    },
    "reportSummary": {
      "title": "Rapport över resultaten från Terapinavigatorn",
      "subtitles": {
        "description": "Beskrivning av problemen och behov av vård",
        "questionnaires": "Frågeformulär",
        "performance": "Funktionsförmåga",
        "treatments": "Vårdformer jag är intresserad av",
        "supportNeeds": "Behov av social-, arbets- eller studerandetjänster",
        "previousTreatments": "Tidigare psykosocial vård"
      },
      "questionnaireDisclaimer": "<strong>Obs!</strong> Poängen som anges här ovan beskriver dina problem och symtom vid tidpunkten då du fyllde i Terapinavigatorn. Poängen innebär inte att en diagnos på någon psykisk störning kan fastställas. För att en diagnos ska kunna fastställas behövs alltid en närmare bedömning av vårdpersonal.",
      "labels": {
        "coupleTherapy": "Kort par- eller familjeterapi",
        "violenceNote": "OBS: Svar på fråga om våld i nära relationer / hot om våld",
        "sleep": "Fråga om sömnproblem",
        "limitGaming": "Beredskap att minska på spelandet",
        "crisis": "Fråga om livskris",
        "crisisEvent": "Livskris på grund av exceptionellt chockerande händelse",
        "exhausted": "Fråga om utbrändhet",
        "drugs": "Fråga om droger eller läkemedel",
        "addiction": "Fråga om funktionella beroenden",
        "dangerousThoughts": "Tankar om att lika gärna kunna vara död eller att skada sig på något sätt (PHQ-9)",
        "crisisDescription": "Preciserande fråga om livskris",
        "crisisEventDescription": "Specificerande fråga om livskris på grund av exceptionellt chockerande händelse",
        "drugsDescription": "Preciserande fråga om droger eller läkemedel",
        "workPerformance": "Arbets- eller studieförmåga under den senaste månaden",
        "relationships": "Människorelationer under den senaste månaden",
        "webTherapy": "Nätterapi",
        "groupTherapy": "Vård i grupp",
        "selfcare": "Assisterad egenvård",
        "exercise": "Motion",
        "drugReview": "Bedömning av medicineringsbehov",
        "residence": "Önskemål om stöd i fråga om boende eller svårigheter i familjelivet",
        "violence": "Våld eller hot om våld i dina nära relationer",
        "workAndStudyStatus": "Arbets- och studerandestatus",
        "workAndStudyStatusDetailed": "Förtydligande om arbets- och studerandestatus",
        "supportForWorkAndStudy": "Önskemål om stöd i fråga om svårigheter med arbete eller studier",
        "previousTreatments": "Tidigare psykosocial vård",
        "previousTreatmentsDuration": "Längden på tidigare vård",
        "previousTreatmentsDescription": "Preciserande fråga om tidigare vård",
        "noPreviousTreatments": "Ingen tidigare psykoterapi eller samtalsstöd med yrkesperson."
      }
    },
    "reportFull": {
      "title": "Lång rapport",
      "description": "I den långa rapporten hittar du alla dina svar och information om hur dina poäng i frågeformulären räknats ut och poänggränserna. Rapporten anger också vilka av dina svar som använts för att välja eventuella tilläggsfrågor.",
      "openLink": "Öppna lång rapport",
      "scores": "poäng",
      "scoreLimits": "poänggränser",
      "result": "Resultat",
      "scoreCalculationError": "Fel i poängräkningen!"
    },
    "reportInstructions": {
      "title": "Så här läser du Terapinavigatorns resultat: Val av lämplig vårdform ",
      "description": "Om du blivit ombedd att fylla i Terapinavigatorn inom hälsovården behöver du inte kunna tolka dina resultat självständigt, utan en yrkesutbildad person gör det för dig som en del av bedömningen av ditt vårdbehov. Yrkespersonen behöver bara koden som står längst upp på den här sidan för att kunna se dina resultat. Om du vill kan du ändå här bekanta dig närmare med de principer som ligger till grund för hur resultaten tolkas och hur man går till väga för att välja en lämplig vårdform.",
      "description2": "Om du hittat och fyllt i Terapinavigatorn självständigt behöver du inte heller kunna tolka dina resultat ensam eller på förhand besluta dig för vilken typ av vård du vill ha. Syftet är att du kontaktar hälsovården om du önskar få hjälp och ni kan sedan där tillsammans använda rapporten för att välja lämplig vårdform.",
      "openLink": "Öppna instruktioner om hur du tolkar resultaten"
    },
    "reportFeedback": {
      "title": "Feedback om Terapinavigatorn",
      "description": "",
      "feedbackQuestion": "Hur upplevde du Terapinavigatorn och sammandragsrapporten?",
      "feedbackOptions": [
        "Precis vad jag behövde, jag fick en klarare bild av min situation",
        "Bra, jag hade nytta av det här",
        "Vet inte",
        "Inte bra, jag hade inte nytta av det här"
      ],
      "thankyou": "Tack för feedbacken!"
    },
    "reportSelfCareLinks": {
      "title": "Egenvårdsmaterial",
      "paragraphs": [
        "Vi har samlat nyttiga egenvårdsmaterial här. De innehåller både allmän information och övningar som kan hjälp dig att må bättre. Dina resultat från Terapinavigatorn kan hjälp dig att välja ut de egenvårdsmaterial som du kan ha mest hjälp av.",
        "Om du vill kan du börja bekanta dig med egenvårdsmaterialen redan innan din vård inleds. Det kan vara till nytta när du inleder vården. Egenvårdsmaterialen kan också utgöra en del av den vård du får inom hälsovården."
      ],
      "links": [
        {
          "text": "Egenvård av depression",
          "address": "https://www.mielenterveystalo.fi/sv/egenvard/egenvardsprogrammet-depression"
        },
        {
          "text": "Egenvård av ångest",
          "address": "https://www.mielenterveystalo.fi/sv/egenvard/egenvardsprogrammet-angest"
        },
        {
          "text": "Egenvård av social ångest",
          "onlyInFinnish": true,
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/sosiaalisen-jannittamisen-omahoito-ohjelma"
        },
        {
          "text": "Egenvård av panik",
          "onlyInFinnish": true,
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/paniikin-omahoito-ohjelma"
        },
        {
          "text": "Egenvård av tvångssyndrom",
          "address": "https://www.mielenterveystalo.fi/sv/egenvard/egenvardsprogrammet-tvangssyndrom"
        },
        {
          "text": "Egenvård av sömnlöshet",
          "onlyInFinnish": true,
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/unettomuuden-omahoito-ohjelma"
        },
        {
          "text": "Självhjälpsprogram vid traumatiska kriser",
          "onlyInFinnish": true,
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/jarkyttavan-tapahtuman-omahoito-ohjelma-mieli-ry"
        }
      ]
    },
    "tests": {
      "phq": {
        "name": "PHQ-9",
        "longName": "Depressionsenkät",
        "limits": [
          "tyder på normal sinnesstämning",
          "tyder på <strong>inga/minimala depressiva symtom</strong>",
          "tyder på <strong>lindriga depressiva symtom </strong>",
          "tyder på <strong>medelsvåra depressiva symtom </strong>",
          "tyder på <strong>svåra depressiva symtom </strong>"
        ]
      },
      "gad": {
        "name": "GAD-7",
        "longName": "Ångestenkät",
        "limits": [
          "tyder på ingen eller minimal ångest",
          "tyder på <strong>lindrig ångest</strong>",
          "tyder på <strong>medelsvår ångest</strong>",
          "tyder på <strong>svår ångest </strong>"
        ]
      },
      "oci": {
        "name": "OCI-R",
        "longName": "Frågeformulär som mäter symtom på OCD",
        "limits": [
          "tyder inte på kliniskt tvångssyndrom",
          "tyder på <strong>kliniskt tvångssyndrom.</strong>"
        ]
      },
      "pdss": {
        "name": "PDSS-SR",
        "longName": "Frågeformulär om paniksymtom",
        "limits": [
          "tyder inte på paniksyndrom",
          "tyder på <strong>lindriga paniksymtom</strong>",
          "tyder på <strong>medelsvåra paniksymtom </strong>",
          "tyder på <strong>relativt svåra paniksymtom</strong>",
          "tyder på <strong>svåra paniksymtom </strong>",
          "tyder på <strong>mycket svåra paniksymtom </strong>"
        ]
      },
      "spin": {
        "name": "SPIN-FIN",
        "longName": "Frågeformulär om social ångest",
        "limits": [
          "tyder på normal / lindrig social ångest",
          "tyder på <strong>medelsvår social ångest </strong>",
          "tyder på <strong>kraftig social ångest </strong>",
          "tyder på <strong>mycket kraftig social ångest </strong>"
        ]
      },
      "audit": {
        "name": "AUDIT-C",
        "longName": "Frågeformulär om alkoholbruk",
        "limits": [
          "under riskgränsen",
          "under gränsen för riskbruk för män",
          "tyder på <strong>riskbruk för kvinnor</strong>",
          "tyder på <strong>riskbruk</strong>"
        ]
      },
      "ies": {
        "name": "IES-R",
        "longName": "Frågeformulär om posttraumatiska stressreaktioner",
        "limits": [
          "tyder inte på kliniskt posttraumatiskt stressyndrom",
          "kan tyda på <strong>kliniskt posttraumatiskt stressyndrom</strong>"
        ]
      },
      "keds": {
        "name": "KEDS",
        "longName": "Utmattningsenkät",
        "limits": [
          "tyder inte på kliniskt utmattningssyndrom",
          "tyder på<strong>kliniskt utmattningssyndrom</strong>"
        ]
      },
      "auditFull": {
        "name": "AUDIT",
        "longName": "Frågeformulär om alkoholbruk",
        "limits": [
          "använder inte alkohol",
          "<strong>små risker av alkoholbruk</strong>",
          "<strong>små risker av alkoholbruk för män</strong>",
          "<strong>något förhöjda risker av alkoholbruk för kvinnor</strong>",
          "<strong>något förhöjda risker av alkoholbruk</strong>",
          "<strong>klart förhöjda risker av alkoholbruk</strong>",
          "<strong>stora risker av alkoholbruk</strong>",
          "<strong>mycket stora risker (möjligt alkoholberoende)</strong>"
        ]
      },
      "bbgs": {
        "name": "BBGS",
        "longName": "Screeningformulär om spelberoende",
        "limits": [
          "tyder inte på penningspelsberoende",
          "tyder på <strong>eventuellt penningspelsberoende</strong>"
        ]
      },
      "bat": {
        "name": "BAT-12",
        "longName": "Frågeformulär om utbrändhet i arbetet",
        "limits": [
          "inte symtom på utmattning",
          "tyder på <strong>förhöjd risk att drabbas av utmattning</strong>",
          "tyder på <strong>sannolik utmattning</strong>"
        ]
      },
      "tsq": {
        "name": "TSQ",
        "longName": "Frågeformulär om posttraumatisk stress",
        "limits": [
          "tyder inte på risk för posttraumatiskt stressyndrom",
          "tyder på <strong>förhöjd risk för posttraumatiskt stressyndrom</strong>"
        ]
      },
      "dudit": {
        "name": "DUDIT",
        "longName": "Frågeformulär om drogrelaterade problem",
        "limits": [
          "inga problem med droganvändning",
          "<strong>sporadiska problem med droganvändning</strong>",
          "<strong>sporadiska problem med droganvändning för män</strong>",
          "<strong>sannolika problem med droganvändning för kvinnor</strong>",
          "<strong>sannolika problem med droganvändning</strong>",
          "<strong>sannolikt starkt drogberoende</strong>"
        ]
      }
    },
    "instructions": {
      "title": "TOLKNING AV TERAPINAVIGATORNS RESULTAT – Val av lämplig psykosocial vård",
      "description": "Terapinavigatorn har två syften: dels att hjälpa dig att få en överblick över dina svårigheter och möjliga vårdalternativ, dels att hjälpa hälsovården att snabbt hänvisa dig till rätt slags vård. Du behöver alltså inte på egen hand kunna tolka dina resultat eller på förhand bestämma dig för vilken typ av vård du vill ha. Rapporten har tagits fram för att ge dig möjlighet att fundera på de här sakerna om du vill, men syftet är att du vid behov söker dig till hälsovården, där du får tillfälle att tillsammans med en vårdperson gå genom olika alternativ och med hjälp av rapporten hitta lämplig vård.",
      "interpretSymptomsTitle": "1) Tolka dina symtom och symtomens svårighetsgrad",
      "interpretSymptomsContent": [
        "Gå genom rapporten som sammanställer Terapinavigatorns resultat och avsnittet ”Frågeformulär”. Poängantalen på frågeformulären ger en antydan om vilka symtom din vård skulle kunna fokusera på, hur allvarliga symtom du har och om du använder alkohol, droger eller läkemedel på ett sätt som behöver beaktas i vårdplanen.",
        "Det är vanligt att samtidigt uppleva flera olika slags symtom, som till exempel depressionssymtom och ångest. Om du upplever flera slags symtom lönar det sig att fundera på vilka symtom som är de mest centrala just nu. De här är oftast de svåraste symtomen och därför samtidigt de symtom som det lönar sig att först försöka lindra för att få så stor hjälp som möjligt.",
        "När de svåraste symtomen blir lindrigare leder det ofta till att också situationen som helhet lättar, men sömnlöshet och missbruk lönar det sig att fästa uppmärksamhet vid skilt, eftersom de inte automatiskt blir bättre i och med att symtomen på till exempel depression eller ångest lättar."
      ],
      "viewTreatmentsTitle": "2) Vårdformer",
      "viewTreatmentsContent": [
        "När du har gått genom rapporten och funderat på vilket symtom du i första hand skulle vilja få vård för, kan du bekanta dig med psykosociala vårdformer som finns listade under det symtomet. Det slutliga valet gör du sedan tillsammans med en vårdperson.",
        "<i>* När du söker dig till vården får du träffa en vårdperson som gör en närmare bedömning av ditt mående och vilket symtom det lönar sig att börja vårda i första hand. Frågeformulären som ingår i Terapinavigatorn används ofta också allmänt av professionella inom vården som en del av en närmare bedömning. </i>"
      ],
      "listTreatmentsTitle": "Vårdformer för olika slags svårigheter",
      "addictionTitle": "När du samtidigt har ett missbruk eller annat beroende",
      "addictionDescription": "Olika slags beroenden kan försämra möjligheterna att dra nytta av psykosocial vård. Om du parallellt med dina psykiska svårigheter också har ett missbruksproblem, lönar det sig att på ett planerat sätt vårda båda problemen. Det lönar sig att söka sig till vården för en närmare bedömning av situationen. Det rekommenderas att du träffar en vårdperson som är utbildad för att vårda både psykiska svårigheter och missbruksproblem. Vid lindriga och medelsvåra alkoholproblem kan du också som en del av vården ha nytta av <linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/terapiaohjelmat/alkoholin_liikakaytto/Pages/default.aspx'>nätterapi för överkonsumtion av alkohol</linkOut> (än så länge bara på finska) och <linkOut href='https://www.mielenterveystalo.fi/sv/aikuiset/itsehoito-ja-oppaat/itsehoito/juomisen_hallinnan_opas/Pages/default.aspx'>egenvårdsprogrammet Kontroll över drickandet på Psykporten.fi</linkOut>.",
      "specialNotesTitle": "Notera särskilt",
      "supportNeedsTitle": "Behov av social-, arbets- eller studerandehälsovårdstjänster",
      "supportNeedsDescription": "Vid svårigheter med boende, utkomst, familj och missbruk lönar det sig att också kartlägga vilket stöd som går att få från socialtjänsterna. När det gäller svårigheter med arbete eller studier är det bra om den offentliga hälsovården samarbetar med arbets- eller studerandehälsovården. TE-byrån erbjuder stöd med arbetssökning och sysselsättning. De bästa förutsättningarna för att bygga upp en fungerande servicehelhet finns när olika serviceenheter samarbetar med varandra.",
      "previousTreatmentsTitle": "Tidigare vård",
      "previousTreatmentsDescription1": "Om du fått psykosocial vård tidigare lönar det sig att göra en individuell bedömning för att hitta en lämplig vårdform:",
      "previousTreatmentsList": [
        "Vilka vårdformer har du haft nytta av tidigare?",
        "Har du tagit del av vårdformer som inte gett dig önskad hjälp?",
        "Vilket slags vård skulle vara mest ändamålsenlig i den här situationen?"
      ],
      "previousTreatmentsDescription2": "Ibland kan det hända att man trots flera vårdförsök inte lyckats hitta något som varit till hjälp på önskat sätt. Svårigheterna kan ha pågått mycket länge och varit i stort sett oförändrade mellan vårdförsöken. I de här situationerna är det viktigt att göra en noggrannare bedömning av behovet av olika tjänster. Det lönar sig också att ta reda på vilka olika stödformer som erbjuds av socialtjänsterna och organisationerna som stöder psykiskt välbefinnande (till exempel stödverksamhet inom Mieli rf, Centralförbundet för Mental Hälsa och föreningarna inom Mentalhälsopoolen).",
      "depressionAccordion": "Depressions-, ångest- och paniksymtom samt sömnlöshet och utmattning",
      "depressionTitle": "Val av lämplig psykosocial vård: depressions-, ångest- och paniksymtom samt sömnlöshet och utmattning ",
      "depressionMediumSymptomsTitle": "Lindriga och medelsvåra symtom",
      "depressionMediumSymptomsContent1": "När symtomen på depression, ångest, panik, sömnlöshet eller utmattning är lindriga eller medelsvåra och funktionsförmågan är relativt god kan till exempel följande psykosociala vårdformer, som erbjuds av den offentliga sektorn, vara till nytta:",
      "depressionMediumSymptomsList": [
        "<linkOut href='https://www.mielenterveystalo.fi/sv/nettiterapiat/Pages/default.aspx'>nätterapi*</linkOut>",
        {
          "listItem": "assisterad egenvård",
          "subItems": [
            "<small>Erbjuds inte ännu vid alla de enheter som använder Terapinavigatorn</small>"
          ]
        },
        "<strong>gruppvård</strong>",
        {
          "listItem": "<strong>kort par- eller familjeterapi</strong>",
          "subItems": [
            "<small>Passar vid problem i parförhållandet eller familjen, där det inte förekommer våld eller hot om våld </small>",
            "<small>Erbjuds inte ännu vid alla de enheter som använder Terapinavigatorn</small>"
          ]
        }
      ],
      "depressionMediumSymptomsContent2": "Regelbunden och hälsosam <strong>motion</strong> som är anpassad till din konditionsnivå rekommenderas som kompletterande vård. Motion i grupp är särskilt nyttigt.",
      "depressionMediumSymptomsContent3": "Om du redan prövat på en eller flera av vårdformerna som räknats upp och inte haft önskad nytta av dem, kan till exempel <strong>första linjens korttidsterapi</strong> eller <strong>korttidspsykoterapi</strong> behövas. Första linjens korttidsterapi är en form av samtalsvård som ges av en yrkesutbildad person inom vården, som också fått utbildning för att erbjuda den här terapiformen. Terapin omfattar c. 5–10 besök och fokuserar på tydliga mål.",
      "depressionMediumSymptomsContent4": "* Nätterapi för utmattning finns inte att erbjuda.",
      "depressionSevereSymptomsTitle": "Allvarliga symtom eller svag funktionsförmåga (inga tidigare vårdförsök)",
      "depressionSevereSymptomsContent1": "Om symtomen på depression, ångest, panik, sömnlöshet eller utmattning är allvarliga eller funktionsförmågan är relativt svag eller svag, lönar det sig att göra en noggrannare bedömning för att hitta en lämplig vårdform. Det lönar sig också ofta att bedöma behovet av medicinering och ta reda på om det finns behov av specialiserad psykiatrisk vård. I många fall är lämplig psykosocial vård då antingen",
      "depressionSevereSymptomsShortTherapy": "korttidspsykoterapi eller",
      "depressionSevereSymptomsLongTherapy": "lång psykoterapi",
      "depressionSevereSymptomsContent2": "Regelbunden och hälsosam <strong>motion</strong> som är anpassad till din konditionsnivå rekommenderas som kompletterande vård. Motion i grupp är särskilt nyttigt.",
      "crisisAccordion": "Kriser och posttraumatiska symtom",
      "crisisTitle": "Val av lämplig psykosocial vård: kriser och posttraumatiska symtom",
      "crisisSituationsTitle": "Kriser",
      "crisisSituationsContent": [
        "Ett sätt att se på kriser är att dela in dem i kriser som beror på en svår livssituation och traumatiska kriser. Kriser som hänger ihop med livssituationen kan vara till exempel en skilsmässa, att gå i pension, att förlora arbetet eller när en anhörig avlider efter en längre sjukdom. Traumatiska kriser kännetecknas av att de ofta är kopplade till fara för livet, till exempel allvarliga trafikolyckor eller eldsvådor, våld, våldtäkt eller sexuellt utnyttjande eller hot om någon av dessa.",
        "Som vård i kriser som beror på livssituationen passar ofta krishjälp eller samtalsstöd som erbjuds av organisationerna som stödjer mental hälsa, om du inte dessutom upplever tydliga andra symtom, såsom depression, ångest eller missbruksproblem. Stöd från socialtjänsterna kan också vara viktigt i de här situationerna. Om du upplever rikligt med andra symtom kan det vara ändamålsenligt att söka dig till psykosocial vård via hälsovården. Arten av symtom och symtomens svårighetsgrad bestämmer då vilken typ av vård som lämpar sig bäst.",
        "Vid traumatiska kriser rekommenderas vid behov kort <strong>samtalsvård</strong> hos en person som är utbildad för att behandla kriser. Syftet med vården är ofta att stödja en naturlig återhämtning efter krisen och stärka de coping-metoder som du redan har."
      ],
      "crisisTraumaTitle": "Posttraumatiska symtom",
      "crisisTraumaContent1": "Posttraumatiska symtom kan förekomma också månader eller till och med år efter de situationer som gett upphov till symtomen, om de här situationerna innehållit till exempel fara för livet, våld, sexuellt utnyttjande eller våldtäkt. Vid posttraumatiska symtom är de primära psykosociala vårdformerna",
      "crisisTraumaList1": "<strong>traumafokuserad kognitiv psykoterapi</strong> och",
      "crisisTraumaList2": "<strong>EMDR-terapi</strong>.",
      "crisisTraumaContent2": "EMDR-terapi erbjuds av yrkesutbildade personer inom mentalvården som utbildats för att erbjuda EMDR-terapi. Traumafokuserad kognitiv psykoterapi erbjuds i allmänhet av psykoterapeuter som är utbildade inom kognitiv beteendeterapi eller psykoterapeuter inom andra psykoterapiriktningar som genomgått kompletterande utbildning inom traumafokuserad kognitiv psykoterapi. Hälsosam <strong>motion</strong> och <strong>yoga</strong> rekommenderas som kompletterande vård.",
      "ocdAccordion": "Tvångssyndrom/OCD",
      "ocdTitle": "Val av lämplig psykosocial vård: tvångssyndrom/OCD",
      "ocdContent1": "Rekommenderade psykosociala vårdformer är",
      "ocdList1": "<strong>nätterapi för OCD</strong> och",
      "ocdList2": "<strong>kognitiv beteendeterapi som bygger på exponering</strong>",
      "ocdContent2": "Kognitiv beteendeterapi som bygger på exponering erbjuds i allmänhet av kognitiva psykoterapeuter, kognitiva beteendeterapeuter och psykoterapeuter inom andra psykoterapiriktningar som genomgått kompletterande utbildning i ämnet."
    }
  }
}
