import React from 'react';

import Discussion from '~/src/components/Chat/Discussion';
import DiscussionHeader from '~/src/components/Chat/DiscussionHeader';
import { mainContentId } from '~/src/constants';
import useChatConnection from '~/src/hooks/useChatConnection';

const ChatDiscussion: React.FC = () => {
  const chatConnection = useChatConnection();

  return (
    <>
      <DiscussionHeader {...chatConnection} />

      <main id={mainContentId}>
        <Discussion {...chatConnection} />
      </main>
    </>
  );
};

export default ChatDiscussion;
