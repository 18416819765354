import React, { useContext, useEffect, useState } from 'react';
import { FlowItemType, MenuItemAction } from '~/src/api';
import Container from '~/src/components/Container';
import Intro from '~/src/components/Intro';
import { flowInit } from '~/src/configurations';
import { AppContext } from '~src/state';
import { Card, Text } from '@chakra-ui/react';
import { CardContentStack } from '~src/components/Card';
import { richTextParser } from '~src/utils';
import { useTranslation } from 'react-i18next';
import { SurveySearch } from '~src/components/SurveyFlow/SurveySearch';
import { useHandleFlowInitClick } from '~src/hooks/useHandleFlowInitClick';
import ErrorCard from '~src/components/ErrorCard';
import Loading from '~src/components/Loading';

const HomeScreenSearchQuestion = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isMenuInitialized, setIsMenuInitialized] = useState(false);

  const { clickLoading, startSurveyError, handleClick } =
    useHandleFlowInitClick({
      redirectToQuestion: false,
    });

  useEffect(() => {
    if (isMenuInitialized === false) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: null,
      });
      setIsMenuInitialized(true);
      handleClick(MenuItemAction.Question, 'oirehaku');
    }
  }, [dispatch, handleClick, isMenuInitialized]);

  const flowResponse = state?.currentFlowItem?.id
    ? state.currentFlowItem
    : null;

  if (clickLoading) return <Loading />;
  if (
    startSurveyError ||
    flowResponse === null ||
    flowResponse.success === false ||
    FlowItemType.SessionClosed === flowResponse.flowItem?.type ||
    (flowResponse.success && !flowResponse.flowItem)
  )
    return <ErrorCard itemData={flowResponse} showButton={false} />;

  return <SurveySearch {...flowResponse} contentPaddingTop={0} />;
};

const HomeDokport = () => {
  const { t } = useTranslation();
  const flowIntro: [string] | string = t('flowInit.paragraph', {
    returnObjects: true,
  });

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Card shadow={flowInit.cardShadow}>
        {flowInit.titleInsideCard && <Intro />}
        <CardContentStack mt={0} pt={1}>
          {!Array.isArray(flowIntro)
            ? flowIntro
            : flowIntro.map((intro, idx) => (
                <Text pt={2} key={'info' + idx} width="100%" as="div">
                  {richTextParser(intro)}
                </Text>
              ))}
          <HomeScreenSearchQuestion />
        </CardContentStack>
      </Card>
    </Container>
  );
};

export default HomeDokport;
