{
  "common": {
    "pageTitle": "Interventionavigaattori",
    "app_name": "Interventio­navigaattori",
    "app_organization": "Terapiat etulinjaan",
    "close": "Sulje",
    "continue": "Jatka",
    "allAnswersRequired": "Vastaa kaikkiin kohtiin päästäksesi eteenpäin",
    "navigateBack": "Palaa takaisin"
  },
  "errors": {
    "generic": {
      "title": "Tapahtui odottamaton virhe",
      "message": "Hups, jotain meni pieleen. Yritä myöhemmin uudelleen."
    },
    "network": {
      "title": "Yhteysvirhe",
      "message": "Tarkista että olet yhteydessä internetiin."
    },
    "errorCard": {
      "title": "Hups, jokin meni pieleen!",
      "paragraph": "Sisältö jota haet ei ole saatavilla.",
      "ctaLabel": "Palaa etusivulle"
    },
    "sessionClosed": {
      "title": "Istunto on suljettu",
      "message": "Sisältö ei ole enää saatavilla."
    }
  },
  "languageSelect": {
    "fi": "Suomeksi",
    "sv": "På svenska"
  },
  "infoModal": {
    "title": "Usein kysyttyjä kysymyksiä",
    "paragraphContent": [
      "<strong>Mitä teen, jos mikään vastauksista ei sovi?</strong><br />Vastaa parhaaksi katsomallasi tavalla jotain, jotta pääset eteenpäin. Ammattilainen arvioi tilannettasi vielä haastattelun yhteydessä. Pääset tällöin täydentämään tai korjaamaan ammattilaiselle tietojasi.",
      "<strong>Menetin tietoni, ovatko ne jossain tallessa? / Voinko saada uuden koodin?</strong><br />Jos täytit Interventionavigaattorin ja otit lopuksi talteen koodin, pystyt lataamaan raporttisi koodin avulla. Tietoturvasyistä vastaukset ovat palautettavissa vain koodilla yhden kuukauden ajan. Ongelmana ovat tilanteet, joissa Interventionavigaattorin täyttö keskeytyy, tekniset ongelmat estävät koodin saamisen tai koodi hukkuu. Pahoittelemme näistä tilanteista aiheutuvaa vaivaa ja kehotamme täyttämään Interventionavigaattorin tarvittaessa uudelleen. Toisella kerralla täyttäminen on onneksi usein nopeampaa.",
      "Terveydenhuollon ammattilainen voi arvioida tilanteesi myös ilman Interventionavigaattorin tietoja. Arvioon tulee tällöin varata kuitenkin enemmän aikaa.",
      "<strong>Onko Interventionavigaattori täytettävä loppuun?</strong><br />Jotta Interventionavigaattorin tietoja voisi hyödyntää ammattilaisen arviossa, sen on oltava loppuun asti täytetty. Interventionavigaattorin täyttäminen voi tuntua välillä raskaalta. Yksittäisen kysymyksen vastausta ei kuitenkaan kannata jäädä liian pitkäksi aikaa pohtimaan.",
      "<strong>Mitä minun on hyvä huomioida tietoturvasta?</strong><br />Interventionavigaattoriin täytetään anonyymia tietoa. Tarkoituksena on, ettei siitä voi tunnistaa täyttäjää. Älä täytä navigaattorin avoimiin tekstikenttiin yksilöiviä henkilötietoja, kuten nimeä tai sähköpostiosoitettasi. Vastausraporttisi voi avata vain lopuksi saamallasi koodilla. Koodin säilytyksessä on syytä olla huolellinen."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "intro": {
    "title": "Interventio­navigaattori",
    "paragraph": "",
    "linkText": "Tietoja Interventio­navigaattorista"
  },
  "flowInit": {
    "title": "",
    "paragraph": [
      "Interventio­navigaattorilla kerätään nuoren mielenterveydestä tietoja, joiden avulla nuori voidaan ohjata hänelle sopivimman palvelun pariin. Interventionavigaattori sopii 13 vuotta täyttäneille nuorille ja nuorille aikuisille sekä huoltajille.",
      "Alla olevista vaihtoehdoista pääset vastaamaan sinulle suunnattuihin kysymyksiin."
    ]
  },
  "terapia": {
    "startButtons": {
      "youth": "Olen nuori",
      "guardian": "Olen huoltaja",
      "start": "Aloita täyttö",
      "startTest": "Haluan vain kokeilla täyttämistä",
      "openResults": "Avaa tuloksesi koodilla",
      "openResultsCombination": "Avaa tulokset koodilla",
      "viewExample": "Katso esimerkki tuloksista",
      "professionalInfo": "Olen ammattilainen",
      "accessibilityStatement": "Saavutettavuusseloste"
    },
    "professionalInfo": {
      "title": "Ammattilaiselle",
      "subtitle": "MIKÄ?",
      "paragraphs": [
        "Interventionavigaattori on digitaalinen työväline, joka jäsentää ja nopeuttaa psykososiaalisen tuen tarpeen arviointia.",
        "Interventionavigaattorin avulla kootaan tarvittavat tiedot nuoren kokonaistilanteen kartoittamiseksi ja tukimuodon valitsemiseksi. Ammattilainen (<strong>ensijäsentäjä</strong>) syventää Interventionavigaattorin keräämiä tietoja haastattelun avulla, jonka päätteeksi muodostuu arvio (<strong>ensijäsennys</strong>) nuorelle sopivasta psykososiaalisesta tuesta."
      ],
      "howSubtitle": "MITEN?",
      "howParagraphs": [
        "Ammattilainen ohjaa nuoren ja tarvittaessa myös huoltajan täyttämään Interventionavigaattorin. Interventionavigaattorissa on omat osionsa sekä nuorelle että huoltajalle, mutta Interventionavigaattori toimii myös yksinään nuoren vastauksilla.",
        "Täytettyään Interventionavigaattorin sekä nuori että huoltaja saavat oman, 8-merkkisen koodin. Nuori pystyy tarkastelemaan omia vastauksiaan tällä koodilla. Koodit toimitetaan sinulle sovitulla tavalla. Koodien avulla saat auki molempien vastaukset yhdistävän <strong>koosteraportin</strong>. Koosteraportin lisäksi voit tarkastella erikseen jokaista vastausta avaamalla <strong>laajan raportin</strong>."
      ],
      "reportSections": {
        "subtitle": "MITÄ?",
        "paragraphs": [
          "Interventionavigaattorin koosteraportti koostuu seuraavista osioista:"
        ],
        "sections": [
          "Ongelman kuvaus",
          "Oirekartoitus",
          "Päihteet",
          "Toimintakyky",
          "Työskentely- ja muutosvalmius",
          "Opiskeluun liittyvät vaikeudet",
          "Kasvuympäristöön ja elämäntilanteeseen liittyvät tekijät",
          "Aikaisemmat psykososiaaliset hoitokontaktit"
        ]
      },
      "instructions": {
        "subtitle": "ENSIJÄSENNYS",
        "list": [
          {
            "text": "Kerro nuorelle, mikä on ensijäsennyksen tarkoitus.",
            "subList": []
          },
          {
            "text": "Kysy nuorelta, miltä interventionavigaattorin täyttäminen tuntui.",
            "subList": []
          },
          {
            "text": "Pyydä nuorelta lupaa kopioida koosteraportti nuoren asiakirjoihin.",
            "subList": []
          },
          {
            "text": "Varmista, että nuori on täyttänyt interventionavigaattorin kaikki kohdat.",
            "subList": []
          },
          {
            "text": "Käy läpi tarvittavat lisäkysymykset.",
            "subList": [
              "Oirekartoitus: itsetuhoisuus, syömisongelmat, käytösongelmat",
              "Päihteet",
              "Toimintakyky: opinnoista suoriutuminen, ystävyyssuhteet, harrastukset, itsestä huolehtiminen",
              "Työskentely- ja muutosvalmius: ongelmien tunnistaminen, mahdollisuus sitoutua käynteihin",
              "Kasvuympäristöön liittyvät tekijät: elämänmuutokset, traumatapahtumat",
              "Aikaisempi psykososiaalinen tuki/hoito"
            ]
          },
          {
            "text": "Kerro nuorelle, mitä oirekartoituksessa tulee esille.",
            "subList": []
          },
          {
            "text": "Kerro nuorelle, miltä toimintakyky näyttää.",
            "subList": []
          },
          {
            "text": "Valitkaa keskeinen ongelma",
            "subList": [
              "Keskeinen ongelma = oireet + toimintakyky",
              "Mihin nuori toivoo apua?"
            ]
          },
          {
            "text": "Valitkaa tarpeenmukainen tuki-/hoitomuoto.",
            "subList": [
              "Varhaisen vaiheen tukitoimet ja interventio kuten esim. IPC, Ahdistuksen lyhytinterventio JA/TAI",
              "Ohjaus tarkempaan arvioon ja/tai intensiivisemmän tuen piiriin "
            ]
          }
        ]
      }
    },
    "openReport": {
      "title": "Avaa tulokset",
      "titleYouth": "Avaa nuoren tulokset",
      "titleGuardian": "Avaa huoltajan tulokset",
      "description": "Kirjoita alle Interventionavigaattorin tulosten yhteydessä saamasi koodi avataksesi tuloksesi uudelleen.",
      "descriptionCombination": "Kirjoita alle Interventionavigaattorin tulosten yhteydessä saadut koodit avataksesi tulokset uudelleen. Voit syöttää 1-3 koodia, ja aukeavan raportin tyyppi riippuu syötettyjen koodien määrästä.",
      "formLabel": "Koodi tulosten avaamiseen",
      "formLabelYouth": "Nuoren raportin koodi",
      "formLabelGuardian1": "Huoltajan raportin koodi",
      "formLabelGuardian2": "Toisen huoltajan raportin koodi",
      "openBtn": "Avaa tulokset"
    },
    "report": {
      "title": "Interventio­navigaattorin tulokset",
      "description": "Näiden tulosten koodi on <strong>{{id}}</strong>. Tämän koodin avulla voit palata myöhemmin tuloksiisi Interventionavigaattorin etusivun \"Katso tulokset\" -linkin kautta.",
      "toc": "Sisällys",
      "copyToClipboard": "Kopioi leikepöydälle",
      "downloadPdf": "Lataa kaikki tulokset pdf-tiedostona",
      "pdfInfoLink": "",
      "pdfInfoLinkUrl": "",
      "printSummary": "Tulosta koosteraportti",
      "pointAbbreviation": "p",
      "not-found": "Raporttia ei löydy annetulla koodilla"
    },
    "reportSummary": {
      "title": "",
      "subtitles": {
        "description": "Ongelmakuvaus",
        "questionnaires": "Oirekartoitus",
        "drugs": "Päihteet",
        "performance": "Toimintakyky",
        "changeReadiness": "Työskentely ja muutosvalmius",
        "studyChallenges": "Opiskeluun liittyvät vaikeudet",
        "environmentChallenges": "Kasvuympäristöön ja elämäntilanteeseen liittyvät tekijät",
        "treatments": "Hoitoja joihin valmis",
        "supportNeeds": "Sosiaali-, työ- ja opiskelupalveluiden tarve",
        "previousTreatments": "Aiemmat psykososiaaliset hoitokontaktit"
      },
      "labels": {
        "coupleTherapy": "Lyhyt pari- tai perheterapia",
        "violenceNote": "HUOM: Lähisuhteiden väkivalta / väkivallan uhka -vastaus",
        "sleep": "Uniongelma-kysymys",
        "crisis": "Elämänkriisikysymys",
        "traumaticCrisis": "Traumaattisen kriisin kysymys",
        "drugs": "Huumausaine- ja lääkekysymys",
        "addiction": "Toiminnalliset riippuvuudet -kysymys",
        "dangerousThoughts": "Itsetuhoiset ajatukset",
        "crisisDescription": "Elämänkriisin tarkennuskysymys",
        "drugsDescription": "Huumaus- ja lääkeaineiden tarkennus",
        "workPerformance": "Suoriutuminen töistä tai opinnoista viimeisen kuukauden aikana",
        "relationships": "Ihmissuhteiden sujuminen viimeisen kuukauden aikana",
        "webTherapy": "Nettiterapia",
        "groupTherapy": "Ryhmahoidot",
        "selfcare": "Ohjattu omahoito",
        "exercise": "Liikunta",
        "drugReview": "Toive lääkitysarviosta",
        "residence": "Lisätuen toive asumiseen tai perheeseen liittyvissä haasteissa",
        "violence": "Liittyykö lähisuhteisiin väkivaltaa tai väkivallan uhkaa",
        "workAndStudyStatus": "Työ- ja opiskelustatus",
        "workAndStudyStatusDetailed": "Tarkennus työ- ja opiskelustatukseen",
        "supportForWorkAndStudy": "Lisätuen toive työhön tai opiskeluun liittyvissä haasteissa",
        "previousTreatments": "Aiemmat psykososiaaliset hoidot",
        "previousTreatmentsDuration": "Aiempien hoitojen kesto",
        "previousTreatmentsDescription": "Aiempien hoitojen tarkennus",
        "noPreviousTreatments": "Ei aiempaa psykoterapiaa tai ammattilaisen keskusteluhoitoja."
      }
    },
    "reportFull": {
      "title": "Laaja raportti",
      "description": "Laajasta tarkistusraportista voit halutessasi katsoa kaikki antamasi vastaukset sekä oirekyselyiden pisteidesi laskutavan ja pisterajat. Siitä ilmenee myös minkä vastausten perusteella sinulle esitettävät lisäkysymykset ovat valikoituneet.",
      "openLink": "Avaa laaja raportti",
      "scores": "pisteet",
      "scoreLimits": "pisterajat",
      "result": "Tulos",
      "scoreCalculationError": "Virhe pisteiden laskennassa!",
      "subtitles": {
        "aloitus": "Tilanteen kartoitus",
        "aggressio": "Valehtelu, näpistys ja tappelu",
        "muutos": "Elämänmuutokset ja stressaavat kokemukset",
        "koulu": "Toimintakyky ja sosiaaliset suhteet",
        "keskustelu": "Ulkopuolisen avun tarve",
        "hoito-kontaktit": "Aikaisemmat psykososiaaliset kontaktit",
        "oppimisvaikeus": "Opiskeluun liittyvät vaikeudet"
      }
    },
    "reportInstructions": {
      "title": "Näin luet Interventio­navigaattorin tuloksia: Sopivan hoitomuodon valitseminen",
      "description": "Jos olet sopinut Interventionavigaattorin täyttämisestä terveydenhuollossa, sinun ei itse tarvitse osata tulkita tuloksiasi itsenäisesti, vaan terveydenhuollon ammattilainen tekee sen sovitusti kanssasi osana terveydenhuollon arviotasi. Ammattilainen tarvitsee vain tämän sivun yläosaan merkityn koodin, voidakseen katsoa vastauksiasi. Halutessasi voit kuitenkin tutustua tästä tarkemmin niihin periaatteisiin, joita tulosten tulkinnassa käytetään, ja siihen, miten sopivaa hoitomuotoa kartoitetaan.",
      "description2": "Samoin, jos olet täyttänyt Interventionavigaattorin itsenäisesti, sinun ei tarvitse osata tulkita vastauksia yksin tai ennalta päättää, mitä hoitoa haluat. Tarkoitus on, että hakeudut apua toivoessasi vastaanotolle ja voitte yhdessä hyödyntää koostetta sopivan hoitomuodon valitsemisessa.",
      "openLink": "Avaa tulosten lukuohjeet"
    },
    "reportFeedback": {
      "title": "Palaute Interventio­navigaattorista",
      "description": "",
      "feedbackQuestion": "Miten koit Interventionavigaattorin ja koosteraportin?",
      "feedbackOptions": [
        "Juuri tätä kaipasin, kiteytti tilanteeni",
        "Hyvä ja hyödyllinen",
        "En osaa sanoa",
        "En kokenut hyväksi tai hyödylliseksi"
      ],
      "thankyou": "Kiitos palautteesta!"
    },
    "reportSelfCareLinksYouth": {
      "title": "Interventio­navigaattorin tulosten tulkinta ja omahoito­materiaaleja",
      "paragraphs": [
        "Voit tutustua tuloksiin. Navigaattori pisteyttää vastauksia, pisteet lasketaan yhteen. Navigaattori antaa arviointimittareista tuloksia, kuten ”viittaa kohtalaiseen masennukseen” tai ”viittaa vähäisiin pakko-oireisiin”. Nämä tulokset ja kuvaukset ovat automaattisesti lasketun summan sanallisia ilmaisuja. Tulosten lopullinen tulkinta on tärkeää tehdä yhdessä ammattilaisen kanssa.",
        "Halutessasi voit tutustua Mielenterveystalon omahoito-ohjelmiin ja muihin sisältöihin.",
        "Omahoito-ohjelmat ovat asiantuntijoiden laatimia kokonaisuuksia, joista saa tietoa ja konkreettisia työkaluja oman tilanteen helpottamiseen.",
        "Voit hyödyntää niitä sisältöjä, joiden koet parhaiten vastaavan omaa tilannettasi. Tällä hetkellä erityisesti nuorille laadittuja omahoito-ohjelmia ovat"
      ],
      "links": [
        {
          "text": "Nuorten ahdistuksen omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/nuorten-ahdistuksen-omahoito-ohjelma"
        },
        {
          "text": "Itsetunto- ja identiteettipulmien omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/itsetunto-ja-identiteettipulmien-omahoito-ohjelma"
        },
        {
          "text": "Kaverisuhteiden haasteiden omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/kaverisuhteiden-haasteiden-omahoito-ohjelma"
        },
        {
          "text": "Nuorten masennuksen omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/nuorten-masennuksen-omahoito-ohjelma"
        },
        {
          "text": "Viiltelyn omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/viiltelyn-omahoito-ohjelma"
        },
        {
          "text": "Opiskelu-uupumuksen ehkäisyn omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/opiskelu-uupumuksen-ehkaisyn-omahoito-ohjelma"
        },
        {
          "text": "Syömisen ja kehonkuvan ongelmien omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/syomisen-ja-kehonkuvan-ongelmien-omahoito-ohjelma"
        }
      ],
      "endtext": "Uusia ohjelmia julkaistaan aika ajoin. Löydät niistä koosteen Nuoret-kohderyhmäsivulta."
    },
    "reportSelfCareLinksGuardian": {
      "title": "Omahoito­materiaaleja",
      "paragraphs": [
        "Nuoruusiässä tunteet heittelevät usein voimakkaasti. Huoltajan saattaa olla vaikea tunnistaa, mitkä mielialan muutoksista ovat murrosikään liittyviä, ja milloin kyseessä voi olla ammattiapua vaativa tilanne.",
        "Voit halutessasi tutustua Mielenterveystalo.fi-sivujen tietoon nuoruusiästä sekä erilaisiin omahoito-ohjelmiin, jotka ovat tarkoitettu mielen hyvinvoinnin tueksi. Omahoito-ohjelmat ovat asiantuntijoiden laatimia kokonaisuuksia, joista voit saada tietoa ja konkreettisia työkaluja oman tilanteen helpottamiseen."
      ],
      "links": [
        {
          "text": "Tietopaketti murrosiästä",
          "address": "https://www.mielenterveystalo.fi/fi/elamankaari-ja-mielenterveys/tietopaketti-murrosiasta"
        },
        {
          "text": "Mikä nuorta vaivaa ja tarvitseeko hän apua?",
          "address": "https://www.mielenterveystalo.fi/fi/elamankaari-ja-mielenterveys/mika-nuorta-vaivaa-ja-tarvitseeko-han-apua"
        },
        {
          "text": "Tietoa läheiselle nuoren eristäytymisestä",
          "address": "https://www.mielenterveystalo.fi/fi/ihmissuhteet/tietoa-laheiselle-nuoren-eristaytymisesta"
        },
        {
          "text": "Tietoa läheiselle sekavasta käytöksestä nuoruudessa",
          "address": "https://www.mielenterveystalo.fi/fi/aggressio-vakivalta/tietoa-laheiselle-sekavasta-kaytoksesta-nuoruudessa"
        }
      ]
    },
    "tests": {
      "phq": {
        "name": "PHQ-9",
        "longName": "Masennuskysely",
        "limits": [
          "viittaa normaaliin mielialaan",
          "viittaa <strong>lieviin / vähäisiin masennusoireisiin</strong>",
          "viittaa <strong>lievä-asteisiin masennusoireisiin</strong>",
          "viittaa <strong>keskivaikeisiin masennusoireisiin</strong>",
          "viittaa <strong>vaikea-asteisiin masennusoireisiin</strong>"
        ]
      },
      "gad": {
        "name": "GAD-7",
        "longName": "Ahdistuneisuuskysely",
        "limits": [
          "viittaa vähäiseen ahdistuneisuuteen",
          "viittaa <strong>lievään ahdistuneisuuteen</strong>",
          "viittaa <strong>kohtalaiseen ahdistuneisuuteen</strong>",
          "viittaa <strong>vaikeaan ahdistuneisuuteen </strong>"
        ]
      },
      "oci": {
        "name": "OCI-R",
        "longName": "Pakko-oirekysely",
        "limits": [
          "ei viittaa kliinisesti merkitsevään pakko-oiretasoon",
          "viittaa <strong>kliinisesti merkitsevään pakko-oiretasoon.</strong>"
        ]
      },
      "pdss": {
        "name": "PDSS-SR",
        "longName": "Paniikkioirekysely",
        "limits": [
          "ei viitteitä merkittävistä paniikkioireista",
          "viittaa <strong>lievään paniikkioireiluun.</strong>",
          "viittaavat <strong>kohtalaiseen paniikkioireiluun</strong>",
          "viittaa <strong>kohtalaisen vakavaan paniikkioireiluun</strong>",
          "viittaa <strong>vakavaan paniikkioireiluun</strong>",
          "viittaa <strong>erittäin vakavaan paniikkioireiluun.</strong>"
        ]
      },
      "spin": {
        "name": "SPIN-FIN",
        "longName": "Sosiaalisen jännittämisen kysely",
        "limits": [
          "viittaa normaaliin / vähäiseen sosiaalisten tilanteiden jännittämiseen",
          "viittaa <strong>kohtalaiseen sosiaalisten tilanteiden jännittämiseen</strong>",
          "viittaa <strong>voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>",
          "viittaa <strong>hyvin voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>"
        ]
      },
      "audit": {
        "name": "AUDIT-C",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "alle riskirajan",
          "viittaa <strong>naisilla riskikäyttöön</strong>",
          "viittaa <strong>miehillä riskikäyttöön</strong>"
        ]
      },
      "auditMale": {
        "name": "AUDIT-C",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "miehillä alle riskirajan",
          "viittaa <strong>miehillä riskikäyttöön</strong>"
        ]
      },
      "auditFemale": {
        "name": "AUDIT-C",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "naisilla alle riskirajan",
          "viittaa <strong>naisilla riskikäyttöön</strong>"
        ]
      },
      "ies": {
        "name": "IES-R",
        "longName": "Traumaperäisen stressin kysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään traumaperäiseen stressiin",
          "voi viitata <strong>kliinisesti merkittävään traumaperäiseen stressiin</strong>"
        ]
      },
      "keds": {
        "name": "KEDS",
        "longName": "Uupumuskysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään uupumukseen",
          "viittaa <strong>kliinisesti merkittävään uupumukseen</strong>"
        ]
      },
      "sci": {
        "name": "SCI",
        "longName": "Unikysely",
        "limits": [
          "<strong>kliinisesti merkittävä unihäiriön epäily</strong>",
          "ei viitteitä kliinisesti merkittävästä unihäiriöstä"
        ]
      },
      "scoff": {
        "name": "SCOFF",
        "longName": "Syömishäiriöseula",
        "limits": [
          "ei viitteitä kliinisesti merkittävistä syömiskäyttäytymisen haasteista",
          "viittaa <strong>kliinisesti merkittäviin syömiskäyttäytymisen haasteisiin</strong>"
        ]
      },
      "auditYouth": {
        "name": "AUDIT",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "ei viitteitä alkoholin käytöstä",
          "<strong>viitteitä alkoholin käytöstä</strong>"
        ]
      },
      "adsume": {
        "name": "ADSUME",
        "longName": "Päihdekysely",
        "limits": [
          "<ul class='adsume-limits'><li>14-15v: Viittaa <strong>raittiuteen tai kokeilukäyttöön</strong></li><li>16-18v: Viittaa <strong>raittiuteen tai kokeilukäyttöön</strong></li></ul>",
          "<ul class='adsume-limits'><li>14-15v: Viittaa <strong>toistuvaan käyttöön</strong></li><li>16-18v: Viittaa <strong>toistuvaan käyttöön</strong></li></ul>",
          "<ul class='adsume-limits'><li>14-15v: Viittaa <strong>riskikäyttöön</strong></li><li>16-18v: Viittaa <strong>riskikäyttöön</strong></li></ul>",
          "<ul class='adsume-limits'><li>14-15v: Viittaa <strong>vaaralliseen kulutukseen</strong></li><li>16-18v: Viittaa <strong>riskikäyttöön</strong></li></ul>",
          "<ul class='adsume-limits'><li>14-15v: Viittaa <strong>vaaralliseen kulutukseen</strong></li><li>16-18v: Viittaa <strong>vaaralliseen kulutukseen</strong></li></ul>"
        ]
      }
    },
    "instructions": {
      "title": "TERAPIANAVIGAATTORIN TULOSTEN TULKINTA – Sopivan psykososiaalisen hoidon valitseminen",
      "description": "Interventionavigaattorilla on kaksi tarkoitusta: auttaa sinua itse hahmottamaan vaikeuksiasi ja mahdollisia hoitovaihtoehtoja, ja auttaa ammattilaista ohjaamaan sinut nopeasti oikeanlaisen avun piiriin. Sinun ei tarvitse siis osata tulkita vastauksia yksin tai ennalta päättää, mitä hoitoa haluat, vaan raportin on tarkoitus antaa tilaisuus näiden pohtimiseen niin halutessasi. Tarkoitus on, että hakeudut apua toivoessasi vastaanotolle ja voitte yhdessä hyödyntää koostetta sopivan hoitomuodon valitsemisessa.",
      "interpretSymptomsTitle": "1) Tulkitse oireesi ja niiden vakavuus",
      "interpretSymptomsContent": [
        "Käy läpi koosteraporttisi Interventio­navigaattorin tuloksista ja sen ”Oirekyselyt”-osio. Oirekyselyiden pistemäärät kertovat suuntaa siitä, mihin oireisiin hoitosi voisi kohdentaa, kuinka vakavista oireista on kyse ja vaikuttaako päihteiden käyttö hoitosuunnitelmaan.",
        "On tavallista, että vaikeuksiin liittyy useanlaisia oireita, kuten masennusoireita ja ahdistusta. Jos sinulla on useanlaisia oireita, pohdi, mikä niistä on pääasiallinen oireesi. Se on yleensä oireista vakavin ja sen helpottumisesta koituisi eniten apua.",
        "Pääasiallisen oireen hoitaminen tuo usein laajempaa helpotusta, mutta etenkin unettomuuteen ja päihdeongelmaan tulisi kiinnittää myös erillistä huomiota. Ne eivät automaattisesti korjaudu esimerkiksi masennuksen tai ahdistuksen helpottumisen myötä."
      ],
      "viewTreatmentsTitle": "2) Katso hoitomuodot",
      "viewTreatmentsContent": [
        "Kun olet tarkastellut koosteraporttiasi ja pohtinut, mihin pääasialliseen oireeseen toivoisit hoidon kohdistuvan, katso alta vaikeuteen soveltuvia psykososiaalisia hoitomuotoja. Sopivin hoitomuoto valitaan lopulta yhdessä ammattilaisen kanssa.",
        "<i>* Kun hakeudut hoitoon, ammattilainen tekee kanssasi tarkemman arvion voinnistasi ja siitä, mihin oireisiin hoito tulisi ensisijaisesti kohdentaa. Myös ammattilaiset käyttävät Interventio­navigaattorissa käytettyjä oirekyselyitä usein osana tarkempaa arviotaan.</i>"
      ],
      "listTreatmentsTitle": "Hoitomuodot eri vaikeuksiin",
      "addictionTitle": "Kun vaikeuksien rinnalla on päihdeongelma tai muu riippuvuus",
      "addictionDescription": "Riippuvuusongelma voi vaikeuttaa psykososiaalisesta hoidosta hyötymistä. Kun mukana on sekä mielenterveysongelma että päihdeongelma, niitä molempia tulisi hoitaa suunnitelmallisesti. On hyvä hakeutua tarkempaan ammattilaisen arvioon. Kasvokkainen hoito päihdehoitoa ja mielenterveyshoitoa tuntevan ammattilaisen kanssa on suositeltavaa. Lievissä ja keskivaikeissa alkoholiongelmissa psykososiaalisen hoidon osana voi myös käyttää mm. <linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/terapiaohjelmat/alkoholin_liikakaytto/Pages/default.aspx'>alkoholin liikakäytön nettiterapiaa</linkOut> ja Mielenterveystalo.fi:n <linkOut href='https://www.mielenterveystalo.fi/aikuiset/itsehoito-ja-oppaat/itsehoito/juomisen_hallinnan_opas/Pages/default.aspx'>Juomisen hallinnan omahoito-ohjelmaa</linkOut>.",
      "specialNotesTitle": "Erityistä huomioitavaa",
      "supportNeedsTitle": "Sosiaali-, työ- ja opiskeluterveydenhuollon palveluiden tarve",
      "supportNeedsDescription": "Asumiseen, toimeentuloon, perheeseen ja päihteisiin liittyvissä haasteissa on hyvä kartoittaa, mitä tukea voisi terveydenhuollon lisäksi saada sosiaalipalveluista. Työhön ja opiskeluun liittyvissä haasteissa on usein suositeltavaa, että julkinen hoitotaho tekee yhteistyötä työ- tai opiskeluterveydenhuollon kanssa. Työllistymisessä tukee TE-toimisto. Hyvä yhteistyö ja suunnitelma eri palveluiden välillä tukee toimivan palvelukokonaisuuden rakentamista.",
      "previousTreatmentsTitle": "Aiemmat hoidot",
      "previousTreatmentsDescription1": "Jos taustalla on aiempia hoitoja, kannattaa sopiva hoitomuoto arvioida yksilöllisesti:",
      "previousTreatmentsList": [
        "Onko hoitomuotoja, joista on ollut apua?",
        "Onko hoitomuotoja, jotka eivät ole auttaneet toivotusti?",
        "Mikä olisi tässä vaiheessa tarkoituksenmukaisin hoito?"
      ],
      "previousTreatmentsDescription2": "Joskus taustalla on useita hoitoyrityksiä, joista ei ole saatu toivottavaa helpotusta. Vaikeudet ovat saattaneet kestää hyvin pitkään, eivätkä ole juurikaan olleet poissa hoitomuotojen välillä. Tällöin on tarpeen tehdä tarkempi arvio eri palveluiden tarpeesta. Myös esimerkiksi sosiaalipalveluiden ja mielenterveysjärjestöjen tukimahdollisuudet on hyvä ottaa huomioon (esim. Mieli ry:n, Mielenterveyden keskusliiton ja Mielenterveyspoolin yhdistysten tukitoiminta).",
      "depressionAccordion": "Masennus-, ahdistus- ja paniikkioireet sekä unettomuus ja uupumus",
      "depressionTitle": "Sopivan psykososiaalisen hoidon valitseminen: masennus-, ahdistus- ja paniikkioireet, unettomuus ja uupumus",
      "depressionMediumSymptomsTitle": "Lievät ja keskivaikeat oireet",
      "depressionMediumSymptomsContent1": "Kun masennukseen, ahdistuneisuuteen, paniikkioireisiin, unettomuuteen tai uupumukseen liittyvät vaikeudet ovat lieviä tai keskivaikeita ja toimintakyky on vähintään kohtalaista, ovat hyviä julkisen terveydenhuollon psykososiaalisia hoitomuotoja esimerkiksi",
      "depressionMediumSymptomsList": [
        "<linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/Pages/default.aspx'>nettiterapia*</linkOut>",
        {
          "listItem": "ohjattu omahoito",
          "subItems": [
            "<small>Ei vielä kaikissa navigaattoria hyödyntävissä yksiköissä</small>"
          ]
        },
        "<strong>ryhmähoidot</strong>",
        {
          "listItem": "<strong>lyhyt pari- tai perheterapia</strong>",
          "subItems": [
            "<small>Soveltuu pari- ja perhepainotteisissa ongelmissa, jos suhteeseen ei liity väkivaltaa tai sen uhkaa</small>",
            "<small>Ei vielä kaikissa navigaattoria hyödyntävissä yksiköissä</small>"
          ]
        }
      ],
      "depressionMediumSymptomsContent2": "Lisäksi kuntoon sopeutettu terveellinen ja säännöllinen <strong>liikunta</strong> on suositeltava täydentävä hoito. Ryhmäliikunta on erityisen suositeltavaa.",
      "depressionMediumSymptomsContent3": "Mikäli yhtä tai useampaa yllä mainituista hoidoista on kokeiltu, eikä niistä ole saatu toivottua hyötyä, voi esim. <strong>etulinjan lyhytterapia</strong> tai <strong>lyhytpsykoterapia</strong> olla tarpeen. Etulinjan lyhytterapia on n. 5–10 käyntiä kestävä tavoitteellinen keskusteluhoito ja sen toteuttaa siihen koulutettu terveydenhuollon ammattilainen.",
      "depressionMediumSymptomsContent4": "* Uupumukseen ei ole tarjolla nettiterapiaa.",
      "depressionSevereSymptomsTitle": "Vakava oireilu tai heikko toimintakyky (ei aiempia hoitoyrityksiä)",
      "depressionSevereSymptomsContent1": "Jos masennukseen, ahdistuneisuuteen, paniikkiin, unettomuuteen tai uupumukseen liittyvät oireet ovat vakavia tai toimintakyky on melko huono tai huono, on syytä arvioida sopivat hoitomuodot tarkemmin. Usein on myös hyvä tehdä lääkitysarvio sekä selvittää, onko psykiatrian erikoissairaanhoidolle tarvetta. Melko usein psykososiaaliseksi hoidoksi soveltuu joko",
      "depressionSevereSymptomsShortTherapy": "lyhytpsykoterapia tai",
      "depressionSevereSymptomsLongTherapy": "pitkä psykoterapia",
      "depressionSevereSymptomsContent2": "Lisäksi kuntoon sopeutettu terveellinen ja säännöllinen <strong>liikunta</strong> on suositeltava täydentävä hoito. Ryhmäliikunta on erityisen suositeltavaa.",
      "crisisAccordion": "Kriisitilanteet ja traumaperäiset oireet",
      "crisisTitle": "Sopivan psykososiaalisen hoidon valitseminen: kriisitilanteet ja traumaperäiset oireet",
      "crisisSituationsTitle": "Kriisitilanteet",
      "crisisSituationsContent": [
        "Yksi tapa hahmottaa kriisejä on jakaa ne elämäntilannekriiseihin ja traumaattisiin kriiseihin. Elämäntilannekriisejä voi olla esimerkiksi avioero, eläkkeelle jääminen, työpaikan menetys tai läheisen menehtyminen pitkäaikaiseen sairauteen. Traumaattiset kriisit puolestaan sisältävät usein hengenvaaraa esim. vakava auto-onnettomuus tai tulipalo, väkivaltaa, raiskauksen tai seksuaalista hyväksikäyttöä tai näiden uhkaa.",
        "Elämäntilannekriiseissä kuten avioerossa tai työpaikan menetyksessä, soveltuu usein esimerkiksi mielenterveysjärjestöjen ja -säätiöiden tarjoama kriisituki ja keskusteluapu, jos vaikeuksiin ei liity selkeää muuta oireilua, kuten masennusta, ahdistusta tai päihdeongelmaa. Myös sosiaalipalveluiden tuki voi tulla kyseeseen. Jos muu oireilu on merkittävää, voi terveydenhuollon tarjoama psykososiaalinen hoito olla tarkoituksenmukaista. Sopivan hoitomuodon valinta perustuu tällöin oireilun laatuun ja vakavuuteen.",
        "Traumaattisissa kriiseissä suositellaan tarvittaessa lyhyttä <strong>keskusteluhoitoa</strong> kriisien hoitoon koulutetun ammattilaisen kanssa. Yleensä hoidon tavoitteena on tukea luonnollista kriisistä selviytymistä ja vahvistaa henkilön selviytymiskeinoja."
      ],
      "crisisTraumaTitle": "Traumaperäiset oireet",
      "crisisTraumaContent1": "Traumaperäiset oireet voivat liittyä myös kuukausia tai vuosiakin sitten koettuihin tilanteisiin, joihin on sisältynyt esimerkiksi hengenvaara, väkivaltaa, raiskaus tai seksuaalista hyväksikäyttöä. Traumaperäisissä oireissa ensisijaiset psykososiaaliset hoitomuodot ovat",
      "crisisTraumaList1": "<strong>traumafokusoitu kognitiivinen psykoterapia</strong> ja",
      "crisisTraumaList2": "<strong>EMDR-silmänliiketerapia</strong>.",
      "crisisTraumaContent2": "EMDR-terapiaa tarjoavat aiheeseen koulutetut mielenterveyden ammattilaiset. Traumafokusoitua kognitiivista psykoterapiaa tarjoaa yleensä kognitiivinen psykoterapeutti, kognitiivisen käyttäytymisterapian psykoterapeutti tai aiheeseen täydennyskoulutuksen saanut muu psykoterapeutti. Terveellinen <strong>liikunta</strong> ja <strong>jooga</strong> ovat suositeltavia täydentäviä hoitoja.",
      "ocdAccordion": "Pakko-oireet",
      "ocdTitle": "Sopivan psykososiaalisen hoidon valitseminen: pakko-oireet",
      "ocdContent1": "Suositeltavia psykososiaalisia hoitomuotoja ovat",
      "ocdList1": "<strong>pakko-oireisen häiriön nettiterapia</strong> ja",
      "ocdList2": "<strong>psykoterapioista altistukseen perustuva kognitiivinen käyttäytymisterapia</strong>",
      "ocdContent2": "Altistukseen perustuvaa kognitiivista käyttäytymisterapiaa tarjoavat yleensä kognitiiviset psykoterapeutit, kognitiivisen käyttäytymisterapian psykoterapeutit ja aiheeseen täydennyskoulutuksen saaneet muut psykoterapeutit."
    },
    "youthHomeTitle": "Nuoren Interventio­navigaattori",
    "youthHome": [
      "Tervetuloa käyttämään Interventio­navigaattoria!",
      "Tässä kyselyssä kartoitetaan sinun elämäntilannettasi ja ajankohtaisia ongelmia tai vaikeuksia, joita olet saattanut kokea. Näitä tietoja kootaan, jotta sinut voidaan ohjata sopivimman tukimuodon pariin.",
      "Kaikilla on joskus pahaa oloa. On normaalia kokea itsensä välillä alakuloiseksi tai ahdistuneeksi. Toisinaan paha olo voi jatkua pidempään. Joka viides nuori kärsii jostain mielenterveyshäiriöstä tai niihin viittaavista oireista. Yleisimpiä nuorten mielenterveysongelmia ovat mieliala-, ahdistuneisuus-, käytös- ja päihdeongelmat. Mitä varhaisemmassa vaiheessa apua saa, sitä harvemmin oireilu tai ongelma pahenee, ja toipuminen on nopeampaa.",
      "Jos sinulla on itsetuhoisia ajatuksia ja olet epävarma turvallisuudestasi hoidon käynnistymistä odottaessasi, hakeudu suoraan ammattilaisen arvioon, esimerkiksi opiskeluhuollon vastaanotolle (terveydenhoitaja, kuraattori tai psykologi) tai terveysasemalle. Kerro ammattilaiselle myös itsetuhoisista ajatuksistasi, jotta saat tilanteeseesi parhaan mahdollisen avun. ",
      "Välitöntä apua edellyttävässä hätätilanteessa hakeudu terveysaseman päivystyskäynnille tai päivystykseen. Voit myös soittaa hätänumeroon 112.",
      "<strong>Varaa aikaa täyttämiseen</strong><br />Interventio­navigaattorin täyttäminen kestää n. <strong>30–40 minuuttia. Keskeneräisiä vastauksia ei voi tallentaa, joten varaathan riittävästi aikaa kyselyn täyttämiseen.</strong>",
      "<div class='frontpage-highlight-box'><strong>Ota lopuksi koodisi talteen</strong><br />Lopussa saat henkilökohtaisen koodin, jonka avulla voit avata yhteenvedon. Yhteenveto on mahdollista avata koodilla kuukauden ajan. Ota koodi varmaan talteen. Opiskeluhuollon tai terveydenhuollon ammattilainen voi kysyä sitä voidakseen luvallasi katsoa tuloksesi. Voit myös halutessasi ladata raportin tuloksistasi laitteellesi.</div>",
      "<strong>Huomioi tietoturva</strong><br />Interventio­navigaattori koostuu anonyymeistä monivalintakysymyksistä, oirekyselyistä ja lyhyistä avoimista tekstikentistä. Älä tietoturvasi varmistamiseksi täytä navigaattorin avoimiin tekstikenttiin yksilöiviä henkilötietoja kuten nimeä, henkilöturvatunnusta tai yhteystietojasi kuten sähköpostiosoitettasi. Mitään yksityiskohtaisia tietoja, joista sinut on helppo tunnistaa, ei tule täyttää.",
      "Interventio­navigaattori on kehitetty osana Terapiat etulinjaan ‑yhteiskehittämishanketta."
    ],
    "guardianHomeTitle": "Huoltajan Interventio­navigaattori",
    "guardianHome": [
      "Tervetuloa käyttämään Interventio­navigaattoria!",
      "Hyvä huoltaja, olet saanut pyynnön täyttää Interventio­navigaattorin, joka koskee nuoresi mielenterveyden tuen tarpeen arviointia. Interventio­navigaattorin tarkoituksena on koota tietoja, joita tarvitaan, jotta nuori voidaan ohjata sopivan psykososiaalisen tuen piiriin. Antamiasi tietoja voidaan tarvittaessa käsitellä yhdessä nuoren kanssa.",
      "Joka viides nuori kärsii jostain mielenterveyshäiriöstä tai niihin viittaavista oireista. Yleisimpiä nuorten mielenterveysongelmia ovat mieliala-, ahdistuneisuus-, käytös- ja päihdeongelmat. Mitä varhaisemmassa vaiheessa apua saa, sitä harvemmin oireilu tai ongelma pahenee, ja toipuminen on nopeampaa.",
      "Nuoren tilannetta on tärkeää arvioida kokonaisvaltaisesti, minkä vuoksi Interventio­navigaattorin täyttämistä toivotaan sekä huoltajalta että nuorelta itseltään. Huoltajan täyttämässä Interventio­navigaattorissa kysytään nuoren terveydestä ja hyvinvoinnista sekä koulunkäynnistä ja vapaa-ajan vietosta.",
      "<strong>Varaa aikaa täyttämiseen</strong><br />Interventio­navigaattorin täyttäminen kestää n. <strong>10–20 minuuttia. Keskeneräisiä vastauksia ei voi tallentaa.</strong>",
      "<div class='frontpage-highlight-box'><strong>Ota lopuksi koodisi talteen</strong><br />Lopussa saat henkilökohtaisen koodin, jolla yhteenveto vastauksistasi voidaan avata kuukauden ajan. Ota koodi varmaan talteen. Opiskeluhuollon tai terveydenhuollon ammattilainen voi kysyä sitä voidakseen luvallasi katsoa vastauksesi. Voit myös halutessasi ladata yhteenvedon laitteellesi.</div>",
      "<strong>Huomioi tietoturva</strong><br />Interventio­navigaattori koostuu anonyymeistä monivalintakysymyksistä, oirekyselyistä ja lyhyistä avoimista tekstikentistä. Tietoturvasi varmistamiseksi tekstikenttiin ei tule kirjoittaa mitään yksityiskohtaisia tietoja, joista sinut on helppo tunnistaa. Älä täytä navigaattorin avoimiin tekstikenttiin yksilöiviä henkilö- tai yhteystietojasi, kuten nimeäsi, henkilötunnustasi tai sähköpostiosoitettasi.",
      "Interventio­navigaattori on kehitetty osana Terapiat etulinjaan ‑yhteiskehittämishanketta."
    ]
  }
}
