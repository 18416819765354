import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { Report } from '~/src/api';
import { useCreateQuestionnaireResult } from '~/src/hooks/useCreateQuestionnaireResult';
import { createReportMap, getAnswerFunction } from '../reportUtils';
import { Subtitle } from '~/src/components/Headings';
import { devlogger } from '~src/utils';

export const SummaryContentV1: FC<{ report: Report }> = ({ report }) => {
  const { t } = useTranslation();
  const { createQuestionnaireResult } = useCreateQuestionnaireResult();
  const data = createReportMap(report.answers);
  const getAnswer = getAnswerFunction(data);

  devlogger('Displaying Terapia summary V1');

  const pariterapiaAnswer = getAnswer('22-pariperhe-tarkennus', {
    label: t('terapia.reportSummary.labels.coupleTherapy'),
  });
  const vakivaltaAnswer = getAnswer('22-väkivalta', {
    label: t('terapia.reportSummary.labels.violenceNote'),
    spaceBeforeNext: true,
  });

  return (
    <>
      <Subtitle toUpper={true}>
        {t('terapia.reportSummary.subtitles.description')}
      </Subtitle>
      {getAnswer('1-tilanne', { spaceBeforeNext: true })}
      <Subtitle>{t('terapia.reportSummary.subtitles.questionnaires')}</Subtitle>

      {createQuestionnaireResult(report, 'phq')}
      {createQuestionnaireResult(report, 'gad')}
      {createQuestionnaireResult(report, 'oci')}
      {createQuestionnaireResult(report, 'pdss')}
      {createQuestionnaireResult(report, 'spin')}
      {createQuestionnaireResult(report, 'ies')}
      {createQuestionnaireResult(report, 'keds')}
      {createQuestionnaireResult(report, 'audit')}
      {getAnswer('13-univaikeudet', {
        label: t('terapia.reportSummary.labels.sleep'),
        upperCase: true,
        bold: true,
      })}

      {getAnswer('4-kriisi', {
        label: t('terapia.reportSummary.labels.crisis'),
        upperCase: true,
        bold: true,
      })}
      {getAnswer('12-tilanne-aika', {
        label: t('terapia.reportSummary.labels.traumaticCrisis'),
        upperCase: true,
        bold: true,
      })}
      {getAnswer('15-huumeet', {
        label: t('terapia.reportSummary.labels.drugs'),
        upperCase: true,
        bold: true,
      })}
      {getAnswer('16-riippuvuus', {
        label: t('terapia.reportSummary.labels.addiction'),
        upperCase: true,
        bold: true,
      })}
      <div data-no-clipboard>
        <br />
        <Trans i18nKey="terapia.reportSummary.questionnaireDisclaimer" />
        <br />
      </div>
      <br />
      {data.get('phq-9')?.answerValue !== '0' &&
        getAnswer('phq-9', {
          label: t('terapia.reportSummary.labels.dangerousThoughts'),
          linebreakAfterLabel: true,
        })}
      {getAnswer('5-syy-kriisiin', {
        label: t('terapia.reportSummary.labels.crisisDescription'),
        linebreakAfterLabel: true,
      })}

      {getAnswer('15-huumeet-tarkennus', {
        label: t('terapia.reportSummary.labels.drugsDescription'),
        linebreakAfterLabel: true,
      })}

      <Subtitle>{t('terapia.reportSummary.subtitles.performance')}</Subtitle>

      {getAnswer('17-toimintakyky', {
        label: t('terapia.reportSummary.labels.workPerformance'),
        upperCase: true,
        linebreakAfterLabel: true,
      })}
      {getAnswer('18-suhteet', {
        label: t('terapia.reportSummary.labels.relationships'),
        upperCase: true,
        linebreakAfterLabel: true,
      })}

      <Subtitle>{t('terapia.reportSummary.subtitles.treatments')}</Subtitle>
      {pariterapiaAnswer}
      {pariterapiaAnswer && vakivaltaAnswer}
      {getAnswer('23-nettiterapia', {
        label: t('terapia.reportSummary.labels.webTherapy'),
      })}
      {getAnswer('25-ryhmähoidot', {
        label: t('terapia.reportSummary.labels.groupTherapy'),
      })}
      {getAnswer('26-omahoito', {
        label: t('terapia.reportSummary.labels.selfcare'),
      })}
      {getAnswer('27-liikunta', {
        label: t('terapia.reportSummary.labels.exercise'),
      })}
      <br />
      {getAnswer('28-lääkäri', {
        label: t('terapia.reportSummary.labels.drugReview'),
      })}
      <br />

      <Subtitle>{t('terapia.reportSummary.subtitles.supportNeeds')}</Subtitle>
      {getAnswer('asuminen', {
        label: t('terapia.reportSummary.labels.residence'),
        linebreakAfterLabel: true,
      })}
      {getAnswer('22-väkivalta', {
        label: t('terapia.reportSummary.labels.violence'),
        linebreakAfterLabel: true,
      })}
      {getAnswer('20-työ', {
        label: t('terapia.reportSummary.labels.workAndStudyStatus'),
        linebreakAfterLabel: true,
      })}
      {getAnswer('työtarkennus', {
        label: t('terapia.reportSummary.labels.workAndStudyStatusDetailed'),
        linebreakAfterLabel: true,
      })}
      {getAnswer('työtuki1', {
        label: t('terapia.reportSummary.labels.supportForWorkAndStudy'),
        linebreakAfterLabel: true,
      })}

      <Subtitle>
        {t('terapia.reportSummary.subtitles.previousTreatments')}
      </Subtitle>
      {!getAnswer('30-hoitokokemus', {
        label: t('terapia.reportSummary.labels.previousTreatments'),
      }) && (
        <Text>{t('terapia.reportSummary.labels.noPreviousTreatments')}</Text>
      )}
      {getAnswer('31-hoitokesto', {
        label: t('terapia.reportSummary.labels.previousTreatmentsDuration'),
        spaceBeforeNext: true,
      })}
      {getAnswer('32-hoitolisä', {
        label: t('terapia.reportSummary.labels.previousTreatmentsDescription'),
        linebreakAfterLabel: true,
      })}
    </>
  );
};
