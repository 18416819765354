import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { gql, useApolloClient } from '@apollo/client';
import { Box, Heading, Image } from '@chakra-ui/react';

import { Options } from '~/src/api';
import {
  formatOptionLabel,
  handleDropdownIndicator,
  handleKeyboardShortcuts,
} from '~/src/utils';
import collectionIcons from '~/src/images/collection-icons';
import useSelectStyles from '~src/hooks/useSelectStyles';

const CONTENT_SEARCH = gql`
  query ContentSearch($query: String) {
    contentSearch(query: $query) {
      label
      value
      imageId
    }
  }
`;

type SearchOption = { icon?: string; label?: string };

const NoResultOptionValue = 'trigger-no-result-answer';

export const SearchField: React.FC<{
  selectSurveyOption: (value: Options) => void;
  handleNoResults?: () => void;
  showUntilOptionsVisible: React.ReactElement | null | undefined;
  showDefaultOptionsAlways?: boolean;
  noResultsText?: string;
}> = ({
  selectSurveyOption,
  showUntilOptionsVisible,
  showDefaultOptionsAlways = true,
  noResultsText,
  handleNoResults,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const selectStyles = useSelectStyles();

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectOptionsVisible, setSelectOptionsVisible] = useState(false);

  const noResultLabel = noResultsText || t('qAndAFlow.search.noResultsLabel');

  const loadOptions = async (inputValue: string, callback: any) => {
    setIsLoadingOptions(true);

    const { data, loading } = await client.query({
      query: CONTENT_SEARCH,
      variables: { query: inputValue },
    });

    if (loading) return null;
    setIsLoadingOptions(false);
    const newOptions =
      data.contentSearch.length === 0
        ? handleNoResults && [
            { label: noResultLabel, value: NoResultOptionValue },
          ]
        : data.contentSearch;
    return callback([...newOptions]);
  };

  return (
    <Box minHeight="330px" width="full">
      <AsyncSelect
        isLoading={isLoadingOptions}
        autoFocus
        cacheOptions
        captureMenuScroll={false}
        components={{
          DropdownIndicator: () => handleDropdownIndicator(showSearchIcon),
          IndicatorSeparator: () => null,
          GroupHeading,
        }}
        maxMenuHeight={300}
        filterOption={undefined}
        formatOptionLabel={formatOptionLabel}
        loadOptions={loadOptions}
        loadingMessage={() => null}
        menuShouldScrollIntoView={false}
        noOptionsMessage={() => null}
        menuPosition="fixed"
        onChange={(option) => {
          const typedOption = option as Options;
          typedOption.value === NoResultOptionValue
            ? handleNoResults?.()
            : selectSurveyOption(typedOption);
        }}
        onInputChange={(val: string) => {
          const shouldShowOptions = val.length > 0;
          setSelectOptionsVisible(shouldShowOptions);
          setShowSearchIcon(!val);
        }}
        onKeyDown={handleKeyboardShortcuts}
        placeholder={t('common.search.placeholder')}
        styles={selectStyles}
      />
      {(!selectOptionsVisible || showDefaultOptionsAlways) &&
        showUntilOptionsVisible}
    </Box>
  );
};

function GroupHeading({ data }: { data: SearchOption }) {
  return (
    <>
      {data?.icon && (
        <Image src={collectionIcons[data.icon]} maxWidth="40px" m={3} />
      )}
      <Heading size="md" textAlign="left" m={3}>
        {data?.label}
      </Heading>
    </>
  );
}
