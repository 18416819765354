import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { flowInit } from '~/src/configurations';
import Container from '~/src/components/Container';
import Card, { CardContentStack } from '~/src/components/Card';
import { FlowItemImage } from '~src/components/SurveyFlow/components/FlowItemImage';
import { FlowItemTitle } from '~src/components/SurveyFlow/components/FlowItemTitle';
import AuthenticationImage from '~/src/images/image-bank/authentication.svg';
import { FlowItemDescription } from '~src/components/SurveyFlow/components/FlowItemDescription';
import { useI18nUtils } from '~src/hooks/i18nUtils';
import { useSetLogo } from '~src/hooks/useAddAnswer';

export const HomeDlaakarit: React.FC = () => {
  const { setLogo } = useSetLogo();
  const { t } = useTranslation();
  const { getI18nArrayOfStrings } = useI18nUtils();

  useEffect(() => void setLogo(null), [setLogo]);

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Card
        shadow={flowInit.cardShadow}
        px={[4, 6, 8]}
        pb={0}
        mb={0}
        alignItems="flex-start"
      >
        <CardContentStack mb={0}>
          <FlowItemImage imgUrl={AuthenticationImage} />
          <FlowItemTitle title={t('flowInit.title')} />
          <FlowItemDescription
            description={getI18nArrayOfStrings('flowInit.paragraph')}
          />
        </CardContentStack>
      </Card>
    </Container>
  );
};
