import React from 'react';
import {
  Box,
  Icon,
  HStack,
  Progress,
  IconButton,
  IconProps,
} from '@chakra-ui/react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { header, styles } from '~/src/configurations';
import { FlowResponse } from '~src/api';
import { ApolloError } from '@apollo/client';

const CheckIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      transform="translate(-358.000000, -89.000000)"
      fill="currentColor"
      d="M381.358549,89.2517642 C380.685307,88.769099 379.748263,88.9235567 379.265549,89.5967642 L365.230549,109.164764 L360.545549,104.475764 C360.166669,104.096705 359.614344,103.948571 359.096629,104.087161 C358.578915,104.225752 358.174464,104.630012 358.035629,105.147661 C357.896795,105.66531 358.044669,106.217705 358.423549,106.596764 L364.358549,112.530764 C364.673751,112.834072 365.104022,112.987422 365.540005,112.95184 C365.975988,112.916257 366.3757,112.695168 366.637549,112.344764 L381.703549,91.3447642 C382.186214,90.6715217 382.031757,89.7344782 381.358549,89.2517642 Z"
      id="Path"
    ></path>
  </Icon>
);

const ProgressBar: React.FC<
  FlowResponse & { showErrorCard: boolean | ApolloError }
> = ({ flowItem, showErrorCard }) => {
  const history = useHistory();
  const { currentStep, totalSteps, showProgressBar, pointOfNoReturn } =
    flowItem || {};

  const itemProgressValue =
    currentStep && totalSteps && 100 / (totalSteps / currentStep);
  const isProgressBarVisible = showProgressBar && !showErrorCard;

  const navigationDisabled =
    itemProgressValue === 100 || pointOfNoReturn || false;
  const { t } = useTranslation();

  if (!isProgressBarVisible || !header.progressBarVisible) return null;
  return (
    <HStack mb={4} spacing={0}>
      <IconButton
        icon={
          <BsArrowLeftShort
            fontSize="3rem"
            style={{ position: 'absolute', marginLeft: '-.1rem' }}
          />
        }
        aria-label={t('common.navigateBack')}
        variant="ghost"
        size="md"
        isDisabled={navigationDisabled}
        onClick={() => !navigationDisabled && history.goBack()}
        background="none"
        borderRadius="full"
        cursor="pointer"
        padding="0"
        _hover={{ background: 'none' }}
      />
      <Box borderRadius="full" mr=".4rem" overflow="hidden" width="100%">
        <Progress
          value={itemProgressValue || 0}
          height="4px"
          colorScheme="primary"
          bgColor={styles.progressBarBgColor}
        />
      </Box>
      <CheckIcon
        w="1.3rem"
        h="1.3rem"
        color={itemProgressValue === 100 ? 'primary.500' : '#ebebeb'}
      />
    </HStack>
  );
};

export default ProgressBar;
