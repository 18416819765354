import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';
import { ReportFeedback } from './ReportFeedback';

export const FeedbackSection: FC<{ title: { text: string; id: string } }> = ({
  title,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading as="h2" size="lg" mt={16} mb={8} id={title.id}>
        {title.text}
      </Heading>
      <Text>{t('terapia.reportFeedback.description')}</Text>
      <ReportFeedback />
    </>
  );
};
