import React from 'react';
import { envs } from '~/src/utils';
import { InterventioPrivacy } from './InterventioPrivacy';
import { TerapiaPrivacy } from './TerapiaPrivacy';

const Privacy: React.FC = () => {
  if (envs.CONFIGURATION === 'Interventionavigaattori')
    return <InterventioPrivacy />;

  if (envs.CONFIGURATION === 'Terapianavigaattori') return <TerapiaPrivacy />;

  return null;
};

export default Privacy;
