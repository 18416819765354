import React, { useCallback, useContext, useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AuthenticationImage from '~/src/images/image-bank/authentication.svg';
import { CardContentStack } from '~/src/components/Card';
import { flowAuthCallbackRoot } from '~/src/constants';
import {
  AuthenticationMethod,
  FlowResponse,
  useGetAuthenticationMethodsQuery,
  useInitAuthenticationMutation,
} from '~/src/api';
import { AppContext } from '~/src/state';
import { authenticationImages } from '~/src/components/SurveyFlow/Authentication/imageMapper';
import { isValidUrl } from '~src/utils/isValidUrl';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemTitle } from './components/FlowItemTitle';
import { FlowItemDescription } from './components/FlowItemDescription';
import { devlogger } from '~src/utils';

const Authentication: React.FC<{
  flowItem: FlowResponse['flowItem'];
  answerId: string;
}> = React.memo(({ flowItem, answerId }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isRetry = urlParams.get('retry');
  const { title, description, imgUrl } = flowItem || {};

  const { data } = useGetAuthenticationMethodsQuery({
    skip: !answerId,
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const methods: AuthenticationMethod[] = useMemo(
    () => data?.authenticationMethods.methods || [],
    [data?.authenticationMethods.methods],
  );

  const [initAuth, { error, loading }] = useInitAuthenticationMutation({
    onError() {
      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      const { authUrl } = data.initAuthentication;
      if (isValidUrl(authUrl)) {
        window.location.href = authUrl || '';
      } else {
        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
        devlogger('Invalid auth url', authUrl);
      }
    },
  });

  const getVariablesPayload = useCallback(
    (method: string) => {
      const { protocol, hostname, port } = window.location;
      const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
      return {
        // NOTE: answerId is actually Answer.UserSession.id
        id: answerId || '',
        data: {
          userSessionId: answerId,
          callback: `${baseUrl}/${flowAuthCallbackRoot}/survey/${answerId}`,
          authMethod: method,
        },
      };
    },
    [answerId],
  );

  const methodsButtons = useMemo(
    () =>
      (methods || []).reduce((acc, method) => {
        acc.push(
          <Button
            key={method.id}
            onClick={() => {
              initAuth({
                variables: getVariablesPayload(method.method || ''),
              });
            }}
            variant="unstyled"
            display="flex"
            height="auto"
            border="2px solid"
            borderRadius={0}
            borderColor="gray.100"
            _hover={{ bg: 'gray.100' }}
            minHeight="79px"
            flexBasis={['132px', '132px', '154px']}
            minWidth={['132px', '132px', '154px']}
          >
            <Image
              src={
                authenticationImages[
                  method.id as keyof typeof authenticationImages
                ]
              }
              p="4px"
              margin="8px"
              maxWidth={['109px', '109px', '125px']}
              alt={method?.description || ''}
              height="55px"
            />
          </Button>,
        );
        return acc;
      }, [] as JSX.Element[]),
    [methods, initAuth, getVariablesPayload],
  );

  return (
    <CardContentStack mb={[4, null, 8]}>
      <FlowItemImage imgUrl={imgUrl || AuthenticationImage} />
      <FlowItemTitle
        title={title || t('healthIssueFlow.authentication.title')}
      />
      <FlowItemDescription
        description={
          description || [t('healthIssueFlow.authentication.paragraph')]
        }
      />

      {(!!error || isRetry) && (
        <Alert status="error" maxWidth={'640px'}>
          <AlertIcon />
          <AlertDescription mr={1}>
            {t(
              error
                ? 'healthIssueFlow.authentication.initError'
                : 'healthIssueFlow.authentication.retryError',
            )}
          </AlertDescription>
          {error && (
            <Tooltip label={error.message} placement="top">
              {/* wrap icon with span to prevent ref problem */}
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          )}
        </Alert>
      )}
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        gap="8px"
        width="100%"
        maxWidth="650px"
      >
        {loading && (
          <Flex
            align="center"
            background="whiteAlpha.600"
            height="100%"
            justify="center"
            position="absolute"
            width="100%"
            zIndex="overlay"
          />
        )}
        {methodsButtons}
      </Flex>
    </CardContentStack>
  );
});

export { Authentication };
