import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import Modal from '~/src/components/Modal';
import { AppContext } from '~/src/state';

const InfoModal: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const { t } = useTranslation();

  const infoContent: [string] | string = t('infoModal.paragraphContent', {
    returnObjects: true,
  });

  return (
    <Modal
      modalIsOpen={state.modalIsOpen}
      onCloseModal={() =>
        dispatch({
          type: 'TOGGLE_INFO_MODAL_STATE',
          payload: !state.modalIsOpen,
        })
      }
      title={t('infoModal.title')}
    >
      {!Array.isArray(infoContent)
        ? infoContent
        : infoContent.map((info, idx) => (
            <Text py={2} key={'info' + idx}>
              <Trans>{info}</Trans>
            </Text>
          ))}
    </Modal>
  );
};

export default InfoModal;
