import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~/src/components/Card';
import { FlowItemSubmit } from './components/FlowItemSubmit';
import {
  FlowResponse,
  QuestionType,
  YedQuestion,
  AnswerInput,
} from '~/src/api';
import { useSearchParams } from 'react-router-dom-v5-compat';
import {
  AUTH_ANSWER_UUID_QUERY_PARAM,
  KUURA_AUTH_ID_QUERY_PARAM,
} from '~src/components/SurveyFlow/Authentication/AuthenticationCallback';
import { AuthenticationSuccessInfo } from '~src/components/SurveyFlow/Authentication/AuthenticationSuccessInfo';
import AuthSuccessImage from '~/src/images/image-bank/authentication-success.svg';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemTitle } from './components/FlowItemTitle';
import { FlowItemImage } from './components/FlowItemImage';
import {
  FlowItemMultiFields,
  isAllQuestionAnswered,
} from './components/FlowItemMultiFields';
import { useValidateAllYedQuestionAnswers } from '~src/hooks/useYedQuestionValidation';

const AuthenticationSuccess: React.FC<FlowResponse> = React.memo(
  ({ id, flowItem }) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [multiFieldValues, setMultiFieldValues] = useState<AnswerInput[]>([]);
    const authAnswerId = searchParams.get(AUTH_ANSWER_UUID_QUERY_PARAM) || '';
    const kuuraAuthId = searchParams.get(KUURA_AUTH_ID_QUERY_PARAM) || '';

    const { title, question, imgUrl, description } = flowItem || {};
    const { buttons, inputs } = question as YedQuestion;

    const { validateAllAnswers } = useValidateAllYedQuestionAnswers(inputs);

    if (!id) return null;
    const variables = {
      id: id,
      answer: [
        ...multiFieldValues,
        {
          name: 'yed-button-option',
          type: QuestionType.ButtonOptions,
          value: buttons[0]?.value.toString(), // button value represents the "edgeNumber"
        },
      ],
    };

    const allQuestionsAnswered = isAllQuestionAnswered(
      ['yed-button-option', ...(inputs || [])],
      variables.answer,
    );
    const isAnswersValid = validateAllAnswers(variables.answer);

    return (
      <>
        <CardContentStack as="form">
          <FlowItemImage
            imgUrl={imgUrl || AuthSuccessImage}
            maxWidth={['170px', null, '230px']}
          />
          <FlowItemTitle
            title={title || t('healthIssueFlow.authenticationSuccess.title')}
          />
          <FlowItemDescription description={description} />
          <AuthenticationSuccessInfo
            authAnswerId={authAnswerId}
            kuuraAuthId={kuuraAuthId}
          />
          <FlowItemMultiFields
            multiFieldValues={multiFieldValues}
            setMultiFieldValues={setMultiFieldValues}
            inputs={inputs}
          />
        </CardContentStack>

        <FlowItemSubmit
          variables={variables}
          disabled={!allQuestionsAnswered || !isAnswersValid}
          type="submit"
        />
      </>
    );
  },
);

export { AuthenticationSuccess };
