import React from 'react';
import { useLocation, useHistory } from 'react-router';
import {
  Box,
  BoxProps,
  Button,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { VscGlobe } from 'react-icons/vsc';
import { langCookie } from '~/src/constants';
import { translations } from '~/src/configurations';
import type { Locale } from '~/src/types';

type LanguageSwitcherProps = BoxProps & { isReturnLinkVisible?: boolean };

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  isReturnLinkVisible,
  ...props
}) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const cookies = new Cookies();
  const currentLocale = i18n.language;
  const { supportedLanguages } = translations;

  const changeLanguage = (lang: Locale) => {
    const oldLang = i18n.language;
    i18n.changeLanguage(lang);
    cookies.set(langCookie, lang, { path: '/' });

    // Change example report to match language
    const exampleReportNames: Record<string, string> = {
      fi: 'esimerkki',
      sv: 'exempel',
    };

    if (location.pathname.includes(exampleReportNames[oldLang]))
      history.push(
        location.pathname.replace(
          exampleReportNames[oldLang],
          exampleReportNames[lang],
        ),
      );
  };

  // Don't display component if only one supported language
  if (supportedLanguages.length <= 1) return null;

  return (
    <Menu>
      {({ isOpen }) => (
        <Box
          position="absolute"
          top={5}
          left={isReturnLinkVisible ? '95px' : 4}
          {...props}
        >
          <MenuButton
            as={Button}
            isActive={isOpen}
            aria-label="Valitse kieli, Ändra språk, Change language"
            variant="unstyled"
            color="primary.500"
            cursor="pointer"
            height="auto"
            minWidth="unset"
            padding=".3rem .75rem"
            zIndex="9"
          >
            <Icon
              as={VscGlobe}
              color="primary"
              mr="2"
              mt="-.15rem"
              width="1.2rem"
              height="1.2rem"
            />
            <Heading as="span" size="xs">
              <Box as="span" display={['none', null, 'unset']}>
                {t(`languageSelect.${currentLocale}`)}
              </Box>
              <Box as="span" display={['unset', null, 'none']}>
                {currentLocale?.toUpperCase()}
              </Box>
            </Heading>
          </MenuButton>

          <MenuList
            shadow={isOpen ? 'base' : undefined}
            pt="3rem"
            mt="-3rem"
            minWidth="10rem"
          >
            {supportedLanguages.map((locale, i) => {
              if (locale === i18n.language) return null;

              return (
                <MenuItem
                  key={`${locale}_${i}`}
                  onClick={() => changeLanguage(locale)}
                  color="primary.500"
                  cursor="pointer"
                  fontSize="sm"
                  fontWeight="bold"
                  px=".75rem"
                >
                  {t(`languageSelect.${locale}`)}
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>
      )}
    </Menu>
  );
};

export default LanguageSwitcher;
