import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Spacer,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FlowItemSubmit } from '~src/components/SurveyFlow/components/FlowItemSubmit';
import { AnswerInput, FlowItem, QuestionType, YedQuestion } from '~/src/api';
import { RatingOptions } from './RatingOptions';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { surveyFlowRoot } from '~src/constants';
import { useDebouncedValue } from '~src/utils/useDebouncedValue';
import {
  FlowItemMultiFields,
  isAllQuestionAnswered,
} from '../components/FlowItemMultiFields';
import { FlowItemDescription } from '../components/FlowItemDescription';
import { CardContentStack } from '~src/components/Card';
import { useValidateAllYedQuestionAnswers } from '~src/hooks/useYedQuestionValidation';

interface FeedbackContentProps {
  answerId: string;
  buttons?: YedQuestion['buttons'];
  inputs?: YedQuestion['inputs'];
  description?: FlowItem['description'];
  feedbackProps?: FlowItem['feedbackProps'];
}

const InitialFeedbackValues: AnswerInput[] = [
  {
    name: 'feedback-rating',
    type: QuestionType.Number,
    value: undefined,
  },
  {
    name: 'feedback',
    type: QuestionType.Text,
    value: '',
  },
];

const FeedbackContent: React.FC<FeedbackContentProps> = ({
  answerId,
  buttons,
  inputs,
  description,
  feedbackProps,
}) => {
  const { t } = useTranslation();
  const { addAnswer, loading } = useAddAnswer(surveyFlowRoot);
  const [multiFieldValues, setMultiFieldValues] = useState<AnswerInput[]>([]);
  const { validateAllAnswers } = useValidateAllYedQuestionAnswers(inputs);

  const doneButtonValue = buttons
    ?.find((button) => button?.type === 'flowComponentAnswer')
    ?.value.toString();
  const goToSearchButtonValue = buttons
    ?.find((button) => button?.type === 'go-to-search')
    ?.value.toString();

  const [feedbackValues, setFeedbackValues] = useState<AnswerInput[]>(
    InitialFeedbackValues,
  );

  const debouncedFeedbackValues = useDebouncedValue<AnswerInput[]>(
    feedbackValues,
    InitialFeedbackValues,
    1000,
  );

  const buildFeedbackAnswerVariables = useCallback(
    ({
      feedbackAnswerValues,
      goToSearch = false,
    }: {
      feedbackAnswerValues: AnswerInput[];
      goToSearch?: boolean;
    }) => {
      return {
        id: answerId,
        answer: [
          ...multiFieldValues,
          ...feedbackAnswerValues,
          {
            name: 'yed-button-option',
            type: QuestionType.ButtonOptions,
            value: goToSearch ? goToSearchButtonValue : doneButtonValue, // value represents the "edgeNumber"
          },
        ],
      };
    },
    [answerId, doneButtonValue, goToSearchButtonValue, multiFieldValues],
  );

  // We want to use debounced values to avoid
  // sending the answer to the backend too often
  useEffect(() => {
    if (debouncedFeedbackValues !== InitialFeedbackValues) {
      addAnswer(
        {
          variables: buildFeedbackAnswerVariables({
            feedbackAnswerValues: debouncedFeedbackValues,
          }),
          // Override default onCompleted to avoid update of the flow item
          onCompleted: () => {},
        },
        false,
      );
    }
  }, [addAnswer, buildFeedbackAnswerVariables, debouncedFeedbackValues]);

  const updateFeedbackAnswer = (name: string, newValue: string) => {
    const newValues: AnswerInput[] = feedbackValues.map((value) => {
      if (value.name === name) {
        return { ...value, value: newValue };
      }
      return value;
    });
    setFeedbackValues(newValues);
  };

  const handleAskNewQuestion = () => {
    addAnswer({
      variables: buildFeedbackAnswerVariables({
        feedbackAnswerValues: feedbackValues,
        goToSearch: true,
      }),
    });
  };

  const ratingEntry = feedbackValues.find(
    (value) => value.name === 'feedback-rating',
  );
  const ratingValue =
    ratingEntry && ratingEntry?.value ? parseInt(ratingEntry.value) : undefined;

  const feedbackEntry = feedbackValues.find(
    (value) => value.name === 'feedback',
  );
  const feedbackValue = feedbackEntry ? feedbackEntry.value : undefined;

  const textareaLabel =
    ratingValue && ratingValue < 4
      ? t('feedback.input.labels.lowValue')
      : t('feedback.input.labels.highValue');

  const allQuestionsAnswered = isAllQuestionAnswered(
    inputs || [],
    multiFieldValues,
  );
  const isAnswersValid = validateAllAnswers(multiFieldValues);

  return (
    <>
      <CardContentStack marginTop={[2, null, 'unset']}>
        <FlowItemMultiFields
          multiFieldValues={multiFieldValues}
          setMultiFieldValues={setMultiFieldValues}
          inputs={inputs}
        />
      </CardContentStack>

      <Box px={[4, 6, 8]}>
        <FlowItemDescription description={description} />
      </Box>
      <RatingOptions
        ratingValue={ratingValue}
        setRatingValue={(value: number | string) =>
          updateFeedbackAnswer('feedback-rating', value.toString())
        }
        ratingLabel={feedbackProps?.starsRatingLabel}
      />
      <Spacer marginBottom={4} />
      {ratingValue && (
        <FormControl paddingLeft={4} paddingRight={4}>
          <FormLabel textAlign="center">{textareaLabel}</FormLabel>
          <Textarea
            value={feedbackValue || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              updateFeedbackAnswer('feedback', e.target.value)
            }
            placeholder={t('feedback.input.placeholder') || ''}
          />
        </FormControl>
      )}

      {goToSearchButtonValue && (
        <Button
          isLoading={loading}
          onClick={() => handleAskNewQuestion()}
          variant="link"
          borderRadius="full"
          marginBottom={8}
          padding={3}
        >
          {t('qAndAFlow.results.newQuestionButtonText')}
        </Button>
      )}

      <FlowItemSubmit
        variables={buildFeedbackAnswerVariables({
          feedbackAnswerValues: feedbackValues,
        })}
        label={t('feedback.submitLabel')}
        isDisabled={!allQuestionsAnswered || !isAnswersValid}
      />
    </>
  );
};

export { FeedbackContent };
