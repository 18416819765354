import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SendIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 22" {...props}>
    <path
      d="M23.82.116a.5.5 0 00-.512-.078l-23 9.5a.5.5 0 00.01.927l6.332 2.459a.5.5 0 00.468-.056l8.4-5.974a.5.5 0 01.637.768l-7 6.745a.5.5 0 00-.153.36V21.5a.5.5 0 00.931.252l3.167-5.425a.251.251 0 01.337-.093l5.826 3.2a.5.5 0 00.73-.332l4-18.5a.508.508 0 00-.173-.486z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Icon>
);

export default SendIcon;
