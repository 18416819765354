import React from 'react';
import { CardContentStack } from '~/src/components/Card';
import { FeedbackContent } from '~/src/components/SurveyFlow/Feedback/FeedbackContent';
import { FlowResponse, YedQuestion } from '~/src/api';
import { FlowItemImage } from './components/FlowItemImage';
import FeedbackImage from '~/src/images/image-bank/feedback.svg';
import { FlowItemTitle } from './components/FlowItemTitle';
import { Box } from '@chakra-ui/react';

const Feedback: React.FC<FlowResponse> = ({ flowItem, id }) => {
  if (!id || !flowItem) return null;

  const { title, description, question, imgUrl, feedbackProps } = flowItem;
  const { buttons = [], inputs } = question as YedQuestion;

  return (
    <>
      <CardContentStack
        padding={[0, 0, 0]}
        paddingBottom={[0, 0, 0]}
        marginTop={[2, null, 'unset']}
      >
        <Box marginTop={12}>
          <FlowItemTitle title={title} />
        </Box>
        <FlowItemImage
          imgUrl={imgUrl || FeedbackImage}
          maxWidth={imgUrl ? [null, null, '200px', '250px'] : '90px'}
        />

        <FeedbackContent
          answerId={id}
          buttons={buttons}
          inputs={inputs}
          description={description}
          feedbackProps={feedbackProps}
        />
      </CardContentStack>
    </>
  );
};

export { Feedback };
