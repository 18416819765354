import { Configuration, IConfigurationPartial, Locale } from '~/src/types';
import { localizations } from '~/src/localizations';
import terapiaConfiguration from './terapianavigaattori';
import { MenuItemAction, GetStartMenuQuery } from '~/src/api';

const startMenu: GetStartMenuQuery = {
  startMenu: [
    {
      translationKey: 'terapia.startButtons.youth',
      action: MenuItemAction.Url,
      actionParameter: '/nuori',
    },
    {
      translationKey: 'terapia.startButtons.guardian',
      action: MenuItemAction.Url,
      actionParameter: '/huoltaja',
    },
    {
      translationKey: 'terapia.startButtons.professionalInfo',
      action: MenuItemAction.Url,
      actionParameter: '/info',
    },
  ],
};

const configuration: IConfigurationPartial = {
  ...terapiaConfiguration,
  startMenu,
  homeScreen: undefined,
  translations: {
    supportedLanguages: [Locale.FI],
    resources: localizations[Configuration.Interventionavigaattori] ?? {},
  },
};

export default configuration;
