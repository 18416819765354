import React, { useState } from 'react';
import { Box, Flex, Stack, ListItem, OrderedList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GetStartMenuQuery, MenuItemAction } from '~/src/api';
import { flowInit, styles } from '~/src/configurations';
import Container from '~/src/components/Container';
import Intro from '~/src/components/Intro';
import Card from '~/src/components/Card';
import { StartMenuButtons } from '~src/components/StartMenu/StartMenuButtons';
import { AcceptPrivacyCheckbox } from './AcceptPrivacyCheckbox';
import { H2, P, H3 } from '~/src/components/Headings/Typography';
import { envs } from '~src/utils';
import { useI18nUtils } from '~src/hooks/i18nUtils';

export const HomeTerapia: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { startMenu } = useGetStartMenu();
  const { t } = useTranslation();
  const { getI18nArrayOfStringsWrapped } = useI18nUtils();
  const isMaintenanceComing = !!envs.UPCOMING_MAINTENANCE_COMPLETED_AT;
  const isMenuDisabled = isMaintenanceComing
    ? [true, false, true, false]
    : [!termsAccepted, false, !termsAccepted, false];

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Card
        shadow={flowInit.cardShadow}
        px={[4, 6, 8]}
        pb={12}
        alignItems="flex-start"
      >
        <Intro titleTranslation="intro.title" />
        <H2>{t('flowInit.title')}</H2>
        <Box fontWeight="bold">
          {getI18nArrayOfStringsWrapped('flowInit.paragraph', P)}
        </Box>

        <H3 mb={2}>{t('flowInit.notice')}</H3>
        <OrderedList>
          {getI18nArrayOfStringsWrapped('flowInit.noticeList', ListItem)}
        </OrderedList>
        <Box
          position="relative"
          bg={styles.pageBgColor}
          py={4}
          pl={16}
          pr={4}
          borderRadius={20}
          my={4}
          color="brand.600"
          fontWeight="bold"
        >
          <Box
            position="absolute"
            fontSize="64px"
            color="red"
            left="28px"
            top="-5px"
          >
            !
          </Box>
          {getI18nArrayOfStringsWrapped('flowInit.warningBox', P)}
        </Box>
        <Flex width="100%" direction="column" alignItems="center">
          <Box maxW="21rem">
            <AcceptPrivacyCheckbox
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
            />
          </Box>

          <Stack spacing="2" pt={6} alignItems="center">
            <StartMenuButtons
              startMenu={startMenu}
              isMenuDisabled={isMenuDisabled}
              isMaintenanceComing={isMaintenanceComing}
            />
          </Stack>
        </Flex>
      </Card>
    </Container>
  );
};

function useGetStartMenu() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const yedSuffix = lang === 'sv' ? '-sv' : lang === 'en' ? '-en' : '';
  const exampleReport =
    lang === 'sv' ? 'exempel' : lang === 'en' ? 'example' : 'esimerkki';

  const startMenu: GetStartMenuQuery['startMenu'] = [
    {
      translationKey: 'terapia.startButtons.start',
      action: MenuItemAction.Question,
      actionParameter: `hus-terapia${yedSuffix}#privacy-ok`,
    },
    {
      translationKey: 'terapia.startButtons.openResults',
      action: MenuItemAction.Url,
      actionParameter: '/report',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.startTest',
      action: MenuItemAction.Question,
      actionParameter: `hus-terapia${yedSuffix}#privacy-ok-test`,
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.viewExample',
      action: MenuItemAction.Url,
      actionParameter: '/report/summary?reportId=' + exampleReport,
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.professionalInfo',
      action: MenuItemAction.Url,
      actionParameter: '/info',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.accessibilityStatement',
      action: MenuItemAction.Url,
      actionParameter: '/accessibility',
      variant: 'link',
    },
  ];

  return { startMenu };
}
