import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  VStack,
  Box,
} from '@chakra-ui/react';
import Container from '~/src/components/Container';
import Card, { CardContentStack } from '~/src/components/Card';
import Accordion from '~/src/components/Accordion';
import { Link } from '~/src/components/Link';
import { useI18nUtils } from '~src/hooks/i18nUtils';

const ReportInstructions: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { getI18nArrayOfStringsWrapped } = useI18nUtils();

  return (
    <Container key={id} px={[4, 8, 12]} py={[4, 8, 12]}>
      <Card>
        <CardContentStack align="flex-start">
          <Heading as="h1" size="xl" marginBottom={4}>
            {t('terapia.instructions.title')}
          </Heading>
          <Text>{t('terapia.instructions.description')}</Text>

          <Heading size="md">
            {t('terapia.instructions.interpretSymptomsTitle')}
          </Heading>
          {getI18nArrayOfStringsWrapped(
            'terapia.instructions.interpretSymptomsContent',
            Text,
          )}

          <Heading size="md">
            {t('terapia.instructions.viewTreatmentsTitle')}
          </Heading>
          {getI18nArrayOfStringsWrapped(
            'terapia.instructions.viewTreatmentsContent',
            Text,
          )}

          <Heading size="md">
            {t('terapia.instructions.listTreatmentsTitle')}
          </Heading>

          <Accordion
            accordionData={[
              {
                title: t('terapia.instructions.depressionAccordion'),
                content: MasennusAccordionContent(),
              },
              {
                title: t('terapia.instructions.crisisAccordion'),
                content: OireiluAccordionContent(),
              },
              {
                title: t('terapia.instructions.ocdAccordion'),
                content: PakkoOireetAccordionContent(),
              },
            ]}
          ></Accordion>

          <Heading size="md">
            {t('terapia.instructions.addictionTitle')}
          </Heading>
          <Text>
            <Trans
              i18nKey="terapia.instructions.addictionDescription"
              components={{ linkOut: <Link isExternal isBold /> }}
            />
          </Text>

          <Heading size="md">
            {t('terapia.instructions.specialNotesTitle')}
          </Heading>
          <Heading size="sm">
            {t('terapia.instructions.supportNeedsTitle')}
          </Heading>
          <Text>{t('terapia.instructions.supportNeedsDescription')}</Text>

          <Heading size="sm">
            {t('terapia.instructions.previousTreatmentsTitle')}
          </Heading>
          <Box>
            <Text>
              {t('terapia.instructions.previousTreatmentsDescription1')}
            </Text>
            <UnorderedList>
              {getI18nArrayOfStringsWrapped(
                'terapia.instructions.previousTreatmentsList',
                ListItem,
              )}
            </UnorderedList>
          </Box>
          <Text>
            {t('terapia.instructions.previousTreatmentsDescription2')}
          </Text>
        </CardContentStack>
      </Card>
    </Container>
  );
};

function MasennusAccordionContent() {
  const { getI18nObject } = useI18nUtils();

  return (
    <VStack align="flex-start">
      <Heading size="md" mb={4}>
        <Trans i18nKey="terapia.instructions.depressionTitle" />
      </Heading>
      <Heading size="sm">
        <Trans i18nKey="terapia.instructions.depressionMediumSymptomsTitle" />
      </Heading>
      <Box>
        <Trans i18nKey="terapia.instructions.depressionMediumSymptomsContent1" />
        <UnorderedList>
          {getI18nObject(
            'terapia.instructions.depressionMediumSymptomsList',
            isListWithSublistTranslation,
          )?.map((el, idx) => {
            if (typeof el === 'string')
              return (
                <ListItem key={el + idx}>
                  <CustomTrans>{el}</CustomTrans>
                </ListItem>
              );

            if ('listItem' in el && Array.isArray(el.subItems)) {
              return (
                <React.Fragment key={'el-array' + idx}>
                  <ListItem>
                    <CustomTrans>{el.listItem}</CustomTrans>
                  </ListItem>
                  <UnorderedList>
                    {el.subItems.map((subEl, idx) => (
                      <ListItem key={'subEl' + idx}>
                        <CustomTrans>{subEl}</CustomTrans>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </React.Fragment>
              );
            }
            return null;
          })}
        </UnorderedList>

        <Text>
          <Trans i18nKey="terapia.instructions.depressionMediumSymptomsContent2" />
        </Text>
      </Box>
      <Text>
        <Trans i18nKey="terapia.instructions.depressionMediumSymptomsContent3" />
      </Text>
      <Text as="i">
        <Trans i18nKey="terapia.instructions.depressionMediumSymptomsContent4" />
      </Text>

      <Heading size="sm" pt={4}>
        <Trans i18nKey="terapia.instructions.depressionSevereSymptomsTitle" />
      </Heading>
      <Box>
        <Text>
          <Trans i18nKey="terapia.instructions.depressionSevereSymptomsContent1" />
        </Text>
        <UnorderedList>
          <ListItem>
            <Trans i18nKey="terapia.instructions.depressionSevereSymptomsShortTherapy" />
          </ListItem>
          <ListItem>
            <Trans i18nKey="terapia.instructions.depressionSevereSymptomsLongTherapy" />
          </ListItem>
        </UnorderedList>
        <Text>
          <Trans i18nKey="terapia.instructions.depressionSevereSymptomsContent2" />
        </Text>
      </Box>
    </VStack>
  );
}

function OireiluAccordionContent() {
  const { getI18nArrayOfStringsWrapped } = useI18nUtils();

  return (
    <VStack align="flex-start">
      <Heading size="md" mb={4}>
        <Trans i18nKey="terapia.instructions.crisisTitle" />
      </Heading>
      <Text as="i">
        <Trans i18nKey="terapia.instructions.crisisSituationsTitle" />
      </Text>
      {getI18nArrayOfStringsWrapped(
        'terapia.instructions.crisisSituationsContent',
        Text,
      )}

      <Text as="i">
        <Trans i18nKey="terapia.instructions.crisisTraumaTitle" />
      </Text>
      <Box>
        <Text>
          <Trans i18nKey="terapia.instructions.crisisTraumaContent1" />
        </Text>
        <UnorderedList>
          <ListItem>
            <Trans i18nKey="terapia.instructions.crisisTraumaList1" />
          </ListItem>
          <ListItem>
            <Trans i18nKey="terapia.instructions.crisisTraumaList2" />
          </ListItem>
        </UnorderedList>
        <Text>
          <Trans i18nKey="terapia.instructions.crisisTraumaContent2" />
        </Text>
      </Box>
    </VStack>
  );
}

function PakkoOireetAccordionContent() {
  return (
    <VStack align="flex-start">
      <Heading size="md" pt={6} pb={2}>
        <Trans i18nKey="terapia.instructions.ocdTitle" />
      </Heading>
      <Box>
        <Text>
          <Trans i18nKey="terapia.instructions.ocdContent1" />
        </Text>
        <UnorderedList>
          <ListItem>
            <Trans i18nKey="terapia.instructions.ocdList1" />
          </ListItem>
          <ListItem>
            <Trans i18nKey="terapia.instructions.ocdList2" />
          </ListItem>
        </UnorderedList>
        <br />
        <Text>
          <Trans i18nKey="terapia.instructions.ocdContent2" />
        </Text>
      </Box>
    </VStack>
  );
}

const CustomTrans: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Trans
    components={{
      linkOut: <Link isExternal isBold />,
      small: <small />,
    }}
  >
    {children}
  </Trans>
);

export default ReportInstructions;

function isListWithSublistTranslation(
  obj: unknown,
): obj is Array<string | { listItem: string; subItems: Array<string> }> {
  // Must be an array
  if (!Array.isArray(obj)) return false;
  // Verify every item in the array
  return obj.every((el: unknown) => {
    // May be string
    if (typeof el === 'string') return true;
    // If not string must be an object
    if (el == null || typeof el !== 'object') return false;
    // Must have listItem property
    if (!('listItem' in el) || typeof el.listItem !== 'string') return false;
    // Must have subItems property and it must be an array of strings
    if (
      'subItems' in el &&
      Array.isArray(el.subItems) &&
      el.subItems.every((s) => typeof s === 'string')
    )
      return true;
    return false;
  });
}
