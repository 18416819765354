import React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import { envs } from '~/src/utils';
import { ReportType } from '~/src/types';

export const DownloadPdfButton: React.FC<{
  reportType: ReportType;
  reportId?: string;
  youthId?: string;
  guardianId1?: string;
  guardianId2?: string;
}> = (props) => {
  const { t, i18n } = useTranslation();

  const params = new URLSearchParams({ ...props, lng: i18n.language });

  return (
    <a
      href={`${envs.SERVER_URL}api/v1/pdf?${params.toString()}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button
        className="hideWhenPrinting"
        variant="ghost"
        size="md"
        fontSize={16}
        whiteSpace="normal"
      >
        {t('terapia.report.downloadPdf')}
        <Icon ml={2} fontSize={20} as={HiOutlineDocumentDownload} />
      </Button>
    </a>
  );
};
