import React, { FC, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Text, Box } from '@chakra-ui/react';
import { Report } from '~/src/api';
import { useCreateQuestionnaireResult } from '~/src/hooks/useCreateQuestionnaireResult';
import { ReportType } from '~/src/types';
import { createReportMap, getAnswerFunction } from '../reportUtils';
import { Title, SummaryHeading } from '../components/Title';
import { SelfCareLinks } from '../components/SelfCareLinks';
import { FullReportSection } from '../components/FullReportSection';
import { Subtitle } from '~/src/components/Headings';
import { ReportInfoLink } from '../components/ReportInfoLink';
import { FeedbackSection } from '../components/FeedbackSection';

export const ReportSummaryYouth: FC<{
  report: Report;
  id: string;
  isPrint: boolean;
}> = ({ report, id, isPrint }) => {
  const { t } = useTranslation();
  const titles: Array<Title> = [
    { text: t('terapia.reportSummary.title'), id: 'summary' },
    { text: t('terapia.reportFeedback.title'), id: 'feedback' },
    { text: t('terapia.reportFull.title'), id: 'fullReport' },
    { text: t('terapia.reportSelfCareLinksYouth.title'), id: 'selfCareLinks' },
    /*{
      text: t('terapia.reportInstructions.title'),
      id: 'instructions',
    },*/
  ];

  useEffect(() => {
    if (isPrint) window.print();
  }, [isPrint]);

  if (isPrint)
    return (
      <Box textAlign="left" width="inherit">
        <Summary report={report} title={titles[0]} id={id} />
        <ReportInfoLink />
      </Box>
    );

  return (
    <Box textAlign="left" width="inherit">
      <Text>
        <Trans i18nKey="terapia.report.description" values={{ id }} />
      </Text>
      <Summary report={report} title={titles[0]} id={id} />
      <FeedbackSection title={titles[1]} />
      <FullReportSection title={titles[2]} id={id} type={ReportType.Youth} />
      <SelfCareLinks title={titles[3]} reportType={ReportType.Youth} />
      {/*<Instructions title={titles[4]} />*/}
    </Box>
  );
};

export const Summary: FC<{
  report: Report;
  title: Title;
  id: string;
}> = ({ report, title, id }) => {
  const { t } = useTranslation();
  const { createQuestionnaireResult } = useCreateQuestionnaireResult();
  const data = createReportMap(report.answers);
  const getAnswer = getAnswerFunction(data, {
    useQuestionAsLabel: false,
    linebreakAfterLabel: false,
    onlyPositiveAnswers: false,
    useRichTextFormatter: false,
  });

  const auditAnswers = (
    <>
      {createQuestionnaireResult(report, 'auditYouth')}
      {getAnswer('huume', {
        label: 'Huumaavien aineiden kokeilu ja käyttö',
        onlyPositiveAnswers: true,
      })}
    </>
  );

  const adsumeAnswers = (
    <>
      {createQuestionnaireResult(report, 'adsume')}
      <br />
      {getAnswer('adsume-6', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer('adsume-10', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer('adsume-11', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer('adsume-12', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
    </>
  );

  const SummaryBody = () => (
    <>
      <Subtitle toUpper={true}>
        {t('terapia.reportSummary.subtitles.description')}
      </Subtitle>
      {getAnswer('aloitus', { spaceBeforeNext: true })}

      <Subtitle>{t('terapia.reportSummary.subtitles.questionnaires')}</Subtitle>
      {createQuestionnaireResult(report, 'phq')}
      {createQuestionnaireResult(report, 'gad')}
      {createQuestionnaireResult(report, 'oci')}
      {createQuestionnaireResult(report, 'spin')}
      {createQuestionnaireResult(report, 'sci')}
      {createQuestionnaireResult(report, 'scoff')}
      <br />
      {data.get('phq-9')?.answerValue !== '0' &&
        getAnswer('phq-9', {
          label: t('terapia.reportSummary.labels.dangerousThoughts'),
          upperCase: true,
          bold: true,
        })}
      {getAnswer('aggressio', {
        label: 'Tappeluihin joutuminen tai toistuva valehtelu',
        upperCase: true,
        bold: true,
        onlyPositiveAnswers: true,
      })}

      <Subtitle>{t('terapia.reportSummary.subtitles.drugs')}</Subtitle>
      {report.reportVersion === 2 ? adsumeAnswers : auditAnswers}

      <Subtitle>{t('terapia.reportSummary.subtitles.performance')}</Subtitle>
      {getAnswer('koulu', {
        label:
          'Koulusta tai opinnoista suoriutuminen viimeisen kuukauden aikana',
      })}
      {getAnswer('ystava', { label: 'Ystävyyssuhteet' })}
      {getAnswer('yksinaisyys', { label: 'Yksinäisyyden kokemus' })}
      {getAnswer('vapaa-aika', {
        label: 'Harrastukset ja mielekäs vapaa-ajan tekeminen',
      })}
      {getAnswer('hygienia', {
        label: 'Henkilökohtaisesta hygieniasta huolehtiminen',
      })}
      {getAnswer('ruutuaika', { label: 'Ruutuaika' })}
      <Subtitle>
        {t('terapia.reportSummary.subtitles.changeReadiness')}
      </Subtitle>
      {getAnswer('keskustelu', {
        label: 'Pystyn keskustelemaan lähiaikuisen kanssa',
      })}
      {getAnswer('avun-tarve', { label: 'Ulkopuolisen avun tarve' })}

      <Subtitle>
        {t('terapia.reportSummary.subtitles.studyChallenges')}
      </Subtitle>
      {getAnswer('oppimisvaikeus', { label: 'Todetut oppimisvaikeudet' })}
      {getAnswer('keskittyminen', {
        label: 'Vaikeuksia opetuksen seuraamisessa',
      })}
      {getAnswer('tehtava', { label: 'Vaikeuksia läksyjen tekemisessä' })}
      {getAnswer('kokeet', {
        label: 'Vaikeuksia kokeisiin valmistautumisessa',
      })}
      {getAnswer('luki', {
        label:
          'Vaikeuksia kirjoittamista, lukemista ja/tai laskemista vaativissa tehtävissä',
      })}
      {getAnswer('esiintyminen', {
        label: 'Vaikeuksia suullisessa esiintymisessä',
      })}
      {getAnswer('vastaaminen', { label: 'Vaikeuksia tunnilla vastaamisessa' })}
      {getAnswer('laitteet', {
        label: 'Vaikeuksia opiskelussa käytettävien laitteiden käytössä',
      })}

      <Subtitle>
        {t('terapia.reportSummary.subtitles.environmentChallenges')}
      </Subtitle>
      {getAnswer('muutos', {
        label: 'Merkittävät muutokset viimeisten kuukausien aikana',
        // Show muutos question always (i.e. onlyPositiveAnswers === false)
        // if stressi question is not shown (i.e. is null)
        onlyPositiveAnswers:
          getAnswer('stressi', { onlyPositiveAnswers: true }) !== null,
      })}
      {getAnswer('stressi', {
        label: 'Stressaava tai pelottava tapahtuma',
        onlyPositiveAnswers: true,
      })}

      <Subtitle>
        {t('terapia.reportSummary.subtitles.previousTreatments')}
      </Subtitle>
      {getAnswer('hoito-kontaktit', {
        label:
          'Ajankohtaisia tai aiempia kontakteja psyykkisen voinnin tai neuropsykiatrisen häiriön vuoksi',
      })}
    </>
  );

  return (
    <>
      <SummaryHeading
        title={title}
        summaryBody={<SummaryBody />}
        id={id}
        type={ReportType.Youth}
      />
      <SummaryBody />
    </>
  );
};
