import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import Container from '~/src/components/Container';
import { flowitemConf } from '~/src/configurations';
import {
  DESCRIPTION_OUTSIDE_CARD_TAG,
  FlowItemDescription,
} from './FlowItemDescription';
import { FlowItem } from '~src/api';

const FlowItemIntro: React.FC<{
  title: FlowItem['title'];
  description: FlowItem['description'];
}> = ({ title, description }) => {
  if (!flowitemConf.showIntro) return null;
  const descriptionVisibleHere =
    description && description[0]?.startsWith(DESCRIPTION_OUTSIDE_CARD_TAG);
  return (
    <Container
      maxWidth={'100%'}
      bgColor={flowitemConf.introBgColor}
      padding="0"
    >
      <Container
        maxWidth={flowitemConf.introMaxWidth}
        color={flowitemConf.introColor}
        padding="16px 8px 0"
        pb={descriptionVisibleHere ? '0' : '16px'}
      >
        <Heading as="h1" textAlign="center" color={flowitemConf.introColor}>
          {title}
        </Heading>
        {descriptionVisibleHere && (
          <Box textAlign={'center'} mt="16px">
            <FlowItemDescription
              description={description}
              textAlign="center"
              place="intro"
            />
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default FlowItemIntro;
