import type { IConfigurationPartial } from '~/src/types';
import { Configuration } from '~/src/types';
import { localizations } from '~/src/localizations';
import colors from '~/src/theme/foundations/colorsHelsinki';
import appLogo from '~/src/images/logo-helsinki.png';

const configuration: IConfigurationPartial = {
  colors,
  header: {
    appLogo,
    appLogoWidth: '8rem',
  },
  translations: {
    resources: localizations[Configuration.Helsinki] ?? {},
  },
};

export default configuration;
