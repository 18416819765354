import React from 'react';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';
import { Element, Text } from 'domhandler/lib/node';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';

import { Link } from '~/src/components/Link';
import { Button } from '@chakra-ui/button';

type ParserOptions = {
  removeTags?: string[];
  allowTags?: string[];
  allowAttr?: string[];
  excludeNoReportAttr?: boolean;
  disableLinkify?: boolean;
  removeExtraBrTags?: boolean;
  openYedModal?: (id: string) => void;
};

const richTextParser = (
  content: [string] | string | undefined | null,
  {
    removeTags,
    allowTags,
    allowAttr,
    excludeNoReportAttr,
    disableLinkify,
    removeExtraBrTags,
    openYedModal,
  }: ParserOptions = {},
): React.ReactNode => {
  if (content == null) return null;

  const linkify = new LinkifyIt();

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      // Find and replace all urls with actual links
      if (domNode instanceof Text) {
        const text = domNode.data;
        if (
          disableLinkify !== true &&
          linkify.pretest(text) &&
          linkify.test(text)
        ) {
          const matches = linkify.match(text) || [];
          for (const match of matches) {
            // Check that Text is not inside of `a` tag (`a` tags cannot be nested)
            if (aTagAsParent(domNode)) return;

            return (
              <>
                {text.substr(0, match.index)}
                <Link href={match.url} isExternal>
                  {match.text}
                </Link>
                {text.substr(match.lastIndex)}
              </>
            );
          }
        }
      }

      if (isElement(domNode)) {
        // Skip parsing of elements having data-skip-parser set
        if (domNode.attribs['data-skip-parser'] != null) return;

        // Remove elements having data-no-report set
        if (excludeNoReportAttr && domNode.attribs['data-no-report'] != null)
          return <></>;

        // replace modal buttons with chakra buttons
        if (
          domNode.name === 'button' &&
          domNode.attribs['data-modal-id'] &&
          openYedModal
        ) {
          return (
            <Button
              variant="link"
              onClick={() => openYedModal(domNode.attribs['data-modal-id'])}
            >
              {domToReact(domNode.children, options)}
            </Button>
          );
        }

        // Find and replace all a tags with Link elements
        if (domNode.name === 'a' && domNode.attribs.href) {
          const linkContent = domToReact(domNode.children, options);

          return (
            <Link href={domNode.attribs.href} isExternal isBold>
              {linkContent}
            </Link>
          );
        }

        // Check if extra br tag should be removed (i.e. we have two consecutive
        // br tags)
        if (removeExtraBrTags && domNode.name === 'br') {
          // Skip removed tags
          let prevSibling = domNode.previousSibling;
          while (
            excludeNoReportAttr &&
            prevSibling != null &&
            isElement(prevSibling) &&
            prevSibling.attribs['data-no-report'] != null
          )
            prevSibling = prevSibling.previousSibling;

          // Remove br tag if previous tag was also br tag
          if (
            prevSibling == null ||
            (isElement(prevSibling) && prevSibling?.name === 'br')
          )
            return <></>;
        }
      }
      return;
    },
  };

  if (!Array.isArray(content)) content = [content];

  const parsedContent = content.map((item, idx) => {
    if (typeof item !== 'string') return null;
    const sanitizedItem = DOMPurify.sanitize(item, {
      ADD_TAGS: allowTags ?? [], // tags which are added to default ALLOWED_TAGS
      ADD_ATTR: allowAttr ?? [], // attributes which are added to default ALLOWED_ATTRIBUTES
      FORBID_TAGS: removeTags ?? [], // tags which are removed (tag content is preserved)
      FORBID_CONTENTS: [], // tags which content is removed (tag must also be in FORBID_TAGS for this to have an effect)
    });

    return (
      <React.Fragment key={idx}>{parse(sanitizedItem, options)}</React.Fragment>
    );
  });

  return parsedContent;
};

/** Traverse recursively up the dom tree to find if there are any `a` tags in
 *  node's parents
 *
 * @param domNode Node to start traversal
 * @returns True if `a` tag exists among node's parents, otherwise false
 */
function aTagAsParent(domNode: DOMNode): boolean {
  // Check if current node is `a` tag
  if (domNode.type === 'tag' && 'name' in domNode && domNode.name === 'a')
    return true;

  // Check if parent is `a` tag
  if (domNode.parent != null) return aTagAsParent(domNode.parent);

  return false;
}

function isElement(domNode: DOMNode): domNode is Element {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
}

export default richTextParser;
