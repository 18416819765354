import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z, ZodAny, ZodString } from 'zod';
import {
  AnswerInput,
  BuildInValidationCode,
  InputField,
  InputFieldValidation,
  Maybe,
} from '../../src/api';

const validationCodeTranslationsKeys: {
  [key in BuildInValidationCode]: string;
} = {
  email: 'errors.validation.invalidEmail',
  phone: 'errors.validation.invalidPhone',
  none: 'errors.validation.invalidValue',
};

type UseYedQuestionValidationProps = {
  validate: (value: string | number) => boolean;
  getValidationSchema: (
    validation: InputFieldValidation | null | undefined,
  ) => ZodString | ZodAny;
  errorMessage: string | null;
};

function useYedQuestionValidation(
  validation?: InputFieldValidation | null | undefined,
): UseYedQuestionValidationProps {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getValidationSchema = useCallback(
    (validation: InputFieldValidation | null | undefined) => {
      const {
        validationCode,
        regexPattern,
        errorMessage: validationMessage,
      } = validation || {};

      return regexPattern
        ? z.string().regex(new RegExp(regexPattern), {
            message: t(
              validationMessage ||
                (validationCode
                  ? validationCodeTranslationsKeys[validationCode]
                  : 'errors.validation.invalidValue'),
            ).toString(),
          })
        : z.any();
    },
    [t],
  );

  const validationSchema: ZodString | ZodAny = useMemo(
    () => getValidationSchema(validation),
    [getValidationSchema, validation],
  );

  const validate = (value: string | number) => {
    const data = validationSchema.safeParse(value);
    if (!data.success) {
      setErrorMessage(data.error.errors[0].message);
      return false;
    }
    setErrorMessage(null);
    return true;
  };

  return { errorMessage, validate, getValidationSchema };
}

function useValidateAllYedQuestionAnswers(
  inputFields: Maybe<InputField[]> | undefined,
) {
  const { getValidationSchema } = useYedQuestionValidation();

  const validationSchemas:
    | { [key: string]: ZodString | ZodAny }
    | null
    | undefined = useMemo(
    () =>
      inputFields
        ?.filter((input: string | InputField) => typeof input !== 'string')
        .reduce(
          (acc: { [key: string]: ZodString | ZodAny }, input: InputField) => {
            acc[input.name] = getValidationSchema(input?.validation);
            return acc;
          },
          {},
        ),
    [inputFields, getValidationSchema],
  );

  const getSchemaByName = (name: string) =>
    validationSchemas && validationSchemas[name];

  const getValueByName = (name: string, allAnswers: AnswerInput[]) =>
    allAnswers?.find((answer) => answer?.name === name);

  const validateAllAnswers = (allAnswers: AnswerInput[]) => {
    if (!validationSchemas || !inputFields) return true; // No validation schemas, skip validation
    return inputFields.every((input) => {
      const currentAnswer = getValueByName(input.name, allAnswers);
      const validationSchema = getSchemaByName(input.name);

      if (validationSchema) {
        const data = validationSchema.safeParse(currentAnswer?.value);
        return data.success;
      }
      return true;
    });
  };

  return { validateAllAnswers };
}

export { useYedQuestionValidation, useValidateAllYedQuestionAnswers };
