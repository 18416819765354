import React from 'react';
import ReactDOMServer from 'react-dom/server';

export const reactToString = (
  element: React.ReactElement,
  useDom = true,
): string => {
  if (useDom) return traverseDOMNodes(element);
  return traverseReactNodes(element);
};

function traverseDOMNodes(element: React.ReactElement): string {
  const htmlString = ReactDOMServer.renderToString(element);
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  let text = '';
  for (const child of div.childNodes) {
    // Handle pure text nodes separately
    if (!(child instanceof HTMLElement)) {
      text += child.textContent;
      continue;
    }

    // Exclude tags with data-no-clipboard attribute
    if (child.dataset.noClipboard === 'true') continue;

    child.innerHTML = child.innerHTML.replace(/<br.*?>/g, '\r\n');

    // Extra space before headings
    if (child.tagName === 'H3') text += '\r\n';

    text += child.textContent;

    // Extra space after headings
    if (child.tagName === 'H3') text += '\r\n';

    // Add linebreak to all non-empty tags and br-tags
    if (child.textContent || child.tagName === 'BR') text += '\r\n';
  }

  return text;
}

function traverseReactNodes(element: React.ReactElement): string {
  let text = '';

  if (!element) {
    text = '';
  } else if (typeof element === 'string') {
    text = element + '\r\n';
  } else if (typeof element === 'number') {
    text = String(element) + '\r\n';
  } else if (Array.isArray(element)) {
    text = element.map((subElement) => reactToString(subElement)).join('');
  } else if (element.props && element.props.children) {
    text = reactToString(element.props.children);
  } else if (element.props && !element.props.children) {
    text = '';
  }

  return text;
}
