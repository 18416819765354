import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SearchField from '~/src/components/SearchField';
import { FlowItemDescription } from './components/FlowItemDescription';
import { CardContentStack } from '~/src/components/Card';
import { surveyFlowRoot } from '~/src/constants';
import { SearchOptions } from './SurveySearch/SearchOptions';
import { FlowResponse, QuestionType, Options, YedQuestion } from '~/src/api';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemTitle } from './components/FlowItemTitle';

type SurveySearchExtraProps = {
  contentPaddingTop?: number;
  showDefaultOptionsAlways?: boolean;
};
const SurveySearch: React.FC<FlowResponse & SurveySearchExtraProps> =
  React.memo(
    ({
      id,
      flowItem,
      contentPaddingTop = [6, 8, 12],
      showDefaultOptionsAlways = true,
    }) => {
      const { t } = useTranslation();
      const { addAnswer } = useAddAnswer(surveyFlowRoot);

      if (!id || !flowItem) return null;
      const { title, description, question, imgUrl, searchProps } = flowItem;
      const { inputs, buttons } = question as YedQuestion;

      const noResultButton = buttons?.find(
        (button) => button?.type === 'no-results',
      );
      const buttonToResult = buttons?.find(
        (button) => button?.type !== 'no-results',
      );

      // If there is a button that triggers a no result answer, add it to the answer
      // otherwise, do nothing
      const noResultsAnswer = noResultButton
        ? () =>
            addAnswer({
              variables: {
                id: id,
                answer: {
                  name: 'yed-button-option',
                  type: QuestionType.ButtonOptions,
                  value: noResultButton?.value.toString(),
                },
              },
            })
        : undefined;

      const selectSurveyOption = (option: Options) =>
        addAnswer({
          variables: {
            id: id,
            answer: [
              {
                name: 'resultSurvey',
                type: QuestionType.Dropdown,
                value: option?.value,
              },
              {
                name: 'yed-button-option',
                type: QuestionType.ButtonOptions,
                value: buttonToResult?.value.toString(), // button value represents the "edgeNumber"
              },
            ],
          },
        });

      const searchOptionElements = inputs && inputs[0]?.options && (
        <Stack width="100%" pt={4}>
          <FlowItemTitle title={t('qAndAFlow.search.FAQ')} />

          <SearchOptions
            options={inputs[0].options as [Options]}
            handleClick={selectSurveyOption}
            searchProps={searchProps}
          />
        </Stack>
      );

      return (
        <CardContentStack pt={contentPaddingTop}>
          <FlowItemTitle title={title || t('qAndAFlow.search.title')} />
          <FlowItemImage imgUrl={imgUrl} />
          <FlowItemDescription description={description} />
          <SearchField
            selectSurveyOption={selectSurveyOption}
            showUntilOptionsVisible={searchOptionElements}
            showDefaultOptionsAlways={showDefaultOptionsAlways}
            handleNoResults={noResultsAnswer}
            noResultsText={noResultButton?.text}
          />
        </CardContentStack>
      );
    },
  );

export { SurveySearch };
