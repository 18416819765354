import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import { isMobileOnly } from 'react-device-detect';

import { closeSessionAnswerObject, surveyFlowRoot } from '~/src/constants';
import { AnswerInput, Exact } from '~/src/api/index';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { flowInit } from '~src/configurations';

interface FlowItemSubmitProps {
  variables:
    | Exact<{
        id: string;
        answer: AnswerInput | AnswerInput[];
      }>
    | undefined;
  label?: string | null;
  closeSession?: boolean;
}

const FlowItemSubmit: React.FC<FlowItemSubmitProps & ButtonProps> = ({
  disabled,
  label,
  variables,
  closeSession,
  ...rest
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [itemOpacity, setItemOpacity] = useState<number>(0);
  const showArrow = !label;

  useEffect(() => {
    setTimeout(() => {
      setItemOpacity(1);
    }, 500);
  }, []);

  const { addAnswer, loading } = useAddAnswer(surveyFlowRoot, () => {
    if (closeSession) {
      history.push('/');
      // return true to prevent new redirect
      return true;
    }
    return undefined;
  });

  if (!variables || !variables.id) return null;

  const answerOnCloseSession: AnswerInput | AnswerInput[] = variables.answer
    ? Array.isArray(variables?.answer)
      ? [...variables.answer, closeSessionAnswerObject]
      : [variables.answer, closeSessionAnswerObject]
    : closeSessionAnswerObject;

  const submitVariables = closeSession
    ? {
        id: variables.id,
        answer: answerOnCloseSession,
      }
    : variables;

  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        addAnswer({ variables: submitVariables });
      }}
      isDisabled={disabled}
      isLoading={loading}
      rightIcon={
        showArrow ? (
          <BsArrowRightShort
            fontSize="2.5rem"
            style={{ marginRight: '-.7rem' }}
          />
        ) : undefined
      }
      borderRadius={
        isMobileOnly
          ? 'full'
          : [
              'full',
              null,
              `0 0 ${flowInit.cardBorderRadius} ${flowInit.cardBorderRadius}`,
            ]
      }
      bottom={isMobileOnly ? '1rem' : ['1rem', null, 'initial']}
      display={
        disabled ? (isMobileOnly ? 'none' : ['none', null, 'flex']) : ['flex']
      }
      minWidth={isMobileOnly ? '10rem' : ['10rem', null, 'initial']}
      opacity={isMobileOnly ? itemOpacity : [itemOpacity, null, '1']}
      position={isMobileOnly ? 'fixed' : ['fixed', null, 'static']}
      right={isMobileOnly ? '1rem' : ['1rem', null, 'initial']}
      width={isMobileOnly ? 'auto' : ['auto', null, '100%']}
      zIndex="docked"
      {...rest}
    >
      {label ?? t('common.continue')}
    </Button>
  );
};

export { FlowItemSubmit };
