import type { IConfigurationPartial } from '~/src/types';
import { Locale, Configuration } from '~/src/types';
import { localizations } from '~/src/localizations';
import { HomeDlaakarit } from '~src/screens/Home/HomeDlaakarit';

const configuration: IConfigurationPartial = {
  flowInit: {
    maxWidth: 'container.md',
  },
  translations: {
    supportedLanguages: [Locale.FI],
    resources: localizations[Configuration.Dlaakarit] ?? {},
  },
  header: {
    showInfoToggle: false,
  },
  homeScreen: HomeDlaakarit,
};

export default configuration;
