import { parse } from 'qs';
import { z } from 'zod';
import { devlogger } from './misc';

export enum PaymentCallback {
  Success = 'success',
  Cancel = 'cancel',
}

// Paytrail callback query parameters schema
const paytrailCallbackQueryParamsSchema = z.object({
  'checkout-account': z.coerce.number(),
  'checkout-algorithm': z.string(),
  'checkout-amount': z.coerce.number(),
  'checkout-settlement-reference': z.string().optional(),
  'checkout-stamp': z.string().max(200),
  'checkout-reference': z.string().max(200),
  'checkout-transaction-id': z.string().optional(),
  'checkout-status': z.enum(['ok', 'pending', 'delayed', 'fail']),
  'checkout-provider': z.string(),
  signature: z.string(),
});

export type PaytrailPaymentCallback = z.infer<
  typeof paytrailCallbackQueryParamsSchema
>;
export const parseReturnSearchString = (
  search: string,
): PaytrailPaymentCallback | null => {
  const params = parse(search, { ignoreQueryPrefix: true });
  const result = paytrailCallbackQueryParamsSchema.safeParse(params);

  if (!result.success) {
    devlogger(result.error);
    return null;
  }
  return result.data;
};
