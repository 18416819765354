import { useSearchParams } from 'react-router-dom-v5-compat';
import { ReportType } from '~/src/types';
import { isSomeEnum } from '~/src/utils/misc';

export function useGetReportInfoFromParams() {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('reportType');
  const reportType = isSomeEnum(ReportType)(type) ? type : ReportType.Default;

  if (reportType === ReportType.Combination)
    return {
      reportType,
      youthId: searchParams.get('youthId'),
      guardianId1: searchParams.get('guardianId1'),
      guardianId2: searchParams.get('guardianId2'),
    };

  return { reportType, id: searchParams.get('reportId') };
}
