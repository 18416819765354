import React from 'react';
import {
  Center,
  Text,
  Textarea,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { InputField, InputMaybe, InputType } from '~/src/api';
import { richTextParser } from '~/src/utils';
import { FlowItemDescription } from '../../components/FlowItemDescription';
import { useYedQuestionValidation } from '../../../../hooks/useYedQuestionValidation';

interface YedInputProps {
  currentValue: InputMaybe<string> | undefined;
  onChange: (value: string | string[] | undefined) => void;
}

const YedTextInput: React.FC<InputField & YedInputProps> = ({
  type,
  text,
  placeholder,
  label,
  footer,
  currentValue,
  validation,
  onChange,
}) => {
  const { t } = useTranslation();

  const { validate, errorMessage } = useYedQuestionValidation(validation);

  const onChangeWithValidation = (value: string) => {
    errorMessage && validate(value);
    onChange(value);
  };

  const inputField = (type: InputType) => {
    if (type === InputType.Textarea) {
      const MAX_CHARS = 2000 as const;
      const remainingChars = MAX_CHARS - (currentValue?.length ?? 0);

      return (
        <>
          <Textarea
            value={currentValue || ''}
            onChange={(e) => onChangeWithValidation(e.target.value)}
            onBlur={(e) => validate(e.target.value)}
            placeholder={placeholder ?? ''}
            rows={4}
            maxLength={MAX_CHARS}
          />
          <Text
            textAlign="right"
            my={2}
            fontSize="sm"
            textColor={remainingChars < 0.1 * MAX_CHARS ? 'red' : undefined}
          >
            {t('common.charactersRemaining')} {remainingChars}/{MAX_CHARS}
          </Text>
        </>
      );
    }

    return (
      <Input
        type={
          type === InputType.Email
            ? 'email'
            : type === InputType.Phonenumber
            ? 'tel'
            : 'text'
        }
        value={currentValue || ''}
        onChange={(e) => onChangeWithValidation(e.target.value)}
        onBlur={(e) => validate(e.target.value)}
        placeholder={placeholder ?? ''}
        borderRadius="full"
        autoComplete="off"
      />
    );
  };

  return (
    <>
      <FlowItemDescription description={[text || '']} />
      <Center>
        <FormControl
          aria-label={label ?? ''}
          id={`yed-input-${type}`}
          pt={4}
          maxWidth="32rem"
          isRequired
        >
          {label && <FormLabel textAlign="center">{label}</FormLabel>}
          {inputField(type)}
          <InputErrorMessages errorMessage={errorMessage} />
          {richTextParser(footer)}
        </FormControl>
      </Center>
    </>
  );
};
export default YedTextInput;

function InputErrorMessages({
  errorMessage,
}: {
  errorMessage?: string | null;
}) {
  if (!errorMessage) return null;
  return (
    <Text color="red" fontSize="sm" textAlign="center">
      {errorMessage}
    </Text>
  );
}
