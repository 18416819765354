import React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { HiOutlinePrinter } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import { reportRoot } from '~/src/constants';
import { ReportType } from '~/src/types';
import { CombinationIds } from '~/src/screens/Report/CombinationReport/CombinationReport';

export const PrintSummaryButton: React.FC<{
  id?: string;
  ids?: CombinationIds;
  type: ReportType;
}> = ({ id: reportId, type: reportType, ids }) => {
  const { t } = useTranslation();
  const params =
    reportType !== ReportType.Combination
      ? new URLSearchParams({ reportId: reportId ?? '', reportType }).toString()
      : new URLSearchParams({ ...(ids ?? {}), reportType }).toString();

  return (
    <a
      href={`/${reportRoot}/summary/print?${params}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button
        className="hideWhenPrinting"
        variant="ghost"
        size="md"
        fontSize={16}
      >
        {t('terapia.report.printSummary')}
        <Icon ml={2} fontSize={20} as={HiOutlinePrinter} />
      </Button>
    </a>
  );
};
