const primary = {
  50: '#e5eeff',
  100: '#bbcbfa',
  200: '#90a8f1',
  300: '#6485e8',
  400: '#3963e0',
  500: '#1f49c6',
  600: '#16399b', // <-- main brand color position
  700: '#0d2970',
  800: '#041846',
  900: '#00081d',
};

export default {
  // Brand colors can be generated in https://smart-swatch.netlify.app/
  brand: primary,
  primary: primary,
  secondary: {
    50: '#d8fbff',
    100: '#abeaff',
    200: '#7bdbff',
    300: '#48cbff',
    400: '#1abcff',
    500: '#00a2e6',
    600: '#007eb4',
    700: '#005a82',
    800: '#003751',
    900: '#001421',
  },
  red: {
    50: '#FCE9EF',
    100: '#F6C1D3',
    200: '#F099B6',
    300: '#EA719A',
    400: '#E4497D',
    500: '#DE2362',
    600: '#B11B4D',
    700: '#85143A',
    800: '#590D27',
    900: '#2C0713',
  },
};
