import React from 'react';
import { Button, Heading, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card, { CardContentStack } from '~/src/components/Card';
import HeaderImage from '~/src/images/image-bank/generic-card-header.svg';
import EnigmaImage from '~/src/images/image-bank/enigma.svg';
import { Link } from 'react-router-dom';
import { FlowItemType, FlowResponse } from '~src/api';
import { FlowItemDescription } from '../SurveyFlow/components/FlowItemDescription';
import { surveyFlowRoot } from '~src/constants';

interface ErrorCardProps {
  title?: string | null;
  message?: string | null;
  itemData?: FlowResponse | null | undefined;
  showButton?: boolean;
}

const ErrorCard: React.FC<ErrorCardProps> = ({
  title,
  message,
  itemData,
  showButton = true,
}) => {
  const { t } = useTranslation();

  const {
    type: itemType,
    description,
    nextNotLockedFlowItemId,
  } = itemData?.flowItem || {
    type: FlowItemType.Error,
  };

  const errorData = [
    {
      type: FlowItemType.AnswerlLocked,
      errorTitle: title || t('errors.answerLocked.title'),
      errorMessage:
        description && description[0]
          ? description
          : [t('errors.answerLocked.message').toString()], // Description from point-of-no-return yEd
      button: {
        to: nextNotLockedFlowItemId
          ? `/${surveyFlowRoot}/backInBusiness/${nextNotLockedFlowItemId}`
          : '/',
        text: t('errors.answerLocked.ctaLabel'),
      },
    },
    {
      type: FlowItemType.SessionClosed,
      errorTitle: title || t('errors.sessionClosed.title'),
      errorMessage: [message || t('errors.sessionClosed.message').toString()],
    },
  ];
  const defaultErrorData = {
    type: FlowItemType.Error,
    errorTitle: title || t('errors.errorCard.title'),
    errorMessage: [message || t('errors.errorCard.paragraph').toString()],
    button: {
      to: '/',
      text: t('errors.errorCard.ctaLabel'),
    },
  };

  const { errorTitle, errorMessage, button } = {
    ...defaultErrorData,
    ...errorData.filter((item) => item.type === itemType)[0],
  };

  return (
    <Card>
      <CardContentStack>
        <Image
          src={HeaderImage}
          alt=""
          display={['none', null, 'block']}
          maxWidth={[null, null, '200px', '250px']}
          py={[null, '4', '6']}
        />

        <Heading>{errorTitle}</Heading>
        <Image src={EnigmaImage} alt="" maxWidth="70px" py={['4', null, '6']} />
        <FlowItemDescription description={errorMessage} />
      </CardContentStack>
      {showButton && (
        <Button as={Link} to={button.to} width="100%" borderRadius={0}>
          {button.text}
        </Button>
      )}
    </Card>
  );
};

export default ErrorCard;
