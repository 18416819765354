import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import Container from '~/src/components/Container';
import { flowInit } from '~/src/configurations';

const Intro: React.FC<{ titleTranslation?: string }> = ({
  titleTranslation,
}) => {
  const { t } = useTranslation();

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Heading
        as="h1"
        fontSize={['2rem', null, '4xl']}
        lineHeight="125%"
        textAlign="center"
        color="brand.500"
        mt={4}
      >
        {t(titleTranslation ?? 'intro.title')}
      </Heading>

      <Text textAlign="center" mt={4}>
        <Trans
          i18nKey="intro.paragraph"
          components={[<strong key="1" />, <strong key="2" />]}
        />
      </Text>
    </Container>
  );
};

export default Intro;
