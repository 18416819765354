import React, { useContext } from 'react';
import { Prompt } from 'react-router';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { SlideFade } from '@chakra-ui/react';
import Container from '~/src/components/Container';
import { AppContext } from '~/src/state';
import { FlowItemType, FlowResponse, useGetFlowItemQuery } from '~/src/api';
import ErrorCard from '~/src/components/ErrorCard';
import Card from '~/src/components/Card';
import ProgressBar from '~/src/components/ProgressBar';

import { SurveySearch } from '~/src/components/SurveyFlow/SurveySearch';
import { BasicInfo } from '~src/components/SurveyFlow/BasicInfo';
import { YedQuestion } from '~/src/components/SurveyFlow/YedQuestion';
import { Feedback } from '~src/components/SurveyFlow/Feedback';

import '~/src/theme/stylesheets/yedStyles.scss';
import { Authentication } from '~src/components/SurveyFlow/Authentication';
import { AuthenticationSuccess } from '~src/components/SurveyFlow/AuthenticationSuccess';
import Payment from '~src/components/SurveyFlow/Payment';
import { Calendly } from '~src/components/SurveyFlow/Calendly';
import FlowItemIntro from '~src/components/SurveyFlow/components/FlowItemIntro';
import { getSessionStorage, removeSessionStorage } from '~src/utils/storage';
import { useSetLogo } from '~src/hooks/useAddAnswer';
import { ensureUrlProtocol } from '~src/utils/isValidUrl';
import { envs } from '~src/utils';

const SurveyFlow: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const { setLogo } = useSetLogo();
  const { id } = useParams<{ id: string }>();
  const dataInState = state?.currentFlowItem?.id === id;

  const { data, error } = useGetFlowItemQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: state.appIsLoading || dataInState,
    onCompleted: (res) => {
      setLogo(res?.flowItem?.flowItem?.logo);
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const itemData = dataInState ? state.currentFlowItem : data?.flowItem;
  const flowResponse = { ...(itemData as FlowResponse) };
  const showErrorCard =
    !id ||
    error ||
    flowResponse.success === false ||
    FlowItemType.SessionClosed === flowResponse.flowItem?.type ||
    (flowResponse.success && !flowResponse.flowItem);

  const {
    title,
    description,
    type: itemType,
    endOfSurveyRedirectUrl,
  } = flowResponse?.flowItem || {};

  const isSlide =
    history.action === 'PUSH' && itemType !== FlowItemType.QaSearch;

  // Two ways to add redirect urls:
  // 1. User entrance url has redirect_url query parameter, it's saved to session storage
  //    and used when user reaches the end of the survey
  // 2. if no redirect_url in session storage, endOfSurveyRedirectUrl used which is set in the flow item
  let finalRedirectUrl = '';
  if (itemType === FlowItemType.EndOfSurvey) {
    const redirectUrlFromStorage = getSessionStorage('redirect_url');
    removeSessionStorage('redirect_url');
    finalRedirectUrl = redirectUrlFromStorage
      ? redirectUrlFromStorage
      : endOfSurveyRedirectUrl || '';
  }

  const mapFlowItems = () => {
    switch (itemType) {
      case FlowItemType.SurveySearch:
        return <SurveySearch {...flowResponse} />;

      case FlowItemType.BasicInfo:
        return <BasicInfo {...flowResponse} />;

      case FlowItemType.YedQuestion:
        return <YedQuestion {...flowResponse} />;

      case FlowItemType.Feedback:
        return <Feedback {...flowResponse} />;

      case FlowItemType.Authentication:
        return (
          <Authentication answerId={id} flowItem={flowResponse.flowItem} />
        );

      case FlowItemType.AuthenticationSuccess:
        return <AuthenticationSuccess {...flowResponse} />;

      case FlowItemType.Payment:
        return <Payment {...flowResponse} />;

      case FlowItemType.Calendly:
        return <Calendly {...flowResponse} />;

      case FlowItemType.EndOfSurvey:
        if (finalRedirectUrl) {
          window.location.href = ensureUrlProtocol(finalRedirectUrl);
          return null;
        }
        return <Redirect to="/" />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <FlowItemIntro title={title} description={description} />
      <Container key={id} padding={[4, 8, 16]}>
        <Prompt
          // Prevent user from going back (i.e. action === 'POP') when flow is closed
          when={FlowItemType.SessionClosed === flowResponse.flowItem?.type}
          message={(_, action) =>
            action === 'POP'
              ? `Kysely on suljettu eikä vastauksia voi enää muuttaa. Haluatko varmasti palata takaisin?${
                  envs.CONFIGURATION === 'Terapianavigaattori' ||
                  envs.CONFIGURATION === 'Interventionavigaattori'
                    ? ' Peruuta mikäli et ole vielä ottanut koodia talteen.'
                    : ''
                }`
              : true
          }
        />
        <ProgressBar showErrorCard={showErrorCard} {...flowResponse} />
        {showErrorCard ? (
          <ErrorCard itemData={itemData} />
        ) : (
          <SlideFade in={!!itemType} offsetX={isSlide ? 300 : 0} offsetY={0}>
            <Card className="QAndA-card">{mapFlowItems()}</Card>
          </SlideFade>
        )}
      </Container>
    </>
  );
};

export { SurveyFlow };
