{
  "common": {
    "app_name": "Tupakkalakko",
    "app_organization": "Duodecim"
  },
  "intro": {
    "paragraph": "<1>Askel kohti savuttomuutta.</1><br /><br /><br /><1>I<br />Tupakoinnin lopettaminen ei ole helppoa - varsinkaan yksin!</1><br /><br />60 % tupakoijista haluaisi lopettaa ja 40 % yrittää lopettamista vuosittain. Ja silti vain 3-4 prosenttia onnistuu lopettamaan tupakoinnin.<br /><br /><br /><1>II<br />Lopetetaan yhdessä!</1><br /><br />Tiedetään kuitenkin, että lopettaminen on selvästi helpompaa, kun omaan päätökseen yhdistetään lääkärin tai muun terveydenhuollon ammattilaisen tuki ja myös lääkehoito.<br /><br /><1>III<br />Aloita nyt!</1><br /><br />Nyt haluamme tarjota päätöksellesi tuen. Aloita tupakasta ja nikotiinista vieroittuminen heti ja suunnitellaan sinulle sopivin tapa lopettaa.<br /><br />Tunnistaudu palveluun ja lääkärimme soittaa sinulle 1-2 päivän sisällä. Palvelu ei maksa sinulle. Mahdollisen lääkehoidon maksat itse.<br /><br /><br /><1>IV<br />Tuemme savuttomuuttasi</1><br /><br />Tupakoinnin lopettamiseen kuuluu onnistumiset mutta joskus myös retkahdukset. Ensimmäiset 6 kuukautta ovat tärkeä aikaa lopettamisen onnistumisen kannalta. Tuemme koko 6 kk ajan ja varmistetaan että lopettaminen todella onnistuu!",
    "linkText": "Tietoja Tupakkalakko-palvelusta"
  },
  "flowInit": {
    "title": ""
  },
  "infoModal": {
    "title": "Ohjeita Tupakkalakko-palveluun"
  }
}
