import React from 'react';
import { Avatar, Box, VStack, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import AvatarPlaceholderIcon from '~/src/components/Chat/AvatarPlaceholderIcon';
import { IChatUser } from '~/src/types';

const ProfessionalAtService: React.FC<IChatUser> = ({
  username,
  profession,
  avatar,
}) => {
  const { t } = useTranslation();
  const avatarSize = ['4.625rem', '6.625rem'];

  return (
    <VStack>
      <Heading as="h2" fontSize="1.125rem">
        {t('chat.discussion.atYourService')}
      </Heading>

      <Avatar
        name={avatar && username}
        src={avatar}
        icon={
          <AvatarPlaceholderIcon color="primary.500" width="90%" height="90%" />
        }
        background={!avatar ? 'none' : 'primary.100'}
        marginBottom={2}
        height={avatarSize}
        width={avatarSize}
      />

      <VStack color="primary.500" spacing={0}>
        <Box fontWeight="bold" display="block">
          {username}
        </Box>
        <Box as="span">{profession}</Box>
      </VStack>
    </VStack>
  );
};

export default ProfessionalAtService;
