import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TbLogout } from 'react-icons/tb';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { useHistory, useLocation } from 'react-router-dom';
import { closeSessionAnswerObject, surveyFlowRoot } from '~src/constants';

export const LogoutButton: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { addAnswer, loading } = useAddAnswer(undefined, () => {
    history.push('/');
    // return true to prevent new redirect
    return true;
  });

  // Extract survey id from pathname
  const regex = new RegExp(`^/${surveyFlowRoot}/[^/]+/([^/]+)$`);
  const match = location.pathname.match(regex);
  const id = match ? match[1] : null;

  // Hide logout button if not in survey flow
  if (!id) return null;

  return (
    <Tooltip label={t('common.logoutButton')}>
      <IconButton
        aria-label={t('common.logoutButton')}
        icon={<TbLogout fontSize="2rem" />}
        onClick={(e) => {
          e.preventDefault();
          addAnswer({
            variables: {
              id: id,
              answer: closeSessionAnswerObject,
            },
          });
        }}
        isLoading={loading}
        variant="ghost"
        size="md"
        isRound
        fontSize={24}
      />
    </Tooltip>
  );
};
