import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loading from '~/src/components/Loading';
import { useGetReportQuery, Report } from '~/src/api';
import { FullReport } from '~/src/screens/FullReport';
import { ReportViewType } from '~/src/types';
import { ReportNotFound } from '../components/ReportNotFound';
import { ReportSummaryGuardian } from './ReportSummaryGuardian';

export const GuardianReport: React.FC<{
  view: ReportViewType;
  id?: string | null;
}> = ({ view, id }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h1" size="xl" marginBottom={4}>
        {t('terapia.report.title')}
      </Heading>

      <GuardianReportBody view={view} id={id} />
    </>
  );
};

const GuardianReportBody: React.FC<{
  view: ReportViewType;
  id?: string | null;
}> = ({ view, id }) => {
  const { data, loading, error } = useGetReportQuery({
    variables: { reportId: id?.trim(), yedNames: ['hus-terapia-huoltaja'] },
    skip: !id,
  });

  const reportData: Report | undefined | null = data?.report;

  if (loading) return <Loading />;
  if (error || !reportData || !id) return <ReportNotFound id={id ?? ''} />;
  if (view === ReportViewType.Full) return <FullReport report={reportData} />;

  return <ReportSummaryGuardian id={id} />;
};
