import { Container, SlideFade, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  PaytrailPaymentStatus,
  QuestionType,
  useGetPaymentQuery,
} from '~src/api';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { devlogger } from '~src/utils';
import { parseReturnSearchString } from '~src/utils/paymentUtil';
import Card, { CardContentStack } from '../../Card';
import Loading from '../../Loading';

/**
 * Payment callback component
 */
const PaymentCallback: React.FC = () => {
  const { flow, answerId } = useParams<{ flow: string; answerId: string }>();
  const [amountOfRefetches, setAmountOfRefetches] = React.useState<number>(0);
  const [paymentStamp, setPaymentStamp] = React.useState<string>('');
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  useMemo(() => {
    const paramsString = parseReturnSearchString(search);
    if (paramsString === null) {
      devlogger('Not valid success/cancel payment callback query string');
      history.push('/');
      return null;
    }
    setPaymentStamp(paramsString['checkout-stamp']);
    return paramsString;
  }, [history, search]);

  const { addAnswer } = useAddAnswer(flow);

  const reTryUrl = `/${flow}/payment/${answerId}?retry=true`;

  const { refetch } = useGetPaymentQuery({
    skip: !answerId || !paymentStamp,
    variables: {
      id: answerId,
      paymentStamp: paymentStamp,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { paytrailStatus = PaytrailPaymentStatus.Fail } = data.payment;
      handleRedirect(paytrailStatus);
    },
    onError() {
      handleRedirect(PaytrailPaymentStatus.Fail);
    },
  });

  if (!answerId || !flow) {
    history.push('/');
    return null;
  }

  function handleRedirect(status: PaytrailPaymentStatus) {
    switch (status) {
      // documentation about statuses https://docs.paytrail.com/#/?id=statuses
      case PaytrailPaymentStatus.Ok:
      case PaytrailPaymentStatus.Pending:
      case PaytrailPaymentStatus.Delayd:
        addAnswer({
          variables: {
            id: answerId,
            answer: [
              {
                type: QuestionType.PaymentSuccess,
                name: 'yed-button-option',
                // value (~ edgeNumber ) will be set in backend
              },
            ],
          },
        });
        break;
      case PaytrailPaymentStatus.Fail:
        history.push(reTryUrl);
        break;
      case PaytrailPaymentStatus.New:
        // If payment callback to backend is not yet completed, try refetch status 5 times with 1s interval
        if (amountOfRefetches < 5) {
          setTimeout(() => {
            setAmountOfRefetches(amountOfRefetches + 1);
            refetch();
          }, 1000);
        } else {
          history.push(reTryUrl);
        }
        break;
      default:
        history.push('/');
        break;
    }
  }

  return (
    <Container
      key={answerId}
      px={[4, 8, 16]}
      py={4}
      sx={{
        '.chakra-offset-slide > *': {
          overflow: 'visible',
        },
      }}
    >
      <SlideFade in={true} offsetX={300} offsetY={0}>
        <Card>
          <CardContentStack mb={[4, null, 8]}>
            <Loading />
            <Text>{t('payment.callbackLoading')}</Text>
          </CardContentStack>
        </Card>
      </SlideFade>
    </Container>
  );
};

export { PaymentCallback };
