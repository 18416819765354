import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { Link } from '~/src/components/Link';
import { useI18nUtils } from '~src/hooks/i18nUtils';
import { Title } from './Title';
import { ReportType } from '~/src/types';
import { capitalizeFirstLetter } from '~/src/utils/misc';

export const SelfCareLinks: FC<{ title: Title; reportType?: ReportType }> = ({
  title,
  reportType,
}) => {
  const { t, i18n } = useTranslation();
  const { getI18nArrayOfStrings } = useI18nUtils();

  // Translation key prefix with suffix from reportType
  const suffix = capitalizeFirstLetter(reportType ?? '');
  const tPrefix = `terapia.reportSelfCareLinks${suffix}.`;

  const links:
    | [{ text: string; address: string; onlyInFinnish?: boolean }]
    | string = t(`${tPrefix}links`, {
    returnObjects: true,
  });

  return (
    <>
      <Heading as="h2" size="lg" mt={16} mb={8} id={title.id}>
        {title.text}
      </Heading>

      {getI18nArrayOfStrings(`${tPrefix}paragraphs`).map((text, idx) => (
        <Text key={idx} my={5}>
          {text}
        </Text>
      ))}

      {!Array.isArray(links) ? (
        links
      ) : (
        <UnorderedList>
          {links.map((link, idx) => (
            <ListItem key={'selfcarelink' + idx}>
              <Link href={link.address} isBold isExternal>
                {link.text}
              </Link>
              {link.onlyInFinnish && ' (än så länge bara på finska)'}
            </ListItem>
          ))}{' '}
        </UnorderedList>
      )}
      {i18n.exists(`${tPrefix}endtext`) && (
        <Text my={5}>{t(`${tPrefix}endtext`)}</Text>
      )}
    </>
  );
};
