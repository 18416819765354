import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from '@chakra-ui/react';
import { ReportType } from '~/src/types';
import { SelfCareLinks } from '../components/SelfCareLinks';
import { reportRoot } from '~/src/constants';
import { Link } from '~/src/components/Link';

export const ReportSummaryGuardian: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();

  const params = new URLSearchParams({
    reportId: id,
    reportType: ReportType.Guardian,
  }).toString();

  return (
    <Box textAlign="left" width="inherit">
      <Text my={5}>
        Kiitos vastauksistasi! Vastauksesi ovat tärkeitä, jotta nuoresi
        tilanteesta saadaan kattava kuva.
      </Text>
      <Text my={5}>
        Vastauksiesi koodi on{' '}
        <Text as="strong" fontSize="2xl" lineHeight={0}>
          {id}
        </Text>
        . Koodin avulla voit kuukauden ajan palata tarkastelemaan vastauksiasi
        huoltajan Interventionavigaattorin ”Avaa tuloksesi koodilla”-painikkeen
        kautta. Nuoresi tuen tai hoidon tarpeen selvittämiseksi anna koodi
        ammattilaiselle, joka voi arvioida nuoresi tilannetta.
      </Text>
      <Text my={5}>
        Yksittäisiä vastauksiasi pääset tarkastelemaan tästä:{' '}
        <Link href={`/${reportRoot}/full?${params}`} isBold isExternal>
          {t('terapia.reportFull.openLink')}
        </Link>
        .
      </Text>
      <SelfCareLinks
        title={{
          text: t('terapia.reportSelfCareLinksGuardian.title'),
          id: 'selfCareLinks',
        }}
        reportType={ReportType.Guardian}
      />
    </Box>
  );
};
