import React, { FC, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Text, Box } from '@chakra-ui/react';
import { Report } from '~/src/api';
import { ReportType } from '~/src/types';
import { Title, SummaryHeading } from '../components/Title';
import { SelfCareLinks } from '../components/SelfCareLinks';
import { FeedbackSection } from '../components/FeedbackSection';
import { Instructions } from '../components/Instructions';
import { FullReportSection } from '../components/FullReportSection';
import { ReportInfoLink } from '../components/ReportInfoLink';
import { SummaryContentV1 } from './SummaryContentV1';
import { SummaryContentV2 } from './SummaryContentV2';

export const ReportSummaryTerapia: FC<{
  report: Report;
  id: string;
  isPrint: boolean;
}> = ({ report, id, isPrint }) => {
  const { t } = useTranslation();
  const titles: Array<Title> = [
    { text: t('terapia.reportSummary.title'), id: 'summary' },
    { text: t('terapia.reportFeedback.title'), id: 'feedback' },
    { text: t('terapia.reportFull.title'), id: 'fullReport' },
    { text: t('terapia.reportSelfCareLinks.title'), id: 'selfCareLinks' },
    {
      text: t('terapia.reportInstructions.title'),
      id: 'instructions',
    },
  ];

  useEffect(() => {
    if (isPrint) window.print();
  }, [isPrint]);

  if (isPrint)
    return (
      <Box textAlign="left" width="inherit">
        <Summary report={report} title={titles[0]} id={id} />
        <ReportInfoLink />
      </Box>
    );

  return (
    <Box textAlign="left" width="inherit">
      <Text>
        <Trans i18nKey="terapia.report.description" values={{ id }} />
      </Text>
      <Summary report={report} title={titles[0]} id={id} />
      <FeedbackSection title={titles[1]} />
      <FullReportSection title={titles[2]} id={id} type={ReportType.Default} />
      <SelfCareLinks title={titles[3]} />
      <Instructions title={titles[4]} />
    </Box>
  );
};

export const Summary: FC<{
  report: Report;
  title: Title;
  id: string;
}> = ({ report, title, id }) => {
  const summaryContent =
    report.reportVersion === 2 ? (
      <SummaryContentV2 report={report} />
    ) : (
      <SummaryContentV1 report={report} />
    );

  return (
    <>
      <SummaryHeading
        title={title}
        summaryBody={summaryContent}
        id={id}
        type={ReportType.Default}
      />
      {summaryContent}
    </>
  );
};
