import type { Configuration, LanguageResources } from '~/src/types';
import { Locale } from '~/src/types';
import LocalizationFiles from './*.json';

export const localizations: Partial<Record<Configuration, LanguageResources>> =
  {};

for (const filename in LocalizationFiles) {
  // Last two letters indicate the language Locale
  const lng = filename.slice(-2) as keyof typeof Locale;
  // Remaining indicates the configuration
  const configuration = filename.slice(0, -2) as keyof typeof Configuration;

  // Assume the lng is a valid locale
  const locale = Locale[lng];

  // Check if configuration has already added to the localizations object
  // Intermediate conf variable required because of
  // https://github.com/microsoft/TypeScript/issues/10530
  const conf = localizations[configuration];
  if (conf) conf[locale] = LocalizationFiles[filename];
  else localizations[configuration] = { [locale]: LocalizationFiles[filename] };
}
