import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CombinationData, CombinationIds } from '../CombinationReport';
import { Subtitle } from '~src/components/Headings';
import { useGetSummaryData } from '../useGetSummaryData';

export const SummaryBodyForCopyToClipboard: FC<{
  data: CombinationData;
  ids: CombinationIds;
}> = ({ data, ids }) => {
  const { t } = useTranslation();
  const { summaryData, getGuardianElementToClipboard } =
    useGetSummaryData(data);

  return (
    <>
      <Subtitle>{t('terapia.reportSummary.title')}</Subtitle>
      {ids.youthId ? (
        <>
          <Subtitle>NUORI</Subtitle>
          {summaryData.map((section, idx) => (
            <Fragment key={`youth-${section.name}-${idx}`}>
              <Subtitle>{section.name}</Subtitle>
              {section.youthContent}
            </Fragment>
          ))}
          <br />
          <br />
        </>
      ) : null}
      {ids.guardianId1 ? (
        <>
          <Subtitle>HUOLTAJA 1</Subtitle>
          {summaryData.map((section, idx) => (
            <Fragment key={`guardian1-${section.name}-${idx}`}>
              <>
                <Subtitle>{section.name}</Subtitle>
                {section.guardianContent.map((g) =>
                  getGuardianElementToClipboard(g, 'guardian1'),
                )}
              </>
            </Fragment>
          ))}
          <br />
          <br />
        </>
      ) : null}
      {ids.guardianId2 ? (
        <>
          <Subtitle>HUOLTAJA 2</Subtitle>
          {summaryData.map((section, idx) => (
            <Fragment key={`guardian2-${section.name}-${idx}`}>
              <>
                <Subtitle>{section.name}</Subtitle>
                {section.guardianContent.map((g) =>
                  getGuardianElementToClipboard(g, 'guardian2'),
                )}
              </>
            </Fragment>
          ))}
          <br />
          <br />
        </>
      ) : null}
    </>
  );
};
