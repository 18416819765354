import React, { FC, ReactNode } from 'react';
import { GridItem, GridItemProps } from '@chakra-ui/react';

export const YouthContent: FC<
  { children?: ReactNode; colSpan?: 1 | 2; addBorder?: boolean } & Omit<
    GridItemProps,
    'colSpan'
  >
> = ({ children, colSpan = 1, addBorder = false, ...rest }) => {
  return (
    <Content
      {...((addBorder === true || colSpan === 2) && {
        borderRight: '2px',
        borderColor: 'white',
      })}
      p={4}
      colSpan={colSpan}
      {...rest}
    >
      {children}
    </Content>
  );
};

export const GuardianContent: FC<
  { children?: ReactNode; colSpan?: 1 | 2 | 3; addBorder?: boolean } & Omit<
    GridItemProps,
    'colSpan'
  >
> = ({ children, colSpan = 1, addBorder = false, ...rest }) => {
  return (
    <Content
      {...((addBorder === true || colSpan === 3) && {
        borderLeft: '2px',
        borderColor: 'white',
      })}
      p={4}
      colSpan={colSpan}
      {...rest}
    >
      {children}
    </Content>
  );
};

const Content: FC<GridItemProps> = ({ children, ...rest }) => {
  return (
    <GridItem alignSelf="stretch" bg="gray.300" p={4} {...rest}>
      {children}
    </GridItem>
  );
};
