import { useState, useEffect, useRef } from 'react';

/**
 * useDebouncedValue - hook
 * - Returns a debounced value that is updated after a delay.
 * @param {T} value
 * @param {T} defaultValue
 * @param {number} [delay=500]
 * @returns {T}
 */
const useDebouncedValue = <T>(value: T, defaultValue: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
};

export { useDebouncedValue };
