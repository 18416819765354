import { Box, FormControl, Radio, RadioGroup, HStack } from '@chakra-ui/react';
import React from 'react';

import { InputField, InputMaybe, Options } from '~/src/api';
import { richTextParser } from '~/src/utils';
import { radioStyles } from '~src/configurations';
import { FlowItemDescription } from '../../components/FlowItemDescription';

interface YedOptionProps {
  currentValue: InputMaybe<string> | undefined;
  onChange: (nextValue: any) => void;
  idx: number;
}

const YedRadioGrid: React.FC<InputField & YedOptionProps> = ({
  type,
  text,
  options,
  currentValue,
  onChange,
  idx,
}) => {
  if (!options) return null;

  const inputOptions = options as Options[];

  const questionColWidth = 0.4;
  const optionColWidth = `${100 / inputOptions.length}%`;

  const answerOptions = inputOptions.map(({ value }, i) => {
    const key = `yed-${type.toLowerCase()}-option-${i}`;

    return (
      <Radio
        key={key}
        value={value}
        width={optionColWidth}
        justifyContent="center"
        p={['0.5rem', '1rem']}
        size={radioStyles?.size ?? 'md'}
        style={{ ...radioStyles }}
      />
    );
  });

  const optionStack = <HStack spacing={[0, 2, 4]}>{answerOptions}</HStack>;

  const optionGroup = (
    <RadioGroup
      onChange={(value) => onChange([value])}
      value={currentValue ?? ''}
    >
      {optionStack}
    </RadioGroup>
  );

  const header = (
    <HStack>
      <Box width={questionColWidth}></Box>
      <Box width={1 - questionColWidth}>
        <HStack spacing={[0, 2, 4]}>
          {inputOptions.map(({ label }, idx) => (
            <Box
              width={optionColWidth}
              key={`radio-header-${idx}`}
              fontSize={['xs', 'sm', 'md']}
              fontWeight="bold"
              textAlign="center"
            >
              {richTextParser(label, {
                allowTags: ['button'],
                allowAttr: ['className'],
              })}
            </Box>
          ))}
        </HStack>
      </Box>
    </HStack>
  );

  return (
    <Box mb="5">
      {idx % 5 === 0 && header}
      <HStack spacing={'auto'}>
        <Box width={questionColWidth} fontSize={['sm', 'sm', 'md']}>
          <FlowItemDescription description={[text || '']} />
        </Box>
        <FormControl as="fieldset" width={1 - questionColWidth}>
          {optionGroup}
        </FormControl>
      </HStack>
    </Box>
  );
};

export default YedRadioGrid;
