{
  "common": {
    "app_name": "Dokportin digivastaanotto",
    "app_organization": "Dokport Oy"
  },
  "intro": {
    "title": "Tervetuloa lääkärin chat-vastaanotolle",
    "paragraph": "Etävastaanoton alussa varmistetaan, että pystymme auttamaan sinua palvelumme kautta. Mikäli vaivasi ei sovellu etänä hoidettavaksi, ohjaamme sinut sopivamman palvelun pariin. Mikäli vaivasi soveltuu etähoitoon, pääset eteenpäin tarkempaan oirearvioon, ja lääkäri on sinuun yhteydessä hoitoasioissa.<br /><br />HUOM! Yhdellä etävastaanotolla voidaan hoitaa vain yhtä terveysasiaa kerrallaan Jos tarvitset apua myös toiseen terveysasiaan, ole hyvä ja aloita uusi asiointi. Vain yksi Kela-korvattu etävastaanotto päivässä on mahdollinen."
  },
  "infoModal": {
    "title": "Ohjeita Digivastaanoton käyttöön",
    "paragraphContent": [
      "Etävastaanoton hinta on 8 eur. Mikäli olet oikeutettu Kela-korvaukseen, haemme Kela-korvauksen puolestasi ja etävastaanotto on sinulle ilmainen. Jos sinulla on voimassa oleva Kela-kortti, olet oikeutettu Kela-korvaukseen. Mikäli et ole oikeutettu Kela-korvaukseen, etävastaanoton hinta on 8 eur + 10 eur laskutuslisä. Lähetämme laskun sähköpostiin vastaanoton jälkeen.",
      "HUOM! Yhdellä etävastaanotolla voidaan hoitaa vain yhtä terveysasiaa kerrallaan.",
      "Jos tarvitset apua myös toiseen terveysasiaan, ole hyvä ja aloita uusi asiointi. Vain yksi Kela-korvattu vastaanotto päivässä on mahdollinen."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "qAndAFlow": {
    "search": {
      "FAQ": "Yleisimmät oireet"
    }
  }
}
