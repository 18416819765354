import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loading from '~/src/components/Loading';
import { useGetReportQuery, Report } from '~/src/api';
import { ReportViewType } from '~/src/types';
import { ReportNotFound } from '../components/ReportNotFound';
import { ReportSummaryCombination } from './ReportSummaryCombination';

export type CombinationIds = {
  youthId?: string;
  guardianId1?: string;
  guardianId2?: string;
};
export type CombinationData = {
  youthData?: Report;
  guardianData1?: Report;
  guardianData2?: Report;
};

type CombinationReportProps = {
  view: ReportViewType;
} & CombinationIds;

export const CombinationReport: React.FC<CombinationReportProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h1" size="xl" marginBottom={4}>
        {t('terapia.reportSummary.title')}
      </Heading>

      <CombinationReportBody {...props} />
    </>
  );
};

const CombinationReportBody: React.FC<CombinationReportProps> = ({
  view,
  youthId,
  guardianId1,
  guardianId2,
}) => {
  const youthReport = useGetReportQuery({
    variables: { reportId: youthId?.trim(), yedNames: ['hus-terapia-nuori'] },
    skip: !youthId,
  });
  const guardianReport1 = useGetReportQuery({
    variables: {
      reportId: guardianId1?.trim(),
      yedNames: ['hus-terapia-huoltaja'],
    },
    skip: !guardianId1,
  });
  const guardianReport2 = useGetReportQuery({
    variables: {
      reportId: guardianId2?.trim(),
      yedNames: ['hus-terapia-huoltaja'],
    },
    skip: !guardianId2,
  });

  const data: CombinationData = {
    youthData: youthReport.data?.report ?? undefined,
    guardianData1: guardianReport1.data?.report ?? undefined,
    guardianData2: guardianReport2.data?.report ?? undefined,
  };

  if (youthReport.loading || guardianReport1.loading || guardianReport2.loading)
    return <Loading />;

  if (youthReport.error) return <ReportNotFound id={youthId ?? ''} />;
  if (guardianReport1.error) return <ReportNotFound id={guardianId1 ?? ''} />;
  if (guardianReport2.error) return <ReportNotFound id={guardianId2 ?? ''} />;

  return (
    <ReportSummaryCombination
      ids={{ youthId, guardianId1, guardianId2 }}
      data={data}
      isPrint={view === ReportViewType.Print}
    />
  );
};
