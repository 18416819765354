{
  "common": {
    "pageTitle": "Terapianavigaattori",
    "app_name": "Terapia­navigaattori",
    "app_organization": "Terapiat etulinjaan",
    "close": "Sulje",
    "continue": "Jatka",
    "dropdownDefaultPlaceholder": "Valitse vaihtoehto",
    "charactersRemaining": "Merkkejä jäljellä",
    "allAnswersRequired": "Vastaa kaikkiin kohtiin päästäksesi eteenpäin",
    "navigateBack": "Palaa takaisin"
  },
  "errors": {
    "upcomingMaintenance": "Palvelussa on alkamassa pian huoltotauko, eikä uuden kyselyn aloittaminen ole siksi nyt mahdollista. Huoltotauko on ohi viimeistään klo",
    "generic": {
      "title": "Tapahtui odottamaton virhe",
      "message": "Hups, jotain meni pieleen. Yritä myöhemmin uudelleen."
    },
    "network": {
      "title": "Yhteysvirhe",
      "message": "Tarkista että olet yhteydessä internetiin."
    },
    "errorCard": {
      "title": "Hups, jokin meni pieleen!",
      "paragraph": "Sisältö jota haet ei ole saatavilla.",
      "ctaLabel": "Palaa etusivulle"
    },
    "sessionClosed": {
      "title": "Istunto on suljettu",
      "message": "Sisältö ei ole enää saatavilla."
    }
  },
  "languageSelect": {
    "fi": "Suomeksi",
    "sv": "På svenska",
    "en": "In English"

  },
  "infoModal": {
    "title": "Usein kysyttyjä kysymyksiä",
    "paragraphContent": [
      "<strong>Mitä teen, jos mikään vastauksista ei sovi?</strong><br />Vastaa parhaaksi katsomallasi tavalla jotain, jotta pääset eteenpäin. Kun hakeudut hoitoon, ammattilainen arvioi vielä tilanteesi. Pääset tällöin täydentämään tai korjaamaan ammattilaiselle tietojasi.",
      "<strong>Menetin tietoni, ovatko ne jossain tallessa? / Voinko saada uuden koodin?</strong><br />Jos täytit Terapianavigaattorin ja otit lopuksi talteen koodin, pystyt koodilla lataamaan raporttisi. Tietoturvasyistä vastaukset ovat palautettavissa vain koodilla ja vain kuukauden ajan. Ratkaisun hankaluutena ovat tilanteet, joissa Terapianavigaattorin täyttö keskeytyy, tekniset ongelmat estävät koodin saamisen tai koodi hukkuu. Pahoittelemme näistä tilanteista aiheutuvaa vaivaa ja kehotamme täyttämään Terapianavigaattorin tarvittaessa uudelleen. Toisella kerralla täyttäminen on onneksi usein nopeampaa.",
      "Terveydenhuollon ammattilainen voi arvioida tilanteesi myös ilman navigaattoritietoja myös navigaattoria hyödyntävissä yksiköissä. Arvioon tulee tällöin varata kuitenkin enemmän aikaa.",
      "<strong>Onko Terapianavigaattori täytettävä loppuun?</strong><br />Jotta Terapianavigaattorin tietoja voisi hyödyntää terveydenhuollon arviossa, sen on oltava loppuun asti täytetty. Terapia­navigaattori voi tuntua raskaalta etenkin silloin, jos vastaustesi pohjalta täytetään kovin monta navigaattorin oirekyselyistä. Yksittäisen kysymyksen vastausta ei kuitenkaan kannata jäädä liian pitkäksi aikaa pohtimaan.",
      "<strong>Mitä minun on hyvä huomioida tietoturvasta?</strong><br />Terapia­navigaattoriin täytetään anonyymia tietoa. Tarkoituksena on, ettei siitä voi tunnistaa täyttäjää. Älä täytä navigaattorin avoimiin tekstikenttiin yksilöiviä henkilötietoja, kuten nimeä tai sähköpostiosoitettasi. Vastausraporttisi voi avata vain lopuksi saamallasi koodilla. Koodin säilytyksessä on syytä olla huolellinen."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "intro": {
    "title": "Terapia­navigaattori",
    "paragraph": "",
    "linkText": "Tietoja Terapia­navigaattorista"
  },
  "flowInit": {
    "title": "Tervetuloa käyttämään Terapia­navigaattoria!",
    "paragraph": [
      "Tässä kyselyssä kartoitetaan sinun elämäntilannettasi sekä ongelmia tai vaikeuksia, joita olet saattanut kokea. Näitä tietoja kootaan, jotta sinut voidaan ohjata sopivimman avun/tuen pariin."
    ],
    "privacyAcceptance": "Hyväksyn antamieni tietojen käsittelyn <linkOut>tietosuojaselosteen</linkOut> mukaisesti",
    "notice": "Huomioi seuraavat asiat:",
    "noticeList": [
      "<strong>Terapianavigaattori täytetään anonyymisti.</strong> Älä kirjoita vastauksiin esimerkiksi nimeä, henkilötunnusta tai mitään yhteystietojasi.",
      "<strong>Varaa aikaa täyttämiseen 20–30 minuuttia.</strong> Vastauksia ei voi tallentaa kesken täyttämisen.",
      "<strong>Ota talteen henkilökohtainen koodi,</strong> jonka saat täytettyäsi Terapianavigaattorin loppuun. VINKKI! Ota koodista kuvakaappaus tai kuva puhelimellasi.",
      "<strong>Toimita koodi terveydenhuollon ammattilaiselle</strong>, joka tarkastelee Terapianavigaattorin tuloksia kanssasi. Koodi toimii yhden kuukauden ajan"
    ],
    "warningBox": [
      "Jos sinulla on itsetuhoisia ajatuksia tai olet epävarma turvallisuudestasi, kerro asiasta suoraan läheiselle ja ammattilaiselle sekä hakeudu suoraan terveydenhuollon ammattilaisen arvioon esimerkiksi terveysasemalle, työterveyteen tai opiskeluterveydenhuoltoon. Kerro ammattilaiselle myös itsetuhoisista ajatuksistasi, jotta saat tilanteeseesi parhaan mahdollisen avun.",
      "Jos olet tilanteessa, jossa tarvitset välitöntä apua, soita tai pyydä läheistäsi soittamaan numeroon 112."
    ]
  },
  "terapia": {
    "startButtons": {
      "start": "Aloita täyttö",
      "startTest": "Haluan vain kokeilla täyttämistä",
      "openResults": "Avaa tuloksesi koodilla",
      "viewExample": "Katso esimerkki tuloksista",
      "professionalInfo": "Ammattilaisten info",
      "accessibilityStatement": "Saavutettavuusseloste"
    },
    "professionalInfo": {
      "title": "Ammattilaisten info",
      "paragraphs": [
        "Terapianavigaattori on digitaalinen työväline, joka nopeuttaa hoidon tarpeen arviointia.",
        "Terapianavigaattori kerää asiakkaan tilanteesta olennaiset esitiedot sekä oiremittareiden tulokset ja tekee niistä koosteen ammattilaiselle ja asiakkaalle. Ammattilainen toteuttaa Terapianavigaattorin keräämien tietojen avulla hoidon tarpeen arvion, jota kutsutaan <strong>ensijäsennykseksi</strong>.",
        "Asiakas on useimmiten ohjattu täyttämään Terapianavigaattori ennen ensimmäistä kontaktia hoidon tarpeen arviota toteuttavaan terveydenhuollon ammattilaiseen. Terapianavigaattorin täytettyään asiakas on saanut 8-merkkisen koodin, jolla saat hänen tuloksensa auki.",
        "Asiakkaan Terapianavigaattorin tulokset saat auki osoitteessa <linkIn to='/report'>https://www.terapianavigaattori.fi/report</linkIn> asiakkaan antamalla koodilla. Koodi avaa automaattisesti <strong>koosteraportin</strong> asiakkaan tuloksista. Lisäksi pääset tarkastelemaan erikseen jokaista asiakkaan vastausta avaamalla <strong>laajan raportin</strong>."
      ],
      "reportSections": {
        "subtitle": "Terapianavigaattorin koosteraportti koostuu seuraavista osioista:",
        "sections": [
          "Ongelmakuvaus ja hakeutuminen hoitoon",
          "Oirekyselyt",
          "Toimintakyky",
          "Hoitoja, joihin valmis",
          "Sosiaali-, työ ja opiskelupalveluiden tarve",
          "Aiemmat psykososiaaliset hoidot"
        ]
      },
      "instructions": {
        "subtitle": "Ensijäsennyksessä keskity seuraaviin:",
        "list": [
          {
            "text": "Varmista kohdeoire ja oireiden voimakkuus",
            "subList": [
              "käy asiakkaan kanssa yhdessä läpi, mitä oiremittareissa näkyy",
              "haastattele tarkemmin oireiden voimakkuus",
              "kuvaa, miten nämä oireet tyypillisesti ilmenevät elämässä",
              "kysy, mihin oireeseen asiakas ensisijaisesti toivoo apua ja muutosta"
            ]
          },
          {
            "text": "Toimintakyvyn arvio",
            "subList": [
              "kuvautuuko asiakkaan vastauksissaan kuvaama toimintakyky kohtalaisena vai parempana",
              "vai kuvaako asiakas toimintakykyä huonona"
            ]
          },
          {
            "text": "Onko aiempaa psykososiaalista hoitoa?",
            "subList": [
              "onko aiempia matalan kynnyksen hoitomuotojen tai näyttöön perustuvien hoitomuotojen hoitojaksoja taustalla?"
            ]
          },
          {
            "text": "Hoitomieltymykset",
            "subList": [
              "mistä matalan kynnyksen hoidoista apua hakeva uskoisi hyötyvänsä?"
            ]
          },
          {
            "text": "Tarkenna seuraavat, jos nousevat esiin Terapianavigaattorin vastauksissa",
            "subList": [
              "millainen traumaattinen kriisi/elämänkriisi kyseessä? miten se on vaikuttanut?",
              "tarkenna millaisia unen ongelmia",
              "päihdeongelmat ja riippuvuudet",
              "itsetuhoisuus: onko itsemurhasuunnitelmia tai -ajatuksia? onko aikomusta toteuttaa? onko menetelmä mietittynä? onko aikaisempia yrityksiä?",
              "arvioi yhteistyötahoihin ohjaamisen tarve (sosiaalipalvelut, työterveyshuolto, opiskelijaterveyshuolto, TE-toimisto, lastensuojelu)"
            ]
          }
        ]
      },
      "paragraphs2": [
        "Asiakkaan ongelmien ja oireiden luonteen sekä hoitomieltymysten perusteella voit ohjata hänet joko 1) nopeasti alkaviin matalan kynnyksen hoitoihin tai 2) tarkempaan arvioon / intensiivisempiin hoitoihin.",
        "Terapianavigaattoria voidaan käyttää soveltuvasti myös perustasolla toteutettavan oirekartoituksen tukena. Tällöin on tärkeää kertoa asiakkaalle, mihin tarkoitukseen Terapianavigaattoria käytetään."
      ]
    },
    "openReport": {
      "title": "Avaa tulokset",
      "description": "Kirjoita alle Terapianavigaattorin tulosten yhteydessä saamasi koodi avataksesi tuloksesi uudelleen.",
      "formLabel": "Koodi tulosten avaamiseen",
      "openBtn": "Avaa tulokset"
    },
    "report": {
      "title": "Terapia­navigaattorin tulokset",
      "description": "Näiden tulosten koodi on <strong>{{id}}</strong>. Tämän koodin avulla voit palata myöhemmin tuloksiisi Terapianavigaattorin etusivun \"Katso tulokset\" -linkin kautta.",
      "toc": "Sisällys",
      "copyToClipboard": "Kopioi leikepöydälle",
      "downloadPdf": "Lataa kaikki tulokset pdf-tiedostona",
      "pdfInfoLink": "Tietoa Terapianavigaattorin tulosten tulkinnasta löydät osoitteesta",
      "pdfInfoLinkUrl": "https://www.terapianavigaattori.fi/report/instructions",
      "printSummary": "Tulosta koosteraportti",
      "pointAbbreviation": "p",
      "not-found": "Raporttia ei löydy annetulla koodilla"
    },
    "reportSummary": {
      "title": "",
      "subtitles": {
        "description": "Ongelmakuvaus ja hakeutuminen hoitoon",
        "questionnaires": "Oirekyselyt",
        "performance": "Toimintakyky",
        "treatments": "Hoitoja joihin valmis",
        "supportNeeds": "Sosiaali-, työ- ja opiskelupalveluiden tarve",
        "previousTreatments": "Aiemmat psykososiaaliset hoidot"
      },
      "questionnaireDisclaimer": "<strong>Huomio!</strong> Yllä olevat pistearvot kuvaavat ongelmiasi ja oireitasi vastaushetkellä. Ne eivät suoraan vielä tarkoita, että kyseinen mielenterveyden häiriö olisi sinulla diagnosoitavissa. Varsinaisen häiriön toteaminen tapahtuu aina terveydenhuollon ammattilaisen arvion perusteella.",
      "labels": {
        "coupleTherapy": "Lyhyt pari- tai perheterapia",
        "violenceNote": "HUOM: Lähisuhteiden väkivalta / väkivallan uhka -vastaus",
        "sleep": "Uniongelma-kysymys",
        "limitGaming": "Valmius vähentää pelaamista",
        "crisis": "Elämänkriisikysymys",
        "crisisEvent": "Elämänkriisi poikkeuksellisen järkyttävästä tapahtumasta ",
        "exhausted": "Uupumuskysymys",
        "drugs": "Huumausaine- ja lääkekysymys",
        "addiction": "Toiminnalliset riippuvuudet -kysymys",
        "dangerousThoughts": "Ajatukset, että voisi olla yhtä hyvin kuollut tai, että voisi vahingoittaa itseään jollakin tavalla (PHQ-9)",
        "crisisDescription": "Elämänkriisin tarkennuskysymys",
        "crisisEventDescription": "Elämänkriisi poikkeuksellisen järkyttävästä tapahtumasta -tarkennuskysymys",
        "drugsDescription": "Huumaus- ja lääkeaineiden tarkennus",
        "workPerformance": "Suoriutuminen töistä tai opinnoista viimeisen kuukauden aikana",
        "relationships": "Ihmissuhteiden sujuminen viimeisen kuukauden aikana",
        "webTherapy": "Nettiterapia",
        "groupTherapy": "Ryhmahoidot",
        "selfcare": "Ohjattu omahoito",
        "exercise": "Liikunta",
        "drugReview": "Toive lääkitysarviosta",
        "residence": "Lisätuen toive asumiseen tai perheeseen liittyvissä haasteissa",
        "violence": "Liittyykö lähisuhteisiin väkivaltaa tai väkivallan uhkaa",
        "workAndStudyStatus": "Työ- ja opiskelustatus",
        "workAndStudyStatusDetailed": "Tarkennus työ- ja opiskelustatukseen",
        "supportForWorkAndStudy": "Lisätuen toive työhön tai opiskeluun liittyvissä haasteissa",
        "previousTreatments": "Aiemmat psykososiaaliset hoidot",
        "previousTreatmentsDuration": "Aiempien hoitojen kesto",
        "previousTreatmentsDescription": "Aiempien hoitojen tarkennus",
        "noPreviousTreatments": "Ei aiempaa psykoterapiaa tai ammattilaisen keskusteluhoitoja."
      }
    },
    "reportFull": {
      "title": "Laaja raportti",
      "description": "Laajasta tarkistusraportista voit halutessasi katsoa kaikki antamasi vastaukset sekä oirekyselyiden pisteidesi laskutavan ja pisterajat. Siitä ilmenee myös minkä vastausten perusteella sinulle esitettävät lisäkysymykset ovat valikoituneet.",
      "openLink": "Avaa laaja raportti",
      "scores": "pisteet",
      "scoreLimits": "pisterajat",
      "result": "Tulos",
      "scoreCalculationError": "Virhe pisteiden laskennassa!"
    },
    "reportInstructions": {
      "title": "Näin luet Terapia­navigaattorin tuloksia: Sopivan hoitomuodon valitseminen",
      "description": "Jos olet sopinut Terapianavigaattorin täyttämisestä terveydenhuollossa, sinun ei itse tarvitse osata tulkita tuloksiasi itsenäisesti, vaan terveydenhuollon ammattilainen tekee sen sovitusti kanssasi osana terveydenhuollon arviotasi. Ammattilainen tarvitsee vain tämän sivun yläosaan merkityn koodin, voidakseen katsoa vastauksiasi. Halutessasi voit kuitenkin tutustua tästä tarkemmin niihin periaatteisiin, joita tulosten tulkinnassa käytetään, ja siihen, miten sopivaa hoitomuotoa kartoitetaan.",
      "description2": "Samoin, jos olet täyttänyt Terapianavigaattorin itsenäisesti, sinun ei tarvitse osata tulkita vastauksia yksin tai ennalta päättää, mitä hoitoa haluat. Tarkoitus on, että hakeudut apua toivoessasi vastaanotolle ja voitte yhdessä hyödyntää koostetta sopivan hoitomuodon valitsemisessa.",
      "openLink": "Avaa tulosten lukuohjeet"
    },
    "reportFeedback": {
      "title": "Palaute Terapia­navigaattorista",
      "description": "",
      "feedbackQuestion": "Miten koit Terapianavigaattorin ja koosteraportin?",
      "feedbackOptions": [
        "Juuri tätä kaipasin, kiteytti tilanteeni",
        "Hyvä ja hyödyllinen",
        "En osaa sanoa",
        "En kokenut hyväksi tai hyödylliseksi"
      ],
      "thankyou": "Kiitos palautteesta!"
    },
    "reportSelfCareLinks": {
      "title": "Omahoito­materiaaleja",
      "paragraphs": [
        "Ohessa on koottu yleisiä omahoitomateriaaleja. Ne sisältävät sekä tietoa että toipumista tukevia harjoituksia. Terapia­navigaattorin tuloksesi voivat auttaa arvioimaan, mitkä omahoitomateriaalit voisivat tukea sinua parhaiten.",
        "Voit halutessasi aloittaa omahoitomateriaaliin tutustumisen jo ennen hoidon alkua. Siitä voi olla hyötyä hoidon etenemisessä. Omahoitomateriaalia voidaan käyttää myös osana hoitoasi ammattilaisen kanssa."
      ],
      "links": [
        {
          "text": "Masennuksen omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/masennuksen-omahoito-ohjelma"
        },
        {
          "text": "Ahdistuksen omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/ahdistuksen-omahoito-ohjelma"
        },
        {
          "text": "Sosiaalisen jännittämisen omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/sosiaalisen-jannittamisen-omahoito-ohjelma"
        },
        {
          "text": "Paniikin omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/paniikin-omahoito-ohjelma"
        },
        {
          "text": "Pakko-oireiden omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/pakko-oireiden-omahoito-ohjelma"
        },
        {
          "text": "Unettomuuden omahoito",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/unettomuuden-omahoito-ohjelma"
        },
        {
          "text": "Järkyttävän tapahtuman omahoito-ohjelma",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/jarkyttavan-tapahtuman-omahoito-ohjelma-mieli-ry"
        }
      ]
    },
    "tests": {
      "phq": {
        "name": "PHQ-9",
        "longName": "Masennuskysely",
        "limits": [
          "viittaa normaaliin mielialaan",
          "viittaa <strong>lieviin / vähäisiin masennusoireisiin</strong>",
          "viittaa <strong>lievä-asteisiin masennusoireisiin</strong>",
          "viittaa <strong>keskivaikeisiin masennusoireisiin</strong>",
          "viittaa <strong>vaikea-asteisiin masennusoireisiin</strong>"
        ]
      },
      "gad": {
        "name": "GAD-7",
        "longName": "Ahdistuneisuuskysely",
        "limits": [
          "viittaa vähäiseen ahdistuneisuuteen",
          "viittaa <strong>lievään ahdistuneisuuteen</strong>",
          "viittaa <strong>kohtalaiseen ahdistuneisuuteen</strong>",
          "viittaa <strong>vaikeaan ahdistuneisuuteen </strong>"
        ]
      },
      "oci": {
        "name": "OCI-R",
        "longName": "Pakko-oirekysely",
        "limits": [
          "ei viittaa kliinisesti merkitsevään pakko-oiretasoon",
          "viittaa <strong>kliinisesti merkitsevään pakko-oiretasoon.</strong>"
        ]
      },
      "pdss": {
        "name": "PDSS-SR",
        "longName": "Paniikkioirekysely",
        "limits": [
          "ei viitteitä merkittävistä paniikkioireista",
          "viittaa <strong>lievään paniikkioireiluun.</strong>",
          "viittaavat <strong>kohtalaiseen paniikkioireiluun</strong>",
          "viittaa <strong>kohtalaisen vakavaan paniikkioireiluun</strong>",
          "viittaa <strong>vakavaan paniikkioireiluun</strong>",
          "viittaa <strong>erittäin vakavaan paniikkioireiluun.</strong>"
        ]
      },
      "spin": {
        "name": "SPIN-FIN",
        "longName": "Sosiaalisen jännittämisen kysely",
        "limits": [
          "viittaa normaaliin / vähäiseen sosiaalisten tilanteiden jännittämiseen",
          "viittaa <strong>kohtalaiseen sosiaalisten tilanteiden jännittämiseen</strong>",
          "viittaa <strong>voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>",
          "viittaa <strong>hyvin voimakkaaseen sosiaalisten tilanteiden jännittämiseen</strong>"
        ]
      },
      "audit": {
        "name": "AUDIT-C",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "alle riskirajan",
          "miehillä alle riskirajan",
          "viittaa <strong>naisilla riskikäyttöön</strong>",
          "viittaa <strong>riskikäyttöön</strong>"
        ]
      },
      "ies": {
        "name": "IES-R",
        "longName": "Traumaperäisen stressin kysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään traumaperäiseen stressiin",
          "voi viitata <strong>kliinisesti merkittävään traumaperäiseen stressiin</strong>"
        ]
      },
      "keds": {
        "name": "KEDS",
        "longName": "Uupumuskysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään uupumukseen",
          "viittaa <strong>kliinisesti merkittävään uupumukseen</strong>"
        ]
      },
      "auditFull": {
        "name": "AUDIT",
        "longName": "Alkoholinkäytön kysely",
        "limits": [
          "ei alkoholin käyttöä",
          "<strong>alkoholin käytön riskit vähäisiä</strong>",
          "<strong>miehillä alkoholin käytön riskit vähäisiä</strong>",
          "<strong>naisilla alkoholin käytön riskit lievästi kasvaneet</strong>",
          "<strong>alkoholin käytön riskit lievästi kasvaneet</strong>",
          "<strong>alkoholin käytön riskit selvästi kasvaneet</strong>",
          "<strong>alkoholin käytön riskit suuria</strong>",
          "<strong>riskit erittäin suuria (mahdollinen alkoholiriippuvuus)</strong>"
        ]
      },
      "bbgs": {
        "name": "BBGS",
        "longName": "Peliriippuvuuden seulakysely",
        "limits": [
          "ei viittaa rahapeliriippuvuuteen",
          "viittaa <strong>mahdolliseen rahapeliriippuvuuteen</strong>"
        ]
      },
      "bat": {
        "name": "BAT-12",
        "longName": "Työuupumus kysely",
        "limits": [
          "Ei työuupumusoireita",
          "viittaa <strong>kohonneeseen riskiin työuupua</strong>",
          "viittaa <strong>todennäköiseen työuupumukseen</strong>"
        ]
      },
      "tsq": {
        "name": "TSQ",
        "longName": "Traumaperäisen stressin kysely",
        "limits": [
          "ei viitteitä traumaperäisen stressihäiriön riskiin",
          "viittaa <strong>kohonneeseen traumaperäisen stressihäiriön riskiin</strong>"
        ]
      },
      "dudit": {
        "name": "DUDIT",
        "longName": "Huumeiden käytön riski",
        "limits": [
          "ei huumeista johtuvia ongelmia",
          "<strong>satunnaisesti huumeista johtuvia ongelmia</strong>",
          "<strong>miehillä satunnaisesti huumeista johtuvia ongelmia</strong>",
          "<strong>naisilla todennäköisesti huumeista johtuvia ongelmia</strong>",
          "<strong>todennäköisesti huumeista johtuvia ongelmia</strong>",
          "<strong>todennäköisesti voimakas huumeriippuvuus</strong>"
        ]
      }
    },
    "instructions": {
      "title": "TERAPIANAVIGAATTORIN TULOSTEN TULKINTA – Sopivan psykososiaalisen hoidon valitseminen",
      "description": "Terapianavigaattorilla on kaksi tarkoitusta: auttaa sinua itse hahmottamaan vaikeuksiasi ja mahdollisia hoitovaihtoehtoja, ja auttaa ammattilaista ohjaamaan sinut nopeasti oikeanlaisen avun piiriin. Sinun ei tarvitse siis osata tulkita vastauksia yksin tai ennalta päättää, mitä hoitoa haluat, vaan raportin on tarkoitus antaa tilaisuus näiden pohtimiseen niin halutessasi. Tarkoitus on, että hakeudut apua toivoessasi vastaanotolle ja voitte yhdessä hyödyntää koostetta sopivan hoitomuodon valitsemisessa.",
      "interpretSymptomsTitle": "1) Tulkitse oireesi ja niiden vakavuus",
      "interpretSymptomsContent": [
        "Käy läpi koosteraporttisi Terapia­navigaattorin tuloksista ja sen ”Oirekyselyt”-osio. Oirekyselyiden pistemäärät kertovat suuntaa siitä, mihin oireisiin hoitosi voisi kohdentaa, kuinka vakavista oireista on kyse ja vaikuttaako päihteiden käyttö hoitosuunnitelmaan.",
        "On tavallista, että vaikeuksiin liittyy useanlaisia oireita, kuten masennusoireita ja ahdistusta. Jos sinulla on useanlaisia oireita, pohdi, mikä niistä on pääasiallinen oireesi. Se on yleensä oireista vakavin ja sen helpottumisesta koituisi eniten apua.",
        "Pääasiallisen oireen hoitaminen tuo usein laajempaa helpotusta, mutta etenkin unettomuuteen ja päihdeongelmaan tulisi kiinnittää myös erillistä huomiota. Ne eivät automaattisesti korjaudu esimerkiksi masennuksen tai ahdistuksen helpottumisen myötä."
      ],
      "viewTreatmentsTitle": "2) Katso hoitomuodot",
      "viewTreatmentsContent": [
        "Kun olet tarkastellut koosteraporttiasi ja pohtinut, mihin pääasialliseen oireeseen toivoisit hoidon kohdistuvan, katso alta vaikeuteen soveltuvia psykososiaalisia hoitomuotoja. Sopivin hoitomuoto valitaan lopulta yhdessä ammattilaisen kanssa.",
        "<i>* Kun hakeudut hoitoon, ammattilainen tekee kanssasi tarkemman arvion voinnistasi ja siitä, mihin oireisiin hoito tulisi ensisijaisesti kohdentaa. Myös ammattilaiset käyttävät Terapia­navigaattorissa käytettyjä oirekyselyitä usein osana tarkempaa arviotaan.</i>"
      ],
      "listTreatmentsTitle": "Hoitomuodot eri vaikeuksiin",
      "addictionTitle": "Kun vaikeuksien rinnalla on päihdeongelma tai muu riippuvuus",
      "addictionDescription": "Riippuvuusongelma voi vaikeuttaa psykososiaalisesta hoidosta hyötymistä. Kun mukana on sekä mielenterveysongelma että päihdeongelma, niitä molempia tulisi hoitaa suunnitelmallisesti. On hyvä hakeutua tarkempaan ammattilaisen arvioon. Kasvokkainen hoito päihdehoitoa ja mielenterveyshoitoa tuntevan ammattilaisen kanssa on suositeltavaa. Lievissä ja keskivaikeissa alkoholiongelmissa psykososiaalisen hoidon osana voi myös käyttää mm. <linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/terapiaohjelmat/alkoholin_liikakaytto/Pages/default.aspx'>alkoholin liikakäytön nettiterapiaa</linkOut> ja Mielenterveystalo.fi:n <linkOut href='https://www.mielenterveystalo.fi/aikuiset/itsehoito-ja-oppaat/itsehoito/juomisen_hallinnan_opas/Pages/default.aspx'>Juomisen hallinnan omahoito-ohjelmaa</linkOut>.",
      "specialNotesTitle": "Erityistä huomioitavaa",
      "supportNeedsTitle": "Sosiaali-, työ- ja opiskeluterveydenhuollon palveluiden tarve",
      "supportNeedsDescription": "Asumiseen, toimeentuloon, perheeseen ja päihteisiin liittyvissä haasteissa on hyvä kartoittaa, mitä tukea voisi terveydenhuollon lisäksi saada sosiaalipalveluista. Työhön ja opiskeluun liittyvissä haasteissa on usein suositeltavaa, että julkinen hoitotaho tekee yhteistyötä työ- tai opiskeluterveydenhuollon kanssa. Työllistymisessä tukee TE-toimisto. Hyvä yhteistyö ja suunnitelma eri palveluiden välillä tukee toimivan palvelukokonaisuuden rakentamista.",
      "previousTreatmentsTitle": "Aiemmat hoidot",
      "previousTreatmentsDescription1": "Jos taustalla on aiempia hoitoja, kannattaa sopiva hoitomuoto arvioida yksilöllisesti:",
      "previousTreatmentsList": [
        "Onko hoitomuotoja, joista on ollut apua?",
        "Onko hoitomuotoja, jotka eivät ole auttaneet toivotusti?",
        "Mikä olisi tässä vaiheessa tarkoituksenmukaisin hoito?"
      ],
      "previousTreatmentsDescription2": "Joskus taustalla on useita hoitoyrityksiä, joista ei ole saatu toivottavaa helpotusta. Vaikeudet ovat saattaneet kestää hyvin pitkään, eivätkä ole juurikaan olleet poissa hoitomuotojen välillä. Tällöin on tarpeen tehdä tarkempi arvio eri palveluiden tarpeesta. Myös esimerkiksi sosiaalipalveluiden ja mielenterveysjärjestöjen tukimahdollisuudet on hyvä ottaa huomioon (esim. Mieli ry:n, Mielenterveyden keskusliiton ja Mielenterveyspoolin yhdistysten tukitoiminta).",
      "depressionAccordion": "Masennus-, ahdistus- ja paniikkioireet sekä unettomuus ja uupumus",
      "depressionTitle": "Sopivan psykososiaalisen hoidon valitseminen: masennus-, ahdistus- ja paniikkioireet, unettomuus ja uupumus",
      "depressionMediumSymptomsTitle": "Lievät ja keskivaikeat oireet",
      "depressionMediumSymptomsContent1": "Kun masennukseen, ahdistuneisuuteen, paniikkioireisiin, unettomuuteen tai uupumukseen liittyvät vaikeudet ovat lieviä tai keskivaikeita ja toimintakyky on vähintään kohtalaista, ovat hyviä julkisen terveydenhuollon psykososiaalisia hoitomuotoja esimerkiksi",
      "depressionMediumSymptomsList": [
        "<linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/Pages/default.aspx'>nettiterapia*</linkOut>",
        {
          "listItem": "ohjattu omahoito",
          "subItems": [
            "<small>Ei vielä kaikissa navigaattoria hyödyntävissä yksiköissä</small>"
          ]
        },
        "<strong>ryhmähoidot</strong>",
        {
          "listItem": "<strong>lyhyt pari- tai perheterapia</strong>",
          "subItems": [
            "<small>Soveltuu pari- ja perhepainotteisissa ongelmissa, jos suhteeseen ei liity väkivaltaa tai sen uhkaa</small>",
            "<small>Ei vielä kaikissa navigaattoria hyödyntävissä yksiköissä</small>"
          ]
        }
      ],
      "depressionMediumSymptomsContent2": "Lisäksi kuntoon sopeutettu terveellinen ja säännöllinen <strong>liikunta</strong> on suositeltava täydentävä hoito. Ryhmäliikunta on erityisen suositeltavaa.",
      "depressionMediumSymptomsContent3": "Mikäli yhtä tai useampaa yllä mainituista hoidoista on kokeiltu, eikä niistä ole saatu toivottua hyötyä, voi esim. <strong>etulinjan lyhytterapia</strong> tai <strong>lyhytpsykoterapia</strong> olla tarpeen. Etulinjan lyhytterapia on n. 5–10 käyntiä kestävä tavoitteellinen keskusteluhoito ja sen toteuttaa siihen koulutettu terveydenhuollon ammattilainen.",
      "depressionMediumSymptomsContent4": "* Uupumukseen ei ole tarjolla nettiterapiaa.",
      "depressionSevereSymptomsTitle": "Vakava oireilu tai heikko toimintakyky (ei aiempia hoitoyrityksiä)",
      "depressionSevereSymptomsContent1": "Jos masennukseen, ahdistuneisuuteen, paniikkiin, unettomuuteen tai uupumukseen liittyvät oireet ovat vakavia tai toimintakyky on melko huono tai huono, on syytä arvioida sopivat hoitomuodot tarkemmin. Usein on myös hyvä tehdä lääkitysarvio sekä selvittää, onko psykiatrian erikoissairaanhoidolle tarvetta. Melko usein psykososiaaliseksi hoidoksi soveltuu joko",
      "depressionSevereSymptomsShortTherapy": "lyhytpsykoterapia tai",
      "depressionSevereSymptomsLongTherapy": "pitkä psykoterapia",
      "depressionSevereSymptomsContent2": "Lisäksi kuntoon sopeutettu terveellinen ja säännöllinen <strong>liikunta</strong> on suositeltava täydentävä hoito. Ryhmäliikunta on erityisen suositeltavaa.",
      "crisisAccordion": "Kriisitilanteet ja traumaperäiset oireet",
      "crisisTitle": "Sopivan psykososiaalisen hoidon valitseminen: kriisitilanteet ja traumaperäiset oireet",
      "crisisSituationsTitle": "Kriisitilanteet",
      "crisisSituationsContent": [
        "Yksi tapa hahmottaa kriisejä on jakaa ne elämäntilannekriiseihin ja traumaattisiin kriiseihin. Elämäntilannekriisejä voi olla esimerkiksi avioero, eläkkeelle jääminen, työpaikan menetys tai läheisen menehtyminen pitkäaikaiseen sairauteen. Traumaattiset kriisit puolestaan sisältävät usein hengenvaaraa esim. vakava auto-onnettomuus tai tulipalo, väkivaltaa, raiskauksen tai seksuaalista hyväksikäyttöä tai näiden uhkaa.",
        "Elämäntilannekriiseissä kuten avioerossa tai työpaikan menetyksessä, soveltuu usein esimerkiksi mielenterveysjärjestöjen ja -säätiöiden tarjoama kriisituki ja keskusteluapu, jos vaikeuksiin ei liity selkeää muuta oireilua, kuten masennusta, ahdistusta tai päihdeongelmaa. Myös sosiaalipalveluiden tuki voi tulla kyseeseen. Jos muu oireilu on merkittävää, voi terveydenhuollon tarjoama psykososiaalinen hoito olla tarkoituksenmukaista. Sopivan hoitomuodon valinta perustuu tällöin oireilun laatuun ja vakavuuteen.",
        "Traumaattisissa kriiseissä suositellaan tarvittaessa lyhyttä <strong>keskusteluhoitoa</strong> kriisien hoitoon koulutetun ammattilaisen kanssa. Yleensä hoidon tavoitteena on tukea luonnollista kriisistä selviytymistä ja vahvistaa henkilön selviytymiskeinoja."
      ],
      "crisisTraumaTitle": "Traumaperäiset oireet",
      "crisisTraumaContent1": "Traumaperäiset oireet voivat liittyä myös kuukausia tai vuosiakin sitten koettuihin tilanteisiin, joihin on sisältynyt esimerkiksi hengenvaara, väkivaltaa, raiskaus tai seksuaalista hyväksikäyttöä. Traumaperäisissä oireissa ensisijaiset psykososiaaliset hoitomuodot ovat",
      "crisisTraumaList1": "<strong>traumafokusoitu kognitiivinen psykoterapia</strong> ja",
      "crisisTraumaList2": "<strong>EMDR-silmänliiketerapia</strong>.",
      "crisisTraumaContent2": "EMDR-terapiaa tarjoavat aiheeseen koulutetut mielenterveyden ammattilaiset. Traumafokusoitua kognitiivista psykoterapiaa tarjoaa yleensä kognitiivinen psykoterapeutti, kognitiivisen käyttäytymisterapian psykoterapeutti tai aiheeseen täydennyskoulutuksen saanut muu psykoterapeutti. Terveellinen <strong>liikunta</strong> ja <strong>jooga</strong> ovat suositeltavia täydentäviä hoitoja.",
      "ocdAccordion": "Pakko-oireet",
      "ocdTitle": "Sopivan psykososiaalisen hoidon valitseminen: pakko-oireet",
      "ocdContent1": "Suositeltavia psykososiaalisia hoitomuotoja ovat",
      "ocdList1": "<strong>pakko-oireisen häiriön nettiterapia</strong> ja",
      "ocdList2": "<strong>psykoterapioista altistukseen perustuva kognitiivinen käyttäytymisterapia</strong>",
      "ocdContent2": "Altistukseen perustuvaa kognitiivista käyttäytymisterapiaa tarjoavat yleensä kognitiiviset psykoterapeutit, kognitiivisen käyttäytymisterapian psykoterapeutit ja aiheeseen täydennyskoulutuksen saaneet muut psykoterapeutit."
    }
  }
}
