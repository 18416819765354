import React from 'react';
import { Stack, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GetStartMenuQuery } from '~/src/api';
import Loading from '~/src/components/Loading';
import Card, { CardContentStack } from '~/src/components/Card';
import { flowInit } from '~/src/configurations';
import { richTextParser } from '~/src/utils';
import '~/src/theme/stylesheets/flowinitStyles.scss';
import Intro from '~/src/components/Intro';
import { StartMenuButtons } from './StartMenuButtons';

const StartMenu: React.FC<{
  startMenuData?: GetStartMenuQuery;
  startMenuLoading: boolean;
  introTextTranslation?: string;
  titleComponentTitleTranslation?: string;
}> = ({
  startMenuData,
  startMenuLoading,
  introTextTranslation,
  titleComponentTitleTranslation,
}) => {
  const { t } = useTranslation();

  const flowIntro: [string] | string = t(
    introTextTranslation ?? 'flowInit.paragraph',
    {
      returnObjects: true,
    },
  );

  return (
    <Card shadow={flowInit.cardShadow}>
      {flowInit.titleInsideCard && (
        <Intro titleTranslation={titleComponentTitleTranslation} />
      )}

      <CardContentStack mt={0} pt={flowInit.cardStackPadding}>
        {t('flowInit.title') && (
          <Heading as="h2" textAlign="center">
            {t('flowInit.title')}
          </Heading>
        )}

        {!Array.isArray(flowIntro)
          ? flowIntro
          : flowIntro.map((intro, idx) => (
              <Text py={2} key={'info' + idx} width="100%" as="div">
                {richTextParser(intro)}
              </Text>
            ))}

        {startMenuLoading ? (
          <Loading />
        ) : (
          <Stack spacing="2" pt={6}>
            <StartMenuButtons startMenu={startMenuData?.startMenu} />
          </Stack>
        )}
      </CardContentStack>
    </Card>
  );
};

export { StartMenu };
