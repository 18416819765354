import React, { useEffect, useMemo, useState } from 'react';
import {
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ButtonGroup,
  Button,
  VStack,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import defaultImage from '~/src/images/image-bank/symptom-inquiry.svg';
import { CardContentStack } from '~/src/components/Card';
import {
  AnswerInput,
  FlowResponse,
  Options,
  QuestionType,
  YedQuestion,
} from '~/src/api';
import { surveyFlowRoot } from '~/src/constants';
import { generateKey } from '~/src/utils';
import SelectedToggleIndicator from '~/src/components/SelectedToggleIndicator';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemTitle } from './components/FlowItemTitle';
import {
  FlowItemMultiFields,
  isAllQuestionAnswered,
} from './components/FlowItemMultiFields';
import { useValidateAllYedQuestionAnswers } from '~src/hooks/useYedQuestionValidation';

const BasicInfo: React.FC<FlowResponse> = React.memo(({ id, flowItem }) => {
  const { t } = useTranslation();
  const [ageValue, setAgeValue] = useState<number | string | undefined>('');
  const [genderValue, setGenderValue] = useState<string | undefined>();
  const [multiFieldValues, setMultiFieldValues] = useState<AnswerInput[]>([]);
  const {
    title,
    answer: answers,
    question,
    imgUrl,
    description,
  } = flowItem || {};
  const { buttons, inputs } = question as YedQuestion;

  const { addAnswer, loading } = useAddAnswer(surveyFlowRoot);
  const { validateAllAnswers } = useValidateAllYedQuestionAnswers(inputs);

  const maxAge = 120;
  const minAge = 0;

  const isValidAge = ageValue && ageValue >= minAge && ageValue <= maxAge;

  const genderOptions: [Options] = t(
    'healthIssueFlow.basicInfo.genderSelect.options',
    {
      returnObjects: true,
    },
  );

  const variables = useMemo(
    () => ({
      id: id ?? '',
      answer: [
        ...multiFieldValues,
        {
          name: 'age',
          type: QuestionType.Age,
          value: ageValue?.toString(),
        },
        {
          name: 'gender',
          type: QuestionType.Gender,
          value: genderValue ?? '',
        },
        {
          name: 'yed-button-option',
          type: QuestionType.ButtonOptions,
          value: buttons[0]?.value.toString(), // button value represents the "edgeNumber"
        },
      ],
    }),
    [multiFieldValues, ageValue, buttons, genderValue, id],
  );
  useEffect(() => {
    const oldAgeValue = answers?.find(
      (answer) => answer?.type === QuestionType.Age,
    )?.value;

    oldAgeValue && setAgeValue(parseInt(oldAgeValue));
  }, [answers]);

  useEffect(() => {
    if (!genderValue) return;
    addAnswer({ variables });
  }, [addAnswer, variables, genderValue]);

  const genderCount: number = genderOptions.length;
  const isTwoOptions = genderCount === 2;

  if (!id) return null;

  const imageToShow = imgUrl || defaultImage;

  const allQuestionsAnswered = isAllQuestionAnswered(
    ['age', 'yed-button-option', ...(inputs || [])],
    variables.answer,
  );
  const isAnswersValid = validateAllAnswers(variables.answer);

  return (
    <CardContentStack>
      <FlowItemTitle title={title || t('healthIssueFlow.basicInfo.title')} />
      <FlowItemImage imgUrl={imageToShow} />
      <FlowItemDescription description={description} />
      <Box minWidth={[250, 350, 450]}>
        <FlowItemMultiFields
          multiFieldValues={multiFieldValues}
          setMultiFieldValues={setMultiFieldValues}
          inputs={inputs}
        />
      </Box>
      <FormControl
        id="ageInput"
        isInvalid={!!ageValue && !isValidAge}
        isRequired
      >
        <FormLabel textAlign="center" mx="0">
          {t('healthIssueFlow.basicInfo.ageInput.label')}
        </FormLabel>

        <NumberInput
          aria-label={t('healthIssueFlow.basicInfo.ageInput.label') || ''}
          value={ageValue}
          onChange={(value) => {
            const numValue = parseInt(value);
            const currentValue = isNaN(numValue) ? undefined : numValue;

            return setAgeValue(currentValue);
          }}
          min={minAge}
          max={maxAge}
          width="15rem"
          margin="0 auto"
          clampValueOnBlur={false}
        >
          <NumberInputField textAlign="center" />

          <FormErrorMessage justifyContent="center">
            {t('healthIssueFlow.basicInfo.ageInput.errorMessage')}
          </FormErrorMessage>
        </NumberInput>
      </FormControl>

      <VStack spacing={0}>
        <FormLabel textAlign="center" mx="0">
          {t('healthIssueFlow.basicInfo.genderSelect.label')}
        </FormLabel>

        <ButtonGroup
          spacing={isTwoOptions ? '3px' : undefined}
          alignItems="center"
        >
          {genderOptions.map(({ value, label }, i) => {
            const borderRadius = isTwoOptions
              ? i === 0
                ? '9999px 0 0 9999px'
                : '0 9999px 9999px 0'
              : undefined;

            return (
              <Button
                key={generateKey(`gender_${value}`)}
                onClick={() => setGenderValue(value)}
                isDisabled={
                  !isValidAge || !allQuestionsAnswered || !isAnswersValid
                }
                isLoading={loading && genderValue === value}
                backgroundColor={
                  genderValue && genderValue !== value ? 'gray.500' : undefined
                }
                borderRadius={borderRadius}
                width="7.5rem"
                minWidth="unset"
              >
                {label}
              </Button>
            );
          })}

          {genderValue && isTwoOptions && (
            <SelectedToggleIndicator
              selected={
                genderValue === genderOptions[0].value ? 'left' : 'right'
              }
            />
          )}
        </ButtonGroup>
      </VStack>
    </CardContentStack>
  );
});

export { BasicInfo };
