import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Text,
  Divider,
  Box,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

import { ReportAnswer, Report } from '~/src/api';
import { envs, richTextParser } from '~/src/utils';
import {
  TestScores,
  TestId,
  getTestId,
  convertAudit,
} from '~/src/utils/questionnaires';
import { TFunction } from 'i18next';
import { useCreateQuestionnaireResult } from '~/src/hooks/useCreateQuestionnaireResult';
import { rangeToStr } from '~/src/utils/misc';
import { H1 } from '~src/components/Headings/Typography';
import { Title } from '../Report/components/Title';
import { TOC } from '../Report/components/TOC';

export const FullReport: React.FC<{ report: Report }> = ({ report }) => {
  const { t } = useTranslation();
  const { createQuestionnaireResult } = useCreateQuestionnaireResult();

  const tocTitles: Array<Title> = [];
  // Add test title when test question first occurs
  const testTitlesAdded: Set<TestId> = new Set();
  let prevTitle: null | undefined | string = null;

  const answerElements = report.answers.reduce(
    (acc: ReactNode[], d, idx, reports) => {
      const [testId] = getTestId(d?.questionId);

      // Check for title first from translation file, then from yED (Terapia
      // only) and last from test titles
      let title = t(`terapia.reportFull.subtitles.${d?.questionId}`, {
        defaultValue: null,
      }) as string | null;

      // For Terapia use titles from yED file, i.e. add a title when it changes
      if (
        envs.CONFIGURATION === 'Terapianavigaattori' &&
        d.title !== prevTitle
      ) {
        if (title === null) title = d.title ?? null;
        prevTitle = d.title;

        // Audit is special case since it may be short or full length
        // Test if audit is full length and if so use full length title
        if (testId === 'audit') {
          const fullAuditAns = reports.find((r) => r.questionId === 'audit-10');
          if (fullAuditAns) title = fullAuditAns.title ?? null;
        }
      }

      // If no title from translation file check if first test question
      if (testId != null && !testTitlesAdded.has(testId)) {
        testTitlesAdded.add(testId);
        if (title === null)
          title = combineTestNames(
            t(`terapia.tests.${testId}.longName`),
            t(`terapia.tests.${testId}.name`),
          );
      }

      // No duplicate titles
      if (tocTitles.some((t) => t.text === title)) title = null;

      // Store titles for TOC
      if (title && d?.questionId)
        tocTitles.push({ text: title, id: d?.questionId });

      acc.push(
        <Box
          className="noPageBreakOnPrint"
          key={'question' + idx}
          sx={{
            small: { color: '#757575' },
            '.center-disclaimer': { display: 'inline-block' },
          }}
        >
          {title && (
            <H1 id={d?.questionId ?? undefined} mt={8}>
              {title}
            </H1>
          )}
          <Text as="div" whiteSpace="pre-wrap">
            {richTextParser(d?.questionText, {
              removeTags: ['b', 'strong'],
              excludeNoReportAttr: true,
              removeExtraBrTags: true,
            })}
          </Text>
          <Text
            mt={2}
            whiteSpace="pre-wrap"
            backgroundColor="rgba(0,0,0,0.09)"
            padding="0.5rem 1rem"
            borderRadius="6px"
          >
            {richTextParser(d?.answerText ?? d?.answerButtonText, {
              removeTags: ['b', 'strong'],
              excludeNoReportAttr: true,
            })}
          </Text>
        </Box>,
      );

      // Add scoretable if last questionnaire question
      const scoretableKey = `scoretable-${idx}`;
      const scoretable = injectScoretable(
        d,
        reports[idx + 1],
        report,
        scoretableKey,
        t,
        createQuestionnaireResult,
      );
      if (scoretable) acc.push(scoretable);

      return acc;
    },
    [],
  );

  return (
    <Box textAlign="left">
      <Heading as="h2" size="lg" py={8}>
        {t('terapia.reportFull.title')}
      </Heading>
      <TOC titles={tocTitles} />
      <VStack spacing={8} align="left" mt={8}>
        {answerElements}
      </VStack>
    </Box>
  );
};

function injectScoretable(
  answer: ReportAnswer,
  nextAnswer: ReportAnswer | undefined,
  report: Report,
  scoretableKey: string,
  t: TFunction,
  createQuestionnaireResult: (
    data: Report | undefined,
    testId: TestId,
    testName?: string,
  ) => React.ReactNode,
): ReactNode {
  // Check if test question
  let [testId] = getTestId(answer.questionId);
  if (testId == null) return null;

  testId = convertAudit(report.answers, testId);

  // Check if last test question, i.e. nextAnswer has different testId
  if (nextAnswer !== undefined) {
    let [nextTestId] = getTestId(nextAnswer?.questionId);
    if (nextTestId != null) {
      nextTestId = convertAudit(report.answers, nextTestId);
      if (testId == nextTestId) return null;
    }
  }

  const scoreLabel =
    combineTestNames(
      t(`terapia.tests.${testId}.longName`),
      t(`terapia.tests.${testId}.name`),
    ) +
    ' ' +
    t('terapia.reportFull.scores');
  return (
    <Box key={scoretableKey} className="noPageBreakOnPrint">
      <Divider mb={8} />
      {createQuestionnaireResult(report, testId, scoreLabel)}
      {generateTestScoretable(testId, t)}
    </Box>
  );
}

function combineTestNames(longName?: string | null, name?: string | null) {
  if (!longName || !name) return null;
  return `${longName} (${name})`;
}
function generateTestScoretable(testId: TestId, t: TFunction): ReactNode {
  return (
    <Box
      className="noPageBreakOnPrint"
      borderWidth="1px"
      borderRadius={8}
      px={4}
      my={8}
    >
      <Table variant="simple" sx={{ 'th, td': { px: 1 } }}>
        <TableCaption placement="top">
          {combineTestNames(
            t(`terapia.tests.${testId}.longName`),
            t(`terapia.tests.${testId}.name`),
          ) ??
            t(`terapia.tests.${testId}.name`) ??
            testId.toUpperCase()}{' '}
          {t('terapia.reportFull.scoreLimits')}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>{t('terapia.reportFull.scores')}</Th>
            <Th>{t('terapia.reportFull.result')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {TestScores[testId].limits.map((test, idx) => (
            <Tr
              key={`testscore-${idx}`}
              sx={{ '&:last-child td': { borderBottom: 0 } }}
            >
              <Td>{rangeToStr(test.min, test.max)}</Td>
              <Td sx={{ overflowWrap: 'anywhere' }}>
                {richTextParser(t(`terapia.tests.${testId}.limits.${idx}`), {
                  removeTags: ['b', 'strong'],
                })}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
