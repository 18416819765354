import React from 'react';
import { useTranslation } from 'react-i18next';
import { TerapiaPrivacyFI } from './TerapiaPrivacyFI';
import { TerapiaPrivacySV } from './TerapiaPrivacySV';
import { TerapiaPrivacyEN } from './TerapiaPrivacyEN';

export const TerapiaPrivacy: React.FC = () => {
  const { i18n } = useTranslation();

  if (i18n.language === 'sv') return <TerapiaPrivacySV />;
  if (i18n.language === 'en') return <TerapiaPrivacyEN />;
  return <TerapiaPrivacyFI />;
};
