export default {
  brand: {
    50: '#ffe2fb',
    100: '#ffb1e8',
    200: '#ff7fd5',
    300: '#ff4dc4',
    400: '#fe1eb3',
    500: '#e5079a',
    600: '#B6007A', // <-- #b30078 replaced with brand color
    700: '#810055',
    800: '#4f0034',
    900: '#1f0014',
  },
};
// Brand color: #B6007A
