import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

interface ModalProps {
  modalIsOpen: boolean;
  onCloseModal?: () => void;
  title?: string | null;
  children?: ReactNode;
  size?: string;
  footerProps?: ModalFooterProps;
}

const Modal: React.FC<ModalProps> = ({
  title,
  onCloseModal,
  modalIsOpen,
  children,
  footerProps,
  size = '3xl',
}) => {
  const { isOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const closeModal = onCloseModal || onClose;

  const closeButtonStyles = {
    fontSize: 20,
    height: 6,
    minWidth: 'unset',
    position: 'absolute',
    right: 4,
    top: 4,
    width: 6,
  };

  return (
    <ChakraModal
      isOpen={modalIsOpen ?? isOpen}
      onClose={closeModal}
      size={size}
    >
      <ModalOverlay />
      <ModalContent mx={[4, 0]}>
        {title && <ModalHeader mt={4}>{title}</ModalHeader>}
        <IconButton
          aria-label="Close"
          icon={<MdClose />}
          onClick={closeModal}
          sx={closeButtonStyles}
          size="md"
          isRound
        />
        <ModalBody>{children}</ModalBody>

        <ModalFooter {...footerProps}>
          <Button onClick={closeModal} size="md">
            {t('common.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
