import React, { useState } from 'react';
import { Box, Flex, ListItem, OrderedList, Stack } from '@chakra-ui/react';

import { GetStartMenuQuery, MenuItemAction } from '~/src/api';
import { flowInit, styles } from '~/src/configurations';
import Container from '~/src/components/Container';
import Intro from '~/src/components/Intro';
import Card from '~/src/components/Card';
import { StartMenuButtons } from '~src/components/StartMenu/StartMenuButtons';
import { AcceptPrivacyCheckbox } from './AcceptPrivacyCheckbox';
import { H2, P, H3 } from '~/src/components/Headings/Typography';
import { envs } from '~src/utils';

const data: GetStartMenuQuery = {
  startMenu: [
    {
      translationKey: 'terapia.startButtons.start',
      action: MenuItemAction.Question,
      actionParameter: 'hus-terapia-nuori#privacy-ok',
    },
    {
      translationKey: 'terapia.startButtons.openResults',
      action: MenuItemAction.Url,
      actionParameter: '/report?reportType=youth',
    },
    {
      translationKey: 'terapia.startButtons.startTest',
      action: MenuItemAction.Question,
      actionParameter: 'hus-terapia-nuori#privacy-ok-test',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.viewExample',
      action: MenuItemAction.Url,
      actionParameter:
        '/report/summary?reportType=youth&reportId=esimerkki-nuori',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.accessibilityStatement',
      action: MenuItemAction.Url,
      actionParameter: '/accessibility',
      variant: 'link',
    },
  ],
};

export const HomeYouth: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isMaintenanceComing = !!envs.UPCOMING_MAINTENANCE_COMPLETED_AT;
  const isMenuDisabled = isMaintenanceComing
    ? [true, false, true, false]
    : [!termsAccepted, false, !termsAccepted, false];

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Card
        shadow={flowInit.cardShadow}
        px={[4, 6, 8]}
        pb={12}
        alignItems="flex-start"
      >
        <Intro titleTranslation="terapia.youthHomeTitle" />
        <H2>Tervetuloa käyttämään Interventio­navigaattoria!</H2>
        <P fontWeight="bold">
          Tässä kyselyssä kartoitetaan sinun elämäntilannettasi sekä ongelmia
          tai vaikeuksia, joita olet saattanut kokea. Näitä tietoja kootaan,
          jotta sinut voidaan ohjata sopivimman avun/tuen pariin.
        </P>
        <H3 mb={2}>Huomioi seuraavat asiat:</H3>
        <OrderedList>
          <ListItem>
            <strong>Interventionavigaattori täytetään anonyymisti.</strong> Älä
            kirjoita vastauksiin esimerkiksi nimeä, henkilötunnusta tai mitään
            yhteystietojasi.
          </ListItem>
          <ListItem>
            <strong>Varaa aikaa täyttämiseen 10–20 minuuttia.</strong>{' '}
            Vastauksia ei voi tallentaa kesken täyttämisen.
          </ListItem>
          <ListItem>
            <strong>Ota talteen henkilökohtainen koodi</strong>, jonka saat
            täytettyäsi Interventio­navigaattorin loppuun. VINKKI! Ota koodista
            kuvakaappaus tai kuva puhelimellasi.
          </ListItem>
          <ListItem>
            <strong>Toimita koodi terveydenhuollon ammattilaiselle</strong>,
            joka tarkastelee Interventionavigaattorin tuloksia kanssasi. Koodi
            toimii yhden kuukauden ajan.
          </ListItem>
        </OrderedList>
        <Box
          position="relative"
          bg={styles.pageBgColor}
          py={4}
          pl={16}
          pr={4}
          borderRadius={20}
          my={4}
          color="brand.600"
          fontWeight="bold"
        >
          <Box
            position="absolute"
            fontSize="64px"
            color="red"
            left="28px"
            top="-5px"
          >
            !
          </Box>
          <P>
            Jos sinulla on itsetuhoisia ajatuksia tai olet epävarma
            turvallisuudestasi, kerro asiasta suoraan läheiselle ja
            ammattilaiselle. Hakeudu esimerkiksi terveydenhoitajalle,
            kuraattorille, psykologille tai terveysasemalle.
          </P>
          <P>
            Jos olet tilanteessa, jossa tarvitset välitöntä apua soita tai pyydä
            läheistäsi soittamaan numeroon 112.
          </P>
        </Box>
        <Flex width="100%" direction="column" alignItems="center">
          <Box maxW="21rem">
            <AcceptPrivacyCheckbox
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
            />
          </Box>

          <Stack spacing="2" pt={6} alignItems="center">
            <StartMenuButtons
              startMenu={data.startMenu}
              isMenuDisabled={isMenuDisabled}
              isMaintenanceComing={isMaintenanceComing}
            />
          </Stack>
        </Flex>
      </Card>
    </Container>
  );
};
