import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import { Provider } from '~src/api';

export type PaymentMethodProps = Provider & { stamp: string };

type PaymentProviderButtonProps = {
  name: Provider['name'];
  svg: Provider['svg'];
  createPayment: () => void;
};

const PaymentProviderButton: React.FC<PaymentProviderButtonProps> = (props) => {
  const { createPayment, name, svg } = props;
  return (
    <Button
      type="submit"
      onClick={() => createPayment()}
      variant="unstyled"
      display="flex"
      height="auto"
      border="2px solid"
      borderRadius={0}
      borderColor="gray.100"
      _hover={{ bg: 'gray.100' }}
      flexBasis={'154px'}
      minWidth={['132px', '154px', '154px']}
      minHeight="79px"
    >
      <Image
        src={svg || ''}
        p="4px"
        margin="8px"
        alt={name || ''}
        maxWidth={['109px', '125px', '125px']}
        height="55px"
      />
    </Button>
  );
};

export { PaymentProviderButton };
