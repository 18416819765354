import type { IConfigurationPartial } from '~/src/types';
import { Locale, Configuration } from '~/src/types';
import { localizations } from '~/src/localizations';
import colors from '~/src/theme/foundations/colorsTerapianavigaattori';
import appLogoFI from '~/src/images/logo-terapianavigaattori_FI.png';
import appLogoSV from '~/src/images/logo-terapianavigaattori_SV.png';
import appLogoEN from '~/src/images/logo-terapianavigaattori_EN.png';
import { HomeTerapia } from '~src/screens/Home/HomeTerapia';

const configuration: IConfigurationPartial = {
  searchEnabled: false,
  colors,
  header: {
    appLogo: appLogoFI,
    appLogoTranslated: { fi: appLogoFI, sv: appLogoSV, en: appLogoEN },
    appLogoPaddingTop: '2em',
    appLogoWidth: '190px',
  },
  flowInit: {
    cardStackPadding: [1, 2, 3],
    maxWidth: 'container.md',
    titleInsideCard: true,
  },
  translations: {
    supportedLanguages: [Locale.FI, Locale.SV, Locale.EN],
    resources: localizations[Configuration.Terapianavigaattori] ?? {},
  },
  styles: {
    pageBgColor: '#EDEDE8',
    cardBgColor: 'white',
    progressBarBgColor: 'primary.50',
  },
  fonts: {
    heading: 'Georgia, serif',
  },
  homeScreen: HomeTerapia,
};

export default configuration;
