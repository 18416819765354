const _temp0 = require("./DefaultEN.json");
const _temp1 = require("./DefaultFI.json");
const _temp2 = require("./DlaakaritFI.json");
const _temp3 = require("./DokportFI.json");
const _temp4 = require("./EspooEN.json");
const _temp5 = require("./EspooFI.json");
const _temp6 = require("./HelsinkiEN.json");
const _temp7 = require("./HelsinkiFI.json");
const _temp8 = require("./InterventionavigaattoriFI.json");
const _temp9 = require("./NeuvolaFI.json");
const _temp10 = require("./TerapianavigaattoriEN.json");
const _temp11 = require("./TerapianavigaattoriFI.json");
const _temp12 = require("./TerapianavigaattoriSV.json");
const _temp13 = require("./TerveysporttiFI.json");
const _temp14 = require("./TupakkalakkoFI.json");
module.exports = {
  "DefaultEN": _temp0,
  "DefaultFI": _temp1,
  "DlaakaritFI": _temp2,
  "DokportFI": _temp3,
  "EspooEN": _temp4,
  "EspooFI": _temp5,
  "HelsinkiEN": _temp6,
  "HelsinkiFI": _temp7,
  "InterventionavigaattoriFI": _temp8,
  "NeuvolaFI": _temp9,
  "TerapianavigaattoriEN": _temp10,
  "TerapianavigaattoriFI": _temp11,
  "TerapianavigaattoriSV": _temp12,
  "TerveysporttiFI": _temp13,
  "TupakkalakkoFI": _temp14
}