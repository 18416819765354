import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Divider,
  Flex,
  Text,
  Heading,
  VisuallyHidden,
} from '@chakra-ui/react';

import { generateKey } from '~/src/utils';
import { InputMaybe } from '~src/api';

export interface SliderOption {
  value: string;
  label: string;
}
interface FlowItemSliderProps {
  currentValue: InputMaybe<string> | undefined;
  options: SliderOption[];
  onChange: (option: SliderOption) => void;
  label: string;
  hideLabel?: boolean;
}

const FlowItemSlider: React.FC<FlowItemSliderProps> = ({
  options,
  label,
  onChange,
  hideLabel,
  currentValue,
}) => {
  let sliderValue = options.findIndex(
    (option) => option.value === currentValue,
  );
  if (sliderValue !== -1) sliderValue = options.length - sliderValue;

  const sliderSteps = options.length;
  const sliderHeading = (
    <Heading as="h3" size="sm" textAlign="center" mb={4} id="rangeSliderLabel">
      {label}
    </Heading>
  );

  return (
    <>
      {hideLabel ? (
        <VisuallyHidden>{sliderHeading}</VisuallyHidden>
      ) : (
        sliderHeading
      )}

      <Flex mb={12} p="4 0" justifyContent="center">
        <Slider
          aria-labelledby="rangeSliderLabel"
          orientation="vertical"
          minH={sliderSteps * 8}
          min={1}
          max={sliderSteps}
          value={sliderValue}
          onClick={() => {
            /*
            Slider onChange is not triggered when sliderValue is -1 (initial
            value when SliderThumb is not visible) AND clicking on first slider
            item (probably a Chakra bug) so we have use onClick to trigger
            parent onChange.

            When clicking other than the first slider item onChange is triggered
            first so sliderValue is not -1 here anymore.
            */
            if (sliderValue !== -1) return;
            onChange(options[sliderSteps - 1]);
          }}
          onChange={(value) => {
            onChange(options[sliderSteps - value]);
          }}
        >
          <SliderTrack
            width={6}
            borderRadius={25}
            bg="white"
            border="1px solid"
            borderColor="gray.300"
          >
            <SliderFilledTrack bg="secondary.500" />
            <Flex
              aria-hidden
              height="100%"
              justify="space-between"
              direction="column"
            >
              {options.map((_, i) => (
                <Divider key={generateKey('sliderDivider', i)} />
              ))}
            </Flex>
          </SliderTrack>
          <SliderThumb
            boxSize={7}
            bg="primary.500"
            visibility={sliderValue > -1 ? 'visible' : 'hidden'}
          />
        </Slider>

        <Flex
          direction="column"
          justify="space-between"
          margin="-.65rem 0 -.65rem"
          paddingLeft="1rem"
          aria-hidden
        >
          {options.map((option, i) => (
            <Text
              key={generateKey(option.value.toString(), i)}
              as="button"
              textAlign="left"
              onClick={(e) => {
                e.preventDefault();
                onChange(options[i]);
              }}
            >
              {option.label}
            </Text>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default FlowItemSlider;
