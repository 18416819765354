import React, { useEffect, useState } from 'react';
import { Center, Container, Heading, Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { generateKey } from '~/src/utils';
import {
  IMessage,
  ChatUsertype,
  IChatUser,
  IChatStatusMessage,
  ChatSessionEndedReason,
  IChatConnection,
} from '~/src/types';
import MessageThread from '~/src/components/Chat/MessageThread';
import ProfessionalAtService from '~/src/components/Chat/ProfessionalAtService';
import MessageInput from '~/src/components/Chat/MessageInput';
import Loading from '~/src/components/Loading';
import WaitingForService from '~/src/components/Chat/WaitingForService';
import TypingIndicator from '~/src/components/Chat/TypingIndicator';
import ChatStatusNotification from '~/src/components/Chat/ChatStatusNotification';
import { chat } from '~/src/configurations';

const Discussion: React.FC<IChatConnection> = ({
  socket,
  connectedUsertype,
  chatId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [chatMessages, setChatMessages] = useState<IMessage[] | []>([]);
  const [onlineUser, setOnlineUser] = useState<IChatUser | undefined>();
  const [chatConnected, setChatConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otherUserTyping, setOtherUserTyping] = useState<
    { username: string; usertype: string; isTyping: boolean } | undefined
  >();
  const [statusMessage, setStatusMessage] = useState<
    IChatStatusMessage | undefined
  >();

  // const messageSeen = chatMessages?.some((message) => message.seen);
  const messagesSent = chatMessages && chatMessages.length > 0;
  let timeout: NodeJS.Timeout | undefined = undefined;
  const isProUser = connectedUsertype === ChatUsertype.Professional;

  useEffect(() => {
    setIsLoading(true);

    if (!socket) {
      setIsLoading(false);
      return;
    }

    socket.on('connect', () => {
      setChatConnected(true);

      socket.emit('getHistory', chatId, (messages: IMessage[]) => {
        setIsLoading(false);
        setChatMessages(messages);
      });
    });

    socket.on('userTyping', (data: Record<string, unknown>) => {
      const { username, usertype, isTyping } = data as any;

      otherUserTyping?.isTyping !== isTyping &&
        setOtherUserTyping({
          username: username,
          usertype: usertype,
          isTyping: isTyping,
        });
    });

    socket.on('newMessage', (newMessage: IMessage) => {
      setChatMessages((chatMessages) => [...chatMessages, newMessage]);
    });

    socket.on('updatedMessage', () => {
      socket.emit('getHistory', chatId, (messages: IMessage[]) => {
        setChatMessages(messages);
      });
    });

    socket.on('connectedUsers', (data: { users: IChatUser[] }) => {
      const otherUser = data.users?.find(
        ({ usertype }) => usertype !== connectedUsertype,
      );

      setOnlineUser(otherUser ?? undefined);
    });

    socket.on('userConnected', (data: IChatUser) => {
      if (data.usertype === connectedUsertype) return;

      setStatusMessage(undefined);
      setOnlineUser(data);
    });

    socket.on('userDisconnected', (data: IChatUser) => {
      if (data.usertype === connectedUsertype) return;

      setOnlineUser(undefined);
    });

    socket.on('sessionEnded', (data: Record<string, unknown>) => {
      const chatUser =
        connectedUsertype === ChatUsertype.Professional &&
        data.reason === ChatSessionEndedReason.LeaveRequest
          ? t('chat.user.client')
          : t('chat.user.generic');

      setStatusMessage({
        message: t('chat.discussion.sessionEnded.message', {
          user: chatUser,
        }),
      });

      if (data.usertype === connectedUsertype) return;

      setOnlineUser(undefined);
    });

    socket.on('serverError', () => {
      setStatusMessage({
        title: t('chat.errors.generic.title'),
        message: t('chat.errors.generic.message'),
        isError: true,
      });
    });

    return () => {
      setChatConnected(false);
    };
    // TODO: check if adding dependencies would break the funcitonality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    // if (
    //   chatMessages &&
    //   chatMessages[chatMessages?.length - 1].usertype !== connectedUsertype
    // ) {
    //   socket.emit('messagesSeen', { chatId });
    // }

    if (!window) return;

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }, [chatMessages]);

  const onMessageSubmit = (messageText: string) => {
    if (messageText.length === 0) return null;

    socket?.emit('sendTyping', {
      chatId: chatId,
      isTyping: false,
    });

    return socket?.emit('sendMessage', {
      id: generateKey(`${chatId}-MESSAGE`),
      chatId: chatId,
      text: messageText,
    });
  };

  const typingTimeout = () => {
    socket?.emit('sendTyping', {
      chatId: chatId,
      isTyping: false,
    });
  };

  const onTyping = () => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(typingTimeout, 5000);

    socket?.emit('sendTyping', {
      chatId: chatId,
      isTyping: true,
    });
  };

  return (
    <Box position="relative">
      <Container key={connectedUsertype} paddingTop={20}>
        {chat.enableProChatView && (
          <Button
            onClick={() => {
              const target = isProUser
                ? `/chat/${chatId}`
                : `/chat/professional/${chatId}`;

              socket?.close();

              return history.push(target);
            }}
            variant="link"
            position="absolute"
            right={4}
            top={4}
            zIndex={9}
          >
            {isProUser ? 'switch to client' : 'switch to pro'}
          </Button>
        )}

        {!isProUser && (
          <Heading
            as="h1"
            color="primary.500"
            marginBottom={12}
            textAlign="center"
          >
            {t('chat.discussion.title')}
          </Heading>
        )}

        {chatConnected && (
          <>
            {isProUser ? null : onlineUser ? (
              <ProfessionalAtService {...onlineUser} />
            ) : (
              <WaitingForService messagesSent={messagesSent} />
            )}

            {messagesSent && (
              <MessageThread
                messages={chatMessages}
                currentUsertype={connectedUsertype}
                otherUserIsOnline={!!onlineUser}
              />
            )}

            {statusMessage && !isLoading && (
              <ChatStatusNotification {...statusMessage} />
            )}

            {otherUserTyping?.isTyping &&
              otherUserTyping.usertype !== connectedUsertype && (
                <TypingIndicator username={otherUserTyping.username} />
              )}
          </>
        )}

        {(!chatConnected || isLoading) && (
          <Center>
            <Loading size={!chatConnected ? 'lg' : 'md'} />
          </Center>
        )}

        {chatConnected && (
          <MessageInput
            handleMessageSubmit={onMessageSubmit}
            handleTyping={onTyping}
          />
        )}
      </Container>
    </Box>
  );
};

export default Discussion;
