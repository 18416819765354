import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { AddAnswerMutation, useAddAnswerMutation } from '~src/api';
import { AppContext } from '~src/state';

export function useSetLogo() {
  const { state, dispatch } = useContext(AppContext);

  /**
   * Set logo to state if it's different from the current logo.
   * @param {string | null | undefined} logo
   * @returns
   * */
  const setLogo = (logo: string | null | undefined) =>
    (logo ?? null) !== state.logo &&
    dispatch({
      type: 'SET_LOGO',
      payload: logo ?? null, // null indicates no custom logo
    });
  return { setLogo };
}

/**
 * useAddAnswer - hook
 * - handle addAnswer - mutation to backend and redirect to next answer page.
 *
 * @param {string} nextPageRoot
 * @param {function} customOnCompleted - A custom handler executed upon mutation completion.
 *   - Return `true` from this function if a redirect has already been performed within the function,
 *     to prevent the default redirect behavior of this hook.
 *   - If no redirect is performed within this function, return `void` or nothing,
 *     and the hook will proceed with its default redirect behavior.
 * @returns
 */
export function useAddAnswer(
  nextPageRoot?: string,
  customOnCompleted: (data: AddAnswerMutation) => void | true = () => {},
) {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const { setLogo } = useSetLogo();

  const [addAnswer, mutationProps] = useAddAnswerMutation({
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      setLogo(data.addAnswer.flowItem?.logo);
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      const alreadyRedirected = customOnCompleted(data);

      if (!alreadyRedirected && nextPageRoot)
        history.push(
          `/${nextPageRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
        );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const addAnswerWithOnMutating = useCallback(
    (
      props: Parameters<typeof addAnswer>[0],
      setLoadingWhenStartingMutation = true,
    ): ReturnType<typeof addAnswer> => {
      // Set flow to loading state when starting mutation ~ onMutating
      if (setLoadingWhenStartingMutation)
        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: true,
        });

      return addAnswer(props);
    },
    [addAnswer, dispatch],
  );

  return { addAnswer: addAnswerWithOnMutating, ...mutationProps };
}
