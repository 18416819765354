import React, { FC, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ReportType } from '~/src/types';
import { Title, SummaryHeading } from '../components/Title';
import { ReportInfoLink } from '../components/ReportInfoLink';
import { CombinationData, CombinationIds } from './CombinationReport';
import { SummaryBody } from './components/SummaryBody';
import { SummaryBodyForCopyToClipboard } from './components/SummaryBodyForCopyToClipboard';
import { reportRoot } from '~/src/constants';
import { Link } from '~/src/components/Link';

type ReportSummaryCombinationProps = {
  ids: CombinationIds;
  data: CombinationData;
  isPrint: boolean;
};

export const ReportSummaryCombination: FC<ReportSummaryCombinationProps> = ({
  ids,
  data,
  isPrint,
}) => {
  useEffect(() => {
    if (isPrint) window.print();
  }, [isPrint]);

  return (
    <Box textAlign="left" width="100%">
      <Summary ids={ids} data={data} />
      {isPrint && <ReportInfoLink />}
    </Box>
  );
};

export const Summary: FC<{
  ids: CombinationIds;
  data: CombinationData;
  title?: Title;
}> = ({ ids, data, title }) => {
  return (
    <>
      <SummaryHeading
        title={title}
        summaryBody={<SummaryBodyForCopyToClipboard data={data} ids={ids} />}
        ids={ids}
        type={ReportType.Combination}
        hidePdfButton={true}
      />
      <FullReportLinks ids={ids} />
      <SummaryBody data={data} />
    </>
  );
};

const FullReportLinks: FC<{ ids: CombinationIds }> = ({ ids }) => {
  const youthParams = ids.youthId
    ? new URLSearchParams({
        reportId: ids.youthId,
        reportType: ReportType.Youth,
      }).toString()
    : null;
  const guardianParams1 = ids.guardianId1
    ? new URLSearchParams({
        reportId: ids.guardianId1,
        reportType: ReportType.Guardian,
      }).toString()
    : null;
  const guardianParams2 = ids.guardianId2
    ? new URLSearchParams({
        reportId: ids.guardianId2,
        reportType: ReportType.Guardian,
      }).toString()
    : null;

  return (
    <Flex
      mt={4}
      columnGap={8}
      rowGap={4}
      justifyContent="center"
      flexWrap="wrap"
    >
      {youthParams && (
        <Link
          href={`/${reportRoot}/full?${youthParams}`}
          isBold
          isExternal
          color="primary.600"
        >
          Nuoren laaja raportti
        </Link>
      )}
      {guardianParams1 && (
        <Link
          href={`/${reportRoot}/full?${guardianParams1}`}
          isBold
          isExternal
          color="primary.600"
        >
          Huoltajan 1 laaja raportti
        </Link>
      )}
      {guardianParams2 && (
        <Link
          href={`/${reportRoot}/full?${guardianParams2}`}
          isBold
          isExternal
          color="primary.600"
        >
          Huoltajan 2 laaja raportti
        </Link>
      )}
    </Flex>
  );
};
