import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { FlowItemType, useStartSurveyQuery } from '~/src/api';
import { surveyFlowRoot } from '~/src/constants';
import ErrorCard from '~/src/components/ErrorCard';
import Container from '~/src/components/Container';
import { upsertSessionStorage } from '~src/utils/storage';

const ShortQuestionRedirect: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data, loading, error } = useStartSurveyQuery({
    variables: { surveyName: id },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect_url');
    if (!redirectUrl || !id) return;
    queryParams.delete('redirect_url');
    history.replace({ search: queryParams.toString() });
    upsertSessionStorage('redirect_url', redirectUrl);
  }, [history, id]);

  if (loading) return null;

  return (
    <Container px={[4, 8, 16]} py={4}>
      {error ||
      data?.startSurvey.flowItem?.type === FlowItemType.EndOfSurvey ? (
        <ErrorCard />
      ) : (
        <Redirect
          to={`/${surveyFlowRoot}/${data?.startSurvey.flowItem?.name}/${data?.startSurvey.id}`}
        />
      )}
    </Container>
  );
};

export default ShortQuestionRedirect;
