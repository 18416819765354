import React, { useEffect } from 'react';
import { GetStartMenuQuery, useGetStartMenuQuery } from '~/src/api';
import Container from '~/src/components/Container';
import Intro from '~/src/components/Intro';
import { StartMenu } from '~src/components/StartMenu/StartMenu';
import { flowInit, startMenu } from '~/src/configurations';
import { useSetLogo } from '~src/hooks/useAddAnswer';

const Home = () => {
  const { setLogo } = useSetLogo();
  // Fetch start menu from the backend if startMenu is string, otherwise use
  // the provided options
  let startMenuType: string | undefined;
  let fixedData: GetStartMenuQuery | undefined;
  if (typeof startMenu === 'string') startMenuType = startMenu;
  else fixedData = startMenu;

  const { data, loading } = useGetStartMenuQuery({
    fetchPolicy: 'cache-first',
    variables: {
      type: startMenuType,
    },
    skip: !!fixedData,
  });

  useEffect(() => void setLogo(null), [setLogo]);

  return (
    <>
      {!flowInit.titleInsideCard && <Intro />}
      <Container maxWidth={flowInit.maxWidth} padding={[4, 8, 8]}>
        <StartMenu
          startMenuData={fixedData ?? data}
          startMenuLoading={loading}
        />
      </Container>
    </>
  );
};

export default Home;
