import React, { useState } from 'react';
import { Stack, Box, ListItem, OrderedList, Flex } from '@chakra-ui/react';

import { GetStartMenuQuery, MenuItemAction } from '~/src/api';
import { flowInit } from '~/src/configurations';
import Container from '~/src/components/Container';
import Card from '~/src/components/Card';
import Intro from '~/src/components/Intro';
import { StartMenuButtons } from '~src/components/StartMenu/StartMenuButtons';
import { AcceptPrivacyCheckbox } from './AcceptPrivacyCheckbox';
import { H2, P, H3 } from '~/src/components/Headings/Typography';
import { envs } from '~src/utils';

const data: GetStartMenuQuery = {
  startMenu: [
    {
      translationKey: 'terapia.startButtons.start',
      action: MenuItemAction.Question,
      actionParameter: 'hus-terapia-huoltaja#privacy-ok',
    },
    {
      translationKey: 'terapia.startButtons.openResults',
      action: MenuItemAction.Url,
      actionParameter: '/report?reportType=guardian',
    },
    {
      translationKey: 'terapia.startButtons.startTest',
      action: MenuItemAction.Question,
      actionParameter: 'hus-terapia-huoltaja#privacy-ok-test',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.viewExample',
      action: MenuItemAction.Url,
      actionParameter:
        '/report/summary?reportType=guardian&reportId=esimerkki-huoltaja',
      variant: 'link',
    },
    {
      translationKey: 'terapia.startButtons.accessibilityStatement',
      action: MenuItemAction.Url,
      actionParameter: '/accessibility',
      variant: 'link',
    },
  ],
};

export const HomeGuardian: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isMaintenanceComing = !!envs.UPCOMING_MAINTENANCE_COMPLETED_AT;
  const isMenuDisabled = isMaintenanceComing
    ? [true, false, true, false]
    : [!termsAccepted, false, !termsAccepted, false];

  return (
    <Container maxWidth={flowInit.maxWidth}>
      <Card
        shadow={flowInit.cardShadow}
        px={[4, 6, 8]}
        pb={12}
        alignItems="flex-start"
      >
        <Intro titleTranslation="terapia.guardianHomeTitle" />
        <H2>Tervetuloa käyttämään Interventio­navigaattoria!</H2>
        <P>
          Hyvä huoltaja, olet saanut pyynnön täyttää Interventionavigaattorin
          tai mahdollisesti itse huolestunut nuoresi voinnista.
          Interventionavigaattorin tarkoituksena on koota tietoa, jonka avulla
          arvioidaan nuoresi mielenterveyden tuen tarvetta. Vastauksesi ovat
          tärkeitä nuoresi tilanteen selvittämiseksi. Antamiasi tietoja voidaan
          tarvittaessa käsitellä ammattilaisen vastaanotolla yhdessä nuoren
          kanssa.
        </P>
        <P>
          Mielenterveyden haasteet ovat koululaisten ja nuorten aikuisten
          tavallisimpia terveysongelmia. Yleisimpiä mielenterveysongelmia
          nuorilla ovat mieliala-, ahdistuneisuus-, käytös- ja päihdeongelmat.
          Mitä varhaisemmassa vaiheessa nuori saa apua, sitä harvemmin oireilu
          tai ongelma pahenee ja toipuminen on nopeampaa.
        </P>
        <H3 mb={2}>Huomioi seuraavat asiat:</H3>
        <OrderedList mb={4}>
          <ListItem>
            <strong>Interventionavigaattori täytetään anonyymisti.</strong> Älä
            kirjoita vastauksiin esimerkiksi nimeä, henkilötunnusta tai mitään
            yhteystietojasi.
          </ListItem>
          <ListItem>
            <strong>Varaa aikaa täyttämiseen 10 minuuttia.</strong> Vastauksia
            ei voi tallentaa kesken täyttämisen.
          </ListItem>
          <ListItem>
            <strong>Ota talteen henkilökohtainen koodi</strong>, jonka saat
            täytettyäsi Interventio­navigaattorin loppuun. VINKKI! Ota koodista
            kuvakaappaus tai kuva puhelimellasi.
          </ListItem>
          <ListItem>
            <strong>Toimita koodi terveydenhuollon ammattilaiselle</strong>,
            joka arvioi nuoresi tuen tarvetta. Koodi toimii yhden kuukauden
            ajan.
          </ListItem>
        </OrderedList>
        <Flex width="100%" direction="column" alignItems="center">
          <Box maxW="21rem">
            <AcceptPrivacyCheckbox
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
            />
          </Box>

          <Stack spacing="2" pt={6} alignItems="center">
            <StartMenuButtons
              startMenu={data.startMenu}
              isMenuDisabled={isMenuDisabled}
              isMaintenanceComing={isMaintenanceComing}
            />
          </Stack>
        </Flex>
      </Card>
    </Container>
  );
};
