import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const AvatarPlaceholderIcon = (props: IconProps) => (
  <Icon viewBox="0 0 72 73" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M33.384 34.007a.75.75 0 00-.384.654v3.246a.75.75 0 001.008.705A5.763 5.763 0 0136 38.255h1.416a.75.75 0 00.519-1.29 22.431 22.431 0 00-3.792-2.943.756.756 0 00-.759-.015z" />
      <path d="M29.7 31.976a16.866 16.866 0 10-15.114-.063.75.75 0 01.414.669v8.45c.001.29.168.554.429.679a4.5 4.5 0 11-3.858 0 .756.756 0 00.429-.678v-6.375a.747.747 0 00-1.14-.64A22.5 22.5 0 000 53.256a1.5 1.5 0 001.5 1.5h27.75a.75.75 0 00.75-.75v-21.6a.438.438 0 00-.3-.43zm-7.5-4.221c-5.995-.012-10.85-4.871-10.857-10.866.006-1.41.295-2.803.849-4.098a.756.756 0 011.134-.3 22.863 22.863 0 0013.5 4.416 22.713 22.713 0 005.4-.666.669.669 0 01.582.129.675.675 0 01.261.534c-.015 5.993-4.875 10.846-10.869 10.85zM67.5 42.026h-5.25a1.5 1.5 0 01-1.5-1.5v-1.5a5.256 5.256 0 00-5.25-5.25h-6a5.256 5.256 0 00-5.25 5.25v1.5a1.5 1.5 0 01-1.5 1.5H37.5a4.5 4.5 0 00-4.5 4.5v21a4.5 4.5 0 004.5 4.5h30a4.5 4.5 0 004.5-4.5v-21a4.5 4.5 0 00-4.5-4.5zm-18.75-3a.75.75 0 01.75-.75h6a.75.75 0 01.75.75v1.5a1.5 1.5 0 01-1.5 1.5h-4.5a1.5 1.5 0 01-1.5-1.5v-1.5zM60 59.276a.75.75 0 01-.75.75H57a1.5 1.5 0 00-1.5 1.5v2.25a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75v-2.25a1.5 1.5 0 00-1.5-1.5h-2.25a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75H48a1.5 1.5 0 001.5-1.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h2.25a.75.75 0 01.75.75v4.5z" />
    </g>
  </Icon>
);

export default AvatarPlaceholderIcon;
