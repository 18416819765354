import { Button, VStack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  YedQuestion,
  FlowResponse,
  QuestionType,
  ButtonStyle,
  AnswerInput,
} from '~/src/api';
import { CardContentStack } from '~/src/components/Card';
import { richTextParser } from '~/src/utils';
import { surveyFlowRoot } from '~/src/constants';
import { buttonHeight } from '~/src/theme/constants';
import YedLinks from './YedQuestion/YedLinks';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemTitle } from './components/FlowItemTitle';
import {
  FlowItemMultiFields,
  isAllQuestionAnswered,
} from './components/FlowItemMultiFields';
import { useValidateAllYedQuestionAnswers } from '~src/hooks/useYedQuestionValidation';

export type InputTypes = string | string[] | undefined;

const YedQuestion: React.FC<FlowResponse> = React.memo(({ id, flowItem }) => {
  const { t, i18n } = useTranslation();
  const [multiFieldValues, setMultiFieldValues] = useState<AnswerInput[]>([]);
  const [buttonValue, setButtonValue] = useState<number | undefined>();

  const { addAnswer, loading } = useAddAnswer(surveyFlowRoot);

  const { inputs, buttons, externalLinks } = flowItem?.question as YedQuestion;
  const { validateAllAnswers } = useValidateAllYedQuestionAnswers(inputs);

  if (!id || !flowItem) return null;
  const { title, description, imgUrl } = flowItem;
  const handleButtonClick = (value: string | undefined) => {
    setButtonValue(value ? parseInt(value) : undefined);

    const answer = [
      ...multiFieldValues,
      {
        name: 'yed-button-option',
        type: QuestionType.ButtonOptions,
        value: value,
      },
    ];

    addAnswer({
      variables: {
        id: id,
        answer: answer,
      },
    });
  };

  const allQuestionsAnswered = isAllQuestionAnswered(
    inputs || [],
    multiFieldValues,
  );

  const isAnswersValid = validateAllAnswers(multiFieldValues);

  const buttonStack = buttons?.map((button, i) => (
    <Button
      key={`yed-button-${i}`}
      isLoading={loading && buttonValue === button?.value}
      onClick={() => handleButtonClick(button?.value.toString())}
      value={button?.value}
      isDisabled={!allQuestionsAnswered || !isAnswersValid}
      width="100%"
      variant={button?.style?.toLowerCase()}
      minHeight={button?.style === ButtonStyle.Link ? buttonHeight : undefined}
    >
      {button?.text && richTextParser([button?.text])}
    </Button>
  ));
  return (
    <>
      <CardContentStack marginTop={[2, null, 'unset']}>
        <FlowItemTitle title={title} />
        <FlowItemImage imgUrl={imgUrl} />
        <FlowItemDescription description={description} />
        <FlowItemMultiFields
          multiFieldValues={multiFieldValues}
          setMultiFieldValues={setMultiFieldValues}
          inputs={inputs}
        />
        {buttonStack && (
          <VStack spacing={2} paddingTop={4}>
            {buttonStack}
          </VStack>
        )}
        {multiFieldValues && i18n.exists('common.allAnswersRequired') ? (
          <Text
            fontSize="sm"
            visibility={
              !allQuestionsAnswered && multiFieldValues.length > 1
                ? undefined
                : 'hidden'
            }
          >
            {t('common.allAnswersRequired')}
          </Text>
        ) : null}

        {externalLinks && <YedLinks links={externalLinks} />}
      </CardContentStack>
    </>
  );
});

export { YedQuestion };
