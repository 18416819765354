import React from 'react';

import Container from '~/src/components/Container';
import ErrorCard from '~/src/components/ErrorCard';

const Home = () => {
  return (
    <Container px={[4, 8, 16]} py={4}>
      <ErrorCard />
    </Container>
  );
};

export default Home;
