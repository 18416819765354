const brand = {
  50: '#e4fcf7',
  100: '#c0f1e6',
  200: '#9ae7d5',
  300: '#73dec5',
  400: '#51d5b5',
  500: '#0396ad', //'#114E40', // <-- replaced with exact brand color
  600: '#03879c',
  700: '#026979',
  800: '#0e3f34',
  900: '#001610',
  gray: '#4A4A4A',
};

export default { brand, primary: brand, secondary: brand };
