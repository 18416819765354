import React, { FC, Fragment, ReactNode } from 'react';
import { Text } from '@chakra-ui/react';
import { richTextParser } from '~/src/utils';
import { GuardianContent } from './components/Contents';
import { ReportMaps, GuardianContentType } from './useGetSummaryData';

export function useGetGuardianElement(reportMap: ReportMaps) {
  const GuardianQA: FC<{ ids: string | Array<string> }> = ({ ids }) => {
    if (!Array.isArray(ids)) ids = [ids];

    const elements = ids.map((id) => (
      <Fragment key={id}>
        <GuardianContent colSpan={1} addBorder={true}>
          {richTextParser(
            reportMap.guardian1.get(id)?.questionText ??
              reportMap.guardian2.get(id)?.questionText,
            { excludeNoReportAttr: true },
          )}
        </GuardianContent>
        <GuardianContent colSpan={1}>
          <Text textAlign="center">
            {reportMap.guardian1.get(id)?.answerButtonText}
          </Text>
        </GuardianContent>
        <GuardianContent colSpan={1}>
          <Text textAlign="center">
            {reportMap.guardian2.get(id)?.answerButtonText}
          </Text>
        </GuardianContent>
      </Fragment>
    ));
    return <>{elements}</>;
  };

  const GuardianText: FC<{ ids: string | Array<string> }> = ({ ids }) => {
    if (!Array.isArray(ids)) ids = [ids];

    const elements = ids.map((id) => (
      <Fragment key={id}>
        {reportMap.guardian1.get(id)?.answerText ? (
          <GuardianContent colSpan={3}>
            <Text fontWeight="bold">Huoltaja 1</Text>
            <Text>{reportMap.guardian1.get(id)?.answerText}</Text>
          </GuardianContent>
        ) : null}
        {reportMap.guardian2.get(id)?.answerText ? (
          <GuardianContent colSpan={3}>
            <Text fontWeight="bold">Huoltaja 2</Text>
            <Text>{reportMap.guardian2.get(id)?.answerText}</Text>
          </GuardianContent>
        ) : null}
      </Fragment>
    ));
    return <>{elements}</>;
  };

  function getGuardianElement(el: GuardianContentType): ReactNode {
    if (typeof el === 'string') return <GuardianQA key={el} ids={el} />;
    if (el.type === 'qa') return <GuardianQA key={el.id} ids={el.id} />;
    return <GuardianText key={el.id} ids={el.id} />;
  }

  function getGuardianElementToClipboard(
    el: GuardianContentType,
    dataKey: keyof typeof reportMap,
  ): ReactNode {
    if (typeof el === 'string') el = { type: 'qa', id: el };

    const data = reportMap[dataKey].get(el.id);

    if (el.type === 'qa')
      return (
        <Fragment key={el.id}>
          <div>
            {richTextParser(
              reportMap.guardian1.get(el.id)?.questionText ??
                reportMap.guardian2.get(el.id)?.questionText,
              { excludeNoReportAttr: true },
            )}
          </div>
          <div>{data?.answerButtonText}</div>
          <br />
        </Fragment>
      );

    return data?.answerText ? <div key={el.id}>{data?.answerText}</div> : null;
  }

  return { getGuardianElement, getGuardianElementToClipboard };
}
