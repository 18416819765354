import { GetStartMenuQuery, MenuItemAction } from '~src/api';
import { localizations } from '~src/localizations';
import { IConfigurationPartial, Locale, Configuration } from '~src/types';
import colors from '~/src/theme/foundations/colorsTerveysporttiVHH';

if (process.env.CONFIGURATION === 'Terveysportti') {
  import('~/src/theme/stylesheets/foundations/terveysporttiStyles.scss');
}

const startMenu: GetStartMenuQuery = {
  startMenu: [
    {
      translationKey: 'NEWS -kysely',
      action: MenuItemAction.Question,
      actionParameter: 'news',
    },
    {
      translationKey: 'Vuorovaikutus ja kohtaaminen ikääntyneiden hoitotyössä',
      action: MenuItemAction.Question,
      actionParameter: 'vuorovaikutus ja kohtaaminen ikääntyneiden hoitotyössä',
    },
    {
      translationKey: 'Vanhusten suunhoito',
      action: MenuItemAction.Question,
      actionParameter: 'vanhusten suunhoito',
    },
  ],
};

const configuration: IConfigurationPartial = {
  searchEnabled: false,
  startMenu,
  colors,
  header: {
    appLogoPaddingTop: '0em',
    appLogoWidth: '120px',
    showInfoToggle: false,
    progressBarVisible: false,
    returnLinkVisible: true,
  },
  flowInit: {
    cardStackPadding: [1, 2, 3],
    cardBorderRadius: '6px',
    maxWidth: 'container.md',
    titleInsideCard: false,
  },
  flowitemConf: {
    showIntro: true,
    introMaxWidth: 'container.md',
    introBgColor: 'green.800',
    introColor: '#FFF',
  },
  translations: {
    supportedLanguages: [Locale.FI],
    resources: localizations[Configuration.Terveysportti] ?? {},
  },
  styles: {
    pageBgColor: '#FFF',
    cardBgColor: 'white',
    progressBarBgColor: 'primary.50',
  },
  radioStyles: {
    color: '#4A4A4A',
    background: 'white',
    borderColor: '#4A4A4A',
    fontSize: '16px',
    colorScheme: 'gray',
    size: 'lg',
  },
  fonts: {
    heading: 'Open Sans, Helvetica Neue, sans-serif',
    body: 'Open Sans, Helvetica Neue, sans-serif',
  },
};

export default configuration;
