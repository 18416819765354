import React, { useContext } from 'react';
import {
  Icon,
  IconButton,
  IconProps,
  ResponsiveValue,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as CSS from 'csstype';

import { AppContext } from '~/src/state';

const InfoIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 23" {...props}>
    <g id="Group" fill="currentColor">
      <path d="M17.8644446,0.01 C16.0946957,-0.0876932441 14.3616964,0.540885558 13.066093,1.75041737 C11.7704897,2.95994918 11.0244486,4.64571924 11.0004446,6.418 C10.97977,7.39894684 11.6573911,8.25687558 12.6164446,8.464 C13.199317,8.57806962 13.8027367,8.42717973 14.2633152,8.05218695 C14.7238936,7.67719417 14.9939807,7.1168942 15.0004446,6.523 C14.9767204,5.54597952 15.5315268,4.64691862 16.4154446,4.23 C17.3068457,3.81199958 18.3601282,3.95210456 19.111264,4.58859112 C19.8623999,5.22507767 20.1734753,6.24108233 19.9074446,7.189 C19.5530387,8.27253332 18.5404587,9.00399819 17.4004446,9 C16.3351109,9.05333256 15.4991105,9.93333302 15.5004446,11 L15.5004446,15.887 C15.4775644,16.8923922 16.177255,17.7701628 17.1624446,17.972 C17.7436332,18.0716543 18.3391787,17.9097563 18.789924,17.5295724 C19.2406694,17.1493886 19.500671,16.5896703 19.5004446,16 L19.5004446,13.031 C19.5038067,12.8245568 19.6337167,12.6414114 19.8274446,12.57 C22.690584,11.4734822 24.3982095,8.52538523 23.9247833,5.49622928 C23.4513571,2.46707333 20.9256227,0.180607399 17.8644446,0.01 Z" />
      <circle cx="17.5004446" cy="21" r="2" />
      <path d="M11.3834446,14.536 C10.8007224,14.4222673 10.1975904,14.573314 9.73725338,14.9482675 C9.27691632,15.323221 9.00696415,15.8833184 9.00044463,16.477 C9.0241689,17.4540205 8.46936246,18.3530814 7.58544463,18.77 C6.69404356,19.1880004 5.6407611,19.0478954 4.88962522,18.4114089 C4.13848935,17.7749223 3.82741397,16.7589177 4.09344463,15.811 C4.44785056,14.7274667 5.46043059,13.9960018 6.60044463,14 C7.66577832,13.9466674 8.50177876,13.066667 8.50044463,12 L8.50044463,7.113 C8.52332488,6.10760783 7.8236343,5.22983716 6.83844463,5.028 C6.25725612,4.92834567 5.66171055,5.09024374 5.21096523,5.47042756 C4.76021991,5.85061138 4.50021822,6.41032973 4.50044463,7 L4.50044463,9.969 C4.49708259,10.1754432 4.36717253,10.3585886 4.17344463,10.43 C1.11727479,11.6020782 -0.590585103,14.8638356 0.187099345,18.0433242 C0.964783793,21.2228129 3.98537185,23.3280194 7.23750612,22.9571231 C10.4896404,22.5862268 12.9586417,19.8549488 13.0004446,16.582 C13.0210228,15.6007486 12.3428966,14.7427114 11.3834446,14.536 Z" />
      <circle id="Oval" cx="6.50044463" cy="2" r="2" />
    </g>
  </Icon>
);

const InfoToggle: React.FC<{
  display?: ResponsiveValue<CSS.Property.Display>;
}> = ({ display }) => {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Tooltip label={t('infoModal.openButtonAriaLabel')}>
      <IconButton
        aria-label={t('infoModal.openButtonAriaLabel')}
        icon={<InfoIcon color="primary.500" />}
        onClick={(e) => {
          dispatch({
            type: 'TOGGLE_INFO_MODAL_STATE',
            payload: !state.modalIsOpen,
          });
          e.currentTarget.blur();
        }}
        variant="ghost"
        size="md"
        isRound
        display={display ?? undefined}
        fontSize={24}
      />
    </Tooltip>
  );
};

export default InfoToggle;
