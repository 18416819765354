import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { Report } from '~/src/api';
import { richTextParser } from '~/src/utils';
import {
  TestScores,
  TestId,
  calculateLimit,
  convertAudit,
} from '~/src/utils/questionnaires';
import { rangeToStr } from '~src/utils/misc';

export function useCreateQuestionnaireResult() {
  const { t } = useTranslation();

  const createQuestionnaireResult = (
    data: Report | undefined,
    testId: TestId,
    testName?: string,
  ): React.ReactNode => {
    if (data === undefined) return null;

    const p = t('terapia.report.pointAbbreviation');

    testId = convertAudit(data.answers, testId);

    const { limit, idx, points } = calculateLimit(data, testId);
    // Don't print results if user didn't answer the test
    if (points === null) return null;

    let testResult;
    if (limit == null || Number.isNaN(points))
      testResult = t('terapia.reportFull.scoreCalculationError');
    else {
      const name = t(`terapia.tests.${testId}.name`);
      const longName = t(`terapia.tests.${testId}.longName`);
      const limitExplanation = t(`terapia.tests.${testId}.limits.${idx}`);

      const fullName =
        testName != null
          ? `<strong>${testName}</strong>`
          : `<strong>${longName}</strong> (${name})`;

      if (testId === 'adsume') {
        const pointRange = `${rangeToStr(limit.min, limit.max)}${p}`;
        testResult = richTextParser(
          `${fullName}: ${points}${p} (${pointRange}) ${limitExplanation}`,
          // Disable to not convert i18n keys to links (only applicable if
          // translation file not present)
          { disableLinkify: true },
        );
      } else {
        const pointRange = `${rangeToStr(limit.min, limit.max)}${p} / ${
          TestScores[testId].maxPoints
        }${p}`;
        testResult = richTextParser(
          `${fullName}: ${points}${p}, ${limitExplanation} (${pointRange})`,
          // Disable to not convert i18n keys to links (only applicable if
          // translation file not present)
          { disableLinkify: true },
        );
      }
    }

    return (
      <Text as="div" className="noPageBreakOnPrint">
        {testResult}
      </Text>
    );
  };

  return { createQuestionnaireResult };
}
