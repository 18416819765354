import React, { useState } from 'react';
import { Button, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItemAction, GetStartMenuQuery } from '~/src/api';
import { useHandleFlowInitClick } from '~/src/hooks/useHandleFlowInitClick';
import { envs } from '~src/utils';

export const StartMenuButtons: React.FC<{
  startMenu?: GetStartMenuQuery['startMenu'];
  isMenuDisabled?: boolean | Array<boolean>;
  isMaintenanceComing?: boolean;
}> = ({ startMenu, isMenuDisabled, isMaintenanceComing }) => {
  const { t } = useTranslation();
  const [currentActionIndex, setCurrentActionIndex] = useState<number>();
  const { clickLoading, handleClick } = useHandleFlowInitClick();

  const buttons = (startMenu ?? []).map((menuItem, i) => {
    const { translationKey, label, action, actionParameter, variant } =
      menuItem as MenuItem;

    const param = actionParameter as string;
    const isLoading = clickLoading && currentActionIndex === i;
    const isDisabled = Array.isArray(isMenuDisabled)
      ? isMenuDisabled[i] === true
      : isMenuDisabled === true;
    const itemKey = `init-button-${action}-${i}`;

    // Add properties for the visual variant of the button
    const variantProps =
      variant === 'link' ? { variant: 'link', padding: 3 } : {};

    return (
      <Button
        key={itemKey}
        onClick={() => {
          setCurrentActionIndex(i);
          return handleClick(action, param);
        }}
        isLoading={isLoading}
        isDisabled={isDisabled || action === MenuItemAction.Disabled}
        style={{ wordWrap: 'normal', whiteSpace: 'normal' }}
        {...variantProps}
      >
        {translationKey ? t(translationKey) : label}
      </Button>
    );
  });

  return (
    <>
      {isMaintenanceComing && (
        <Box
          bg={'red.600'}
          p={4}
          my={4}
          borderRadius={20}
          color="white"
          fontWeight="bold"
        >
          {t('errors.upcomingMaintenance')}{' '}
          {envs.UPCOMING_MAINTENANCE_COMPLETED_AT}.
        </Box>
      )}
      {buttons}
    </>
  );
};
