import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loading from '~/src/components/Loading';
import { useGetReportQuery, Report } from '~/src/api';
import { PdfReport } from '../components/PdfReport';
import { FullReport } from '~/src/screens/FullReport';
import { ReportViewType } from '~/src/types';
import { ReportNotFound } from '../components/ReportNotFound';
import { ReportSummaryTerapia, Summary } from './ReportSummaryTerapia';

export const TerapiaReport: React.FC<{
  view: ReportViewType;
  id?: string | null;
}> = ({ view, id }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h1" size="xl" marginBottom={4}>
        {t('terapia.report.title')}
      </Heading>

      <TerapiaReportBody view={view} id={id} />
    </>
  );
};

const TerapiaReportBody: React.FC<{
  view: ReportViewType;
  id?: string | null;
}> = ({ view, id }) => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetReportQuery({
    variables: {
      reportId: id?.trim(),
      yedNames: ['hus-terapia', 'hus-terapia-sv', 'hus-terapia-en'],
    },
    skip: !id,
  });

  const reportData: Report | undefined | null = data?.report;

  if (loading) return <Loading />;
  if (error || !reportData || !id) return <ReportNotFound id={id ?? ''} />;
  if (view === ReportViewType.Full) return <FullReport report={reportData} />;

  if (view === ReportViewType.Pdf)
    return (
      <PdfReport
        id={id}
        summary={
          <Summary
            report={reportData}
            title={{ text: t('terapia.reportSummary.title'), id: 'summary' }}
            id={id}
          />
        }
        fullReport={<FullReport report={reportData} />}
      />
    );

  return (
    <ReportSummaryTerapia
      report={reportData}
      id={id}
      isPrint={view === ReportViewType.Print}
    />
  );
};
