import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import '~/src/theme/stylesheets/printReportPdf.scss';
import { ReportInfoLink } from './ReportInfoLink';

export const PdfReport: React.FC<{
  id: string;
  summary: JSX.Element;
  fullReport: JSX.Element;
}> = ({ id, summary, fullReport }) => {
  return (
    <Box textAlign="left" className="pdfReport">
      <Text>
        <Trans i18nKey="terapia.report.description" values={{ id }} />
      </Text>
      {summary}
      {fullReport}
      <ReportInfoLink />
    </Box>
  );
};
