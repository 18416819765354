import React from 'react';
import { Text } from '@chakra-ui/react';

import { ReportAnswer } from '~/src/api';
import { devlogger, richTextParser } from '~/src/utils';

type GetAnswerOptions = {
  upperCase: boolean;
  onlyPositiveAnswers: boolean;
  includeTextElement: boolean;
  label: string | null;
  linebreakAfterLabel: boolean;
  spaceBeforeNext: boolean;
  bold: boolean;
  useQuestionAsLabel: boolean;
  useRichTextFormatter: boolean;
};

export type ReportMap = Map<string, { a: string; q: string } & ReportAnswer>;

export function createReportMap(report: ReportAnswer[]): ReportMap {
  const reportMap = new Map();
  report.forEach((r) =>
    reportMap.set(r.questionId, {
      q: r.questionText,
      a: r.answerText ?? r.answerButtonText,
      ...r,
    }),
  );
  return reportMap;
}

export function getAnswerFunction(
  data: ReportMap,
  defaultOptions?: Partial<GetAnswerOptions>,
) {
  const defaults: GetAnswerOptions = {
    upperCase: false,
    onlyPositiveAnswers: true,
    includeTextElement: true,
    label: '',
    linebreakAfterLabel: false,
    spaceBeforeNext: false,
    bold: false,
    useQuestionAsLabel: false,
    useRichTextFormatter: false,
    ...(defaultOptions ?? {}),
  };

  return (id: string, options?: Partial<GetAnswerOptions>) => {
    const optionsWithDefaults = { ...defaults, ...(options ?? {}) };
    const {
      upperCase,
      onlyPositiveAnswers,
      includeTextElement,
      label,
      linebreakAfterLabel,
      spaceBeforeNext,
      bold,
      useQuestionAsLabel,
      useRichTextFormatter,
    } = optionsWithDefaults;

    // When yEd files are parsed the node id's are converted to lower case
    const answer = data.get(id.toLowerCase());
    let answerStr = answer?.a;
    if (answerStr == null) {
      devlogger(`Answer for question <${id}> not found`);
      return null;
    }
    if (upperCase) answerStr = answerStr.toUpperCase();

    const answerText = useRichTextFormatter
      ? richTextParser(answerStr, {
          removeTags: ['b', 'strong'],
          excludeNoReportAttr: true,
        })
      : answerStr;

    const labelStr = useQuestionAsLabel ? answer?.q : label ? label + ': ' : '';
    const labelText =
      useRichTextFormatter && labelStr
        ? richTextParser(labelStr, {
            removeTags: ['b', 'strong'],
            excludeNoReportAttr: true,
          })
        : labelStr;

    if (onlyPositiveAnswers && answer?.answerId === '0') {
      devlogger(
        `Answer id for question <${id}> is non-positive (${answer?.answerId}), skipping`,
      );
      return null;
    }
    if (includeTextElement)
      return (
        <>
          <Text
            as="div"
            className="noPageBreakOnPrint"
            whiteSpace="pre-wrap"
            fontWeight={bold ? 'bold' : undefined}
          >
            {labelText && <Text as="i">{labelText}</Text>}
            {linebreakAfterLabel && <br />}
            {answerText}
          </Text>
          {(spaceBeforeNext || linebreakAfterLabel) && <br />}
        </>
      );
    return answerText;
  };
}
