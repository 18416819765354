import React from 'react';
import { Spinner } from '@chakra-ui/react';

const Loading: React.FC<{ size?: string }> = ({ size = 'xl' }) => {
  return (
    <Spinner
      thickness="4px"
      speed="0.76s"
      emptyColor="gray.200"
      color="primary.500"
      size={size}
    />
  );
};

export default Loading;
