const primary = {
  50: '#bbd2d8',
  100: '#8eb4be',
  200: '#6096a4',
  300: '#498797',
  400: '#33788a',
  500: '#1c697d', // <-- replaced with brand color
  600: '#165464',
  700: '#144a58',
  800: '#113f4b',
  900: '#0b2a32',
}; // Official petrol blue brand color: #1c697d

export default {
  // Generated with https://maketintsandshades.com/
  brand: {
    50: '#d2e1e5',
    100: '#bbd2d8',
    200: '#8eb4be',
    300: '#6096a4',
    400: '#498797',
    500: '#33788a',
    600: '#1c697d', // <-- replaced with brand color
    700: '#165464',
    800: '#144a58',
    900: '#113f4b',
  }, // Official petrol blue brand color moved up one step
  primary: primary,
  secondary: {
    50: '#fee7c4',
    100: '#fddfb1',
    200: '#fdd89d',
    300: '#fbc062',
    400: '#fbb84f',
    500: '#fab03b', // <-- brand secondary color
    600: '#e19e35',
    700: '#c88d2f',
    800: '#af7b29',
    900: '#966a23',
  },
};
