import { VStack, Box, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FlowItem } from '~src/api';
import Modal from '~src/components/Modal';
import ParagraphStack from '~src/components/ParagraphStack';
import { flowitemConf } from '~src/configurations';
import { richTextParser } from '~src/utils';
import {
  ModalContent,
  pickModalsFromDescription,
} from '~src/utils/pickModalsFromDescription';

export const DESCRIPTION_OUTSIDE_CARD_TAG = '[outside-of-card]';

const FlowItemDescription: React.FC<{
  description: FlowItem['description'];
  textAlign?: 'center' | 'left' | 'right';
  place?: 'card' | 'intro';
}> = ({ description, textAlign = 'left', place = 'card' }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [currentModal, setCurrentModal] = useState<ModalContent>();
  const hasDescription =
    description && description[0] && description.length > 0;

  if (!hasDescription || (hasDescription && typeof description[0] !== 'string'))
    return <></>;

  // If the description is visible in FlowItemIntro,
  // don't show same description again inside the card
  if (
    flowitemConf.showIntro &&
    place === 'card' &&
    description[0].startsWith(DESCRIPTION_OUTSIDE_CARD_TAG)
  )
    return <></>;

  const modifiedDescription = [...description];
  modifiedDescription[0] = modifiedDescription[0].replace(
    DESCRIPTION_OUTSIDE_CARD_TAG,
    '',
  );

  const content = pickModalsFromDescription(
    modifiedDescription.join('<br/><br/>'),
  );
  const showModal = (uuid: string) => {
    setCurrentModal(content.modals.find((x) => x.uuid === uuid) ?? undefined);
    onOpen();
  };

  const htmlDescription: any = richTextParser([content.description], {
    allowTags: ['iframe', 'button'],
    allowAttr: ['className'],
    openYedModal: showModal,
  }); // allow iframe to embed YouTube videos

  return (
    <>
      <Modal
        modalIsOpen={isOpen}
        onCloseModal={onClose}
        title={currentModal?.title}
      >
        {currentModal?.modalDescription}
      </Modal>
      {htmlDescription && (
        <ParagraphStack pb={4} width="100%">
          <VStack>
            {htmlDescription.map((x: any, i: number) => (
              <Box width="100%" key={`result-item-${i}`} textAlign={textAlign}>
                {x}
              </Box>
            ))}
          </VStack>
        </ParagraphStack>
      )}
    </>
  );
};

export { FlowItemDescription };
