import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { createReportMap, getAnswerFunction, ReportMap } from '../reportUtils';
import { CombinationData } from './CombinationReport';
import { useCreateQuestionnaireResult } from '~src/hooks/useCreateQuestionnaireResult';
import { useGetGuardianElement } from './useGetGuardianElement';

export type GuardianContentType = string | { type: 'qa' | 'text'; id: string };

export type SummaryData = Array<{
  name: string;
  youthContent: ReactNode;
  guardianContent: Array<GuardianContentType>;
}>;

export type ReportMaps = {
  youth: ReportMap;
  guardian1: ReportMap;
  guardian2: ReportMap;
};

export function useGetSummaryData(data: CombinationData) {
  const { t } = useTranslation();
  const { createQuestionnaireResult } = useCreateQuestionnaireResult();

  const report = {
    youth: data.youthData,
    guardian1: data.guardianData1,
    guardian2: data.guardianData2,
  };
  const reportMap: ReportMaps = {
    youth: createReportMap(data.youthData?.answers ?? []),
    guardian1: createReportMap(data.guardianData1?.answers ?? []),
    guardian2: createReportMap(data.guardianData2?.answers ?? []),
  };
  const getAnswerFunctionOptions = {
    useQuestionAsLabel: false,
    linebreakAfterLabel: false,
    onlyPositiveAnswers: false,
    useRichTextFormatter: false,
  };
  const getAnswer = {
    youth: getAnswerFunction(reportMap.youth, getAnswerFunctionOptions),
    guardian1: getAnswerFunction(reportMap.guardian1, getAnswerFunctionOptions),
    guardian2: getAnswerFunction(reportMap.guardian2, getAnswerFunctionOptions),
  };

  const { getGuardianElement, getGuardianElementToClipboard } =
    useGetGuardianElement(reportMap);

  const auditAnswers = (
    <>
      {createQuestionnaireResult(report.youth, 'auditYouth')}
      {getAnswer.youth('huume', {
        label: 'Huumaavien aineiden kokeilu ja käyttö',
        onlyPositiveAnswers: true,
      })}
    </>
  );

  const adsumeAnswers = (
    <>
      {createQuestionnaireResult(report.youth, 'adsume')}
      <br />
      {getAnswer.youth('adsume-6', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer.youth('adsume-10', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer.youth('adsume-11', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
      {getAnswer.youth('adsume-12', {
        useQuestionAsLabel: true,
        linebreakAfterLabel: true,
        useRichTextFormatter: true,
      })}
    </>
  );

  const summaryData: SummaryData = [
    {
      name: t('terapia.reportSummary.subtitles.description'),
      youthContent: getAnswer.youth('aloitus'),
      guardianContent: [
        'h-psyykkinen-vointi',
        { type: 'text', id: 'h-huolen-aiheet' },
      ],
    },
    {
      name: t('terapia.reportSummary.subtitles.questionnaires'),
      youthContent: (
        <>
          {createQuestionnaireResult(report.youth, 'phq')}
          {createQuestionnaireResult(report.youth, 'gad')}
          {createQuestionnaireResult(report.youth, 'oci')}
          {createQuestionnaireResult(report.youth, 'spin')}
          {createQuestionnaireResult(report.youth, 'sci')}
          {createQuestionnaireResult(report.youth, 'scoff')}
          <br />
          {reportMap.youth.get('phq-9')?.answerValue !== '0' &&
            getAnswer.youth('phq-9', {
              label: t('terapia.reportSummary.labels.dangerousThoughts'),
              upperCase: true,
              bold: true,
            })}
          {getAnswer.youth('aggressio', {
            label: 'Tappeluihin joutuminen tai toistuva valehtelu',
            upperCase: true,
            bold: true,
            onlyPositiveAnswers: true,
          })}
        </>
      ),
      guardianContent: ['h-univaikeus', 'h-syömishairio', 'h-aggressio'],
    },
    {
      name: t('terapia.reportSummary.subtitles.drugs'),
      youthContent:
        report.youth?.reportVersion === 2 ? adsumeAnswers : auditAnswers,
      guardianContent: ['h-alkoholi', 'h-paihteet'],
    },
    {
      name: t('terapia.reportSummary.subtitles.performance'),
      youthContent: (
        <>
          {getAnswer.youth('koulu', {
            label:
              'Koulusta tai opinnoista suoriutuminen viimeisen kuukauden aikana',
          })}
          {getAnswer.youth('ystava', { label: 'Ystävyyssuhteet' })}
          {getAnswer.youth('yksinaisyys', { label: 'Yksinäisyyden kokemus' })}
          {getAnswer.youth('vapaa-aika', {
            label: 'Harrastukset ja mielekäs vapaa-ajan tekeminen',
          })}
          {getAnswer.youth('hygienia', {
            label: 'Henkilökohtaisesta hygieniasta huolehtiminen',
          })}
          {getAnswer.youth('ruutuaika', { label: 'Ruutuaika' })}
        </>
      ),
      guardianContent: [
        'h-opinnot',
        'h-poissaolo',
        'h-poissaolo-maara',
        'h-ystava',
        'h-vapaa-aika',
        'h-hygienia',
        'h-ruutu-maara',
      ],
    },
    {
      name: t('terapia.reportSummary.subtitles.changeReadiness'),
      youthContent: (
        <>
          {getAnswer.youth('keskustelu', {
            label: 'Pystyn keskustelemaan lähiaikuisen kanssa',
          })}
          {getAnswer.youth('avun-tarve', {
            label: 'Ulkopuolisen avun tarve',
          })}
        </>
      ),
      guardianContent: ['h-ammatti-tuki'],
    },
    {
      name: t('terapia.reportSummary.subtitles.studyChallenges'),
      youthContent: (
        <>
          {getAnswer.youth('oppimisvaikeus', {
            label: 'Todetut oppimisvaikeudet',
          })}
          {getAnswer.youth('keskittyminen', {
            label: 'Vaikeuksia opetuksen seuraamisessa',
          })}
          {getAnswer.youth('tehtava', {
            label: 'Vaikeuksia läksyjen tekemisessä',
          })}
          {getAnswer.youth('kokeet', {
            label: 'Vaikeuksia kokeisiin valmistautumisessa',
          })}
          {getAnswer.youth('luki', {
            label:
              'Vaikeuksia kirjoittamista, lukemista ja/tai laskemista vaativissa tehtävissä',
          })}
          {getAnswer.youth('esiintyminen', {
            label: 'Vaikeuksia suullisessa esiintymisessä',
          })}
          {getAnswer.youth('vastaaminen', {
            label: 'Vaikeuksia tunnilla vastaamisessa',
          })}
          {getAnswer.youth('laitteet', {
            label: 'Vaikeuksia opiskelussa käytettävien laitteiden käytössä',
          })}
        </>
      ),
      guardianContent: ['h-oppismisvaikeus'],
    },
    {
      name: t('terapia.reportSummary.subtitles.environmentChallenges'),
      youthContent: (
        <>
          {getAnswer.youth('muutos', {
            label: 'Merkittävät muutokset viimeisten kuukausien aikana',
            // Show muutos question always (i.e. onlyPositiveAnswers === false)
            // if stressi question is not shown (i.e. is null)
            onlyPositiveAnswers:
              getAnswer.youth('stressi', { onlyPositiveAnswers: true }) !==
              null,
          })}
          {getAnswer.youth('stressi', {
            label: 'Stressaava tai pelottava tapahtuma',
            onlyPositiveAnswers: true,
          })}
        </>
      ),
      guardianContent: ['h-muutos', 'h-keskustelu', 'h-tuki', 'h-sospalvelu'],
    },
    {
      name: t('terapia.reportSummary.subtitles.previousTreatments'),
      youthContent: (
        <>
          {getAnswer.youth('hoito-kontaktit', {
            label:
              'Ajankohtaisia tai aiempia kontakteja psyykkisen voinnin tai neuropsykiatrisen häiriön vuoksi',
          })}
        </>
      ),
      guardianContent: ['h-kontaktit'],
    },
  ];

  return { summaryData, getGuardianElement, getGuardianElementToClipboard };
}
