import React from 'react';
import { useTranslation } from 'react-i18next';

import { devlogger, envs } from '~/src/utils';
import { AccessibilityStatementFI } from './AccessibilityStatementFI';

export const AccessibilityStatement: React.FC = () => {
  const { i18n } = useTranslation();

  const site =
    envs.CONFIGURATION === 'Terapianavigaattori'
      ? 'Terapianavigaattori.fi'
      : envs.CONFIGURATION === 'Interventionavigaattori'
      ? 'Interventionavigaattori.fi'
      : null;

  if (site == null) return <>Accessibility statement not found</>;

  if (i18n.language === 'sv')
    devlogger('SV accessibility statement translation missing, using FI');

  if (i18n.language === 'en')
    devlogger('EN accessibility statement translation missing, using FI');

  return <AccessibilityStatementFI site={site} />;
};
