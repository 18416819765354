import React from 'react';
import { Text, Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from '~/src/components/Link';

export const ReportInfoLink = () => {
  const { t } = useTranslation();
  const pdfInfoLink = t('terapia.report.pdfInfoLink');
  const pdfInfoLinkUrl = t('terapia.report.pdfInfoLinkUrl');

  if (!pdfInfoLink && !pdfInfoLinkUrl) return null;

  return (
    <>
      <Divider my={8} />
      <Text>
        {pdfInfoLink}{' '}
        <Link href={pdfInfoLinkUrl ?? '#'} isExternal>
          {pdfInfoLinkUrl}
        </Link>
      </Text>
    </>
  );
};
