const handleKeyboardShortcuts = (e: any) => {
  if (e.code === 'Home') {
    e.preventDefault();

    e.shiftKey
      ? (e.target.selectionStart = 0)
      : e.target.setSelectionRange(0, 0);
  }

  if (e.code === 'End') {
    e.preventDefault();

    const length = e.target.value.length;

    e.shiftKey
      ? (e.target.selectionEnd = length)
      : e.target.setSelectionRange(length, length);
  }

  return null;
};

export default handleKeyboardShortcuts;
