import React from 'react';
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Box,
  TextProps,
} from '@chakra-ui/react';
import Container from '~/src/components/Container';
import Card from '~/src/components/Card';

export const TerapiaPrivacyEN: React.FC = () => {
  return (
    <Container>
      <Card>
        <Box textAlign="left" p={[4, 6, 8]}>
          <Heading as="h1" size="xl" pb={8}>
            Therapy Navigator – Privacy Policy
          </Heading>
          <CustomText>Privacy Policy prepared on 15th February 2023</CustomText>
          <CustomText>Privacy Policy updated on 13th April 2023</CustomText>
          <SubTitle>Data Controller</SubTitle>
          <CustomText>
            <strong>Name:</strong>
            <br />
            HUS Group, the joint authority for Helsinki and Uusimaa
          </CustomText>
          <CustomText>
            <strong>Address:</strong>
            <br />
            HUS Group,
            <br />
            Stenbäckinkatu 9, Helsinki
            <br />
            P.O. BOX 100, FI-00029 HUS
            <br />
          </CustomText>
          <CustomText>
            <strong>Contact Information:</strong>
            <br />
            Phone Switchboard: 09 4711
            <br />
            Registry Fax: 09 471 75500
            <br />
            Registry Email: keskuskirjaamo@hus.fi
            <br />
            Mailing Address: HUS Group&apos;s Registry, P.O. BOX 200, FI-00029
            HUS
          </CustomText>
          <SubTitle>Person Responsible for the Register</SubTitle>
          Director of Psychiatry, Senior Medican Director Jesper Ekelund
          <SubTitle>Contact Details of the Data Protection Officer</SubTitle>
          <CustomText>
            <strong>Mailing Address:</strong>
            <br />
            HUS Group&apos;s Registry
            <br />
            P.O. BOX 200, FI-00029 HUS
          </CustomText>
          <CustomText>
            <strong>Email:</strong>
            <br />
            eutietosuoja@hus.fi
          </CustomText>
          <SubTitle>
            Contact Details for Matters Regarding the Register
          </SubTitle>
          <CustomText>
            First-Line Therapies Initiative: terapiatetulinjaan@hus.fi
          </CustomText>
          <SubTitle>Purpose of Processing Personal Data</SubTitle>
          <CustomText>
            The Therapy Navigator is intended for individuals seeking social and
            health services for mental health problems to complete before the
            assessment of their need for support, treatment, or service. The
            collected data is retained so that the individual who completed the
            Therapy Navigator can provide them to the assessing party.
          </CustomText>
          The data will not be used for automated decision-making or profiling.
          <SubTitle>Legal Basis for Processing Data</SubTitle>
          <CustomText>
            The legal basis for processing personal data is Article 9(2)(a) of
            the EU General Data Protection Regulation: the data subject&apos;s
            explicit consent to the processing of personal data concerning them.
          </CustomText>
          <SubTitle>Contents of the Register</SubTitle>
          <CustomText pb={0}>Information recorded in the register:</CustomText>
          <UnorderedList>
            <ListItem>
              the respondent&apos;s description of their own situation
            </ListItem>
            <ListItem>
              responses to questionnaires assessing mental health disorders and
              presenting problems
            </ListItem>
            <ListItem>
              answers to questions about, for example, social and occupational
              functioning, previous treatments, and treatment preferences
            </ListItem>
            <ListItem>
              an optional question about the respondent&apos;s place of
              residence (their wellbeing services county)
            </ListItem>
            <ListItem>
              an 8-character code used by the respondent to access their answers
              and the automatically generated report for up to one month after
              completion.
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            The survey does not collect directly identifiable information about
            the respondent. An 8-character code is given to the respondent at
            the end of the Therapy Navigator. The respondent is responsible for
            keeping the code safe, and the data controller cannot recover a lost
            code.
          </CustomText>
          <SubTitle>Regular Sources of Data</SubTitle>
          <CustomText>
            The information to be stored is collected from the respondent by
            using the Therapy Navigator service or is generated randomly during
            the process (8-character code referring to the responses).
          </CustomText>
          <SubTitle>Recipients of the Data</SubTitle>
          <CustomText>
            The data will be stored in Google&apos;s Hamina data center.
          </CustomText>
          <CustomText>
            A local social or health care professional can access the data only
            if the respondent provides them with the 8-character code, which
            allows the responses and the compiled report to be accessed within
            one month after completion.
          </CustomText>
          <CustomText>
            The data in the system can be accessed only by the system
            administrator via an ssh connection, and the First-Line Therapies
            project team, which can download an anonymized report about the use
            of the Therapy Navigator. The Therapy Navigator has been built and
            is maintained by Kuura Health Ltd.
          </CustomText>
          <SubTitle>Regular Transfers of Data</SubTitle>
          <CustomText>
            The data controller does not routinely transfer data to other
            parties. The individual who has completed the Therapy Navigator and
            possesses the 8-character code can share their data with a third
            party of their choice by sharing the code.
          </CustomText>
          <SubTitle>Data Retention Period</SubTitle>
          <CustomText>
            The collected data will be retained for thirty-one (31) days, after
            which they will be permanently anonymized by removing the
            8-character code and open-ended responses. After the removal of this
            data, the information will no longer be accessible to the
            respondent.
          </CustomText>
          <SubTitle>Transfer of Data Outside the EU or EEA</SubTitle>
          <CustomText>
            The data controller does not transfer data outside the EU or the
            EEA.
          </CustomText>
          <CustomText>
            The cloud service provider (Google) used for data retention has a
            technical connection to a server center located in Finland outside
            the EU/EEA, mainly for technical maintenance. Although it is
            possible to access the server center&apos;s data from outside the
            EU/EEA in third countries, based on the data controller&apos;s
            impact assessment, this does not pose any risks to individuals using
            the Therapy Navigator. The stored data and their protection
            principles are described in other parts of this document.
          </CustomText>
          <SubTitle>Data Protection Principles</SubTitle>
          <CustomText>
            Data processing is carried out with care, and the data processed
            using information systems are protected adequately. When storing
            registry information on Internet servers, their physical and digital
            security is appropriately taken care of. The data controller ensures
            that stored data as well as the server access rights and other
            information critical for the security of personal data are treated
            confidentially and only by employees whose job description includes
            such tasks.
          </CustomText>
          <SubTitle>Data Subject&apos;s Rights</SubTitle>
          <CustomText pb={0}>
            The data subject has the following rights:
          </CustomText>
          <UnorderedList>
            <ListItem>
              Right to withdraw consent to data processing (Article 7)
            </ListItem>
            <ListItem>
              Right to access personal data (Article 15)
              <br />
              the data subject can make a written request for information about
              their own data
            </ListItem>
            <ListItem>
              Right to rectification of data (Article 16)
              <br />
              the data subject can make a written request for correction
            </ListItem>
            <ListItem>
              Right to erasure of data (Article 17)
              <br />
              the data subject can request the removal of information that is
              irrelevant or incorrect for the register&apos;s purpose
            </ListItem>
            <ListItem>
              Right to restriction of processing (Article 18)
              <br />
              the data subject can make a formal request, which will be
              processed on a case-by-case basis
            </ListItem>
            <ListItem>
              Right to object to processing (Article 21)
              <br />
              the data subject can make a formal request, which will be
              processed on a case-by-case basis
            </ListItem>
            <ListItem>
              Right to data portability (Article 20)
              <br />
              the data subject can make a formal request, which will be
              processed on a case-by-case basis
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            Applications or requests concerning the above-mentioned rights shall
            be submitted to the party specified in the section &quot;Contact
            Details for Matters Regarding the Register&quot;.
          </CustomText>
          <CustomText>
            If the data controller can demonstrate that it cannot identify the
            data subject, Articles 15 to 20 do not apply, unless the data
            subject provides additional information enabling their
            identification to exercise these rights (Article 11(2)).
          </CustomText>
          <SubTitle>
            Right to Lodge a Complaint with a Supervisory Authority
          </SubTitle>
          <CustomText>
            Every data subject has the right to lodge a complaint with a
            supervisory authority, in particular in the Member State of their
            habitual residence or place of work, or where the alleged breach
            occurred, if the data subject considers that the processing of
            personal data concerning him or her breaches the EU data protection
            regulation. This is without prejudice to other administrative or
            judicial remedies.
          </CustomText>
          <CustomText>Supervisory Authority Information:</CustomText>
          <CustomText>
            <strong>Office of the Data Protection Ombudsman</strong>
            <br />
            https://tietosuoja.fi/en/frontpage
            <br />
            Visiting Address: Lintulahdenkuja 4, 00530 Helsinki
            <br />
            Mailing Address: PO Box 800, 00531 Helsinki
            <br />
            Email: tietosuoja(at)om.fi Phone Switchboard: 029 566 6700
            <br />
            Registry: 029 566 6768
          </CustomText>
        </Box>
      </Card>
    </Container>
  );
};

const SubTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Heading as="h2" size="lg" py={4} textAlign="left">
    {children}
  </Heading>
);

const CustomText: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text pb={4} {...rest}>
    {children}
  </Text>
);
