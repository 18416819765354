import React, { useContext } from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Container from '~/src/components/Container';
import ParagraphStack from '~/src/components/ParagraphStack';
import { useStartFlowLazyQuery } from '~/src/api';
import { surveyFlowRoot } from '~/src/constants';
import ChatInit from '~/src/components/Chat/ChatInit';
import { AppContext } from '~/src/state';

const Chat = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);

  const [initFlow] = useStartFlowLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ start }) => {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: start,
      });

      history.push(`/${surveyFlowRoot}/${start.flowItem?.name}/${start.id}`);

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  return (
    <>
      <Container>
        <Heading
          as="h1"
          color="primary.500"
          fontSize={['2rem', null, '4xl']}
          lineHeight="125%"
          mt={8}
          mb={8}
          textAlign="center"
        >
          {t('chat.intro.title')}
        </Heading>

        <ParagraphStack center>
          <Text>{t('chat.intro.firstParagraph')}</Text>

          <Text>
            <Trans i18nKey="chat.intro.secondParagraph">
              Start
              <Button
                variant="link"
                onClick={() => {
                  dispatch({
                    type: 'SET_APP_LOADING_STATE',
                    payload: true,
                  });

                  return initFlow();
                }}
                verticalAlign="initial"
              >
                here
              </Button>
            </Trans>
          </Text>
        </ParagraphStack>

        <ChatInit marginTop={12} />
      </Container>
    </>
  );
};

export default Chat;
