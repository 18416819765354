{
  "common": {
    "app_name": "Terveysportti - Vanhusten hoiva",
    "app_organization": "Duodecim Oy"
  },
  "intro": {
    "title": "Tervetuloa lääkärin chat-vastaanotolle",
    "paragraph": "NEWS - kyselyn avulla voit arvioida vanhuksen tilaa ja tarvetta hoivaan. Kysely on tarkoitettu vanhuksen hoitajalle, joka on saanut koulutusta kyselyn käyttöön."
  },
  "infoModal": {
    "title": "Ohjeita lorem ipsum..",
    "paragraphContent": [
      "NEWS kysely vanhusten hoivan avuksi",
      "lorem ipsum dolor es..."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "flowInit": {
    "title": "Mitä asiasi koskee?"
  }
}
