import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';
import { reportRoot } from '~/src/constants';
import { Link } from '~/src/components/Link';
import { ReportType } from '~/src/types';

export const FullReportSection: FC<{
  id: string;
  type: ReportType;
  title: { text: string; id: string };
}> = ({ id: reportId, type: reportType, title }) => {
  const { t } = useTranslation();
  const params = new URLSearchParams({ reportId, reportType }).toString();

  return (
    <>
      <Heading as="h2" size="lg" mt={16} mb={8} id={title.id}>
        {title.text}
      </Heading>
      <Text>{t('terapia.reportFull.description')}</Text>
      <Text mt={4}>
        <Link href={`/${reportRoot}/full?${params}`} isBold isExternal>
          {t('terapia.reportFull.openLink')}
        </Link>
      </Text>
    </>
  );
};
