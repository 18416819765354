import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Container from '~/src/components/Container';
import ParagraphStack from '~/src/components/ParagraphStack';
import Card, { CardContentStack } from '~/src/components/Card';
import { reportRoot } from '~/src/constants';
import { ReportType } from '~/src/types';
import { useGetReportInfoFromParams } from '~/src/hooks/useGetReportInfoFromParams';

const ReportDownload = () => {
  const { reportType } = useGetReportInfoFromParams();
  const { t } = useTranslation();
  const [formIds, setFormIds] = useReducer(
    (
      prevState: Record<string, string>,
      newValues: Record<string, string | undefined>,
    ) => {
      const newState = { ...prevState };
      // Delete undefined values, otherwise they show up in urlParams string
      for (const [key, val] of Object.entries(newValues))
        if (val === undefined) delete newState[key];
        else newState[key] = val;

      return newState;
    },
    {},
  );

  useEffect(() => {
    setFormIds({ reportType });
  }, [reportType]);

  let urlParams = new URLSearchParams(formIds).toString();
  if (urlParams !== '') urlParams = `?${urlParams}`;

  // Default values for some fields
  let inputForms: Array<{ id: string; tKeyLabel: string }> = [
    { id: 'reportId', tKeyLabel: 'terapia.openReport.formLabel' },
  ];
  let titleKey = 'terapia.openReport.title';
  let descKey = 'terapia.openReport.description';

  // Override default field values by reportType
  if (reportType === ReportType.Youth) titleKey += 'Youth';
  if (reportType === ReportType.Guardian) titleKey += 'Guardian';
  if (reportType === ReportType.Combination) {
    descKey += 'Combination';
    inputForms = [
      { id: 'youthId', tKeyLabel: 'terapia.openReport.formLabelYouth' },
      { id: 'guardianId1', tKeyLabel: 'terapia.openReport.formLabelGuardian1' },
      { id: 'guardianId2', tKeyLabel: 'terapia.openReport.formLabelGuardian2' },
    ];
  }

  return (
    <Container>
      <Card marginTop={12}>
        <Heading
          as="h1"
          color="primary.500"
          fontSize={['2rem', null, '4xl']}
          lineHeight="125%"
          mt={8}
          mb={8}
          textAlign="center"
        >
          {t(titleKey)}
        </Heading>

        <ParagraphStack center px={8}>
          <Text>{t(descKey)}</Text>
        </ParagraphStack>

        <CardContentStack as="form" maxWidth="23rem">
          {inputForms.map((form) => (
            <FormControl id={form.id} key={form.id}>
              <FormLabel textAlign="center">{t(form.tKeyLabel)}</FormLabel>

              <Input
                type="text"
                value={formIds[form.id] ?? ''}
                onChange={(e) =>
                  setFormIds({
                    [form.id]: e.target.value || undefined,
                  })
                }
                placeholder="C78D96DA"
                textAlign="center"
                autoComplete="off"
              />
            </FormControl>
          ))}

          <Link to={`/${reportRoot}/summary${urlParams}`}>
            <Button
              type="submit"
              isDisabled={Object.keys(formIds).length <= 1}
              minW="20rem"
              width="100%"
            >
              {t('terapia.openReport.openBtn')}
            </Button>
          </Link>
        </CardContentStack>
      </Card>
    </Container>
  );
};

export default ReportDownload;
