import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderedList, ListItem } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Link } from '~/src/components/Link';
import { Subtitle } from '~/src/components/Headings/Subtitle';
import { Title } from './Title';

export const TOC: FC<{ titles: Array<Title> }> = ({ titles }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  if (titles.length === 0) return null;

  return (
    <>
      <Subtitle toUpper={false}>{t('terapia.report.toc')}</Subtitle>
      <OrderedList mb={4}>
        {titles.map((title) => (
          <ListItem key={title.id} my={1}>
            <Link to={`${search}#${title.id}`} type="hash" isBold>
              {title.text}
            </Link>
            <br />
          </ListItem>
        ))}
      </OrderedList>
    </>
  );
};
