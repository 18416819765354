import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Flex,
  Input,
  InputRightElement,
  Box,
  FormLabel,
  InputGroup,
} from '@chakra-ui/react';
import {
  FlowResponse,
  Provider,
  useGetPaymentProviderListQuery,
} from '~/src/api';
import Loading from '~/src/components/Loading';
import { CardContentStack } from '~src/components/Card';
import '~/src/theme/stylesheets/paymentStyles.scss';
import { PaymentProviderButton } from '~/src/components/SurveyFlow/Payment/PaymentProviderButton';
import { FlowItemTitle } from './components/FlowItemTitle';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemImage } from './components/FlowItemImage';
//import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { z } from 'zod';
import { BiCheck } from 'react-icons/bi';
import { devlogger } from '~src/utils';
import { makeUniqueId } from '@apollo/client/utilities';
import { CreatePaymentButton } from './Payment/CreatePaymentButton';
import { PaymentTerms } from './Payment/PaymentTerms';

export const KUURA_CUSTOMER_SERVICE_EMAIL = 'info@kuurahealth.com';
type EmailData = {
  email: string;
  valid: boolean;
  validDefault?: boolean;
};
const emailSchema = z.object({
  email: z.string().email(),
});

export const Payment: React.FC<FlowResponse> = ({ flowItem, id }) => {
  const { t } = useTranslation();

  const { title, description, imgUrl, paymentProps } = flowItem || {};
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>();
  const [error, setError] = useState<Error | string | boolean | null>(null);
  const [emailData, setEmailData] = useState<EmailData>(() => {
    const valid = emailSchema.safeParse({
      email: paymentProps?.customerEmail,
    }).success;
    return {
      email: paymentProps?.customerEmail || '',
      valid,
      validDefault: valid,
    };
  });

  const urlParams = new URLSearchParams(location.search);
  const isRetry = urlParams.get('retry');

  const { data, loading } = useGetPaymentProviderListQuery({
    variables: {
      input: {
        amount: Number(paymentProps?.price),
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted() {},
    onError() {
      devlogger('Error in getPaymentProviderListQuery');
      setError(true);
    },
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailData({ email, valid: emailSchema.safeParse({ email }).success });
  };

  const { paymentProviderList } = data || {};
  const PaymentProviders = paymentProviderList?.providers ? (
    <>
      {(paymentProviderList.providers || []).map((providerData: Provider) => (
        <PaymentProviderButton
          key={makeUniqueId('provider-button-component-')}
          name={providerData.name}
          svg={providerData.svg}
          createPayment={() => setSelectedProvider(providerData)}
        />
      ))}
    </>
  ) : null;

  const descriptionText = description && description[0] ? description : [''];

  return (
    <CardContentStack mb={[4, null, 8]}>
      <FlowItemTitle title={title || t('payment.title')} />
      <FlowItemImage imgUrl={imgUrl} />
      <FlowItemDescription description={descriptionText} />

      {!emailData?.validDefault && (
        <InputGroup width="100%" maxWidth="650px" flexDirection={'column'}>
          <FormLabel textAlign={'center'} fontWeight={'normal'}>
            {t('payment.emailLabel')}
          </FormLabel>
          <Input
            type={'email'}
            value={emailData.email}
            onChange={(e) => handleEmailChange(e)}
            placeholder={'tyytyväinen@asiakas.fi'}
            borderRadius="full"
            autoComplete="off"
            borderColor={
              emailData.email && !emailData.valid ? 'red.500' : undefined
            }
          />
          {emailData.email && emailData.valid && (
            <InputRightElement fontSize="2em" mt="1.1em" mr="0.2em">
              <BiCheck color="green" />
            </InputRightElement>
          )}
        </InputGroup>
      )}
      {(!!error || isRetry) && (
        <Alert status="error" maxWidth={'640px'}>
          <AlertIcon />
          <AlertDescription mr={1}>
            <>
              {t(error ? 'payment.error.title' : 'payment.failedDescription')}
              <br />
              <a
                href={`mailto:${KUURA_CUSTOMER_SERVICE_EMAIL}?subject=${t(
                  'payment.errorEmailTitle',
                )}&body=${t('payment.errorEmailBody', { answerId: id })}`}
                style={{ marginRight: 2 }}
              >
                {KUURA_CUSTOMER_SERVICE_EMAIL}
              </a>
            </>
          </AlertDescription>
          {/*error && (
            <Tooltip label={error?.message || ''} placement="top">
              {/* wrap icon with span to prevent ref problem * /}
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          )*/}
        </Alert>
      )}

      <PaymentTerms
        paymentTerms={paymentProps?.paymentTerms || ''}
        paytrailTerms={paymentProviderList?.terms || ''}
      />
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        gap="8px"
        width="100%"
        maxWidth="650px"
        position="relative"
      >
        {PaymentProviders}
        {!emailData.valid && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundColor="rgba(255, 255, 255, 0.6)"
            zIndex="overlay"
            cursor="not-allowed"
          />
        )}
      </Flex>
      {((loading && !paymentProviderList?.providers) || selectedProvider) && (
        <Flex
          align="center"
          background="whiteAlpha.600"
          height="100%"
          justify="center"
          position="absolute"
          width="100%"
          zIndex="overlay"
          p={100}
        >
          <Loading />
        </Flex>
      )}
      {selectedProvider && (
        <CreatePaymentButton
          id={id || ''}
          paymentData={{
            selectedProvider,
            email: emailData.email,
            paymentProps,
          }}
          setError={(errorPayload) => {
            setSelectedProvider(null);
            setError(errorPayload);
          }}
        />
      )}
    </CardContentStack>
  );
};

export default Payment;
