import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Icon,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';

type LinkProps = {
  /** Should link text be in bold */
  isBold?: boolean;
  /** Type of the Link. Default is normal, other options are React Router Link and HashLink */
  type?: 'normal' | 'router' | 'hash';
  /** Target address for normal link */
  href?: string;
  /** Target address for React Router and HashLink*/
  to?: string; // Required since included in Chakra's Link props only when as={ReactRouterLink}
} & ChakraLinkProps;

export const Link: React.FC<LinkProps> = ({
  isBold,
  type,
  children,
  ...rest
}) => {
  const props: {
    fontWeight?: string;
    as?: typeof ReactRouterLink | typeof HashLink;
  } = {};
  if (isBold === true) props.fontWeight = 'bold';
  if (type === 'router') props.as = ReactRouterLink;
  if (type === 'hash') props.as = HashLink;
  return (
    <ChakraLink overflowWrap="anywhere" {...props} {...rest}>
      {children}
      {rest.isExternal && <Icon as={FiExternalLink} mx={1} mb={'0.15rem'} />}
    </ChakraLink>
  );
};
