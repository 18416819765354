import React from 'react';
import { Button, Link, Stack } from '@chakra-ui/react';
import {
  FlowItemSearchProps,
  Maybe,
  Options,
  SearchLinksStyles,
} from '~/src/api';

interface SearchOptionsProps {
  options: [Options];
  handleClick: (value: Options) => void;
  searchProps?: Maybe<FlowItemSearchProps>;
}

const SearchOptions: React.FC<SearchOptionsProps> = ({
  options,
  handleClick,
  searchProps,
}) => {
  const { linksStyle } = searchProps || {};
  const showLinksAsButton = linksStyle === SearchLinksStyles.Button;

  return (
    <Stack
      spacing="2"
      pt={6}
      width="fit-content"
      margin={showLinksAsButton ? 'auto' : 0}
    >
      {options?.map((option, i) => {
        if (!option) return null;
        return showLinksAsButton ? (
          <Button
            key={option.value + i}
            onClick={() => handleClick(option)}
            display="inline-flex"
          >
            {option.label}
          </Button>
        ) : (
          <Link
            key={option.value + i}
            as="button"
            onClick={(e) => {
              e.preventDefault();
              return handleClick(option);
            }}
            color="primary.500"
            textAlign="left"
          >
            {option.label}
          </Link>
        );
      })}
    </Stack>
  );
};

export { SearchOptions };
