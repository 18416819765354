import React, { FC, ReactNode } from 'react';
import {
  Center,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';

export const Section: FC<
  {
    name: string;
    toUpper?: boolean;
    youthContent?: ReactNode;
    guardianContent?: ReactNode;
  } & FlexProps
> = ({ name, toUpper = true, youthContent, guardianContent, ...rest }) => {
  return (
    <Flex direction={['column', null, null, 'row']} mt={8} {...rest}>
      <Grid
        templateColumns="4fr minmax(6rem, 1fr)"
        templateRows="56px auto"
        gap={0}
        flex={1}
      >
        <Flex
          bg="blue.700"
          borderLeftRadius={6}
          alignItems="center"
          lineHeight="1.4"
        >
          <Text as="h3" size="md" px={4} color="white">
            {toUpper !== false ? name.toUpperCase() : name}
          </Text>
        </Flex>
        <GridItem
          w="100%"
          bg="blue.700"
          alignSelf="center"
          justifySelf="center"
        >
          <Center color="white" h="56px">
            Nuori
          </Center>
        </GridItem>
        {youthContent}
      </Grid>
      <Grid
        templateColumns="3fr minmax(6rem, 1fr) minmax(6rem, 1fr)"
        templateRows="56px auto"
        gap={0}
        flex={1}
      >
        <GridItem bg="blue.700"></GridItem>
        <GridItem w="100%" bg="blue.700">
          <Center color="white" h="56px">
            Huoltaja 1
          </Center>
        </GridItem>
        <GridItem w="100%" bg="blue.700" borderRightRadius={6}>
          <Center color="white" h="56px">
            Huoltaja 2
          </Center>
        </GridItem>
        {guardianContent}
      </Grid>
    </Flex>
  );
};
