import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, InputMaybe, InputType, Options } from '~/src/api';
import { richTextParser } from '~/src/utils';
import { radioStyles } from '~src/configurations';
import { FlowItemDescription } from '../../components/FlowItemDescription';

interface YedOptionProps {
  currentValue: InputMaybe<string> | undefined;
  onChange: (nextValue: any) => void;
  idx: number;
}

const YedOptions: React.FC<InputField & YedOptionProps> = ({
  type,
  text,
  label,
  placeholder,
  options,
  currentValue,
  onChange,
  idx,
}) => {
  const { t } = useTranslation();

  if (!options) return null;

  const inputOptions = options as Options[];

  const answerOptions = inputOptions.map(({ value, label }, i) => {
    const key = `yed-${idx}-${type.toLowerCase()}-option-${i}`;

    if (type === InputType.Radio) {
      return (
        <Radio
          key={key}
          value={value}
          py="0.5rem"
          id={key}
          size={radioStyles?.size ?? 'md'}
          style={{ ...radioStyles }}
        >
          {richTextParser(label, { allowAttr: ['className'] })}
        </Radio>
      );
    }

    if (type === InputType.Dropdown) {
      return (
        <option key={key} value={value} id={key}>
          {label}
        </option>
      );
    }

    return (
      <Checkbox key={key} value={value} py="0.5rem" id={key}>
        {richTextParser(label)}
      </Checkbox>
    );
  });

  const optionStack = <Stack spacing={1}>{answerOptions}</Stack>;

  const optionGroup =
    type === InputType.Radio ? (
      <RadioGroup
        onChange={(value) => onChange([value])}
        value={currentValue ?? ''}
      >
        {optionStack}
      </RadioGroup>
    ) : type === InputType.Dropdown ? (
      <Select
        placeholder={
          placeholder ?? t('common.dropdownDefaultPlaceholder') ?? undefined
        }
        value={currentValue ?? ''}
        onChange={(ev) => onChange([ev.target.value])}
        width="fit-content"
        mx="auto"
        mt={4}
      >
        {answerOptions}
      </Select>
    ) : (
      <CheckboxGroup onChange={onChange}>{optionStack}</CheckboxGroup>
    );

  return (
    <Box mb="5">
      <FlowItemDescription description={[text || '']} />
      <FormControl mt={2} as="fieldset" className="yed-option-group">
        {label && (
          <FormLabel textAlign="center" as="legend">
            {label}
          </FormLabel>
        )}
        {optionGroup}
      </FormControl>
    </Box>
  );
};

export default YedOptions;
