import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import {
  AnswerInput,
  InputField,
  InputType,
  Maybe,
  QuestionType,
  YedQuestion,
} from '~src/api';
import YedOptions from '../YedQuestion/YedOptions';
import YedRadioGrid from '../YedQuestion/YedRadioGrid';
import { YedSlider } from '../YedQuestion/YedSlider/YedSlider';
import YedTextInput from '../YedQuestion/YedTextInput';

type FlowItemMultiFieldsProps = {
  inputs: YedQuestion['inputs'];
  multiFieldValues: AnswerInput[];
  setMultiFieldValues: React.Dispatch<React.SetStateAction<AnswerInput[]>>;
};
type Mapper = {
  [K in InputType]: {
    element:
      | typeof YedTextInput
      | typeof YedOptions
      | typeof YedRadioGrid
      | typeof YedSlider;
    answerType: QuestionType;
  };
};

const mapper: Mapper = {
  [InputType.Text]: { element: YedTextInput, answerType: QuestionType.Text },
  [InputType.Textarea]: {
    element: YedTextInput,
    answerType: QuestionType.Text,
  },
  [InputType.Radio]: { element: YedOptions, answerType: QuestionType.Radio },
  [InputType.Checkbox]: {
    element: YedOptions,
    answerType: QuestionType.Checkbox,
  },
  [InputType.Dropdown]: {
    element: YedOptions,
    answerType: QuestionType.Dropdown,
  },
  [InputType.RadioGrid]: {
    element: YedRadioGrid,
    answerType: QuestionType.Radio,
  },
  [InputType.Phonenumber]: {
    element: YedTextInput,
    answerType: QuestionType.Text,
  },
  [InputType.Email]: {
    element: YedTextInput,
    answerType: QuestionType.Text,
  },
  [InputType.Slider]: {
    element: YedSlider,
    answerType: QuestionType.Slider,
  },
};

export const isAllQuestionAnswered = (
  inputFields: Array<string | InputField>,
  allAnswers: AnswerInput[],
) =>
  inputFields.every((input) => {
    const currentAnswer = allAnswers?.find(
      (answer) =>
        answer?.name === (typeof input === 'string' ? input : input.name),
    );
    return (
      // Undefined, empty string or array indicates the question is not answered
      (currentAnswer && currentAnswer.value) ||
      // Dropdown is optional to answer
      (typeof input === 'object' && input?.type === InputType.Dropdown)
    );
  });

const FlowItemMultiFields: React.FC<FlowItemMultiFieldsProps> = ({
  inputs,
  multiFieldValues,
  setMultiFieldValues,
}) => {
  const updateInputValueArray = (
    input: InputField,
    value?: string | string[] | undefined,
  ) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const newValues = valueArray.map((value) => ({
      name: input.name,
      type: mapper[input.type].answerType,
      value: value?.toString(),
    }));

    // Replace old input.name entries with new ones
    const newMultiFieldValues = structuredClone(multiFieldValues)
      .filter((newInput) => newInput.name !== input.name)
      .concat(newValues);

    setMultiFieldValues(newMultiFieldValues);
  };

  const inputStack = inputs?.reduce(
    (arr: React.ReactNode[], input: Maybe<InputField>, idx: number) => {
      if (!input) return arr;

      const inputType = input?.type;

      const currentValue = multiFieldValues.find(
        (currentInput) => currentInput.name === input.name,
      );
      const Element = mapper[inputType]?.element;
      Element &&
        arr.push(
          <Box mb={1} key={`yed-text-${idx}`}>
            <Element
              {...input}
              onChange={(value: string | string[]) =>
                updateInputValueArray(input, value)
              }
              currentValue={currentValue?.value ?? undefined}
              idx={idx}
            />
          </Box>,
        );

      return arr;
    },
    [],
  );

  return (
    <Stack width={'100%'} pt={[0, 1, 1]}>
      {inputStack}
    </Stack>
  );
};

export { FlowItemMultiFields };
