export default {
  parts: ['container', 'button', 'panel', 'icon'],
  baseStyle: {
    container: {
      border: 'none',
      mb: 3,
    },
    button: {
      fontWeight: 'bold',
      fontSize: 'md',
      color: 'primary.600',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'none',
      },
    },
    panel: {
      pt: 0,
      ml: '34px',
    },
    icon: {
      color: 'gray.500',
      width: '0.8rem',
      height: '0.8rem',
      mr: 5,
    },
  },
};
