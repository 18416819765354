import type { IConfigurationPartial } from '~/src/types';
import { Locale, Configuration } from '~/src/types';
import { localizations } from '~/src/localizations';

const configuration: IConfigurationPartial = {
  startMenu: 'savuton',
  searchEnabled: false,
  flowInit: {
    cardShadow: 'none',
    cardStackPadding: 0,
  },
  translations: {
    supportedLanguages: [Locale.FI],
    resources: localizations[Configuration.Tupakkalakko] ?? {},
  },
};

export default configuration;
