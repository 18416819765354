import React from 'react';
import { VscTriangleRight } from 'react-icons/vsc';
import {
  useToken,
  Box,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemState,
} from '@chakra-ui/react';

type accordionDataItem = {
  title: string | JSX.Element | null;
  content: string | JSX.Element;
};

export type accordionData = { accordionData: accordionDataItem[] };

export function CustomAccordion({ accordionData }: accordionData): JSX.Element {
  return (
    <Accordion defaultIndex={[]} allowMultiple>
      {accordionData.map((data, idx) => (
        <CustomAccordionItem
          title={data.title}
          content={data.content}
          key={idx}
        />
      ))}
    </Accordion>
  );
}

function CustomAccordionItem({ title, content }: accordionDataItem) {
  return (
    <AccordionItem>
      <h1>
        <AccordionButton>
          <CustomAccordionIcon />
          <Box flex="1" textAlign="left">
            {title}
          </Box>
        </AccordionButton>
      </h1>
      <AccordionPanel pb={4}>{content}</AccordionPanel>
    </AccordionItem>
  );
}

function CustomAccordionIcon() {
  const { isOpen, isDisabled } = useAccordionItemState();

  const [color, width, height, mr] = useToken(
    'components.Accordion.baseStyle.icon',
    ['color', 'width', 'height', 'mr'],
  );

  const iconStyles = {
    opacity: isDisabled ? 0.4 : 1,
    transform: isOpen ? 'rotate(90deg)' : undefined,
    transition: 'transform 0.2s',
    transformOrigin: 'center',
    color,
    width,
    height,
    mr,
  };

  return (
    <Icon as={VscTriangleRight} __css={iconStyles}>
      {' '}
    </Icon>
  );
}
