import { extendTheme, ThemeOverride } from '@chakra-ui/react';

import Accordion from './components/accordion';
import Button from './components/button';
import FormLabel from './components/formLabel';
import Input from './components/input';
import Link from './components/link';
import NumberInput from './components/numberInput';
import Text from './components/text';
import Textarea from './components/textarea';
import Heading from './components/heading';
import Radio from './components/radio';
import Checkbox from './components/checkbox';

import { colors, fonts, styles } from '~/src/configurations';

const overrides: ThemeOverride = {
  borders: {
    base: '1px solid #E9F4FF',
  },
  colors,
  components: {
    Accordion,
    Button,
    FormLabel,
    Input,
    Link,
    NumberInput,
    Text,
    Textarea,
    Heading,
    Radio,
    Checkbox,
  },
  fonts: {
    heading: fonts?.heading ?? 'Quicksand, sans-serif',
    body: fonts?.body ?? 'Quicksand, sans-serif',
  },
  textStyles: {},
  shadows: {
    base: '0 0 14px 0 rgba(0, 0, 0, 0.1)',
  },
  styles: {
    global: {
      body: {
        fontWeight: '500',
        marginBottom: '2rem', // Extra padding for scroll when submit is fixed
        background: styles.pageBgColor,
      },
      'strong, b': {
        fontWeight: 'bold',
      },
      'ul, ol': {
        marginTop: '.2rem',
        paddingLeft: '1.3rem',
        'li + li': {
          marginTop: '.1rem',
        },
      },
      'img, svg': {
        verticalAlign: 'middle',
      },
    },
  },
};

export default extendTheme(overrides);
