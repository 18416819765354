{
  "common": {
    "pageTitle": "Therapy Navigator",
    "app_name": "Therapy Navigator",
    "app_organization": "First-Line Therapies",
    "close": "Close",
    "continue": "Continue",
    "dropdownDefaultPlaceholder": "Choose an option",
    "charactersRemaining": "Characters remaining",
    "allAnswersRequired": "All answers required to continue",
    "navigateBack": "Go back"
  },
  "errors": {
    "upcomingMaintenance": "A maintenance break is starting in the service, and it is therefore not possible to start a new survey now. The maintenance break ends at approximately",
    "generic": {
      "title": "An unexpected error occurred",
      "message": "Oops, something went wrong. Try again later."
    },
    "network": {
      "title": "Connection error",
      "message": "Check that you are connected to the internet."
    },
    "errorCard": {
      "title": "Oops, something went wrong.",
      "paragraph": "The content you are looking for is not available.",
      "ctaLabel": "Return to the front page"
    },
    "sessionClosed": {
      "title": "The session is closed",
      "message": "The content is no longer available."
    }
  },
  "infoModal": {
    "title": "Frequently Asked Questions",
    "paragraphContent": [
      "<strong>What do I do if none of the answers are suitable?</strong><br />Please answer the best that you can so that you can continue to fill in the assessment. When you are seeking help, a professional will still meet with you to assess your situation. You will then be able to complete or correct your information as needed.",
      "<strong>I lost my answers, are they stored somewhere? / Can I get a new code?</strong><br />If you filled out the Therapy Navigator and collected the code at the end, you can use the code to download your report. For data security reasons, answers can only be returned with a code and only for a period of one month. If there is a situation in which your access to the the Therapy navigator is interrupted, there are technical problems preventing the code from being received, or the code is lost, unfortunately the code cannot be retrieved. We apologize for the inconvenience caused by these situations and urge you to complete the Therapy Navigator again if necessary. Fortunately, completing it the second time is often faster.",
       "A healthcare professional can assess your situation even without navigator data, even in healthcare units that use the Therapy Navigator. However, more time should be allocated for the assessment.",
       "<strong>Does the Therapy Navigator have to be fully completed?</strong><br />In order to use the information from the Therapy Navigator in your needs assessment for mental health care, it must be completely filled out and submitted. The Therapy Navigator can seem burdensome, especially if a lot of the navigator's symptom questionnaires are filled out. It is best to just answer as quickly and as best as you can to move through the questionnaires quickly.",
       "<strong>What should I note about data security?</strong><br />Anonymous information is filled into the Therapy Navigator. The intention is that the person completing it cannot be identified. Do not fill in the open text fields of the navigator with any identifying personal information, such as your name or email address. Your response report can only be opened at the end with the code you received. You should therefore be careful when storing the code."
    ],
    "openButtonAriaLabel": "Open Service info"
  },
  "intro": {
    "title": "Therapy Navigator",
    "paragraph": "",
    "linkText": "About the Therapy Navigator"
  },
  "flowInit": {
    "title": "Welcome to the Therapy Navigator!",
    "paragraph": [
      "This survey maps your life situation and any problems or difficulties you may have experienced. This information is gathered so that you can be directed to the most appropriate help/support for your situation."
    ],
    "privacyAcceptance": "I agree to the processing of the data I provide in accordance with the <linkOut>data protection statement</linkOut>",
    "notice": "Note the following:",
    "noticeList": [
      "<strong>The Therapy Navigator is completed anonymously.</strong> Do not write your name, social security number or any of your contact information in the answers in the navigator.",
       "<strong>It takes approximately 20-30 minutes to complete the Therapy Navigator.</strong> Your answers cannot be saved while filling out the Navigator.",
       "<strong>Store your personal code,</strong> which you will receive after completing the Therapy Navigator. HINT: Take a screenshot or a picture of the code with your phone.",
       "<strong>Then you can send the code to a healthcare professional </strong> who will review the Therapy Navigator results with you. The code is active for one month"
     ],
    "warningBox": [
      "If you have suicidal thoughts or are unsure about your safety, tell your family member, a friend and/or a professional, and apply directly for an assessment by a health care professional, for example at a health center, occupational health centre or student health care centre. Always tell a professional about your suicidal thoughts so that you can get the best possible help for your situation.",
       "If you are in a situation where you need immediate help, call or ask someone close to you to call 112 to get emergency medical help."
     ]
  },
  "terapia": {
    "startButtons": {
      "start": "Begin",
      "startTest": "I just want to try out the Therapy Navigator",
      "openResults": "Open your results with a code",
      "viewExample": "View an example of the results",
      "professionalInfo": "Info for health care professionals",
      "accessibilityStatement": "Accessibility Statement"
    },
    "professionalInfo": {
      "title": "Info for health care professionals",
      "paragraphs": [
         "The Therapy Navigator is a digital tool that speeds up the needs assessment for mental health care.",
         "Therapy Navigator collects the relevant preliminary information about the client's situation and the results of the symptom measures and summarises them for the professional and the client. The professional uses the information collected by the therapy navigator to carry out an assessment of treatment needs, called the <strong>First Assessment</strong>.",
         "In most cases the client is directed to fill in the Therapy Navigator before the first contact with the healthcare professional carrying out the First Assessment. After completing the Therapy Navigator, the client is given an 8-digit code to unlock their results.",
         "You can open the client's results at <linkIn to='/report'>https://www.terapianavigattori.fi/report</linkIn> with the code provided by the client. The code automatically opens a summary report of the client's results. You can also access each of the client's responses individually by opening <strong>the comprehensive report.</strong>."
       ],
      "reportSections": {
        "subtitle": "The Therapy Navigator summary report consists of the following sections:",
        "sections": [
          "Problem description and seeking treatment",
          "Questionnaires",
          "Social and occupational functioning",
          "Current preferences for treatment",
          "Need for social, work and study services",
          "Previous psychological treatments"
        ]
      },
      "instructions": {
        "subtitle": "In the First Assessment, focus on the following",
        "list": [
          {
            "text": "Confirm the target symptom and the severity of the symptoms",
            "subList": [
              "together with the client, go through the questionnaire results",
              "interview the client about the severity of the symptoms in more detail",
              "describe how these symptoms typically manifest themselves in life",
              "ask for which symptom the client is primarily seeking help for and to change"
            ]
          },
          {
            "text": "Performance assessment",
            "subList": [
              "does the client's social and occupational functioning appear as moderate or better",
              "or whether the client describes these as poorer"
            ]
          },
          {
            "text": "Has the client had previous psychological care?",
            "subList": [
              "has there been previous engagement in low-threshold or evidence-based treatments?"
            ]
          },
          {
            "text": "Treatment preferences",
            "subList": [
              "which low-threshold treatments would the client seeking help think they would benefit from?"
            ]
          },
          {
            "text": "Expand on the following if they are present in the Therapy Navigator's answers",
            "subList": [
              "what kind of traumatic crisis/life crisis has the client experienced? How has it affected them?",
              "specify what kind of sleep problems the client has",
              "substance abuse problems and addictions",
              "suicidality: any suicidal thoughts or plans? Is there suicidal intention present? Has a suicide method been considered? Any previous attempts?",
              "assess the need for referral to other services (social services, occupational health care, student health care, TE office, child protection)"
             ]
          }
        ]
      },
      "paragraphs2": [
        "Depending on the nature of the client's problems and symptoms, and their preferences for treatment, you can refer them to either 1) immediate low-threshold treatments or 2) a more detailed assessment / more intensive treatments.",
        "The Therapy Navigator can also be used appropriate tool to support symptom assessment at a basic level. In this case, it is important to inform the client about the purpose of the Therapy Navigator."
       ]
    },
    "openReport": {
      "title": "Open the results",
      "description": "Enter the code connected to your Therapy Navigator results below to open your results again.",
      "formLabel": "Code to open the results",
      "openBtn": "Open the results"
    },
    "report": {
      "title": "Therapy Navigator results",
      "description": "The code for these results is <strong>{{id}}</strong>. With this code, you can return to your results later via the \"View results\" link on the Therapy Navigator front page.",
       "toc": "Content",
      "copyToClipboard": "Copy to clipboard",
      "downloadPdf": "Download all results as a pdf-file",
      "pdfInfoLink": "Information on interpretating the results of the Therapy Navigator can be found at",
      "pdfInfoLinkUrl": "https://www.terapianavigaattori.fi/report/instructions",
      "printSummary": "Print the summary report",
      "pointAbbreviation": "p",
      "not-found": "The report cannot be found with the given code"
    },
    "reportSummary": {
      "title": "",
      "subtitles": {
        "description": "Problem description and seeking treatment",
        "questionnaires": "Questionnaires",
        "performance":  "Social and occupational functioning",
        "treatments": "Treatments you are ready for",
        "supportNeeds": "Need for social, work and study services",
        "previousTreatments":  "Previous psychological care"
      },
      "questionnaireDisclaimer": "<strong>NOTE:</strong>The scores from the questionnaires describe your problems and symptoms at the time of the completion of the Therapy Navigator. They do not directly imply that you have a diagnosable mental disorder. The actual diagnosis of a disorder is always based on an assessment by a healthcare professional.",
       "labels": {
        "coupleTherapy": "Short couple or family therapy",
        "violenceNote": "NOTE: Domestic violence / threat of violence response",
        "sleep": "Sleeping problems question",
        "limitGaming": "Readiness to reduce gambling",
        "crisis": "Life crisis question",
        "crisisEvent": "Crisis due to an exceptionally shocking event",
        "exhausted": "Fatigue",
        "drugs": "Drugs and medicine",
        "addiction": "Functional dependencies question",
        "dangerousThoughts": "Thoughts that you would be better off dead or of hurting yourself in some way (PHQ-9)",
        "crisisDescription": "Life crisis clarification question",
        "crisisEventDescription": "Crisis due to an exceptionally shocking event clarification question",
        "drugsDescription": "Drugs and medicine clarification question ",
        "workPerformance": "Performance at work or studies during the last month",
        "relationships": "Interpersonal relationships during the last month",
        "webTherapy": "Guided digital therapies",
        "groupTherapy": "Group therapy",
        "selfcare": "Guided self-help",
        "exercise": "Physical exercise",
        "drugReview": "Request for a medication review",
        "residence": "Need for additional support regarding housing or family-related challenges",
        "violence": "Domestic violence or a threat of violence",
        "workAndStudyStatus": "Work and study status",
        "workAndStudyStatusDetailed": "Clarification on work and study status",
        "supportForWorkAndStudy": "Need for additional support regarding work or study-related challenges",
        "previousTreatments": "Previous psychological treatments",
        "previousTreatmentsDuration": "Length of previous treatments",
        "previousTreatmentsDescription": "Clarification regarding previous treatments",
        "noPreviousTreatments": "No previous psychological treaments"
      }
    },
    "reportFull": {
      "title": "Comprehensive report",
      "description": "If you wish, you can view all the answers you have given, as well as the calculation of your scores on the symptom questionnaires and the scoring times, in the the comprehensive report. It also shows which answers have been used to select the additional questions that will be asked.",
      "openLink": "Open the comprehensive report",
      "scores": "scores",
      "scoreLimits": "cut-off scores",
      "result": "Results",
      "scoreCalculationError": "Error in score calculations!"
    },
    "reportInstructions": {
      "title": "How to read the results of the Therapy Navigator: choosing the right treatment",
      "description": "If you have completed the Therapy Navigator at the request of a healthcare professional, you do not need to independently interpret your results. Instead, a healthcare professional will do this with you as part of your healthcare assessment. The professional only requires the code indicated at the top of this page in order to do this. However, if you wish, you can read more about the principles used to interpret the results and how to identify appropriate treatment.",
      "description2": "Likewise, if you have completed the Therapy Navigator independently, there's no need for you to interpret the results on your own or decide in advance about the treatments you want. The intention is for you to seek assistance when you want to get help and that together with a healthcare professional can use the summary to select the right treatment.",
      "openLink": "Open interpretation instructions for the results"
    },
    "reportFeedback": {
      "title": "Feedback on the Therapy Navigator",
      "description": "",
      "feedbackQuestion": "How did you experience the Therapy Navigator and the summary report?",
      "feedbackOptions": [
        "Just what I needed, it summed up my situation",
        "Beneficial and useful",
        "I can't say",
        "I didn't find it beneficial or useful"
      ],
      "thankyou": "Thank you for the feedback!"
    },
    "reportSelfCareLinks": {
      "title": "Self-help materials",
      "paragraphs": [
        "Here, we have compiled general self-care materials for you. They contain both information and exercises to support your recovery. The results from the Therapy Navigator can help assess which self-care materials might support you best.",
        "If you wish, you can start reading the self-care materials before you start treatment. This can be useful for as your treatment progresses. The self-care materials can also be used as part of your treatment with a healthcare professional."
      ],
      "links": [
        {
          "text": "Self-Help Program for Depression (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/masennuksen-omahoito-ohjelma"
        },
        {
          "text": "Self-Help Program for Anxiety",
          "address": "https://www.mielenterveystalo.fi/en/self-help/self-help-program-anxiety"
        },
        {
          "text": "Self-Help Program for Social Anxiety (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/sosiaalisen-jannittamisen-omahoito-ohjelma"
        },
        {
          "text": "Self-Help Program for Panic Disorder (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/paniikin-omahoito-ohjelma"
        },
        {
          "text": "Self-Help Program for Obsessive-Compulsive Disorder (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/pakko-oireiden-omahoito-ohjelma"
        },
        {
          "text": "Self-Help Program for Insomnia (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/unettomuuden-omahoito-ohjelma"
        },
        {
          "text": "Self-Help Program for a Shocking Event (in Finnish)",
          "address": "https://www.mielenterveystalo.fi/fi/omahoito/jarkyttavan-tapahtuman-omahoito-ohjelma-mieli-ry"
        }
      ]
    },
    "tests": {
      "phq": {
        "name": "PHQ-9",
        "longName": "Patient Health Questionnaire 9",
        "limits": [
          "indicates no or minimal depressive symptoms",
          "indicates <strong>mild depressive symptoms</strong>",
          "indicates <strong>moderate depressive symptoms</strong>",
          "indicates <strong>moderately severe depressive symptoms</strong>",
          "indicates <strong>severe depressive symptoms</strong>"
        ]
      },
      "gad": {
        "name": "GAD-7",
        "longName": "Generalized Anxiety Disorder 7",
        "limits": [
          "indicates no or minimal anxiety",
          "indicates <strong>mild anxiety </strong>",
          "indicates <strong>moderate anxiety </strong>",
          "indicates <strong>severe anxiety</strong>"
        ]
      },
      "oci": {
        "name": "OCI-R",
        "longName": "Obsessive-Compulsive Inventory",
        "limits": [
          "does not indicate a clinically significant level of obsessive thoughts or compulsive behaviour",
          "indicates a <strong>clinically significant level of obsessive thoughts and/or compulsive behaviour.</strong>"
        ]
      },
      "pdss": {
        "name": "PDSS-SR",
        "longName": "Panic Disorder Severity Scale",
        "limits": [
          "no indication of panic disorder",
          "indicates <strong>mild panic disorder</strong>",
          "indicates <strong>moderate panic disorder</strong>",
          "indicates <strong>moderately severe panic disorder</strong>",
          "indicates <strong>severe panic disorder</strong>",
          "indicates <strong>very severe panic disorder</strong>"
        ]
      },
      "spin": {
        "name": "SPIN",
        "longName": "Social Phobia Inventory",
        "limits": [
          "indicates the absence of or mild level of social anxiety symptoms",
          "indicates <strong>a moderate level of social anxiety symptoms</strong>",
          "indicates <strong>a severe level of social anxiety</strong>",
          "indicates <strong>a very severe level of social anxiety symptoms</strong>"
        ]
      },
      "audit": {
        "name": "AUDIT-C",
        "longName": "Alcohol Use Disorders Identification Test",
        "limits": [
          "below the cut-off score for risk consumption",
          "below the cut-off score for risk consumption of alcohol for men",
          "indicates <strong>an increased risk for harmful alcohol consumption for women</strong>",
          "indicates <strong>an increased risk for harmful alcohol consumption</strong>"
        ]
      },
      "ies": {
        "name": "IES-R",
        "longName": "Traumaperäisen stressin kysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään traumaperäiseen stressiin",
          "voi viitata <strong>kliinisesti merkittävään traumaperäiseen stressiin</strong>"
        ]
      },
      "keds": {
        "name": "KEDS",
        "longName": "Uupumuskysely",
        "limits": [
          "ei viittaa kliinisesti merkittävään uupumukseen",
          "viittaa <strong>kliinisesti merkittävään uupumukseen</strong>"
        ]
      },
      "auditFull": {
        "name": "AUDIT",
        "longName": "Alcohol Use Disorders Identification Test",
        "limits": [
          "no alcohol use",
          "<strong>low-risk alcohol consumption</strong>",
          "<strong>low-risk alcohol consumption for men</strong>",
          "<strong>increased risk of harmful alcohol consumption for women</strong>",
          "<strong>increased risk of harmful alcohol consumption</strong>",
          "<strong>significantly increased risk of harmful alcohol consumption</strong>",
          "<strong>higher risk of harmful alcohol consumption</strong>",
          "<strong>very high-risk alcohol consumption (possible alcohol dependence)</strong>"
        ]
      },
      "bbgs": {
        "name": "BBGS",
        "longName": "Brief Biosocial Gambling Screen",
        "limits": [
          "does not indicate gambling -related problems",
          "indicates <strong>potential gambling-related problems</strong>"
        ]
      },
      "bat": {
        "name": "BAT-12",
        "longName": "Burnout Assessment Tool",
        "limits": [
          "indicates no risk for burnout",
          "indicates <strong>an increased risk for burnout</strong>",
          "indicates <strong>a very high risk of burnout</strong>"
        ]
      },
      "tsq": {
        "name": "TSQ",
        "longName": "Trauma Screening Questionnaire",
        "limits": [
          "indicates no risk for post-traumatic stress disorder",
          "indicates <strong>an increased risk for post-traumatic stress disorder</strong>"
        ]
      },
      "dudit": {
        "name": "DUDIT",
        "longName": "Drug Use Disorders Identification Test",
        "limits": [
          "no drug-related problems",
          "<strong>occasional drug-related problems</strong>",
          "<strong>occasional drug-related problems for men</strong>",
          "<strong>occasional drug-related problems for women</strong>",
          "<strong>likely drug-related problems</strong>",
          "<strong>likely to have a strong drug dependence</strong>"
        ]
      }
    },
    "instructions": {
      "title": "INTERPRETING THE THERAPY NAVIGATOR RESULTS - Choosing an appropriate treatment approach",
      "description": "The Therapy Navigator serves two purposes: to help you identify your difficulties and potential treatment options, and to help a healthcare professional guide you quickly towards appropriate help. Therefore, you don't need to interpret the responses on your own or decide in advance what treatment you want. The report is intended to provide you with an opportunity to reflect on these options if you wish to do so. The intention is for you to seek assistance when you're ready and, together with a healthcare professional, use the summary to help you choose the right treatment.",
      "interpretSymptomsTitle": "1) Interpret your symptoms and their severity",
      "interpretSymptomsContent": [
        "Go through the summary report of your Therapy Navigator results and the 'Questionnaires' section. The scores from the symptom measures provide information on what symptoms treatment could target, the severity of those symptoms, and whether substance abuse may impact the treatment plan",
        "It's common for difficulties to manifest through various symptoms, such as symptoms of depression and anxiety. If you have multiple symptoms, consider which one is your main symptom. It is usually the most severe symptom  and the symptom which would be most beneficial to receive treatment for.",
        "Treating the main symptom often brings alleviation of other symptoms, but particular attention should be paid to insomnia and substance abuse symptoms. These symptoms may not automatically improve with the alleviation of depression or anxiety."
      ],
      "viewTreatmentsTitle": "2) Go through the treatment options",
      "viewTreatmentsContent": [
        "After reviewing your comprehensive report and considering on which main symptom you would like the treatment to focus on, please see below for psychological treatment options that can help you with your difficulties. The most appropriate treatment approach will ultimately be selected in collaboration with a healthcare professional",
        "<i>* When you seek treatment, a professional will will work with you to make a more detailed assessment of your current condition and help you determine which symptoms should be the main focus of treatment. Healthcare professionals often use the symptom questionnaires used in the Therapy Navigator as part of more detailed assessments</i>"
      ],
      "listTreatmentsTitle": "Treatment options for different difficulties",
      "addictionTitle": "When difficulties are accompanied by a substance abuse problem or another addiction",
      "addictionDescription": "Problems with addiction can make it difficult for a person to benefit from psychological care. When both a mental health problem and substance abuse problem are present, both problems should be addressed systematically. It is a good idea in this case to seek a thorough assessment from a professional. In cases of mild to moderate alcohol-related issues, incorporating psychological treatment can also involve using resources such as the guided digital therapy for excessive alcohol use</linkOut> and the <linkOut href='https://www.mielenterveystalo.fi/aikuiset/itsehoito-ja-oppaat/itsehoito/juomisen_hallinnan_opas/Pages/default.aspx'>Self-Care Program for Managing Drinking (in Finnish)</linkOut> on Mielenterveystalo.fi, along with in-person treatments involving a professional with expertise in substance abuse and mental health treatments.",
      "specialNotesTitle": "Special Considerations",
      "supportNeedsTitle": "Need for Social, Occupational, and Student Healthcare Services",
      "supportNeedsDescription": "In challenges related to housing, livelihood, family, and substance abuse, it's a good idea to assess what support is available from social services in addition to healthcare. For challenges related to work and studies, it's often advisable for the public healthcare provider to work with occupational or student healthcare services. The Employment and Economic Development Office (TE Office) provides support in employment-related matters. Effective collaboration and planning between different services helps to build a functioning service package.",
      "previousTreatmentsTitle": "Previous treatment",
      "previousTreatmentsDescription1": "If there has been previous treatment, the appropriate treatment options should be assessed on an individual basis:",
      "previousTreatmentsList": [
        "Are there any treatments that have been helpful in the past?",
        "Are there treatment methods that have not worked as expected?",
        "What would be the most suitable treatment at this stage?"
      ],
      "previousTreatmentsDescription2": "Sometimes there are several attempts to treat the condition, which have not resulted in alleviation of symptoms. The difficulties may have persisted for a long time and remained mostly unchanged between treatment methods. In such cases, a more detailed assessment of the need for different services is necessary. Additionally, the support options provided by social services and mental health organizations should considered (for example, support activities by associations like Mieli ry, Finnish Association for Mental Health, and Mielenterveyspooli).",
      "depressionAccordion": "Symptoms of depression, anxiety, panic, insomnia and fatigue",
      "depressionTitle": "Selecting appropriate psychological treatment: Depression, Anxiety, Panic Symptoms, Insomnia, and Fatigue",
      "depressionMediumSymptomsTitle": "Mild and moderate symptoms",
      "depressionMediumSymptomsContent1": "When difficulties with depression, anxiety, panic symptoms, insomnia, or fatigue are mild to moderate, and functioning is at least moderate, effective psychological treatment options within public healthcare may include",
      "depressionMediumSymptomsList": [
        "<linkOut href='https://www.mielenterveystalo.fi/nettiterapiat/Pages/default.aspx'>guided digital therapies*</linkOut>",
        {
          "listItem": "guided self-help",
          "subItems": [
            "<small>Not yet in all services utilising the Therapy Navigator</small>"
          ]
        },
        "<strong>group therapy</strong>",
        {
          "listItem": "<strong>short couple or family therapy interventions</strong>",
          "subItems": [
            "<small>Suitable for couple- and family-oriented issues when there is no violence or its threat involved in the relationship.</small>",
            "<small>Not yet in all services utilising the Therapy Navigator</small>"
          ]
        }
      ],
      "depressionMediumSymptomsContent2": "In addition, healthy and regular <strong>exercise</strong> is recommended as a complementary treatment. Group exercise is particularly recommended.",
      "depressionMediumSymptomsContent3": "If one or more of the above-mentioned treatments have been tried and have not yielded the desired benefit, <strong>short-term cognitive therapy</strong> or <strong>brief psychotherapy</strong>, for example, may be necessary. Short-term cognitive therapy is a goal-oriented psychological treatment lasting approximately 5-10 sessions and is provided by a trained healthcare professional.",
      "depressionMediumSymptomsContent4": "* There is no guided digital therapy for fatigue.",
      "depressionSevereSymptomsTitle": "Severe symptoms or poor social and occupational functioning (no previous attempts at treatment)",
      "depressionSevereSymptomsContent1": "If symptoms related to depression, anxiety, panic, insomnia, or fatigue are severe, or if functional ability is quite poor, a more detailed assessment of treatment options is recommended. Usually, it is also a good idea to conduct a medication assessment and to determine whether specialist psychiatric care is needed. Quite often, suitable psychological treatment is either",
      "depressionSevereSymptomsShortTherapy": "brief psychotherapy or",
      "depressionSevereSymptomsLongTherapy": "long-term psychotherapy",
      "depressionSevereSymptomsContent2": "In addition, incorporating tailored, healthy, and regular <strong>exercise</strong> is recommended as complementary treatment. Group exercise is particularly recommended.",
      "crisisAccordion": "Crisis Situations and Trauma-related Symptoms",
      "crisisTitle": "Selecting appropriate psychological treatment: Crisis Situations and Trauma-related Symptoms",
      "crisisSituationsTitle": "Crisis Situations",
      "crisisSituationsContent": [
        "One way to understand crises is to divide them into life crises and traumatic crises. Life crises can include events such as divorce, retirement, job loss, or the death of a loved one from a long-term illness. Traumatic crises, on the other hand, often involve a life-threatening situation such as a severe car accident or fire, violence, rape or sexual abuse, or the threat of these.",
        "In life crises such as divorce or job loss, crisis support and counseling offered by mental health organizations and foundations can often be helpful, especially if there are clearly no additional symptoms such as depression, anxiety, or substance use issues. Support from social services may also help. If other symptoms are significant, psychological treatment offered by healthcare professionals may be appropriate. The choice of suitable treatment depends on the nature and severity of the symptoms.",
        "In a traumatic crisis, short-term <strong>psychological therapy</strong> with a trained professional specializing in crisis intervention is recommended, if necessary. Generally, the goal of treatment is to support natural crisis coping mechanisms and strengthen the person's coping skills."
      ],
      "crisisTraumaTitle": "Trauma-related symptoms",
      "crisisTraumaContent1": "Trauma-related symptoms can also be related to events that happened months or even years ago, such as life-threatening violence, rape or sexual abuse. The main psychological treatment methods for trauma-related symptoms are",
      "crisisTraumaList1": "<strong>trauma-focused cognitive psychotherapy </strong> and",
      "crisisTraumaList2": "<strong>EMDR Eye Movement Desensitization and Reprocessing therapy</strong>.",
      "crisisTraumaContent2": "EMDR therapy is provided by a specially trained mental health professional. Trauma-focused cognitive psychotherapy is typically offered by cognitive psychotherapists, cognitive behavioral therapy psychotherapists, or other psychotherapists who have received additional training in the area. Healthy exercise and yoga are recommended complementary treatments.",
      "ocdAccordion": "Obsessive-Compulsive Symptoms",
      "ocdTitle": "Selecting Appropriate Psychological Treatment: Obsessive-Compulsive Symptoms",
      "ocdContent1": "Recommended psychological treatment methods include guided digital therapy for obsessive-compulsive disorder and exposure-based cognitive behavioral therapy.",
      "ocdList1": "<strong>guided digital therapy for obsessive-compulsive disorder </strong> and",
      "ocdList2": "<strong>exposure-based cognitive behavioral therapy.</strong>",
      "ocdContent2": "Exposure-based cognitive behavioral therapy is generally provided by cognitive psychotherapists, cognitive-behavioral psychotherapists, or other psychotherapists who have received additional training in the area."
    }
  }
}
