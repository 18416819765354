import React, { useState } from 'react';
import {
  Heading,
  Text,
  List,
  OrderedList,
  ListItem,
  Stack,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Container from '~/src/components/Container';
import Card, { CardContentStack } from '~/src/components/Card';
import { useI18nUtils } from '~src/hooks/i18nUtils';
import { Link } from '~/src/components/Link';
import { envs } from '~/src/utils';
import { StartMenuButtons } from '~src/components/StartMenu/StartMenuButtons';
import { MenuItemAction } from '~/src/api';
import { AcceptPrivacyCheckbox } from '~/src/screens/Home/AcceptPrivacyCheckbox';
import { H3 } from '~/src/components/Headings/Typography';

const Info: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { getI18nArrayOfStrings, getI18nArrayOfStringsWrapped, getI18nObject } =
    useI18nUtils();

  return (
    <Container className="noPaddingTopForPrint" px={[4, 8, 12]} py={[4, 8, 12]}>
      <Card className="reportCard">
        <CardContentStack className="noPaddingTopForPrint" align="flex-start">
          <Heading
            as="h1"
            size="xl"
            marginBottom={4}
            textAlign="center"
            w="100%"
          >
            {t(`terapia.professionalInfo.title`)}
          </Heading>

          {envs.CONFIGURATION === 'Interventionavigaattori' ? (
            <InterventioButtons />
          ) : null}

          {i18n.exists('terapia.professionalInfo.subtitle') && (
            <SubTitle>{t(`terapia.professionalInfo.subtitle`)}</SubTitle>
          )}
          {getI18nArrayOfStringsWrapped(
            `terapia.professionalInfo.paragraphs`,
            Text,
            true,
            {
              linkIn: <Link type="router" isBold />,
            },
          )}

          {i18n.exists('terapia.professionalInfo.howSubtitle') && (
            <SubTitle>{t(`terapia.professionalInfo.howSubtitle`)}</SubTitle>
          )}
          {i18n.exists('terapia.professionalInfo.howParagraphs') &&
            getI18nArrayOfStringsWrapped(
              'terapia.professionalInfo.howParagraphs',
              Text,
            )}

          <SubTitle>
            {t(`terapia.professionalInfo.reportSections.subtitle`)}
          </SubTitle>
          {i18n.exists('terapia.professionalInfo.reportSections.paragraphs') &&
            getI18nArrayOfStringsWrapped(
              'terapia.professionalInfo.reportSections.paragraphs',
              Text,
            )}

          <List
            {...(envs.CONFIGURATION === 'Interventionavigaattori'
              ? { as: 'ol', listStyleType: 'decimal' }
              : { as: 'ul', listStyleType: 'initial' })}
          >
            {getI18nArrayOfStrings(
              `terapia.professionalInfo.reportSections.sections`,
            ).map((el, idx) => (
              <ListItem key={idx}>{el}</ListItem>
            ))}
          </List>

          <SubTitle>
            {t(`terapia.professionalInfo.instructions.subtitle`)}
          </SubTitle>

          <OrderedList>
            {getI18nObject(
              `terapia.professionalInfo.instructions.list`,
              isListWithSublistTranslation,
            )?.map((el, idx) => {
              const listItem = <ListItem key={idx}>{el.text}</ListItem>;

              if (Array.isArray(el.subList)) {
                return (
                  <React.Fragment key={'el-fragment' + idx}>
                    {listItem}
                    <List
                      {...(envs.CONFIGURATION === 'Interventionavigaattori'
                        ? { as: 'ul', listStyleType: 'initial' }
                        : {
                            as: 'ol',
                            listStyleType: 'lower-alpha',
                            ml: '1em',
                          })}
                    >
                      {el.subList.map((subEl, idx) => (
                        <ListItem key={'subEl' + idx}>{subEl}</ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                );
              } else {
                return listItem;
              }
            })}
          </OrderedList>
          {i18n.exists('terapia.professionalInfo.paragraphs2') &&
            getI18nArrayOfStringsWrapped(
              'terapia.professionalInfo.paragraphs2',
              Text,
            )}
        </CardContentStack>
      </Card>
    </Container>
  );
};

export default Info;

const SubTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Heading as="h2" size="lg" pt={8} textAlign="left">
    {children}
  </Heading>
);

function isListWithSublistTranslation(
  obj: unknown,
): obj is Array<{ text: string; subList?: Array<string> }> {
  // Must be an array
  if (!Array.isArray(obj)) return false;
  // Verify every item in the array
  return obj.every((el: unknown) => {
    // Must be object
    if (el == null || typeof el !== 'object') return false;
    // Must have text property
    if (!('text' in el) || typeof el.text !== 'string') return false;
    // subList property is optional
    if (!('subList' in el) || el.subList === undefined) return true;
    // If subList is present it must be an array of strings
    if (
      Array.isArray(el.subList) &&
      el.subList.every((s) => typeof s === 'string')
    )
      return true;
    return false;
  });
}

const openResultsButtons = [
  {
    translationKey: 'terapia.startButtons.openResultsCombination',
    action: MenuItemAction.Url,
    actionParameter: '/report?reportType=combination',
  },
  {
    translationKey: 'terapia.startButtons.viewExample',
    action: MenuItemAction.Url,
    actionParameter:
      '/report/summary?reportType=combination&youthId=esimerkki-nuori&guardianId1=esimerkki-huoltaja&guardianId2=esimerkki-huoltaja2',
    variant: 'link',
  },
];

const testQuestions = [
  {
    label: 'Nuoren interventionavigaattori',
    action: MenuItemAction.Question,
    actionParameter: 'hus-terapia-nuori#privacy-ok-test',
    variant: 'link',
  },
  {
    translationKey: 'Huoltajan interventionavigaattori',
    action: MenuItemAction.Question,
    actionParameter: 'hus-terapia-huoltaja#privacy-ok-test',
    variant: 'link',
  },
];

const InterventioButtons: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isMaintenanceComing = !!envs.UPCOMING_MAINTENANCE_COMPLETED_AT;
  const isMenuDisabled = isMaintenanceComing ? true : !termsAccepted;

  return (
    <Flex width="100%" direction="column" alignItems="center">
      <Stack spacing="2" pt={6} alignItems="center">
        <StartMenuButtons startMenu={openResultsButtons} />
        <Box maxW="22rem">
          <H3 my={2}>Kokeile navigaattorin täyttämistä</H3>
          <AcceptPrivacyCheckbox
            termsAccepted={termsAccepted}
            setTermsAccepted={setTermsAccepted}
          />
        </Box>
        <StartMenuButtons
          startMenu={testQuestions}
          isMenuDisabled={isMenuDisabled}
          isMaintenanceComing={isMaintenanceComing}
        />
      </Stack>
    </Flex>
  );
};
