import React from 'react';
import Container from '~/src/components/Container';
import Card, { CardContentStack } from '~/src/components/Card';
import { ReportType, ReportViewType } from '~/src/types';
import { useGetReportInfoFromParams } from '~/src/hooks/useGetReportInfoFromParams';
import { TerapiaReport } from './TerapiaReport';
import { YouthReport } from './YouthReport';
import { ReportNotFound } from './components/ReportNotFound';
import { GuardianReport } from './GuardianReport';
import { CombinationReport } from './CombinationReport';

const Report: React.FC<{ view: ReportViewType }> = ({ view }) => {
  const { id, reportType, youthId, guardianId1, guardianId2 } =
    useGetReportInfoFromParams();

  return (
    <Container
      className="noPaddingTopForPrint"
      maxWidth={
        reportType === ReportType.Combination ? 'container.xl' : undefined
      }
      key={id}
      px={[4, 8, 12]}
      py={[4, 8, 12]}
    >
      <Card className="reportCard">
        <CardContentStack className="noPaddingTopForPrint">
          {reportType === ReportType.Default ? (
            <TerapiaReport view={view} id={id} />
          ) : reportType === ReportType.Youth ? (
            <YouthReport view={view} id={id} />
          ) : reportType === ReportType.Guardian ? (
            <GuardianReport view={view} id={id} />
          ) : reportType === ReportType.Combination ? (
            <CombinationReport
              view={view}
              youthId={youthId ?? undefined}
              guardianId1={guardianId1 ?? undefined}
              guardianId2={guardianId2 ?? undefined}
            />
          ) : (
            <ReportNotFound id={id ?? ''} />
          )}
        </CardContentStack>
      </Card>
    </Container>
  );
};

export default Report;
