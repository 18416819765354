export function isValidUrl(str: any) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function ensureUrlProtocol(url: string) {
  return url.startsWith('http') ? url : 'https://' + url;
}
