import { TFuncKey } from 'i18next';
import React, { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';

type Translation = string | Translation[] | { [key: string]: Translation };

export function useI18nUtils() {
  const { t } = useTranslation();

  function getI18nArrayOfStringsWrapped(
    key: string, // i18n translation key
    Wrapper?: React.FC<{ children: ReactNode }>, // wrap each array element to this component
    useTrans?: boolean, //defaults to true, only applicable if Wrapper is defined
    components?: Record<string, JSX.Element>, // Trans component property, only applicable if useTrans is true
  ) {
    const arr = getI18nArrayOfStrings(key);

    if (Wrapper) {
      return arr.map((text, idx) => (
        <Wrapper key={'i18nArray' + idx}>
          {useTrans === false ? (
            text
          ) : (
            <Trans components={components}>{text}</Trans>
          )}
        </Wrapper>
      ));
    }

    return arr;
  }

  function getI18nArray(key: string): Translation[] {
    const strArr = t<TFuncKey, Translation | null>(key, {
      returnObjects: true,
    });
    const arr = strArr == null ? [] : Array.isArray(strArr) ? strArr : [strArr];
    return arr;
  }

  function getI18nArrayOfStrings(key: string): string[] {
    const arr = getI18nArray(key);

    if (!isStringArray(arr))
      throw new Error(`Given translation key doesn't contain array of strings`);

    return arr;
  }

  function getI18nObject<T>(key: string, isTypeT: (obj: unknown) => obj is T) {
    const obj = t<TFuncKey, Translation | null>(key, { returnObjects: true });
    if (isTypeT(obj)) return obj;
    // eslint-disable-next-line no-console
    console.error('Invalid translation structure');
    return null;
  }

  function isStringArray(array: Array<unknown>): array is Array<string> {
    return Array.isArray(array) && array.every((a) => typeof a === 'string');
  }

  return {
    getI18nArrayOfStringsWrapped,
    getI18nArray,
    getI18nArrayOfStrings,
    getI18nObject,
  };
}
