import React from 'react';
import { useTranslation } from 'react-i18next';

export const ReportNotFound = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  return (
    <>
      {t('terapia.report.not-found')} ({id})
    </>
  );
};
