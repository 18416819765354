import React from 'react';
import { Box, Checkbox } from '@chakra-ui/react';
import { Link } from '~/src/components/Link';
import { privacyRoot } from '~/src/constants';
import { Trans } from 'react-i18next';

export const AcceptPrivacyCheckbox: React.FC<{
  termsAccepted: boolean;
  setTermsAccepted: (val: boolean) => void;
}> = ({ termsAccepted, setTermsAccepted }) => (
  <Checkbox
    p={2}
    pb={0}
    alignItems="flex-start"
    isChecked={termsAccepted}
    onChange={(e) => setTermsAccepted(e.target.checked)}
  >
    <Box mt="-5px" ml="0.3rem">
      <Trans
        i18nKey="flowInit.privacyAcceptance"
        components={{ linkOut: <Link href={`/${privacyRoot}`} isExternal /> }}
      />
    </Box>
  </Checkbox>
);
