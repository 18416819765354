import React, { useContext } from 'react';
import { Box, Flex, Icon, Image, VisuallyHidden } from '@chakra-ui/react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import LanguageSwitcher from '~/src/components/LanguageSwitcher/LanguageSwitcher';
import { header } from '~/src/configurations';
import InfoToggle from '~/src/components/InfoToggle';
import { AppContext } from '~src/state';
import { Locale } from '~/src/types';
import { getSessionStorage } from '~src/utils/storage';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ensureUrlProtocol } from '~src/utils/isValidUrl';
import { LogoutButton } from '../LogoutButton';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    state: { logo: customLogoUrl },
  } = useContext(AppContext);

  const { appLogoTranslated, appLogo, returnLinkVisible } = header;

  const defaultLogoUrl =
    appLogoTranslated[i18next.language as Locale] || appLogo;
  const logoUrl = customLogoUrl || defaultLogoUrl;

  // Pages with no header shown on mobile, e.g. '/chat'
  const noHeaderShownOnMobile: string[] = [];

  const headerDisplay = noHeaderShownOnMobile.includes(location.pathname)
    ? ['none', null, 'flex']
    : 'flex';

  const redirectUrlFromStorage = getSessionStorage('redirect_url');
  const isReturnLinkVisible =
    returnLinkVisible && redirectUrlFromStorage && location.pathname !== '/';

  return (
    <Flex
      as="header"
      alignItems="center"
      p={4}
      height="4.7rem"
      display={headerDisplay}
      mb={4}
    >
      {isReturnLinkVisible && (
        <a
          href={ensureUrlProtocol(redirectUrlFromStorage)}
          style={{ position: 'absolute', top: '25px', left: '16px' }}
        >
          <Flex
            color="primary.800"
            gap="1"
            flexDirection="row"
            alignItems="center"
          >
            <Icon as={MdKeyboardArrowLeft} height={6} width={6} />
            <Box>Palaa</Box>
          </Flex>
        </a>
      )}
      <LanguageSwitcher isReturnLinkVisible={!!isReturnLinkVisible} />
      <Box position="relative" margin="0 auto">
        <Link to="/">
          <VisuallyHidden>{t('common.app_name')}</VisuallyHidden>
          {/* If first FlowItem is still loading customLogoUrl is undefined
              --> delay showing logo until we know which logo to show to prevent
               flickering. Dash is used to disable logo. */}
          {customLogoUrl !== undefined && logoUrl && logoUrl !== '-' && (
            <Image
              src={logoUrl}
              width={header.appLogoWidth}
              paddingTop={header.appLogoPaddingTop}
              height="auto"
              alt={
                // Show organization name as alt text only if custom logo is not set
                logoUrl === defaultLogoUrl
                  ? t('common.app_organization') ?? ''
                  : ''
              }
            />
          )}
        </Link>
      </Box>
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        ml="-6"
        mt={{ base: '16px', sm: '0px' }}
        className="hideWhenPrinting"
      >
        <LogoutButton />
        {header.showInfoToggle && <InfoToggle />}
      </Flex>
    </Flex>
  );
};

export default Header;
