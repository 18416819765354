export default {
  // Generated with https://smart-swatch.netlify.app/ and all colors moved to
  // one level higher to get 600 match brand color as in other palettes
  brand: {
    50: '#ffeadc',
    100: '#ffeadc',
    200: '#ffc9af',
    300: '#ffa67e',
    400: '#ff844c',
    500: '#ff611a',
    600: '#FD4F00', // <-- #e64800 replaced with brand color
    700: '#b43700',
    800: '#812700',
    900: '#4f1600',
  },
};
// Official brand color: #FD4F00
