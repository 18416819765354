import React from 'react';
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Box,
  TextProps,
} from '@chakra-ui/react';
import Container from '~/src/components/Container';
import Card from '~/src/components/Card';

export const TerapiaPrivacySV: React.FC = () => {
  return (
    <Container>
      <Card>
        <Box textAlign="left" p={[4, 6, 8]}>
          <Heading as="h1" size="xl" pb={8}>
            Terapi­navigatorn – Data­skydds­beskrivning
          </Heading>
          <CustomText>
            Data­skydds­beskrivning upprättad den 15 februari 2023
          </CustomText>
          <CustomText>
            Data­skydds­beskrivning uppdaterad den 13 april 2023
          </CustomText>
          <SubTitle>Personuppgifts­ansvarig</SubTitle>
          <CustomText>
            <strong>Namn:</strong>
            <br />
            HUS-sammanslutningen
          </CustomText>
          <CustomText>
            <strong>Adress:</strong>
            <br />
            HUS-sammanslutningen,
            <br />
            Stenbäcksgatan 9, Helsingfors
            <br />
            PB 100, 00029 HUS
            <br />
          </CustomText>
          <CustomText>
            <strong>Kontaktuppgifter:</strong>
            <br />
            Växel: 09 4711
            <br />
            Registratorkontorets telefax: 09 471 75500
            <br />
            Registratorkontorets e-post: keskuskirjaamo@hus.fi
            <br />
            Postadress: HUS centralregistratorkontor, PB 200, 00029 HUS
          </CustomText>
          <SubTitle>Kontaktperson i registerärenden</SubTitle>
          Sektorchef Jesper Ekelund
          <SubTitle>Kontaktuppgifter till dataskyddsombudet</SubTitle>
          <CustomText>
            <strong>Postadress:</strong>
            <br />
            HUS centralregistratorkontor
            <br />
            PB 200 00029 HUS
          </CustomText>
          <CustomText>
            <strong>E-post:</strong>
            <br />
            eutietosuoja@hus.fi
          </CustomText>
          <SubTitle>Kontaktuppgifter i ärenden som rör registret</SubTitle>
          <CustomText>
            Första linjens terapier: terapiatetulinjaan@hus.fi
          </CustomText>
          <SubTitle>Ändamålet med behandling av personuppgifter</SubTitle>
          <CustomText>
            Terapinavigatorn är avsedd för personer som söker sig till social-
            och hälsovårdstjänster på grund av psykiska problem och fylls i före
            bedömning av behov av stöd, vård eller service. Terapinavigatorn
            samlar in information som användaren sedan kan ge vidare till den
            som utför bedömningen.
          </CustomText>
          Informationen används inte för automatiserat beslutsfattande eller
          profilering.
          <SubTitle>Grunden för behandling av personuppgifter</SubTitle>
          <CustomText>
            Den rättsliga grunden för behandlingen av personuppgifter är EU:s
            allmänna dataskyddsförordning, artikel 9 (Behandling av särskilda
            kategorier av personuppgifter) punkt 2 a användaren av
            Terpinavigatorn ger sitt uttryckliga samtycke till att hens
            uppgifter behandlas.
          </CustomText>
          <SubTitle>Information i registret</SubTitle>
          <CustomText pb={0}>Uppgifter som sparas i registret:</CustomText>
          <UnorderedList>
            <ListItem>användarens beskrivning av sin egen situation</ListItem>
            <ListItem>
              svar på frågeformulär som kartlägger psykiska störningar och
              problem
            </ListItem>
            <ListItem>
              svar på frågor om t.ex. arbetsförmåga, tidigare vård och
              vårdpreferenser
            </ListItem>
            <ListItem>
              svar på valfri fråga om vilket välfärdsområde användaren bor inom
            </ListItem>
            <ListItem>
              kod bestående av 8 tecken, med vilken användaren får tillgång till
              sina svar och den automatiskt genererade rapporten under en månads
              tid.
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            Terapinavigatorn samlar inte in identifierande information om
            användaren. Den 8-siffriga koden ges till användaren när hen fyllt i
            Terapinavigatorn till slut. Användaren är personligen ansvarig för
            att förvara koden och den registeransvarige kan inte återställa en
            borttappad kod.
          </CustomText>
          <SubTitle>Informationskällor</SubTitle>
          <CustomText>
            Informationen som lagras består av användarens svar i
            Terapinavigatorn och den slumpmässigt genererade koden på 8 tecken
            som användaren får.
          </CustomText>
          <SubTitle>Grupper som får tillgång uppgifter</SubTitle>
          <CustomText>
            Uppgifterna lagras i Googles datacenter i Fredrikshamn.
          </CustomText>
          <CustomText>
            Personer inom social- och hälsovårdstjänsterna kan få tillgång till
            uppgifterna endast om användaren ger dem koden med vilka användarens
            svar och rapporten som sammanställs på basis av dem kan öppnas.
            Koden är i kraft i en månads tid från tidpunkten användaren fyllt i
            Terapinavigatorn.
          </CustomText>
          <CustomText>
            Data som lagras i systemet kan endast nås av systemadministratören
            via en ssh-anslutning, samt av projektgruppen Första linjens
            terapier, som kan ladda ner en rapport om användningen av
            Terapinavigatorn, där uppgifterna anonymiseras. Terapinavigatorn är
            byggd och upprätthålls av Kuura Health Oy.
          </CustomText>
          <SubTitle>Överläming av uppgifter</SubTitle>
          <CustomText>
            Den personuppgiftsansvarige lämnar inte regelbundet ut information
            till andra parter. En person som har fyllt i Terapinavigatorn och
            har fått en kod på 8 tecken kan genom att dela koden välja att dela
            sina uppgifter med en tredje part.
          </CustomText>
          <SubTitle>Lagringstid</SubTitle>
          <CustomText>
            Insamlade uppgifter sparas i trettioen (31) dagar, varefter
            uppgifterna anonymiseras permanent genom att koden på 8 tecken och
            svar på öppna frågor tas bort. Efter att denna information har
            raderats är informationen inte längre tillgänglig för användaren.
          </CustomText>
          <SubTitle>Dataöverföring utanför EU eller EES</SubTitle>
          <CustomText>
            Den personuppgiftsansvarige överför inte uppgifter till länder
            utanför EU eller EES.
          </CustomText>
          <CustomText>
            Leverantören av den molntjänst som används för att lagra
            personuppgifter (Google) har en teknisk kontakt med sitt datacenter
            i Finland för att kunna utföra bland annat tekniskt underhåll från
            länder utanför EU/EES-området. Trots att det är möjligt att nå
            datacentret från tredje länder utanför EU/EES-området, har den
            registeransvarige på basis av den konsekvensbedömning med avseende
            på dataskydd som utförts, att detta inte utgör en risk för personer
            som använder Terapinavigatorn. Informationen som lagras och
            principerna för hur den skyddas finns beskrivna på andra ställen i
            den här dataskyddsbeskrivningen.
          </CustomText>
          <SubTitle>Principer för registerskydd</SubTitle>
          <CustomText>
            Aktsamhet iakttas vid behandling av registret och de uppgifter som
            behandlas med hjälp av informationssystem är ändamålsensligt
            skyddade. När registerdata lagras på Internetservrar sköts den
            fysiska och digitala datasäkerheten för deras hårdvara på ett
            ändamålsenligt sätt. Den personuppgiftsansvarige säkerställer att
            lagrade uppgifter samt serveråtkomsträttigheter och annan
            information som är avgörande för skyddet av personuppgofter hanteras
            konfidentiellt och endast av de anställda i vilkas arbetsbeskrivning
            det ingår.
          </CustomText>
          <SubTitle>Den registrerades rättigheter</SubTitle>
          <CustomText pb={0}>
            Den registrerade personen har följande rättigheter:
          </CustomText>
          <UnorderedList>
            <ListItem>
              Rätt att återkalla sitt samtycke till behandling av sina
              personuppgifter (artikel 7)
            </ListItem>
            <ListItem>
              Den registrerades rätt till tillgång (artikel 15)
              <br />
              den registrerade kan göra en skriftlig informationsbegäran
              gällande sina egna uppgifter
            </ListItem>
            <ListItem>
              Rätt till rättelse (artikel 16)
              <br />
              den registrerade kan lämna in en skriftlig begäran om rättelse
            </ListItem>
            <ListItem>
              Rätt till radering (artikel 17)
              <br />
              den registrerade kan kräva radering av onödiga eller felaktiga
              uppgifter med hänsyn till syftet med registret
            </ListItem>
            <ListItem>
              Rätt till begränsning av behandling (artikel 18)
              <br />
              den registrerade kan göra ett anspråk i fritt format, som alltid
              behandlas från fall till fall
            </ListItem>
            <ListItem>
              Rätt att göra invändningar (artikel 21)
              <br />
              den registrerade kan göra ett anspråk i fritt format, som alltid
              behandlas från fall till fall
            </ListItem>
            <ListItem>
              Rätten att överföra uppgifter från ett system till ett annat
              (artikel 20), den registrerade kan göra en begäran i fritt format,
              som alltid behandlas från fall till fall.
            </ListItem>
          </UnorderedList>
          <CustomText pt={4}>
            Ansökningar eller krav avseende ovan nämnda rättigheter ska lämnas
            till den instans som anges i avsnittet &quot;Kontaktuppgifter i
            ärenden som rör registret&quot;.
          </CustomText>
          <CustomText>
            Om den personuppgiftsansvarige kan visa att den inte kan identifiera
            den registrerade är artiklarna 15–20 inte tillämpliga, såvida inte
            den registrerade, för att utöva sina rättigheter enligt dessa
            artiklar, tillhandahåller ytterligare information genom vilken han
            kan identifieras (artikel 11 punkt 2).
          </CustomText>
          <SubTitle>
            Rätt att lämna in klagomål till tillsynsmyndigheten
          </SubTitle>
          <CustomText>
            Varje registrerad har rätt att lämna in ett klagomål till en
            tillsynsmyndighet, särskilt i den medlemsstat där hans/hennes
            permanenta bostad eller arbetsplats är eller där den påstådda
            kränkningen har skett, om den registrerade anser att behandlingen av
            personuppgifter som rör honom/henne bryter mot EU:s
            dataskyddsförordning, utan att det påverkar andra administrativa
            överklaganden eller rättsmedel.
          </CustomText>
          <CustomText>Uppgifter om tillsynsmyndigheten:</CustomText>
          <CustomText>
            <strong>Dataombudsmannens byrå</strong>
            <br />
            https://tietosuoja.fi/etusivu
            <br />
            Besöksadress: Fågelviksgränden 4, 00530 Helsingfors
            <br />
            Postadress: Box 800, 00531 Helsingfors
            <br />
            E-post: tietosuoja(at)om.fi Telefonväxel: 029 566 6700
            <br />
            Registratorkontor: 029 566 6768
          </CustomText>
        </Box>
      </Card>
    </Container>
  );
};

const SubTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Heading as="h2" size="lg" py={4} textAlign="left">
    {children}
  </Heading>
);

const CustomText: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text pb={4} {...rest}>
    {children}
  </Text>
);
