import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';
import { reportRoot } from '~/src/constants';
import { Link } from '~/src/components/Link';

export const Instructions: FC<{ title: { text: string; id: string } }> = ({
  title,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading as="h2" size="lg" mt={16} mb={8} id={title.id}>
        {title.text}
      </Heading>
      <Text>{t('terapia.reportInstructions.description')}</Text>
      <Text my={5}>{t('terapia.reportInstructions.description2')}</Text>
      <Text>
        <Link href={`/${reportRoot}/instructions`} isBold isExternal>
          {t('terapia.reportInstructions.openLink')}
        </Link>
      </Text>
    </>
  );
};
