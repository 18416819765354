import { Container as ChakraContainer, BoxProps } from '@chakra-ui/react';
import React from 'react';

const Container: React.FC<BoxProps> = ({ maxWidth, ...props }) => {
  return (
    <ChakraContainer
      maxWidth={maxWidth ?? 'container.lg'}
      {...props}
      p={props.padding ?? [2, 4, 6]}
    >
      {props.children}
    </ChakraContainer>
  );
};

export default Container;
