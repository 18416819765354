import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';

import { FlowItemDescription } from '../../components/FlowItemDescription';
import { InputField, InputMaybe, Options } from '~/src/api';
import FlowItemSlider, {
  SliderOption,
} from '~/src/components/formComponents/Slider';

interface YedOptionProps {
  currentValue: InputMaybe<string> | undefined;
  onChange: (value: string) => void;
}

const YedSlider: React.FC<InputField & YedOptionProps> = ({
  //type,
  text,
  label,
  //placeholder,
  options,
  currentValue,
  onChange,
}) => {
  if (
    !options ||
    (typeof currentValue !== 'string' && currentValue !== undefined)
  )
    return null;

  const sliderOptions = options
    .map((option: Options) => ({
      value: option.value,
      label: option.label,
    }))
    .reverse();

  if (!sliderOptions) return null;
  return (
    <Box mb="5">
      <FlowItemDescription description={[text || '']} />
      <FormControl mt={2} as="fieldset">
        {label && <FormLabel as="legend">{label}</FormLabel>}
        <FlowItemSlider
          options={sliderOptions}
          currentValue={currentValue}
          onChange={(option: SliderOption) => {
            onChange(option.value);
          }}
          label={label || ''}
        />
      </FormControl>
    </Box>
  );
};

export { YedSlider };
